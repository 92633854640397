<div class="modal-background change-email-container">
    <app-popup-nav [title]="(('Common.Email' | translate) + ' ' + ('Common.Modify' | translate))"
        style="width: 100%"></app-popup-nav>
    <div class="change-email-inner">
        <div class=" change-email-icon-wrap">
            <img src="/assets/icon/email_change.svg" />
        </div>
        <h1 class="change-email-title">
            <span [innerHTML]="('Common.Email'|translate) + ' '">이메일</span>
            <span [innerHTML]="'Common.Modify'|translate">변경</span>
        </h1>

        <div class="change-email-notice"
            [innerHTML]="'Account.InputPasswordForChangeEmail'|translate:{email:email}">
            EMAIL 을 사용하시려면 비밀번호를 입력하세요.
        </div>

        <div class="change-email-input-wrap">
            <input #input_password type="password" class="change-email-input"
                [placeholder]="'Common.Password'|translate" (click)="hideMessge()" />
        </div>

        <div #error class="change-email-error common-vertical-alignment">
            <div class="change-email-error-message" [innerHTML]="errMsg"></div>
        </div>

        <div class="change-email-button-wrap common-vertical-alignment">
            <button class="common-button1" [innerHTML]="'Verification.ChangeEmail'|translate"
                (click)="changeEmail()">이메일 변경
                완료하기</button>
            <button [innerHTML]="(desktopSize ? 'Account.Cancel'
            : 'Account.GoToMainPage'
         ) | translate" class="common-button2"
                (click)="desktopSize? close() : goToMain()">돌아가기</button>
        </div>

    </div>
    <div *ngIf="showResult" class="modal-background-alert-popup">
        <div class="password-popup-inner modifyuser-popup-msg">
            <div [innerHTML]="message1">이메일 변경이 완료 되었습니다.</div>
            <div [innerHtml]="message2">다시 로그인 해주시기 바랍니다.</div>
            <div class="common-button1" [innerHTML]="'Receipt.Close'|translate" (click)="close()">닫기
            </div>
        </div>
    </div>
</div>