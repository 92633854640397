import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { Util, UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-input',
    template: `
<div style="min-height:65px; padding:8px; 15px;" >
    <div #border class="uk-width-expand uk-inline signup-input-border">
        <div class="uk-position-center-left uk-margin-left uk-text-small">{{title}}</div>
        <input [type]="(type==='PASSWORD' || type === 'PASSWORD_CONFIRM')?'password':'text'"
            class="uk-input signup-input" [placeholder]="placeholder" (input)="onInput($event)"
            [style.width]="title===''?'90%':'70%'"
            [style.margin]="title===''?'auto':'0 0 0 30%'" />
        <img #loadingspiner *ngIf="emailCheckSpinner" style="display: none;" src="assets/icon/signup_email_spin.gif">
    </div>
    <div #description class="uk-text-center signup-input-description">{{desc}}</div>
</div>
    `
})
export class InputComponent implements OnInit, OnChanges {

    @ViewChild('border') borderElement!: ElementRef<HTMLDivElement>;
    @ViewChild('description') descElement!: ElementRef<HTMLDivElement>;
    @ViewChild('loadingspiner') loadingElement?: ElementRef<HTMLImageElement>;

    @Input() placeholder = 'please input your email';
    @Input() title = 'email'
    @Input() desc = 'use valid email address';
    @Input() status: 'NORMAL' | 'OK' | 'WARN' = 'NORMAL';
    @Input() type: 'TEXT' | 'PASSWORD' | 'PASSWORD_CONFIRM' | 'EMAIL_EXIST' | 'EMAIL_NEW' | 'REFERRALCODE' = 'TEXT';
    @Input() refInput: InputComponent | null = null;
    @Input() emailCheckSpinner = false;

    private valid = false;
    private value: string = '';
    private checkTimer = 0;
    private desc_normal: string = '';

    constructor(
        private client: ApiClientService,
        private util: UtilityService,
    ) {
    }

    ngOnInit(): void {
        this.desc_normal = this.desc;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.borderElement === undefined) return;
        const status = changes['desc_status']?.currentValue as 'NORMAL' | 'OK' | 'WARN';
        if (status !== undefined) {
            this.updateStatus();
        }
    }

    getValue(): false | string {
        this.validate(true);
        if (!this.valid) return false;
        return this.value;
    }
    setValue(value: string) {
        (this.borderElement.nativeElement.getElementsByClassName('signup-input')[0] as HTMLInputElement).value = value;
        this.value = value;
        this.validate();
    }

    onInput(event: Event) {
        this.value = (event.target as HTMLInputElement).value;
        this.validate();
    }

    private updateStatus() {
        if (this.status === 'OK') {
            this.borderElement.nativeElement.classList.remove('border-warn');
            this.descElement.nativeElement.classList.remove('color-warn');
            this.descElement.nativeElement.classList.add('color-secondary3');
        } else if (this.status === 'WARN') {
            this.borderElement.nativeElement.classList.add('border-warn');
            this.descElement.nativeElement.classList.remove('color-secondary3');
            this.descElement.nativeElement.classList.add('color-warn');
        } else {
            this.borderElement.nativeElement.classList.remove('border-warn');
            this.descElement.nativeElement.classList.remove('color-warn');
            this.descElement.nativeElement.classList.remove('color-secondary3');
        }
    }

    private async validate(finalcheck = false) {
        const oldstatus = this.status;
        if (this.value.length === 0) {
            if (finalcheck) {
                this.status = 'WARN';

            } else {
                this.status = 'NORMAL';
                /*
                if (this.type === 'EMAIL') {
                    this.desc = await this.util.getTranslation('Signup.EmailNotice');
                } else if (this.type === 'PASSWORD') {
                    this.desc = await this.util.getTranslation('Signup.PasswordNotice');
                } else if (this.type === 'PASSWORD_CONFIRM') {
                    this.desc = '';
                }
                */
                this.desc = this.desc_normal;

            }

        } else if (this.type === 'EMAIL_EXIST' || this.type === 'EMAIL_NEW') {
            if (Util.isValidEmail(this.value)) {
                if (this.checkTimer > 0) {
                    window.clearTimeout(this.checkTimer);
                    this.checkTimer = 0;
                }
                if (!finalcheck) {
                    this.status = 'OK';
                    this.desc = await this.util.getTranslation('Signup.CheckingEmail');
                    if (this.loadingElement) {
                        this.loadingElement.nativeElement.style.display = 'block';
                    }
                    this.checkTimer = window.setTimeout(() => {
                        this.checkEmailExist().then(async (exist: boolean) => {
                            if (this.loadingElement) {
                                this.loadingElement.nativeElement.style.display = 'none';
                            }
                            if (this.type === 'EMAIL_NEW') {
                                if (exist) {
                                    if (this.status !== 'WARN') {
                                        this.status = 'WARN';
                                        this.updateStatus();
                                    }
                                    this.desc = await this.util.getTranslation('Signup.EmailExist');
                                } else {
                                    this.desc = await this.util.getTranslation('Signup.EmailOk');
                                }
                            } else {
                                if (!exist) {
                                    if (this.status !== 'WARN') {
                                        this.status = 'WARN';
                                        this.updateStatus();
                                    }
                                    this.desc = await this.util.getTranslation('Signup.EmailNotExist');
                                } else {
                                    this.desc = this.desc_normal;
                                }

                            }
                        });
                    }, 800);
                }
            } else {
                this.status = 'WARN';
                this.desc = await this.util.getTranslation('Signup.EmailInvalid');
            }

        } else if (this.type === 'PASSWORD') {
            if (this.value.length >= 8 && this.value.length <= 12) {
                this.status = 'OK'
                this.desc = await this.util.getTranslation('Signup.PasswordOk');
            } else {
                this.status = 'WARN';
                this.desc = await this.util.getTranslation('Signup.PasswordNotice');
            }
        } else if (this.type === 'PASSWORD_CONFIRM') {
            if (this.refInput === null) {
                console.error('password input component not found');
            } else {
                const refvalue = this.refInput.getValue();
                if (refvalue === false || refvalue !== this.value) {
                    this.status = 'WARN';
                    this.desc = await this.util.getTranslation('Signup.PasswordNotMatch');
                } else {
                    this.status = 'OK';
                    this.desc = await this.util.getTranslation('Signup.PasswordMatch');
                }
            }
        } else if (this.type === 'REFERRALCODE') {
            if (this.value.length > 0 && /^[a-zA-Z0-9]{8}$/.test(this.value) === false) {
                this.status = 'WARN';
                this.desc = await this.util.getTranslation('Signup.ReferralCodeInvalid');
            } else {

                if (this.checkTimer > 0) {
                    window.clearTimeout(this.checkTimer);
                    this.checkTimer = 0;
                }
                if (!finalcheck) {
                    this.status = 'OK';
                    this.desc = await this.util.getTranslation('Signup.CheckingReferralCode');
                    if (this.loadingElement) {
                        this.loadingElement.nativeElement.style.display = 'block';
                    }
                    this.checkTimer = window.setTimeout(() => {
                        this.checkReferralCode().then(async (valid: boolean) => {
                            if (this.loadingElement) {
                                this.loadingElement.nativeElement.style.display = 'none';
                            }
                            if (!valid) {
                                if (this.status !== 'WARN') {
                                    this.status = 'WARN';
                                    this.updateStatus();
                                }
                                this.desc = await this.util.getTranslation('Signup.ReferralCodeNotExist');
                            } else {
                                this.desc = this.desc_normal;
                            }
                        });
                    }, 800);
                }

                this.status = 'OK';
                this.desc = '';
            }

        }
        this.valid = this.status === 'OK';
        if (oldstatus !== this.status) this.updateStatus();
    }

    private async checkEmailExist(): Promise<boolean> {
        const checkresult = await this.client.api.CheckEmailExist.send({ email: this.value });
        console.log(checkresult);
        return checkresult.message === 'ok';
    }

    private async checkReferralCode(): Promise<boolean> {
        const checkresult = await this.client.api.CheckReferralCode.send({ code: this.value });
        console.log(checkresult);
        return checkresult.message === 'ok';
    }
}