import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, Subject } from 'rxjs';


export enum SCREEN_SIZE {
    mobile,
    tablet,
    desktop,
}

@Injectable({
    providedIn: 'root'
})
export class ResizeService {

    currentSize!: SCREEN_SIZE

    get onResize$(): Observable<SCREEN_SIZE> {
        //distinctUntilChanged()를 통하여 중복을 방지해준다. 실제로 값이 바뀌었을때만 각 컴포넌트에서 호출된다. (ex. mobile ->tablet 바뀌는 순간만 호출 )
        return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
    }

    private resizeSubject: Subject<SCREEN_SIZE>;

    constructor() {
        this.resizeSubject = new Subject();
    }

    onResize(size: SCREEN_SIZE) {
        this.currentSize = size;
        this.resizeSubject.next(size)
    }

    getCurrent() {
        return this.currentSize;
    }
}
