<div class="asset-viewer-control-outer">
    <div *ngIf="three.object !== null" class="asset-viewer-control-status">
        <div class="asset-viewer-control-item-outer" *ngIf="getMeshInfo() as info">
            <div>vertices <span style="color:greenyellow;">{{info.vertices}}</span></div>
            <div>triangles <span style="color:greenyellow;">{{info.triangles}}</span></div>
        </div>
        <br />
        <div class="asset-viewer-control-item-outer">
            <button [class]="three.object.visible?'activated':''"
                (click)="three.setObjectVisible()">object
            </button>
            <button [class]="three.wireframe.visible?'activated':''"
                (click)="three.setWireframe()">wireframe
            </button>
            <button [class]="three.vertexnormal.visible?'activated':''"
                (click)="three.setVertexNormal()">vertexnormal
            </button>
        </div>
        <br />
        <div class="asset-viewer-control-item-outer">
            <button [class]="three.preset === 'Default'?'activated':''"
                (click)="three.setPreset('Default')">Default
            </button>
            <button [class]="three.preset === 'Preset1'?'activated':''"
                (click)="three.setPreset('Preset1')">Preset1
            </button>
            <button [class]="three.preset === 'Preset2'?'activated':''"
                (click)="three.setPreset('Preset2')">Preset2
            </button>
            <button [class]="three.preset === 'Preset3'?'activated':''"
                (click)="three.setPreset('Preset3')">Preset3(for no texture)
            </button>
            <button [class]="three.preset === 'Preset4'?'activated':''"
                (click)="three.setPreset('Preset4')">Preset4(Face)
            </button>
        </div>
        <br />
        <div class="asset-viewer-control-item-outer">
            <button [class]="three.pointlight1.visible?'activated':''"
                (click)="three.pointlight1.visible = !three.pointlight1.visible;three.render()">
                pointlight1
            </button>
            <input type="number" step="0.1" [(ngModel)]="three.pointlight1.intensity"
                (change)="three.render()">
        </div>
        <div class="asset-viewer-control-item-outer">
            <button [class]="three.pointlight2.visible?'activated':''"
                (click)="three.pointlight2.visible = !three.pointlight2.visible;three.render()">
                pointlight2
            </button>
            <input type="number" step="0.1" [(ngModel)]="three.pointlight2.intensity"
                (change)="three.render()">
        </div>
        <div class="asset-viewer-control-item-outer">
            <button [class]="three.ambientlight.visible?'activated':''"
                (click)="three.ambientlight.visible= !three.ambientlight.visible;three.render()">
                ambientlight
            </button>
            <input type="number" step="0.1" [(ngModel)]="three.ambientlight.intensity"
                (change)="three.render()">
        </div>
        <br />
        <div *ngFor="let item of getMapInfo()|keyvalue" class="asset-viewer-control-item-outer">
            <button style="position:relative;"
                (click)="three.setTextureVisible(textureType(item.key))"
                [class]="three.getTextureVisible(textureType(item.key))?'activated':''">
                <img *ngFor="let texture of item.value" style="width:64px;height:64px;"
                    [src]="s(texture.image.src)">
                <div class="asset-viewer-control-item-texture-name">{{item.key}}
                    {{three.getTextureVisible(textureType(item.key))?'✓':''}}
                </div>
            </button>
        </div>
    </div>
</div>