import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Util, UtilityService } from 'src/app/lib/util';
import { CdkScrollable } from '@angular/cdk/scrolling';
import gsap from 'gsap';

@Component({
    selector: 'app-business-modeling',
    templateUrl: './business-modeling.component.html',
    styleUrls: ['./common/business-common.component.scss']
})
export class BusinessModelingComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('customVideo') customVideo!: ElementRef;
    @ViewChild('enterVideo') enterVideo!: ElementRef;
    @ViewChild('modelingVideo') modelingVideo!: ElementRef;
    @ViewChild('youtubeVideo') youtubeVideo!: ElementRef;
    @ViewChild(CdkScrollable) scrollable!: CdkScrollable;
    @ViewChild('videoContainer') videoContainer!: ElementRef;
    @ViewChild('title_container') title_container!: ElementRef<HTMLDivElement>;

    costumeIndex = 0;
    currentPage = 0;
    location = 0;
    sliderWrapFunc: (value: number) => number = value => value;
    sliderOffset = 0;
    slideWidth = 1000;

    costumeList: {
        idx: number;
        url: string;
    }[] = [
            { idx: 0, url: 'url(/assets/image/business/custom/slider/1.png)' },
            { idx: 1, url: 'url(/assets/image/business/custom/slider/2.png)' },
            { idx: 2, url: 'url(/assets/image/business/custom/slider/3.png)' },
            { idx: 3, url: 'url(/assets/image/business/custom/slider/4.png)' },
            { idx: 4, url: 'url(/assets/image/business/custom/slider/5.png)' },
            { idx: 5, url: 'url(/assets/image/business/custom/slider/6.png)' },
            { idx: 6, url: 'url(/assets/image/business/custom/slider/7.png)' },
            { idx: 7, url: 'url(/assets/image/business/custom/slider/8.png)' },
            { idx: 8, url: 'url(/assets/image/business/custom/slider/9.png)' },
            { idx: 9, url: 'url(/assets/image/business/custom/slider/10.png)' },
            { idx: 10, url: 'url(/assets/image/business/custom/slider/11.png)' },
            { idx: 11, url: 'url(/assets/image/business/custom/slider/12.png)' }
        ];


    partnerTop = [1, 2, 3, 4, 5, 6];
    partnerBottom = [7, 8, 9, 10, 11, 12];
    descinfo = [
        {
            title: 'Custom.RandD',
            desc: 'Custom.RandDDesc',
            paragraph:
                'Custom.RandDParagraph'
            ,
            imagecount: ['design', 'development']
        },
        {
            title: 'Custom.ArchiandInterior',
            desc: 'Custom.ArchiandInteriorDesc',
            paragraph:
                'Custom.ArchiandInteriorParagraph'
            ,
            imagecount: ['4', '3']
        },
        {
            title: 'Custom.Entertainment',
            desc: 'Custom.EntertianmentDesc',
            paragraph:
                'Custom.EntertainmentParagraph'
            ,
            imagecount: ['architecture', 'interior']
        },
        {
            title: 'Custom.GameAndXR',
            desc: 'Custom.GameAndXRDesc',
            paragraph:
                'Custom.GameAndXRParagraph'
            ,
            imagecount: ['architecture', 'interior']
        },
        {
            title: 'Custom.3DPringting',
            desc: 'Custom.3DPringtingDesc',
            paragraph:
                'Custom.3DPringtingParagraph'
            ,
            imagecount: ['printing']
        }
    ];

    whymerrorinfo = [
        {
            title: 'ForBusiness.AllinOneSystem',
            desc: 'ForBusiness.Solution',

        },
        {
            title: 'ForBusiness.3DConsultant',
            desc: 'ForBusiness.Consultation'
        },
        {
            title: 'ForBusiness.CustomMade',
            desc: 'ForBusiness.Customization'
        },
        {
            title: 'ForBusiness.Excellence',
            desc: 'ForBusiness.ResonablePrice'
        }
    ];

    portfolioList = [
        { order: 1, hover: false, projectinfo: ['ForBusiness.ProjectTitle1', 'ForBusiness.ProjectDesc1'], address: '36' },
        { order: 2, hover: false, projectinfo: ['ForBusiness.ProjectTitle2', 'ForBusiness.ProjectDesc2'], address: '2' },
        { order: 3, hover: false, projectinfo: ['ForBusiness.ProjectTitle3', 'ForBusiness.ProjectDesc3'], address: '37' },
        { order: 4, hover: false, projectinfo: ['ForBusiness.ProjectTitle4', 'ForBusiness.ProjectDesc4'], address: '33' },
        { order: 5, hover: false, projectinfo: ['ForBusiness.ProjectTitle5', 'ForBusiness.ProjectDesc5'], address: '34' },
        { order: 6, hover: false, projectinfo: ['ForBusiness.ProjectTitle6', 'ForBusiness.ProjectDesc6'], address: '38' },
        { order: 7, hover: false, projectinfo: ['ForBusiness.ProjectTitle7', 'ForBusiness.ProjectDesc7'], address: '35' },
        { order: 8, hover: false, projectinfo: ['ForBusiness.ProjectTitle8', 'ForBusiness.ProjectDesc8'], address: '41' },
        { order: 9, hover: false, projectinfo: ['ForBusiness.ProjectTitle9', 'ForBusiness.ProjectDesc9'], address: '40' },
        { order: 10, hover: false, projectinfo: ['ForBusiness.ProjectTitle10', 'ForBusiness.ProjectDesc10'], address: '31' },
        { order: 11, hover: false, projectinfo: ['ForBusiness.ProjectTitle11', 'ForBusiness.ProjectDesc11'], address: '42' },
        { order: 12, hover: false, projectinfo: ['ForBusiness.ProjectTitle12', 'ForBusiness.ProjectDesc12'], address: '3' }]

    constructor(
        public util: UtilityService,
        private renderer: Renderer2,
    ) {
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.resizeCallback);
    }

    ngAfterViewInit(): void {
        this.resizeCallback();
        this.initSliderItems();
        this.setMarquee();
        this.playVideo();
        this.addIntersectionObserver();
        const container1 = document.getElementById(`business-common-beforeafter-outer-0`) as HTMLDivElement;
        (document.getElementById(`beforeafter-slider-0`) as HTMLInputElement).addEventListener('input', (e) => {
            const target = e.target as HTMLInputElement;
            container1.style.setProperty(`--position-0`, `${target.value}%`);
        })

        const container2 = document.getElementById(`business-common-beforeafter-outer-1`) as HTMLDivElement;
        (document.getElementById(`beforeafter-slider-1`) as HTMLInputElement).addEventListener('input', (e) => {
            const target = e.target as HTMLInputElement;
            container2.style.setProperty(`--position-1`, `${target.value}%`);
        })
    }

    resizeCallback = (event?: UIEvent) => {
        const currentWidth = document.body.clientWidth;
        const slidelist = document.querySelectorAll('.business-img-slider-item-outer');
        const aspectRatio = 700 / 1000;
        let newWidth = (1000 * currentWidth) / 1480
        let newHeight = newWidth * aspectRatio;
        if (newHeight > 700) newHeight = 700;



        if (currentWidth + 6 <= 2560) {
            this.slideWidth = newWidth;
            const outer = document.getElementsByClassName('business-img-slider-outer')[0] as HTMLDivElement;
            outer.style.height = `${newHeight}px`;
            slidelist.forEach((slide) => {
                (slide as HTMLDivElement).style.width = `${newWidth}px`;
            })
        }
    }

    ngOnDestroy(): void {
        this.stopVideo();
        window.removeEventListener('resize', this.resizeCallback);
    }


    scrollToService() {

        const titletop = this.title_container.nativeElement.offsetTop
        const nav = (document.getElementsByClassName('header-outer')[0] as HTMLDListElement).clientHeight;

        const height = titletop - nav;


        Util.scrollTo(height);
    }

    setMarquee() {
        const root = document.documentElement;
        const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--partner-marquee-elements-displayed");
        const marqueeContent = document.querySelector("ul.business-common-marquee-content") as HTMLUListElement;
        const marqueeContent1 = document.querySelector("ul.business-common-marquee-content1") as HTMLUListElement;

        root.style.setProperty("--partner-marquee-elements", String(marqueeContent.children.length));

        for (let i = 0; i < Number(marqueeElementsDisplayed); i++) {
            marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
            marqueeContent1.appendChild(marqueeContent1.children[i].cloneNode(true));
        }

    }

    playVideo() {
        const videoList = [this.customVideo.nativeElement, this.modelingVideo.nativeElement, this.enterVideo.nativeElement]
        videoList.forEach((video) => {
            video.muted = true;
            video.autoplay = true;
            video.loop = true;
            video.playsInline = true;

            // youtube.setAttribute("src", "youtube.com/embed/" + '3dLQrkYaaNc' + "?modestbranding=1&;showinfo=0&;autohide=1&;rel=0;");
            this.renderer.listen(video, 'loadedmetadata', () => {
                video.play();
            });
        })


    }

    stopVideo(): void {
        const videoList = [this.customVideo.nativeElement, this.modelingVideo.nativeElement, this.enterVideo.nativeElement];
        videoList.forEach((video) => {
            video.pause();
            video.currentTime = 0;
        });
    }

    animateText() {
        const delay = 0.3;
        const textlist = document.querySelectorAll('.business-common-video-text')

        for (let i = 0; i < textlist.length; i++) {
            let order = i + 1;
            const slide = textlist[i] as HTMLDivElement;
            setTimeout(() => {
                slide.classList.add(`business-common-video-text${order}`);
            }, delay * 1000 * order);
        }
    }

    addIntersectionObserver(): void {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const intersectionRatio = entry.intersectionRatio;
                    if (intersectionRatio >= 0.3) {
                        this.animateText();
                    }
                }
            });
        }, {
            threshold: [0.3]
        });

        observer.observe(this.videoContainer.nativeElement);
    }


    private initSliderItems() {
        const outer = document.getElementsByClassName('business-img-slider-outer')[0] as HTMLDivElement;
        if (!outer) return;
        this.sliderOffset = (outer.clientWidth - this.slideWidth) * 0.5 / this.slideWidth * 100;
        this.sliderWrapFunc = gsap.utils.wrap(-100 - this.sliderOffset,
            (this.costumeList.length - 1) * 100 - this.sliderOffset);
        setTimeout(() => {
            gsap.set('.business-img-slider-item-outer', {
                xPercent: i => i * 100 + this.sliderOffset,
                modifiers: {
                    xPercent: this.sliderWrapFunc
                }
            });
            gsap.to('.business-img-slider-item-inner', {
                scale: index => index === 0 ? 1 : 0.98
            });
        }, 1);
    }

    anim(dir: number) {
        this.location += dir;
        this.costumeIndex += dir;
        if (this.costumeIndex >= this.costumeList.length) this.costumeIndex -= this.costumeList.length;
        else if (this.costumeIndex < 0) this.costumeIndex += this.costumeList.length;
        this.currentPage = this.costumeList[this.costumeIndex].idx
        gsap.to('.business-img-slider-item-outer', {
            xPercent: index => {
                const element = document.getElementsByClassName('business-img-slider-item-outer')[index] as HTMLDivElement;
                let value = gsap.getProperty(element, 'xPercent') as number;
                let newvalue = (index - this.location) * 100 + this.sliderOffset;
                newvalue = this.sliderWrapFunc(newvalue);
                if (dir > 0) {
                    if (newvalue > value) newvalue -= 100 * this.costumeList.length;
                } else if (dir < 0) {
                    if (newvalue < value) newvalue += 100 * this.costumeList.length;
                }
                return newvalue;
            },
            modifiers: {
                xPercent: this.sliderWrapFunc
            },
        });
        gsap.to('.business-img-slider-item-inner', {
            scale: index => {
                if (index === this.costumeIndex) return 1;
                else return 0.98;
            }
        });
        return;
    }

    prevCostume() {
        this.anim(-1);
    }
    nextCostume() {
        this.anim(+1);
    }
}
