<div class="assets-container" [style.min-height]="screenSize ===2 ? '1100px': ''">
    <div class="assets-outer">
        <div class="assets-inner">
            <div class="assets-data">
                <div id="itemlist">

                    <div class="assets-top">
                        <div class="assets-breadcrumb">
                            <div>
                                <span routerLink="/store"
                                    [innerHTML]="'Common.Home'|translate">Home</span>
                            </div>
                            <div class="common-horizontal-alignment">
                                <img uk-svg src="/assets/icon/chevron_right_20.svg" />
                                <span *ngIf="!searchcondition.searchtext.length" routerLink="/asset"
                                    [innerHtml]="'Common.3DAssets'|translate">3D
                                    Assets</span>
                                <span *ngIf="searchcondition.searchtext.length"
                                    [innerHtml]="'Common.SearchResults'|translate">Search
                                    Results</span>
                            </div>
                            <div *ngIf="(categoryType !== undefined)&&(categoryType !== 'all')"
                                class="common-horizontal-alignment">
                                <img uk-svg src="/assets/icon/chevron_right_20.svg" />
                                <span
                                    [innerHTML]="'Sidebar.' + currentCategory(categoryType) | translate"></span>
                            </div>
                        </div>

                        <div class="assets-header-wrap">
                            <h1 class="assets-header" [innerHTML]=" (searchcondition.searchtext.length === 0 ? 'Common.3DAssets'
                            : 'Common.SearchResults'
                         )|translate">
                                3D ASSETS</h1>

                            <ul class="assets-tags" *ngIf="searchcondition.searchtext.length !== 0">
                                <li class="asset-tag"
                                    *ngFor="let tag of searchcondition.searchtext">
                                    <span>{{tag}}</span>
                                    <div class="asset-tag-close-icon" (click)="popTag(tag)">
                                        <img src="/assets/icon/close_24.svg" />
                                    </div>

                                </li>
                            </ul>
                        </div>



                        <ul class="assets-category">
                            <li class="assets-category-label"
                                [style]="(all.isActive || router.url.includes('/asset')) && !router.url.includes('/asset/human') && !router.url.includes('/asset/object') && !router.url.includes('/asset/space') ? 'color: #FFCA66; border-bottom: 2px solid #FFCA66': 'color:#BDBDBD'"
                                routerLinkActive="active-link" #all="routerLinkActive"
                                routerLink="/asset/all" [routerLinkActiveOptions]="{exact: false}"
                                [innerHtml]="'Search.AllAssets'|translate">All</li>
                            <li class="assets-category-label" routerLink="/asset/human"
                                [innerHtml]="'Sidebar.Human'|translate"
                                [class.active-link]="human.isActive"
                                [style]="human.isActive ?  'color: #FFCA66; border-bottom: 2px solid #FFCA66': 'color:#BDBDBD'"
                                routerLinkActive="active" #human="routerLinkActive">Human</li>
                            <li class="assets-category-label" routerLink="/asset/object"
                                [class.active-link]="object.isActive"
                                [style]="object.isActive ? 'color: #FFCA66; border-bottom: 2px solid #FFCA66': 'color:#BDBDBD'"
                                routerLinkActive="active" #object="routerLinkActive"
                                [innerHtml]="'Sidebar.Object'|translate">Object</li>
                            <li class="assets-category-label" routerLink="/asset/space"
                                [class.active-link]="space.isActive"
                                [style]="space.isActive ?  'color: #FFCA66; border-bottom: 2px solid #FFCA66': 'color:#BDBDBD'"
                                routerLinkActive="active" #space="routerLinkActive"
                                [innerHtml]="'Sidebar.Space'|translate">Space</li>
                        </ul>

                        <div
                            [ngClass]="mobileFilter ? 'assets-mobile-filter-bar' : 'assets-filter-bar'">
                            <app-filter #filterComponent style="width: 100%;height: 100%;"
                                [categoryType]="categoryType" (openFilter)="openFilter($event)"
                                [searchcondition]="searchcondition"></app-filter>
                        </div>
                    </div>

                    <div class="index-item-container">
                        <div *ngFor="let model of Modellist" style="width:100%">
                            <app-index-item [asset]="model" [ApiUrl]="ApiUrl">
                            </app-index-item>
                        </div>

                    </div>
                </div>


                <div class="item-empty-page common-vertical-alignment"
                    *ngIf="totalItems === 0 && this.apiChecked">
                    <img src="/assets/image/empty/reset_filter.png" />
                    <span *ngIf="filterEmpty" class="item-empty-phrase"
                        [innerHTML]="'Message.ResetFilter'|translate">필터를
                        재설정 하여 주시기 바랍니다.</span>
                    <span *ngIf="searchEmpty" class="item-empty-phrase"
                        [innerHTML]="'Message.NoSearchResults'|translate">검색
                        결과가 없습니다.</span>
                    <button class="common-button1" (click)="resetFilter()" *ngIf="filterEmpty"
                        [innerHTML]="'Facial.FilterClearAll'|translate">필터 모두 지우기</button>
                </div>

                <div id="button_more">
                    <button class="common-button1 index-more-button"
                        *ngIf="totalItems>Modellist.length" [innerHTML]="'Common.SeeMore'|translate"
                        (click)="load(); scrollDown()">
                        더 보기
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>