import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { PresetType, ThreeCanvas } from 'src/app/lib/threecanvas';
import { Data } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';

@Component({
    selector: 'app-admin-thumbnail',
    templateUrl: './admin-thumbnail.component.html',
})
export class AdminThumbnailComponent implements OnInit, AfterViewInit {

    assetno = 0;
    type: Data.AssetOptionType;
    three: ThreeCanvas;
    notexture = false;
    div!: HTMLDivElement;
    width = 1024;
    height = 1024;
    asset: Data.Asset | null = null;
    texturelist: Data.TextureMapType[] = [];

    constructor(
        private route: ActivatedRoute,
        private client: ApiClientService
    ) {
        this.assetno = parseInt(this.route.snapshot.params['assetno']);
        this.type = this.route.snapshot.params['type'];
        const option = this.route.snapshot.params['option'];
        if (option !== undefined) {
            this.notexture = option === 'notexture';
            if (this.notexture === false) {
                const tex_list = option.split('_');
                for (const tex of tex_list) {
                    if (tex.length > 0) this.texturelist.push(tex);
                }
            }
        }
        this.three = new ThreeCanvas();

        const w = parseInt(this.route.snapshot.queryParams['width']);
        const h = parseInt(this.route.snapshot.queryParams['height']);
        if (!isNaN(w)) this.width = w;
        if (!isNaN(h)) this.height = h;
        console.log(w, h, this.width, this.height);
        console.log(`assetno: ${this.assetno}, type: ${this.type}, notexture: ${this.notexture}, texturelist: ${this.texturelist}`);
    }

    ngOnInit(): void {
        console.log(this.assetno);
    }

    ngAfterViewInit(): void {
        this.div = document.getElementById('canvas') as HTMLDivElement;
        this.three.init(this.div);
        this.load();
    }

    async load() {
        const res = await this.client.api.GetAssetinfo.send({ assetno: this.assetno, currency: 'KRW' });
        this.asset = res.asset;
        const { names } = await this.three.loadFBX(this.assetno,
            `/admin/thumbnail/model/${this.assetno}/${this.type}`,
            this.texturelist, this.notexture, true, this.type);
        this.setDefaultCamera();
        if (this.notexture === false) {
            if (names.length < 2) {
                const textureblob = await fetch(`/admin/thumbnail/texture/${this.assetno}/${this.type}`).then(r => r.blob());
                await this.three.updateTexture(URL.createObjectURL(textureblob), 'DIFF');
            } else {
                for (let i = 0; i < names.length; i++) {
                    const index = CommonUtil.findTextureIndex(names[i]);
                    const textureblob = await fetch(`/admin/thumbnail/texture/${this.assetno}/${this.type}/${index + 1}`).then(r => r.blob());
                    await this.three.updateTexture(URL.createObjectURL(textureblob), 'DIFF', index);
                }
            }
        }
        this.three.render(this.width * 2, this.height * 2);
        this.div.classList.add('completed');
    }

    async capture(width = 4096, height = 4096) {
        const image = await this.three.captureImageAsDataURL(width, height);
        const a = document.createElement('a');
        a.href = image;
        a.download = '' + this.assetno + '-TH.png';
        document.body.appendChild(a);
        a.click();
    }

    setDefaultCamera() {
        if (this.asset === null || this.asset.tag === undefined) return;
        if (this.asset.tag.includes('사람')) this.three.setCameraForHuman(1.6, false);
        else this.three.setCamera();
    }

    async thumbnail(index: number) {
        this.setDefaultCamera();
        this.three.setPreset('Thumbnail' + index as PresetType);
        if (this.notexture) this.three.setDefaultMaterial();
        this.three.render(this.width * 2, this.height * 2);
        this.div.classList.add('completed_' + index);
    }

}
