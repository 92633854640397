<nav class="header-container" #main_header>
    <div class="header-strip-banner-wrap" style="width: 100%;height: auto;position: relative;">
        <div class="header-strip-banner" #strip_banner>
            <div>
                <div class="header-strip-banner-text">
                    <p class="animate-text">
                        <span [innerHTML]="'Banner.StripBanner1'|translate"
                            (click)="navigate('/signup')"></span>
                        <span [innerHTML]="'Banner.StripBanner2'|translate"
                            (click)="navigate('/event/referral')"></span>
                    </p>
                </div>
                <div class="header-strip-close-icon" (click)="closeBanner()">
                    <img src="/assets/icon/close_24_black.svg" />
                </div>
            </div>
        </div>


    </div>

    <div class="header-outer">
        <div class="main-version" [style.opacity]="versionopacity">{{version}}</div>
        <div class="header-inner">
            <div class="header-left">
                <div class="header-hamburger-bar">
                    <img class="header-hamburger-icon" id="hamburger-icon"
                        src="/assets/icon/menu_24.svg" (click)=" showLinkMenu=!showLinkMenu" />
                    <div *ngIf="showLinkMenu" class="header-tablet-link-outer">
                        <app-dropdown-nav [userinfo]="userinfo" (closed)="showLinkMenu=false">
                        </app-dropdown-nav>
                    </div>
                </div>

                <div class="header-merror-logo">
                    <a routerLink="/">
                        <img src="/assets/image/logo.svg"
                            [style.filter]="'hue-rotate(' + logofilterdeg + 'deg)'" />
                    </a>
                </div>

                <div class="header-link-outer">
                    <ul class="header-link-words">
                        <li class="header-link-word" (mouseenter)="toggleState('assetNav', true)"
                            (mouseleave)="toggleState('assetNav',false)">
                            <div class="header-link-assets">
                                <div (click)="navigate('/store');showAssetsNav =false">
                                    <span>STORE</span>
                                    <img src="/assets/icon/chevron_down_20.svg" />
                                </div>

                                <div class="header-dropdown-assets" *ngIf="showAssetsNav">
                                    <app-dropdown-nav [userinfo]="userinfo" [navTitle]="'assets'"
                                        (closed)="showAssetsNav = $event">
                                    </app-dropdown-nav>
                                </div>

                            </div>

                        </li>

                        <li class="header-link-word" (mouseenter)="toggleState('businessNav', true)"
                            (mouseleave)="toggleState('businessNav',false)">
                            <div class="header-link-business">
                                <div (click)="navigate('/business');showBusinessNav=false">
                                    <span>ME.RROR BIZ</span>
                                    <img src="/assets/icon/chevron_down_20.svg" />
                                </div>
                                <div class="header-dropdown-business" *ngIf="showBusinessNav">
                                    <app-dropdown-nav [userinfo]="userinfo" [navTitle]="'business'"
                                        (closed)="showBusinessNav = $event">
                                    </app-dropdown-nav>
                                </div>
                            </div>

                        </li>
                        <li class="header-link-word">
                            <a href="https://merror.tistory.com" target="_blank">BLOG</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header-right" id="navbar-right-container">
                <div id="header-search-icon">
                    <img (click)="showSearchTerm = true" src="/assets/icon/search_24.svg" />
                </div>

                <div class="header-search" id="header-search-container"
                    *ngIf="screenSize === 2  || showSearchTerm">
                    <div class="header-main-search" style="flex-grow: 1;">
                        <app-main-search style="flex-grow:1;"
                            (toggleFocused)="showSearchTerm = $event"></app-main-search>
                        <img (click)="showSearchTerm = false" class="dropdown-search-close-icon"
                            src="/assets/icon/close_24.svg" />

                    </div>

                    <div class="header-search-dropdown">
                        <app-dropdown-nav *ngIf="showSearchTerm" [userinfo]="userinfo"
                            [navTitle]="'search'" (closed)="showSearchTerm = false">
                        </app-dropdown-nav>
                    </div>
                </div>


                <div class="header-icon-outer">
                    <ul class="header-icons">
                        <div class="header-icon-wrap">
                            <li class="header-icon">
                                <a (click)="navigate('/cart')">
                                    <img class="header-icon-img" src="/assets/icon/cart_24.svg" />
                                    <div *ngIf="cartcount>0" class="header-icon-count">
                                        {{cartcount>9 ? '9+' : cartcount}}
                                    </div>
                                </a>
                            </li>

                            <li class="header-icon">
                                <a (click)="navigate('/favorite')">
                                    <img class="header-icon-img" src="/assets/icon/star_24.svg" />
                                </a>
                            </li>
                        </div>

                        <li class="header-icon">
                            <div class="header-member-container" *ngIf="userinfo.userno > 0"
                                (mouseenter)="screenSize !== 0 ? toggleState('mypage', true): ''"
                                (mouseleave)="screenSize !== 0 ? toggleState('mypage',false): ''">
                                <div class="header-member"
                                    (click)="screenSize === 0 ? toggleState('mypage', !showMypage): ''">
                                    <img src="/assets/icon/profile_24.svg" />
                                    <img src="/assets/icon/chevron_down_20.svg" />
                                </div>
                                <div id="mypage_dropdown" class="header-mypage"
                                    *ngIf="showMypage && userinfo.userno > 0">
                                    <app-popup-nav *ngIf="!desktopSize"
                                        [title]="'Common.Mypage' | translate"
                                        [popup]="'mypage'"></app-popup-nav>
                                    <app-dropdown-nav [userinfo]="userinfo" [navTitle]="'mypage'"
                                        (closed)="showMypage = $event">
                                    </app-dropdown-nav>
                                </div>
                            </div>
                            <div class="header-nonmember" *ngIf="userinfo.userno === 0">
                                <button class="common-button2 header-login-button"
                                    [innerHTML]="'Common.Login'|translate"
                                    (click)="login()">Login</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>