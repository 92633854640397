import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../lib/util';
import { ActivatedRoute } from '@angular/router';

declare var Landbot: any;

export const landbotConfig = {
    quotation: {
        ko: 'H-1566503-6T6E3Q0CTNJD4RU6',
        en: 'H-1576190-YRMHLW4NRMGSKR1K'
    },
    customproduct: {
        ko: 'H-1567592-EHTZUDSQQ4Y434X7',
        en: 'H-1576425-RAJ79TZQ3SMIIFDX'
    },
    contact: {
        ko: 'H-1574812-BC0AHTGJULO6UQIE',
        en: 'H-1576328-7Y0DNE18MTE06IHL'
    }
}

@Component({
    selector: 'app-chatbot',
    template: ` 
    <div id="myLandbot" style="width:100%;height:1000px;" ></div>
`
})
export class ChatbotComponent implements OnInit {

    lang = this.util.getCurrentLang();
    config = '';
    constructor(
        private util: UtilityService,
        private route: ActivatedRoute
    ) {
        if (this.route.snapshot.url.length > 1) {
            const type = this.route.snapshot.url[1].path;
            if (type === 'quotation') {
                this.config = landbotConfig.quotation[this.lang];
            } else if (type === 'customproduct') {
                this.config = landbotConfig.customproduct[this.lang];
            } else {
                this.config = landbotConfig.contact[this.lang];
            }
        } else {
            this.config = landbotConfig.contact[this.lang];
        }

        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.createChatbot();
    }

    createChatbot() {
        const script = document.createElement('script');
        script.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
        script.async = true;
        script.setAttribute('SameSite', 'None');
        script.setAttribute('Secure', '');
        script.onload = () => {
            new Landbot.Container({
                container: '#myLandbot',
                configUrl: `https://storage.googleapis.com/landbot.online/v3/${this.config}/index.json`,
            });
        }
        document.head.appendChild(script);
    }
}
