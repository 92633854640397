import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { UtilityService } from 'src/app/lib/util';
import { InputComponent } from '../common/input.component';

@Component({
    selector: 'app-reset-password-popup',
    templateUrl: './reset-password-popup.component.html',
    styleUrls: ['./reset-password-popup.component.scss'],
})
export class ResetPasswordPopupComponent implements OnInit, AfterViewInit {

    @ViewChild('input_password_newpassword') inputPasswordNewRef!: ElementRef<HTMLInputElement>;
    @ViewChild('input_password_passwordconfirm') inputPasswordConfirmRef!: ElementRef<HTMLInputElement>;
    @ViewChild('password_length_msg') pwLengthMsg!: ElementRef<HTMLElement>;
    @ViewChild('password_match_msg') pwMatchMsg!: ElementRef<HTMLElement>;

    getResponse = false;
    private key = '';
    private email = '';

    message1 = '';
    message2 = '';

    newPasswordWrong = '';
    confirmPasswordNotMatch = '';

    constructor(
        private router: Router,
        private client: ApiClientService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private util: UtilityService
    ) { }

    ngOnInit(): void {
        const key = this.route.snapshot.paramMap.get('key');
        const email = this.route.snapshot.paramMap.get('email');
        if (key !== null) this.key = key;
        if (email !== null) this.email = email;

        this.checkKey(key, email);
    }

    private async checkKey(key: string | null, email: string | null) {
        console.log(key, email);
        if (key === null || email === null) {
            this.showInvalidKeyMessage();
        } else {
            const res = await this.client.api.CheckUserRequest.send({ email, key });
            console.log(res);
            if (res.message !== 'ok') this.showInvalidKeyMessage();
        }
    }

    ngAfterViewInit(): void {
    }

    async close() {
        await this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
    }

    onInput(ref: ElementRef<HTMLInputElement>) {
        const value = ref.nativeElement.value;
        const parent = ref.nativeElement.parentElement;
        const validColor = '#FFEDCC';
        const invalidColor = '#DC362E';
        if (parent === null) return;

        if (!this.inputPasswordNewRef.nativeElement.value.length) {
            this.inputPasswordNewRef.nativeElement.parentElement?.classList.add('border-warn');
        }

        if (ref === this.inputPasswordNewRef) {
            const value1 = this.inputPasswordConfirmRef.nativeElement.value;
            this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                this.newPasswordWrong = value;
            });

            if ((value.length < 8) || (value.length > 12)) {
                parent.classList.add('border-warn');
                this.pwLengthMsg.nativeElement.style.color = invalidColor;
                (parent.nextSibling as HTMLDivElement).classList.add('password-popup-warn');
                this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                    this.newPasswordWrong = value;
                });
            }

            else {
                this.translate.get('Signup.PasswordOk').subscribe(value => {
                    this.newPasswordWrong = value;
                });
                this.inputPasswordNewRef.nativeElement.parentElement!.classList.remove('border-warn');
                this.pwLengthMsg.nativeElement.style.color = validColor;

                if ((value1 === value) && (value1.length !== 0)) {
                    parent.classList.remove('border-warn');
                    this.pwLengthMsg.nativeElement.style.color = validColor;
                    this.pwMatchMsg.nativeElement.style.color = validColor;
                    this.inputPasswordConfirmRef.nativeElement.parentElement?.classList.remove('border-warn');
                    (parent.nextSibling as HTMLDivElement).classList.remove('password-popup-warn');
                    this.translate.get('Signup.PasswordOk').subscribe(value => {
                        this.newPasswordWrong = value;
                    });
                    this.translate.get('Signup.PasswordMatch').subscribe(value => {
                        this.confirmPasswordNotMatch = value;
                    });
                }
            }
            if ((value1 !== value) && (value1.length !== 0)) {
                this.translate.get('Signup.PasswordNotMatch').subscribe(value => {
                    this.confirmPasswordNotMatch = value;
                });
                this.pwMatchMsg.nativeElement.classList.add('password-popup-warn');
                this.pwMatchMsg.nativeElement.style.color = invalidColor;
                this.inputPasswordConfirmRef.nativeElement.parentElement?.classList.add('border-warn');
            }
        } else {
            const newRef = this.inputPasswordNewRef.nativeElement;
            const value2 = newRef.value;
            if (value2.length === 0) {
                newRef.parentElement?.classList.add('border-warn')
            }
            if (value.length < 8) {
                this.confirmPasswordNotMatch = '';
                this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                    this.confirmPasswordNotMatch = value;
                });
                this.pwMatchMsg.nativeElement.style.color = invalidColor;
                parent.classList.add('border-warn');
            }
            else if ((value === value2) && (value.length >= 8 && value.length <= 12)) {
                parent.classList.remove('border-warn');
                this.pwMatchMsg.nativeElement.classList.remove('password-popup-warn');
                this.pwMatchMsg.nativeElement.style.color = validColor;
                this.pwLengthMsg.nativeElement.style.color = validColor;
                this.translate.get('Signup.PasswordMatch').subscribe(value => {
                    this.confirmPasswordNotMatch = value;
                });
            } else {
                this.translate.get('Signup.PasswordNotMatch').subscribe(value => {
                    this.confirmPasswordNotMatch = value;
                });
                this.pwMatchMsg.nativeElement.classList.add('password-popup-warn');
                this.pwMatchMsg.nativeElement.style.color = invalidColor;
                parent.classList.add('border-warn');
            }
        }

    }

    async resetPassword() {
        const passwordRef = this.inputPasswordNewRef.nativeElement
        const passwordConfirmRef = this.inputPasswordConfirmRef.nativeElement;
        const password = passwordRef.value;
        const passwordConfirm = passwordConfirmRef.value;

        if (password.length === 0) {
            passwordRef.parentElement?.classList.add('border-warn');
            return;
        }

        if (passwordConfirm.length === 0) {
            passwordConfirmRef.parentElement?.classList.add('border-warn');
            return;
        }

        if (password !== passwordConfirm) { return; }

        const res = await this.client.api.ResetPassword.send({
            email: this.email, key: this.key, password
        });

        this.getResponse = true;
        if (res.message === 'ok') {
            this.message1 = await this.util.getTranslation('Signup.PasswordChanged');
            this.message2 = await this.util.getTranslation('Signup.LoginWithChangedPassword');

        } else if (res.message.includes('invalid key info')) {
            this.showInvalidKeyMessage();

        } else {
            this.message1 = await this.util.getTranslation('Signup.InvalidUserinfo1');
            this.message2 = await this.util.getTranslation('Signup.InvalidUserinfo2');

        }
    }

    private async showInvalidKeyMessage() {
        this.getResponse = true;
        this.message1 = await this.util.getTranslation('Signup.InvalidPasswordKey1');
        this.message2 = await this.util.getTranslation('Signup.InvalidPasswordKey2');
    }

}
