import { AfterContentInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { IamportService, PayMethodType, RequestPayCallbackParamType } from '../lib/iamport';
import { ConfirmPurchaseInfo, Data, RequestPurchaseInfo } from '../../server/lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { Util, UtilityService } from '../lib/util';
import { JsonPipe, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ResizeService } from '../lib/resize.service';

export const PayMethodList: {
    [key in Data.CurrencyType]: {
        method: PayMethodType; key: string; iconsrc: string
    }[]
} = {
    KRW: [
        { method: 'card', key: 'Order.CreditCard', iconsrc: '/assets/icon/credit_card.svg' },
        { method: 'trans', key: 'Order.AccountTransfer', iconsrc: '/assets/icon/money_transfer.svg' },
        { method: 'kakaopay', key: 'Order.Kakao', iconsrc: '/assets/icon/kakaopay.svg' },
        { method: 'naverpay', key: 'Order.Naver', iconsrc: '/assets/icon/naverpay.svg' },
    ],
    USD: [
        { method: 'paypal', key: 'Order.Paypal', iconsrc: '/assets/icon/paypal.svg' },
    ]
};

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, AfterContentInit, OnDestroy {

    constructor(
        private client: ApiClientService,
        private iamport: IamportService,
        private util: UtilityService,
        private location: Location,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private resizeSvc: ResizeService
    ) {

        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
        });
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.currency = this.util.getCurrentCurrency();
            this.currencykey = 'Common.' + this.currency;
            this.paymethodlist = PayMethodList[this.currency];
            await this.getCouponList();
            this.setPayMethod(this.paymethodlist[0].method, 0);
            this.initOrders(true);
        });
    }

    @ViewChild('couponInput') couponInput!: ElementRef<HTMLInputElement>;

    ApiUrl = this.client.baseurl;
    orders = this.client.getOrders() as Data.OrderAsset[];
    items: Data.OrderAsset[] = [];

    originPrice = 0;
    discountPrice = 0;
    couponDcPrice = 0;

    paymentPrice = this.client.getTotalPrice();
    orderNo = '';
    completed = false;
    firstOption = CommonUtil.assetFirstOption;
    withComma = this.util.withComma;
    ObjectKeys = Object.keys;
    getOption = (asset: Data.Asset, type: string) =>
        asset.options[type as Data.AssetOptionType] as Data.AssetOption;


    getKey = (options: {
        [key in Data.AssetOptionType]?: Data.AssetOption;
    }): Data.AssetOptionType => {
        const optionKeys = Object.keys(options);
        const firstOptionKey = (optionKeys[0]) as Data.AssetOptionType;
        return firstOptionKey;
    }


    showOrder = true;
    showComplete = false;
    showError = false;
    redirected = false;

    merchant_uid = '';
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;
    paymethodlist = PayMethodList[this.currency];
    paymethod = this.paymethodlist[0].method;
    transdisabled = false;
    onLangChangeSub: Subscription | null = null;

    licenseType: Data.LicenseType = 'personal';
    licenseUsername = '';
    showLicensePopup = false;

    initCheck = false;

    screenSize = this.resizeSvc.getCurrent();

    couponList: Data.Coupon[] = [];
    dcList: number[] = [];

    initPriceCheck = false;

    @ViewChild('username') username!: ElementRef<HTMLSpanElement>;
    @ViewChild('mo_header') mo_header!: ElementRef<HTMLDivElement>;

    ngOnInit(): void {
        window.scrollTo(0, 0);

        const imp_uid = this.route.snapshot.queryParamMap.get('imp_uid');
        const merchant_uid = this.route.snapshot.queryParamMap.get('merchant_uid');
        const imp_success = this.route.snapshot.queryParamMap.get('imp_success') === 'true';
        const message = this.route.snapshot.queryParamMap.get('error_msg');
        const url = this.route.snapshot.url;

        console.log(imp_uid, merchant_uid, url);


        if (url.length === 2 && merchant_uid !== null && imp_uid !== null) {
            if (url[1].path.startsWith('paypal')) {
                this.redirected = true;
                this.merchant_uid = merchant_uid;
                const infostr = url[1].path.substring(6);
                const info = JSON.parse(decodeURI(window.atob(infostr.replace(/_/g, '='))));
                if (imp_success) {
                    // paypal인경우 항상 USD
                    this.postPayment(imp_uid, merchant_uid, 'USD', info);
                } else if (message?.startsWith('User cancelled payment process')) {
                    // pg closed by user
                    this.initOrders();
                } else {
                    console.error('payment failed : paypal error');
                    this.showOrder = false;
                    this.showError = true;
                }
            } else if (url[1].path.startsWith('complete')) {
                this.redirected = true;
                this.merchant_uid = merchant_uid;
                const infostr = url[1].path.substring(8);
                const info = JSON.parse(decodeURI(window.atob(infostr.replace(/_/g, '='))));
                if (imp_success) {
                    // paypal이 아닌 경우 항상 KRW
                    this.postPayment(imp_uid, merchant_uid, 'KRW', info);
                } else if (message?.startsWith('F0005:결제가 중단되었습니다')) {
                    // pg closed by user
                    this.initOrders();
                } else {
                    console.error('payment failed : error on payment redirect');
                    this.showOrder = false;
                    this.showError = true;
                }
            } else {
                this.initOrders();
            }
        } else {
            this.initOrders();
        }

        this.getCouponList();
    }

    initOrders(langChange?: boolean) {
        this.items = [];
        this.orders = this.client.getOrders();

        for (const item of this.orders) {
            const option = this.getKey(item.options)
            //show_couponlist: coupon_dropdown을 개별적으로 관리하기위해함
            item.show_couponlist = false;

            this.dcList.push((item.options[option]?.itemprice as number) - (item.options[option]?.itemdcprice as number))
            this.items.push(item);
        }

        this.preparePayment(langChange);
    }

    ngAfterContentInit(): void {
    }

    ngOnDestroy(): void {

        localStorage.removeItem('dcList');
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }

    setPayMethod(method: PayMethodType, index: number) {
        if (this.transdisabled === false || method !== 'trans') this.paymethod = method;

        const methodList = document.getElementsByClassName('order-paymethod-list');
        // paymethod.classList.add('order-paymethod-checked')

        for (let i = 0; i < methodList.length; i++) {
            if (i !== index) {
                methodList[i].classList.remove('order-paymethod-checked')
            } else {
                methodList[i].classList.add('order-paymethod-checked')
            }
        }
        console.log(this.paymethod);
    }

    async preparePayment(langChange?: boolean) {
        if (this.orders.length === 0) return;

        const info: RequestPurchaseInfo[] = [];
        for (const order of this.orders) {
            const item = {
                assetno: order.assetno,
                optionno: this.firstOption(order).optionno,
                itemid: this.firstOption(order).itemid,
                coupon: ''
            };
            const coupon = this.couponList.find(c => c.applieditemid === item.itemid);
            if (coupon !== undefined) item.coupon = coupon.no;
            info.push(item);
        }
        const requestresult = await this.client.api.RequestPurchase.send({
            list: info, currency: this.util.getCurrentCurrency()
        });

        if (requestresult.message !== 'ok') {

            // TODO 아임포트 결제모듈이 테스트 상태인 동안은 운영서버에서 결제 막음
            if (requestresult.message === 'invalid pgmode') {
                this.util.showMessage({
                    message: '결제모듈 준비중입니다.',
                    type: 'WARN'
                });
                return;
            }
            // TODO ***************************************************************

            // order request failed!!!
            return;
        }

        this.merchant_uid = requestresult.merchant_uid;

        this.paymentPrice = 0;
        this.originPrice = 0;
        this.discountPrice = 0;
        this.couponDcPrice = 0;

        this.dcList = [];

        for (const reqitem of requestresult.list) {
            for (const item of this.items) {
                const option = item.options[this.getKey(item.options)];
                if (option !== undefined && option.itemid === reqitem.itemid) {
                    option.itemprice = reqitem.price;
                    option.itemdcprice = reqitem.dcprice;
                    //언어바뀔때 dcList 하단에서 한번 저장.
                    this.dcList.push(option.itemprice - option.itemdcprice)
                    this.originPrice += option.itemprice;
                    this.paymentPrice += option.itemdcprice;
                    break;
                }

            }
        }

        if (langChange !== undefined || (!this.initPriceCheck)) localStorage.setItem('dcList', JSON.stringify(this.dcList));
        const discoutList = JSON.parse(localStorage.getItem('dcList')!);
        this.discountPrice = discoutList.reduce(((acc: number, cur: number) => acc + cur), 0);
        for (let i = 0; i < this.items.length; i++) {
            const option = this.getKey(this.items[i].options)
            if (this.items[i].coupon_dc === true) {

                this.couponDcPrice += (((this.items[i].options[option]?.itemprice as number)) - discoutList[i]) - ((this.items[i].options[option]?.itemdcprice as number))
            }
        }

        this.initPriceCheck = true;

        this.transdisabled = this.currency === 'KRW' && this.paymentPrice < 150;
    }

    async payment(pgresult: RequestPayCallbackParamType | null = null) {

        this.initCheck = true;

        if (this.licenseUsername.trim() === '') {
            this.username.nativeElement.style.opacity = '1';
            if (!this.screenSize) this.scrollToLicense();

            return;
        }
        if (this.merchant_uid === '') return;

        if (this.paymentPrice === 0) {
            // 쿠폰 등으로만 결제해서 금액이 0인경우
            this.postPayment('', this.merchant_uid, this.currency);
            return;
        }

        if (pgresult === null) {
            let name = this.orders[0].name;
            if (this.orders.length > 1) {
                name = name.substring(0, 8);
                // TODO : translation
                name += '... 외 ' + (this.orders.length - 1) + '건'
            }
            const { userinfo } = this.client.userinfoSubject.getValue();

            const info: ConfirmPurchaseInfo[] = [];
            for (const order of this.orders) {
                const item = {
                    assetno: order.assetno,
                    optionno: this.firstOption(order).optionno,
                    itemid: this.firstOption(order).itemid,
                    name: order.name,
                    price: this.firstOption(order).itemdcprice,
                    coupon: ''
                };
                const coupon = this.couponList.find(c => c.applieditemid === item.itemid);
                if (coupon !== undefined) item.coupon = coupon.no;
                info.push(item);
            }

            pgresult = await this.iamport.requestPay({
                amount: this.paymentPrice,
                buyer_email: userinfo.email,
                buyer_name: userinfo.email,
                buyer_tel: '010-0000-0000',
                digital: true,
                merchant_uid: this.merchant_uid,
                name,
                pay_method: this.paymethod
            }, window.btoa(encodeURI(JSON.stringify(info))).replace(/=/g, '_'));
        }
        console.log(pgresult);

        if (pgresult.success) {
            // paypal인 경우 payment()를 실행하지 않으므로 항상 원화결제
            this.postPayment(pgresult.imp_uid, pgresult.merchant_uid, 'KRW');
        } else {
            console.error('payment failed : ' + pgresult.error_msg);

            if (pgresult.error_msg.startsWith('사용자가 결제를 취소')) {
                setTimeout(() => {
                    this.back();
                    this.back();
                }, 1)
            } else {
                this.showOrder = false;
                this.showError = true;
            }
        }
    }

    async postPayment(imp_uid: string, merchant_uid: string, currency: Data.CurrencyType, iteminfo?: ConfirmPurchaseInfo[]) {
        console.log('!!!!!!!!!!!!!!!!!', imp_uid, merchant_uid, this.util.getCurrentCurrency());
        if (iteminfo === undefined) {
            iteminfo = [];
            for (const order of this.orders) {
                const item = {
                    assetno: order.assetno,
                    optionno: this.firstOption(order).optionno,
                    itemid: this.firstOption(order).itemid,
                    name: order.name,
                    price: this.firstOption(order).itemdcprice,
                    coupon: ''
                };
                const coupon = this.couponList.find(c => c.applieditemid === item.itemid);
                if (coupon !== undefined) item.coupon = coupon.no;
                iteminfo.push(item);
            }
        }
        const confirmresult = await this.client.api.ConfirmPurchase.send({
            imp_uid,
            merchant_uid,
            orderid: '',
            currency,
            licenseinfo: { type: this.licenseType, username: this.licenseUsername.substring(0, 41) },
            list: iteminfo
        });
        console.log(confirmresult);
        if (confirmresult.message === 'ok') {
            // confirm success
            this.orderNo = confirmresult.orderid;
            this.removeItemsFromCart();
            this.showOrder = false;
            this.showComplete = true;

            localStorage.removeItem('OrderList');

        } else {
            // confirm failed
            console.error('confirm failed : ' + confirmresult.message)
            this.showOrder = false;
            this.showError = true;
        }
    }

    async removeItemsFromCart() {
        const removeoptionlist: number[] = [];
        for (let i = 0; i < this.orders.length; i++) {
            const model = this.orders[i]
            const keys = Object.keys(model.options) as Data.AssetOptionType[];
            const option = model.options[keys[0]] as Data.AssetOption;
            removeoptionlist.push(option.optionno);
        }
        if (removeoptionlist.length > 0) {
            await this.client.api.RemoveFromCart.send({
                assetno: 0,
                optionno: removeoptionlist
            });
        }
    }

    back() {
        this.location.back();
    }

    reload() {
        location.reload()
    }

    downloadNow() {
        console.log(this.orders);
        for (const item of this.orders) {
            const option = CommonUtil.assetFirstOption(item);
            this.util.downloadAsset(item.assetno, option.optionno, option.type);
        }
    }

    checkLicensePopupClose(event: Event) {
        if (event.composedPath().findIndex(
            e => (e as HTMLElement).className === 'order-license-popup') > -1) return;
        this.showLicensePopup = false;
    }

    scrollToLicense() {
        const { top, height } = this.mo_header.nativeElement.getBoundingClientRect();
        const scrolledTopLength = window.pageYOffset;
        const scrollToLicense = scrolledTopLength + top - height - 15
        // Util.scrollTo(scrollToLicense)
    }

    async getCouponList() {
        const res = await this.client.api.GetCouponList.send({});
        console.log(res);
        if (res.message === 'ok') {
            this.couponList = res.list;
        }
    }

    getAvailableCoupon(itemid = 0) {
        const result: Data.Coupon[] = [];
        for (const coupon of this.couponList) {
            if (coupon.state !== 'Available') continue;
            if (coupon.applieditemid === itemid) result.push(coupon);
            else if (coupon.applieditemid === 0
                && (coupon.itemid === 0 || coupon.itemid === itemid)) result.push(coupon);
        }
        return result;
    }


    async onCouponChange(itemid: number | undefined, couponno: string, index: number, type = 'Order.SelectCoupon') {
        //쿠폰 input디자인 로직
        const priceContainer = document.getElementById(`order-price-${index}`) as HTMLDivElement
        const icon = document.getElementById(`couponIcon-${index}`) as HTMLDivElement
        const input = document.getElementById(`couponInput-${index}`) as HTMLInputElement
        (type === 'Default' ? type = ''
            : type === 'Welcome' ? type = 'Order.WelcomeCoupon'
                : type === 'InviteFriend' ? type = 'Order.FriendCoupon'
                    : type = type
        )
        priceContainer.style.opacity = '0';
        if (type !== 'Order.SelectCoupon') {
            icon.style.display = 'flex'
            setTimeout(() => {
                priceContainer.style.transition = 'all 0.1s ease-in-out';
                priceContainer.style.opacity = '1';
            }, 1)
            input.classList.add('order-input-active')


        } else {
            icon.style.display = 'none'
            setTimeout(() => {
                priceContainer.style.transition = 'all 0.1s ease-in-out';
                priceContainer.style.opacity = '1';
            }, 1)
            input.classList.remove('order-input-active')
        }

        priceContainer.style.transition = 'none';

        this.items[index].show_couponlist = false;

        if (itemid === undefined) {
            return
        }

        input.value = await this.util.getTranslation(type);

        //쿠폰 적용 로직
        if (couponno === '' && itemid > 0) {
            const coupon = this.couponList.find(c => c.applieditemid === itemid);
            if (coupon !== undefined) coupon.applieditemid = 0;

            this.items[index].coupon_dc = false;

        } else {
            const coupon = this.couponList.find(c => c.no === couponno);
            if (coupon === undefined) return;

            //같은아이템에 대한 적용된 쿠폰 체크 후 이미 적용된 상태면 적용취소.
            const coupon_applied_before = this.couponList.find(c => c.applieditemid === itemid);
            if (coupon_applied_before !== undefined) {
                coupon_applied_before.applieditemid = 0;
            }

            if (coupon.itemid === 0 ||
                (coupon.itemid > 0 && coupon.itemid === itemid)) {
                coupon.applieditemid = itemid;
            }
            this.items[index].coupon_dc = true;
        }

        this.preparePayment();
    }

    toggleDropdown(index: number) {
        this.items[index].show_couponlist = !this.items[index].show_couponlist;

        for (let i = 0; i < this.items.length; i++) {
            if (i !== index) {
                this.items[i].show_couponlist = false;
            }
        }
    }
}
