import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { InputComponent } from '../common/input.component';
import { UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-request-password-reset-popup',
    templateUrl: 'request-password-reset-popup.component.html',
    styleUrls: ['./request-password-reset-popup.component.scss'],
})
export class RequestPasswordResetPopupComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private router: Router,
        private client: ApiClientService,
        private translate: TranslateService,
        private util: UtilityService,
    ) { }

    ngOnInit(): void {
        this.translate.get('Signup.SendPasswordResetMail').subscribe(value => {
            this.mailSentButtonNotice = value;
        });
    }

    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    @ViewChild('email') inputEmailComponent!: InputComponent;
    @ViewChild('alert') alert!: ElementRef<HTMLElement>;
    @ViewChild('mailsendbtn') mailSendBtn!: ElementRef<HTMLElement>;

    mailFlag = true;
    mailSentNotice = '';
    mailSentButtonNotice = '';
    beforeInput = '';
    sendTimer = -1;
    resendTimer = -1;

    close(event?: Event) {
        let flag = false;
        if (event) {
            const div = event.target as HTMLDivElement;
            for (let i = 0; i < div.classList.length; i++) {
                const item = div.classList[i];
                if (item === 'item-popup-background') {
                    flag = true;
                    break;
                }
            }
        } else {
            flag = true;
        }
        if (flag) this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
    }

    async sendMail() {
        if (!this.mailFlag) {
            console.log('Block!');
            return;
        }
        this.mailFlag = false;

        const email = this.inputEmailComponent.getValue();
        if (email === false) {
            console.error('email not valid');
            return;
        }

        this.translate.get('Account.Sending').subscribe(value => {
            this.mailSendBtn.nativeElement.innerHTML = value + '<div class="dots-1"></div>';
        });
        const res = await this.client.api.PasswordResetRequest.send({
            email, lang: this.util.getCurrentLang(), referralcode: ''
        });
        this.translate.get('Signup.ReSendPasswordResetMail').subscribe(value => {
            this.mailSendBtn.nativeElement.innerHTML = value;
        });

        if (res.message === 'ok') {
            this.showMailSendNotice(email);
        }
        this.mailFlag = true;
    }

    async showMailSendNotice(email: string) {
        if (this.sendTimer !== -1) {
            clearTimeout(this.sendTimer);
            this.sendTimer = -1;
        }
        if (this.resendTimer !== -1) {
            clearTimeout(this.resendTimer);
            this.resendTimer = -1;
        }


        if (this.beforeInput !== email) {
            this.mailSentNotice = await this.util.getTranslation('Signup.PasswordResetMailSent');
            this.alert.nativeElement.style.opacity = '1';
            this.sendTimer = window.setTimeout(() => {
                this.sendTimer = -1;
                this.alert.nativeElement.style.opacity = '0';
            }, 3000);
        }
        else {
            this.mailSentNotice = await this.util.getTranslation('Signup.PasswordResetMailResent');
            this.alert.nativeElement.style.opacity = '1';
            this.resendTimer = window.setTimeout(() => {
                this.resendTimer = -1;
                this.alert.nativeElement.style.opacity = '0';
            }, 3000);
        }
        this.beforeInput = email;
    }

}
