import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-business-carousel',
    templateUrl: './business-carousel.component.html',
    styleUrls: ['./business-carousel.component.scss']
})
export class BusinessCarouselComponent implements OnInit {

    @Input() index!: number;
    @Input() imgList!: number[];
    @Input() page!: string;

    currenIdx = 1;

    hover_prev = false;
    hover_next = false;
    constructor() { }

    ngOnInit(): void {
    }

    moveCarousel(direction: number) {
        let newDirection = this.currenIdx + direction;

        if (newDirection > this.imgList.length) this.currenIdx = 1;
        else if (newDirection < 1) this.currenIdx = this.imgList.length;
        else this.currenIdx = newDirection;
    }

}
