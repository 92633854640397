<div #root id="signin-ui-modal" class="signin-container">
    <form #form class="signin-wrap">
        <h3 class="signin-title" [innerHTML]="'Common.Login'|translate">로그인</h3>
        <div class="signin-input-wrap">
            <label for="{{idPrefix}}email">
                <span class="signin-span" [innerHTML]="'Common.Email'|translate"></span>
            </label>
            <input class="sigin-input" id="{{idPrefix}}email" type="text"
                [placeholder]="'Common.Email'|translate" (keyup.enter)="validation()" />
            <label for="{{idPrefix}}password">
                <span class="signin-span" [innerHTML]="'Common.Password'|translate"></span>
            </label>
            <input class="sigin-input" id="{{idPrefix}}password" type="password"
                [placeholder]="'Common.Password'|translate" (keyup.enter)="validation()" />
        </div>
        <div class="signin-error-message">
            <span>
                {{desc}}
            </span>

        </div>

        <button type="submit" class="signin-button common-button1"
            [innerHTML]="'Common.Login'|translate" (click)="signIn()"></button>



        <div class="signin-pagemove-container">
            <ul class="signin-pagemove-title">
                <li [innerHTML]="'Signup.ResetPassword'|translate" (click)="resetPasswordPage()">
                    비밀번호 재설정</li>
            </ul>
        </div>

        <div class="signin-dividier-horizontal"></div>

        <div class="signin-signup-container">
            <div [innerHTML]="'Signin.SigninDesc1'|translate">지금 회원가입하고 무료로 에셋을 다운받으세요!</div>
            <button [innerHTML]="'Signup.Signup'|translate"
                class="common-button2 signin-signup-button" (click)="goToSignup()">JOIN</button>
        </div>

    </form>
    <div id="signin-close-button" (click)="close()">
        <img src="/assets/icon/close_24.svg" />
    </div>
</div>