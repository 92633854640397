<div class="admin-index-outer">
    <div class="admin-index-refresh-button-outer flex-wrap">
        <div class="admin-index-refresh-button" style="color:orange;" (click)="refresh()">다시 읽어오기
        </div>
        <div class="admin-index-refresh-button" (click)="load()">업데이트 확인</div>
    </div>
    <div class="admin-index-itemlist-outer">
        <div>등록된 상품
            <span class="admin-index-itemlist-filter">
                <span style="color:chartreuse;">{{assetlist_all.KRW.length}}</span>개
                <span>
                    <button [class]="viewfilter_price==='All'?'activated':''"
                        (click)="setViewFilter('All', null)">모두 보기</button>
                    <button [class]="viewfilter_price==='SpecialDeal'?'activated':''"
                        (click)="setViewFilter('SpecialDeal', null)">무료에셋</button>
                    <button [class]="viewfilter_price==='NotDefaultPrice'?'activated':''"
                        (click)="setViewFilter('NotDefaultPrice', null)">기본가격 제외</button>
                </span>
                <!-- <span style="color:chartreuse;">{{assetlist_all.KRW.length}}</span>개
                <span *ngIf="viewfilter_price==='SpecialDeal'">
                    중<span style="color:orange;">{{assetlist_specialdeal.KRW.length}}</span>개
                </span>
                <span *ngIf="viewfilter_price==='NotDefaultPrice'">
                    중<span style="color:orange;">{{assetlist_notdefaultprice.KRW.length}}</span>개
                </span> -->
            </span>
            <!-- <span class="admin-index-itemlist-filter" style="margin-left:50px;">
                <button [class]="viewtype==='Icon'?'activated':''"
                    (click)="setViewType('Icon')">아이콘보기</button>
                <button [class]="viewtype==='List'?'activated':''"
                    (click)="setViewType('List')">목록보기</button>
            </span> -->
            <span class="admin-index-itemlist-filter" style="margin-left:50px;">
                <button [class]="viewfilter_type==='All'?'activated':''"
                    (click)="setViewFilter(null,'All')">모두보기</button>
                <button [class]="viewfilter_type==='Human'?'activated':''"
                    (click)="setViewFilter(null,'Human')">사람</button>
                <button [class]="viewfilter_type==='Posed'?'activated':''"
                    (click)="setViewFilter(null,'Posed')">Posed</button>
                <button [class]="viewfilter_type==='APosed'?'activated':''"
                    (click)="setViewFilter(null,'APosed')">A-Posed</button>
                <button [class]="viewfilter_type==='Object'?'activated':''"
                    (click)="setViewFilter(null,'Object')">사물</button>
                <button [class]="viewfilter_type==='Space'?'activated':''"
                    (click)="setViewFilter(null,'Space')">공간</button>
            </span>
        </div>

        <!-- IconView -->
        <div class="admin-index-itemlist-inner flex-wrap"
            *ngIf="assetlist_view.KRW.length>0 && viewtype === 'Icon'">
            <div *ngFor="let asset of assetlist_view.KRW" (click)="showAsset(asset)"
                class="admin-index-item-iconview">
                <div><img src="{{asset.image1}}"></div>
                <div class="flex-wrap">
                    <div style="color:orange;">{{asset.assetno}}</div>
                    <div style="color:palevioletred;">{{getAssetType(asset)}}</div>
                    <div style="color:yellowgreen;" *ngFor="let item of asset.options|keyvalue">
                        {{item.key}}
                    </div>
                    <div *ngIf="asset.tag.includes('SpecialDeal')" style="color:tomato;">무료!
                    </div>
                </div>
            </div>
        </div>

        <div class="admin-index-itemlist-inner" style="overflow-x:scroll;"
            *ngIf="assetlist_view.KRW.length>0 && viewtype === 'List'">
            <div *ngFor="let asset_krw of assetlist_view.KRW;let index = index"
                (click)="showAsset(asset_krw)" class="admin-index-item-listview">
                <div *ngIf="assetlist_view.USD[index] as asset_usd">
                    <span
                        style="width:36px;text-align:right;color:orange;">{{asset_krw.assetno}}</span>
                    <span style="width:30px;color:palevioletred;">{{
                        asset_krw.tag.includes('사람') ? '사람'
                        : asset_krw.tag.includes('사물') ? '사물'
                        : asset_krw.tag.includes('공간') ? '공간' : '기타'
                        }}</span>
                    <span style="width:36px;text-align:right;color:cornflowerblue;">{{
                        asset_krw.popularity}}</span><span>점</span>
                    <span style="color:lightgreen;">{{asset_krw.name}}</span>
                    <span> / </span>
                    <span style="color:lightcoral;">{{asset_usd.name}}</span>
                </div>
                <div>
                    <div *ngFor="let item of asset_krw.options|keyvalue;let optionindex = index"
                        class="admin-index-item-listview-option">
                        <section *ngIf="{
                            krw:(assetlist_view.KRW[index].options|keyvalue)[optionindex].value,
                            usd:(assetlist_view.USD[index].options|keyvalue)[optionindex].value
                        } as option">
                            <span style="color:yellowgreen;">{{item.key}}</span>
                            <span>
                                KRW<span style="color:tomato;display:inline;">{{
                                    option.krw?.itemprice}}</span>/<span
                                    style="color:tomato;display:inline;">{{
                                    option.krw?.itemdcprice}}</span>
                                USD<span style="color:tomato;display:inline;">{{
                                    option.usd?.itemprice}}</span>/<span
                                    style="color:tomato;display:inline;">{{
                                    option.usd?.itemdcprice}}</span>
                            </span>
                            <span style="color:palegreen;">{{
                                calcMB(option.krw === undefined ? 0 : option.krw.size)}}MB</span>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- 2024-03-28 등록 전 상품은 storeinfo에서 확인 -->
    <!-- <div class="admin-index-itemlist-outer" *ngIf="newassetlist.length>0">
        <div>등록되지 않은 상품</div>
        <div class="admin-index-itemlist-inner flex-wrap">
            <div *ngFor="let info of newassetlist;let index = index" (click)="showNewAsset(index)"
                class="admin-index-item-iconview">
                <div><img src="/models/{{info.assetno}}/{{info.thumbnail}}"></div>
                <div class="flex-wrap">
                    <div style="color:orange;">{{info.assetno}}</div>
                    <div style="color:palevioletred;">{{info.assettype}}</div>
                    <div style="color:yellowgreen;" *ngFor="let item of info.options|keyvalue">
                        {{item.key}}
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div div class="admin-index-itemdetail-outer" *ngIf="asset_krw!==null && asset_usd!==null"
    [style.display]="showItemDetail?'block':'none'">
    <div class="admin-index-itemdetail">
        <div class="admin-index-itemdetail-close-button" (click)="closeDetail()">
            <img src="assets/icon/close.svg">
        </div>
        <div class="flex-wrap">
            <div>번호 <span style="color:orange;">{{asset_krw.assetno}}</span></div>
            <div>등록일시 <span style="color:cornflowerblue;">{{formatDate(asset_krw.regdate)}}</span>
            </div>
            <div>인기도 <span style="color:cornflowerblue;">{{asset_krw.popularity}}</span></div>
            <div>이름[ko] <span style="color:lightgreen;">{{asset_krw.name}}</span></div>
            <div>이름[en] <span style="color:lightcoral;">{{asset_usd.name}}</span></div>
        </div>
        <div class="flex-wrap" style="display:none;">
            <div>
                Flag <span *ngFor="let flagstring of ESCList" style="color:burlywood;">
                    {{checkFlag(asset_krw, flagstring) ? flagstring : ''}}</span>
            </div>
        </div>
        <div class="flex-wrap">
            <div>Tags</div>
            <div *ngFor="let tag of asset_tags" class="admin-index-tagitem">
                <span *ngIf="tags_not_removed.includes(tag)===false"
                    style="color:orange;cursor:pointer;" (click)="removeTag(tag)">x</span>
                <span [style.color]="tag[0]==='_'?'deepskyblue':''"> {{tag}} </span>
                <span style="color:red;font-size:10px;"
                    *ngIf="getTagCount(tag) as count">{{count}}</span>
            </div>
        </div>
        <div class="flex-wrap">
            <div *ngFor="let option of asset_options" class="admin-index-itemdetail-option-outer">
                <div>
                    <span style="color:orange;cursor:pointer;"
                        (click)="setViewer(option.KRW.type)">{{('Search.'+option.KRW.type)|translate}}</span>
                    <span style="display:inline-block;margin-left:10px;">
                        <button
                            class="admin-index-checkbox-button {{asset_defaultprice[option.KRW.type]?'activated':''}}"
                            (click)="toggleDefaultPrice(option.KRW.type)">
                            <input type="checkbox" id="admin-index-specialdeal"
                                [(ngModel)]="asset_defaultprice[option.KRW.type]">
                            <label for="admin-index-specialdeal"></label>
                            <div>기본설정 가격</div>
                        </button>
                    </span>
                </div>
                <div class="admin-index-itemdetail-price-outer"
                    *ngFor="let currency of CurrencyType">
                    <span style="color:yellowgreen;">[{{currency}}]</span>
                    가격 <input type="text" [(ngModel)]="option[currency].itemprice"
                        [readOnly]="asset_defaultprice[option[currency].type]">
                    할인가격 <input type="text" [(ngModel)]="option[currency].itemdcprice"
                        [readOnly]="asset_defaultprice[option[currency].type]">
                    할인율 <span style="color:aquamarine;">{{calcDCRate(option[currency].itemprice,
                        option[currency].itemdcprice)}}%</span>
                </div>
                <div>
                    <span style="color:yellow;">Sketchfab ID</span>
                    &nbsp;
                    <input type="text" [(ngModel)]="option.KRW.info.viewerid" style="width:300px;">
                </div>
                <div>다운로드크기 <span style="color:palegreen;">{{calcMB(option.KRW.size)}}MB</span>
                </div>
                <div *ngFor="let info of [option.KRW.info]">
                    파일 형식 <span style="color:violet;">{{info.format}}</span>
                    텍스쳐 형식 <span style="color:violet;">{{info.texture.format}}</span>
                    텍스쳐 셋 갯수 <span style="color:orangered;">{{info.texture.count}}</span>
                    텍스쳐 맵 <span style="color:orchid;">{{info.texture.maps}}</span>
                    폴리곤 <span style="color:tomato;">{{info.polygons}}</span>
                </div>
            </div>
        </div>
        <div class="admin-index-itemdetail-controls-outer flex-wrap">
            <div>이름[ko] <input type="text" class="input-name" [(ngModel)]="asset_krw.name"></div>
            <div>이름[en] <input type="text" class="input-name" [(ngModel)]="asset_usd.name"></div>
            <div>
                <div class="admin-index-select-box noselect">{{selectCategory}}</div>
                <span *ngIf="selectCategory==='Human'">
                    <select [(ngModel)]="selectCategoryHumanSex">
                        <option value="Male">남성</option>
                        <option value="Female">여성</option>
                    </select>
                    <select [(ngModel)]="selectCategoryHumanAge">
                        <option value="Age_0">어린이</option>
                        <option value="Age_10">10대</option>
                        <option value="Age_20">20대</option>
                        <option value="Age_30">30대</option>
                        <option value="Age_40_50">40-50대</option>
                        <option value="Age_60_">60대 이상</option>
                    </select>
                    <select [(ngModel)]="selectCategoryHumanType">
                        <option value="Type_Posed">Posed</option>
                        <option value="Type_APosed">A-Posed</option>
                        <option value="Type_Rigged">Rigged</option>
                        <option value="Type_Facial">Facial</option>
                    </select>
                </span>

            </div>
            <div *ngIf="selectCategory!=='Human'">
                <div class="admin-index-select-box2 noselect">
                    <div style="width: 100%; height: 100%;"
                        (click)="showSubCategory=!showSubCategory">
                        <span>하위 카테고리</span>
                        <img uk-svg src="/assets/icon/chevron_down_20.svg" />
                    </div>

                    <div class="admin-index-subcategory-options" *ngIf="showSubCategory">
                        <div class="admin-index-subcategory-option"
                            [style.display]="selectCategory === 'Space'? ((subCategory.en === 'interior') || (subCategory.en === 'exterior')? 'flex': 'none'):'flex'"
                            *ngFor="let subCategory of subCategoryList; let index = index; let first=first; let last=last">
                            <input id="admin-index-subcategory-{{subCategory.en}}" type="checkbox"
                                (click)="toggleSubcategory(subCategory)"
                                [checked]="checkSubcategory(subCategory)" />
                            <label for="admin-index-subcategory-{{subCategory.en}}"></label>
                            <div>
                                <div class="admin-index-subcategory">{{subCategory.kr}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>태그 <input type="text" [(ngModel)]="tmp_tag" (keyup.enter)="onTagSubmit()"></div>
            <div>
                <input type="submit" class="input-button" value="태그추가" (click)="onTagSubmit()">
            </div>
            <div>
                <button class="admin-index-checkbox-button {{asset_specialdeal?'activated':''}}"
                    (click)="toggleSpecialDeal()">
                    <input type="checkbox" id="admin-index-specialdeal"
                        [(ngModel)]="asset_specialdeal">
                    <label for="admin-index-specialdeal"></label>
                    <div>무료에셋</div>
                </button>
            </div>
            <div>
                <input type="submit" class="input-button input-button-bold" style="color:skyblue;"
                    value="저장" (click)="updateProduct()">
            </div>
            <div>
                <input type="submit" class="input-button input-button-bold"
                    style="color:palevioletred;" value="삭제"
                    (click)="deleteProduct(asset_krw.assetno)">
            </div>
        </div>
        <div class="admin-index-itemdetail-image-outer flex-wrap">
            <div>
                <div class=" flex-wrap">
                    <div style="color:coral;">3DView</div>
                </div>
                <div class=" flex-wrap">
                    <!-- <button (click)="changeBGColor()">
                        <span style="display:flex;align-items:center;">배경색
                            <div style="width:12px;height:12px;margin-left:3px;"
                                [style.background-color]="viewerBGColorList[viewerBGColorIndex]">
                            </div>
                        </span>
                    </button> -->
                    <button [class]="canvas_wireframe?'activated':''"
                        (click)="canvas_wireframe = threeviewer.setWireframe()">와이어프레임</button>
                    <button [class]="canvas_background?'activated':''"
                        (click)="canvas_background = threeviewer.setBackground()">배경</button>
                    <button [class]="canvas_logo?'activated':''"
                        (click)="canvas_logo = threeviewer.setLogo()">로고</button>
                </div>
                <div class="flex-wrap">
                    <button (click)="setDefaultCamera()">기본위치</button>
                    <button (click)="threeviewer.rotateCameraY(45)">오른쪽으로 45도 회전</button>
                    <button (click)="threeviewer.rotateCameraY(-45)">왼쪽으로 45도 회전</button>
                    <button (click)="threeviewer.setCameraOnTop()">위에서보기</button>
                </div>
                <div class="flex-wrap" style="margin:5px 0;">
                    <div class="admin-index-viewer-outer">
                        <div class="admin-index-viewer-message">처리중...</div>
                        <div id="threeviewer" style="position:absolute;"
                            [style.background-color]="viewerBGColorList[viewerBGColorIndex]"
                            [style.box-shadow]="'0 0 5px 5px ' + viewerBGColorList[viewerBGColorIndex]">
                        </div>
                    </div>
                    <div style="max-width:300px;margin-left:20px;">
                        <app-asset-viewer-control [three]="threeviewer"></app-asset-viewer-control>
                    </div>
                </div>
                <div class="flex-wrap" style="width:100%;">
                    <span>보이는 이미지 저장</span>
                    <button (click)="capture(1920,1080)">1920x1080</button>
                    <button (click)="capture(1200,1200)">1200x1200</button>
                    <button (click)="captureTransparentBG(1920,1080)">1920x1080(투명배경)</button>
                    <button (click)="captureTransparentBG(1200,1200)">1200x1200(투명배경)</button>
                </div>
                <div class="flex-wrap" style="width:100%;">
                    <span class="tooltip" tooltip-text="미러 상품페이지 용 이미지
                        1200x1200 정면
                        1200x1200 90도
                        1200x1200 180도
                        1200x1200 270도
                        1200x1200 정면 와이어프레임">모든 이미지 생성(<span
                            style="color:orange;">미러</span>)</span>
                    <button (click)="captureAll_merror(false, false)">배경, 로고 모두 없음</button>
                    <button (click)="captureAll_merror(true, false)">배경 있음, 로고 없음</button>
                    <button (click)="captureAll_merror(false, true)">배경 없음, 로고 있음</button>
                    <button (click)="captureAll_merror(true, true)">배경, 로고 모두 있음</button>
                    <button (click)="captureAll_merror(false, false, true)">배경 투명, 로고 없음</button>
                </div>
                <div class="flex-wrap" style="width:100%;">
                    <span class="tooltip" tooltip-text="제휴몰 용 이미지
                        1920x1080 정면
                        1920x1080 45도
                        1920x1080 90도
                        1920x1080 180도
                        1920x1080 270도
                        1920x1080 315도
                        1920x1080 윗면
                        1920x1080 정면 와이어프레임 
                        1920x1080 180도 와이어프레임 
                        1920x1080 45도 와이어프레임
                        1200x1200 정면">모든 이미지 생성(<span style="color:tomato;">제휴몰</span>)</span>
                    <button (click)="captureAll_3rdparty(false, false)">배경, 로고 모두 없음</button>
                    <button (click)="captureAll_3rdparty(true, false)">배경 있음, 로고 없음</button>
                    <button (click)="captureAll_3rdparty(false, true)">배경 없음, 로고 있음</button>
                    <button (click)="captureAll_3rdparty(true, true)">배경, 로고 모두 있음</button>
                    <button (click)="captureAll_3rdparty(false, false, true)">배경 투명, 로고 없음</button>
                </div>
            </div>
            <!-- <div>
                <div style="color:orange;">thumbnail 1024x1024</div>
                <img src="/admin/thumbnail/{{asset_krw.assetno}}-TH">
            </div>
            <div>
                <div style="color:orange;">opengraph thumbnail(link preview) 1024x640</div>
                <img src="/admin/thumbnail/{{asset_krw.assetno}}-TH-OG">
            </div> -->
        </div>
        <div class="admin-index">
        </div>
    </div>
</div>

<div style="display:none;">
    <div>
        <button (click)="checkAssetSource()">CheckAssetSource</button>
        <button (click)="checkAssetFiles()">CheckAssetFiles</button>
        <button (click)="updateAssetFiles()">UpdateAssetFiles</button>
        <button (click)="getAssetFileInfo()">GetAssetFileInfo</button>
        <span *ngIf="waiting">waiting server finish job</span>
    </div>
</div>

<div #popup class="admin-index-popup-outer">
    <div #consolelog id="consolelog">
        <span class="admin-index-popup-rate">업데이트 진행률</span>
        <progress class="admin-index-popup-progressbar" [value]="progressValue"
            [max]="progressMaxValue"></progress>


        <span #zip id="zip-update">zip파일을 만드는중입니다.{{this.incompleteZip}}개 남았습니다.</span>
        <span #thumbnail id="thumbnail-update">썸네일을 만드는중입니다.{{this.incompleteTH}}개 남았습니다.</span>
        <span #update_done id="update-done">업데이트가 완료되었습니다.</span>
    </div>
    <div #productinfo id="productinfo"></div>
    <div #updateproduct id="updateproduct">등록/업데이트 하시겠습니까?</div>
    <div class="admin-index-popup-button-outer">
        <div #button_ok class="admin-index-popup-button" (click)="onPopupOk()">확인</div>
        <div #button_cancel class="admin-index-popup-button" (click)="closePopup()">취소</div>
    </div>
</div>