<!-- header시작 -->
<app-header [userinfo]="userinfo" [cartcount]="cartcount" [favoritecount]="favoritecount"
    [desktopSize]="desktopSize"></app-header>
<!-- header끝 -->

<div>
    <div id="router-outlet-main" style="background-color: #313131;"
        [style.min-height]="desktopSize ? '640px' : ''">
        <router-outlet></router-outlet>
    </div>
    <div id="router-outlet-productdetail" style="background-color: #313131;display:none;">
        <router-outlet name="productdetail"></router-outlet>
    </div>
</div>

<app-common-popup *ngIf="showPopup === true" (changeLang)="setLang($event)"
    [lang]="lang"></app-common-popup>

<!-- footer시작 -->
<app-footer></app-footer>
<!-- footer끝 -->

<!-- mobile일때 하단 네비게이션바 -->
<app-global-navigation [style.display]="desktopSize? 'none': 'block'"
    [cartcount]="cartcount"></app-global-navigation>

<router-outlet name="popup"></router-outlet>
<div id="signin-background" *ngIf="showLoginPopup && userinfo.userno === 0"
    (mousedown)="closeSigninPopup($event)">
    <app-popup-nav [title]="'Common.Login' | translate" [popup]="'login'"></app-popup-nav>
    <app-signin-ui (closed)="toggleUserNav(false)"> </app-signin-ui>
</div>