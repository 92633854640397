import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiClientService } from '../../lib/api-client.service';
import { Data } from 'src/server/lib/types';
import { CookieUtil } from 'src/app/lib/util';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-referral-event',
    templateUrl: './referral-event.component.html',
    styleUrls: ['./referral-event.component.scss']
})
export class ReferralEventComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('popup') popup!: ElementRef;
    currentLang = 'en';
    onLangChangeSub: Subscription | null = null;

    userinfo: Data.User = {
        userno: 0,
        userid: 0,
        email: '',
        password: '',
        status: 'NotVerified',
        recv_email: false,
        regdate: new Date()
    };

    constructor(
        private client: ApiClientService,
        public translate: TranslateService,
        private router: Router
    ) {

    }

    ngOnInit(): void {

        window.scrollTo(0, 0);
        const userinfo = this.client.userinfoSubject.asObservable();

        userinfo.subscribe(info => {
            this.userinfo = info.userinfo
        });

        this.currentLang = CookieUtil.getCookie('Lang')

        this.onLangChangeSub = this.translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        })

    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {

    }

    openCopyPopup() {
        this.popup.nativeElement.style.opacity = '1';

        setTimeout(() => {
            this.popup.nativeElement.style.opacity = '0';
        }, 2000);
    }

    openLoginPopup() {
        this.client.openPopupSubject.next({ url: '', openPopup: true });
    }

    moveToMyCoupon() {
        this.client.accountLocationSubject.next({ location: 'coupon' });
        this.router.navigate(['/account'], { state: { mycoupon: true } });
    }
}
