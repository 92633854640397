import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ThreeCanvas } from "src/app/lib/threecanvas";
import { Data } from "src/server/lib/types";
import { Material, Mesh, MeshStandardMaterial, SkinnedMesh, Texture } from "three";

@Component({
    selector: 'app-asset-viewer-control',
    templateUrl: './asset-viewer-control.component.html',
    styleUrls: ['./asset-viewer-control.component.scss']
})
export class AssetViewerControlComponent implements OnInit {
    @Input() three!: ThreeCanvas;
    init = false;
    s = this.sanitization.bypassSecurityTrustResourceUrl;

    constructor(private sanitization: DomSanitizer) {
    }

    ngOnInit(): void {
    }

    getMeshInfo() {
        if (this.three === undefined || this.three.object === null) return;

        let vertices = 0;
        let triangles = 0;
        this.three.object.traverse(object => {
            if (object instanceof Mesh || object instanceof SkinnedMesh) {
                vertices += object.geometry.attributes.position.count;
                if (object.geometry.index === null) {
                    triangles += object.geometry.attributes.position.count / 3;
                } else {
                    triangles += object.geometry.index.count / 3;
                }
            }
        });
        return { vertices, triangles };
    }

    getMapInfo() {
        const mapinfo: {
            [key in Data.TextureMapType]?: Texture[];
        } = {};

        for (const type of Data.TextureMapTypes) {
            if (this.three.texturelist[type].length > 0) mapinfo[type] = this.three.texturelist[type];
        }
        return mapinfo;
    }
    textureType(key: string): Data.TextureMapType {
        return key as any;
    }
}