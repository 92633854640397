import { Component, OnInit } from '@angular/core';
import { AdminClientService } from 'src/app/lib/api-client.service';
import { AssetCache, CacheAssetList } from 'src/server/lib/assetcache';
import { AssetFileInfo } from 'src/server/lib/assetutil';
import { SketchfabStatusDetail } from 'src/server/lib/sketchfabutil';
import { StoreCategoryItem, StoreInfoItem } from 'src/server/lib/storeinfo';
import { Data, ProductInfo } from 'src/server/lib/types';

type StoreInfoItemFiltered = StoreInfoItem & {
    status: string;
    sketchfab_title: string;
    sketchfab_price: number | null;
    tags_merror: string[];
    tags_sketchfab: string[];
    flag: number;
};

@Component({
    selector: 'app-admin-storeinfo',
    styleUrls: ['./admin-storeinfo.component.scss', '../common/spinner.scss'],
    templateUrl: './admin-storeinfo.component.html',
})
export class AdminStoreinfoComponent implements OnInit {

    constructor(
        private admin: AdminClientService
    ) { }
    private storeinfo: StoreInfoItem[] = [];
    private categoryinfo: StoreCategoryItem[] = [];
    assetnolist: number[] = [];
    storeinfo_filtered: StoreInfoItemFiltered[] = [];
    storeinfo_filtered_byasset: StoreInfoItemFiltered[][] = [];
    assetinfolist: AssetFileInfo[] = [];
    lastassetno = 0;
    assetcache: CacheAssetList = { KRW: [], USD: [] };

    ngOnInit(): void {
        this.init();
    }
    private async init() {
        await this.admin.send({ command: 'GetAssetCache' }, data => {
            this.assetcache.KRW = data.KRW;
            this.assetcache.USD = data.USD;
        });
        await this.admin.send({ command: 'GetStoreInfo' },
            (result: { storeinfo: StoreInfoItem[]; categoryinfo: StoreCategoryItem[]; }) => {
                this.storeinfo = result.storeinfo;
                this.categoryinfo = result.categoryinfo;

                for (const item of this.storeinfo) {
                    if (item.assetno <= 1251) continue;
                    if (this.assetcache.KRW.findIndex(ac => ac.assetno === item.assetno) > -1) continue;

                    const filteritem: StoreInfoItemFiltered = item as any;
                    filteritem.status = 'waiting to processed...';

                    const sketchfab_title = item.title_en
                        + (item.title_ext1.length > 0 ? ' ' + item.title_ext1 : '')
                        + (item.title_ext2.length > 0 ? ' ' + item.title_ext2 : '');
                    filteritem.sketchfab_title = sketchfab_title;
                    filteritem.sketchfab_price = 0;
                    filteritem.tags_sketchfab = [...item.tags_en, ...item.tags_ext];

                    const { tags, flag } = this.checkTagAndFlag([...item.tags_ko, ...item.tags_en], item);
                    filteritem.tags_merror = tags
                    filteritem.flag = flag;

                    this.storeinfo_filtered.push(filteritem);
                    if (this.assetnolist.includes(item.assetno) === false) this.assetnolist.push(item.assetno);
                }
                this.assetnolist.sort((a, b) => a - b);
                for (const assetno of this.assetnolist) {
                    this.storeinfo_filtered_byasset.push(
                        this.storeinfo_filtered.filter(item => item.assetno === assetno)
                    );
                }
                this.checkAssetFiles();
            }
        );
        this.lastassetno = this.assetcache.KRW[0].assetno;

    }

    makeCategoryStrArray(category: string, filter: string) {
        const list: string[] = [];
        const index = ['human', 'object', 'space'].indexOf(category);
        list.push(['사람', '사물', '공간'][index]);
        if (category === 'object' && filter.startsWith('2')) list.push('의류');
        const c = this.categoryinfo.find(item => item.category === category && item.filter === filter);
        if (c !== undefined) list.push(c.name.ko);
        return list;
    }
    isMerrorUploadable(storeinfolist: StoreInfoItemFiltered[]) {
        let uploadable = true;
        if (this.assetcache.KRW.findIndex(ac => ac.assetno === storeinfolist[0].assetno) > -1) return false;
        for (const info of storeinfolist) {
            if (info.sketchfab_uid.length === 0 || info.status !== 'sketchfab uploaded') {
                uploadable = false;
                break;
            }
        }
        return uploadable;
    }
    async updateOnMerror(storeinfolist: StoreInfoItemFiltered[]) {
        if (this.isMerrorUploadable(storeinfolist) === false) return;
        const assetinfo = this.assetinfolist.find(item => item.assetno === storeinfolist[0].assetno);
        if (assetinfo === undefined
            || assetinfo.asset_krw === null || assetinfo.asset_usd === null) return;

        document.getElementById('button_merror_update_' + storeinfolist[0].assetno)!
            .setAttribute('disabled', 'true');

        const productinfo: ProductInfo = {
            assetno: assetinfo.assetno,
            name: {
                KRW: assetinfo.asset_krw.name,
                USD: assetinfo.asset_usd.name
            },
            vieweridinfo: {},
            priceinfo: {},
            tags: assetinfo.asset_krw.tag as string[],
        };
        let isfree = false;
        for (const info of storeinfolist) {
            if (info.isfree) isfree = true;
            productinfo.vieweridinfo[info.option] = info.sketchfab_uid;
            productinfo.priceinfo[info.option] = {
                KRW: { originprice: info.price_krw, dcprice: info.dcprice_krw },
                USD: { originprice: info.price_usd, dcprice: info.dcprice_usd }
            }
        }

        await this.admin.send({
            command: 'UpdateProduct', data: { productinfo, specialdeal: isfree }
        }, data => console.log(data));
        await this.admin.send({ command: 'GetAssetCache' }, data => {
            this.assetcache.KRW = data.KRW;
            this.assetcache.USD = data.USD;
        });

        document.getElementById('button_merror_update_' + storeinfolist[0].assetno)!
            .removeAttribute('disabled');
    }

    private async checkAssetFiles() {
        this.assetnolist.forEach(async assetno => {
            this.storeinfo_filtered.filter(item => item.assetno === assetno).forEach(
                item => item.status = 'check source files and copy to server...');

            await this.admin.send({ command: 'CheckAssetSource', data: { from: assetno, to: assetno } },
                data => console.log(data)
            );

            this.storeinfo_filtered.filter(item => item.assetno === assetno).forEach(
                item => item.status = 'update asset archive files...');

            await this.admin.send({ command: 'UpdateAssetFiles', data: { from: assetno, to: assetno } },
                data => console.log(data)
            );

            this.storeinfo_filtered.filter(item => item.assetno === assetno).forEach(
                item => item.status = 'getting files status...');

            let assetinfo: AssetFileInfo = {} as any;
            await this.admin.send({ command: 'GetAssetFilesInfo', data: { from: assetno, to: assetno } },
                data => {
                    assetinfo = data[0];
                }
            );
            console.log(assetinfo);
            this.assetinfolist.push(assetinfo);

            const storeinfolist = this.storeinfo_filtered.filter(item => item.assetno === assetno);
            storeinfolist.forEach(item => {

                item.status = 'checking sketchfab...';

                if (item.sketchfab_title.length > 48) {
                    item.status = 'sketchfab_title is too long (max 48 chars)';
                    return;
                }

                if (item.sketchfab_categories.length === 0) {
                    item.status = 'no sketchfab category';
                    return;
                }

                if (item.sketchfab_uid.length > 0) {
                    this.waitingSketchfabProcessing(item);
                } else {
                    item.status = 'ready';
                }
            });

            if (storeinfolist[0] !== undefined) {
                if (assetinfo.asset_krw !== null) {
                    assetinfo.asset_krw.name = storeinfolist[0].title_ko;
                    assetinfo.asset_krw.tag = storeinfolist[0].tags_merror;
                    assetinfo.asset_krw.flag = storeinfolist[0].flag;
                }
                if (assetinfo.asset_usd !== null) {
                    assetinfo.asset_usd.name = storeinfolist[0].title_en;
                    assetinfo.asset_usd.tag = storeinfolist[0].tags_merror;
                    assetinfo.asset_usd.flag = storeinfolist[0].flag;
                }
            }
        });
    }

    async uploadToSketchfab(storeinfo: StoreInfoItemFiltered) {
        const assetinfo = this.assetinfolist.find(item => item.assetno === storeinfo.assetno);
        if (assetinfo === undefined) return;

        const optionname = storeinfo.option as Data.AssetOptionType;
        const assetoption = assetinfo?.options[optionname];
        if (assetoption === undefined) return;

        storeinfo.status = 'uploading to sketchfab...';
        document.getElementById('button_sk_upload_' + storeinfo.assetno + '_' + storeinfo.option)!
            .setAttribute('disabled', 'true');

        let sketchfab_uid = '';
        await this.admin.send({
            command: 'UploadSketchfabModel',
            data: { storeinfo, filepath: assetinfo.path + '/' + assetoption.zip.name }
        }, data => sketchfab_uid = data);
        if (sketchfab_uid === null) {
            storeinfo.status = 'sketchfab upload failed, please check!';
            return;
        }

        storeinfo.sketchfab_uid = sketchfab_uid;
        storeinfo.status = 'sketchfab uploaded, processing yet...';

        let updateresult = false;
        this.admin.send({
            command: 'UpdateStoreInfo_SketchfabUID',
            data: { assetno: storeinfo.assetno, option: storeinfo.option, uid: sketchfab_uid }
        }, data => updateresult = data);
        console.log('update uid : ' + updateresult);

        this.waitingSketchfabProcessing(storeinfo);
    }

    viewOnSketchfab(uid: string) {
        window.open('https://sketchfab.com/models/' + uid, '_blank');
    }

    async waitingSketchfabProcessing(storeinfo: StoreInfoItemFiltered) {
        await new Promise<void>(resolve => {
            const func = () => {
                this.admin.send({ command: 'GetSketchfabModel', data: { title: '', uid: storeinfo.sketchfab_uid } },
                    data => {
                        if (data === null) {
                            storeinfo.status = '구글시트에 스케치팹ID가 있지만, 스케치팹에서 찾을수 없습니다!'
                            resolve();
                            return;
                        }
                        const status: SketchfabStatusDetail = data;
                        if (status.status.processing === 'SUCCEEDED') {
                            storeinfo.sketchfab_price = status.price;
                            storeinfo.status = 'sketchfab uploaded';
                            if (status.publishedAt === null) {
                                storeinfo.status += ', but not published yet!';
                            }
                            resolve();
                        } else if (status.status.processing === 'PROCESSING') {
                            storeinfo.status = 'sketchfab uploaded, processing yet...';
                            setTimeout(func, 4000);
                        } else {
                            storeinfo.status = 'sketchfab uploaded, processing failed, please check!';
                            resolve();
                        }
                    }
                );
            };
            func();
        });
    }

    private checkTagAndFlag(tags: string[], sinfo: StoreInfoItem): { tags: string[], flag: number } {
        const resulttags: string[] = [];
        const resultflag = Data.ESearchCondition.None;
        const result = { tags: resulttags, flag: resultflag };

        const tmptags = tags.filter(tag => tag.trim().length > 0).map(tag => tag.toLowerCase());
        const ignoretag = [
            '사람', '사물', '공간', 'human', 'object', 'space',
            'posed', 'aposed', 'a-posed', 'rigged', 'facial',
            'man', 'woman', 'man3d', 'woman3d', 'male', 'female',
            'noai'
        ];
        ignoretag.forEach(itag => {
            const index = tmptags.indexOf(itag);
            if (index > -1) tmptags.splice(index, 1);
        });

        if (sinfo.category === 'human') {
            tmptags.push('사람', 'human');

            if (sinfo.category_filter === 'P') {
                tmptags.push('포즈드', 'posed');
                result.flag = Data.ESearchCondition.Type_Posed;
            } else if (sinfo.category_filter === 'AP') {
                tmptags.push('A-포즈드', 'a-posed');
                result.flag = Data.ESearchCondition.Type_APosed;
            } else if (sinfo.category_filter === 'F') {
                tmptags.push('페이스', 'facial');
                result.flag = Data.ESearchCondition.Type_Facial;
            }

            if (sinfo.gender === 'male') {
                tmptags.push('남성', 'male', 'man');
                result.flag |= Data.ESearchCondition.Male;
            } else {
                tmptags.push('여성', 'female', 'woman');
                result.flag |= Data.ESearchCondition.Female;
            }

            if (sinfo.ages < 10) {
                tmptags.push('10대미만', 'kid', '어린이');
                if (sinfo.gender === 'male') {
                    tmptags.push('남자아이', 'boy');
                } else {
                    tmptags.push('여자아이', 'girl');
                }
                result.flag |= Data.ESearchCondition.Age_0;

            } else if (sinfo.ages === 10) {
                tmptags.push('10대', '10s', 'child', '청소년');
                if (sinfo.gender === 'male') {
                    tmptags.push('남자아이', 'boy');
                } else {
                    tmptags.push('여자아이', 'girl');
                }
                result.flag |= Data.ESearchCondition.Age_10

            } else if (sinfo.ages >= 40 && sinfo.ages <= 50) {
                tmptags.push('' + sinfo.ages + '대', '' + sinfo.ages + 's', 'middleage', '중년');
                result.flag |= Data.ESearchCondition.Age_40_50;
            } else if (sinfo.ages >= 60) {
                tmptags.push('' + sinfo.ages + '대이상', 'old', '노인');
                result.flag |= Data.ESearchCondition.Age_60_;
            } else {
                tmptags.push('' + sinfo.ages + '대', '' + sinfo.ages + 's');
                if (sinfo.ages === 20) result.flag |= Data.ESearchCondition.Age_20;
                else if (sinfo.ages === 30) result.flag |= Data.ESearchCondition.Age_30;
            }

        } else if (sinfo.category === 'object') {
            tmptags.push('사물', 'object');
            result.flag = Data.ESearchCondition.Type_Object;
            const category = this.categoryinfo.find(item =>
                item.category === sinfo.category && item.filter === sinfo.category_filter);
            if (category !== undefined) {
                tmptags.push(category.name.en.toLowerCase(), category.name.ko);
            }
            if (category?.filter.startsWith('2')) tmptags.push('clothings', '의류');


        } else if (sinfo.category === 'space') {
            tmptags.push('공간', 'space');
            result.flag = Data.ESearchCondition.Type_Space;
            const category = this.categoryinfo.find(item =>
                item.category === sinfo.category && item.filter === sinfo.category_filter);
            if (category !== undefined) {
                tmptags.push(category.name.en.toLowerCase(), category.name.ko);
            }
        }
        result.tags = tmptags;
        return result;
    }
}
