<main class="order-container">
    <div class="order-outer">
        <div class="order-inner" *ngIf="showOrder && orders.length > 0">
            <div class="order-title" [innerHTML]="'Common.Checkout'|translate">CHECK OUT</div>

            <div class="order-align">
                <div class="order-left">
                    <div class="order-list-outer">
                        <div class="order-list-title" [innerHTML]="'Order.TobeOrdered'|translate">주문
                            상품</div>
                        <div class="order-list-inner">
                            <div class="order-item-outer"
                                *ngFor="let item of items;let index=index;">
                                <div class="order-item-inner">

                                    <div class="order-item-wrap1">
                                        <div class=" order-item-thumbnail">
                                            <div
                                                [style.background-image]="'url(' + ApiUrl + item.image1 + ')'">
                                            </div>
                                        </div>
                                        <div class="order-item-desc">
                                            <div>

                                                <div class="order-item-name">{{item.name}}</div>
                                                <div class="order-item-option">
                                                    <span
                                                        [innerHTML]="('Search.'+getKey(item.options))|translate"></span>
                                                </div>



                                            </div>
                                            <div>
                                                <div class="mo-order-item-price"
                                                    *ngIf="this.screenSize === 0"
                                                    id="order-price-{{index}}">
                                                    <div class="order-item-dc-price">
                                                        <div>
                                                            <img src="/assets/icon/coupon_color_24.svg"
                                                                id="couponIcon-{{index}}"
                                                                style="display:none;margin-right: 8px;   transition: all 0.5s ease-in-out;">
                                                        </div>
                                                        <span>{{withComma(item.options[this.getKey(item.options)]?.itemdcprice)}}</span>
                                                        <span>&nbsp;</span>
                                                        <span
                                                            [innerHTML]="currencykey|translate">원</span>
                                                    </div>
                                                    <div class="order-item-origin-price"
                                                        *ngIf="(item.options[this.getKey(item.options)]?.itemprice) !== (item.options[this.getKey(item.options)]?.itemdcprice)">
                                                        <span>{{(item.options[this.getKey(item.options)]?.itemprice)}}</span>
                                                        <span
                                                            [innerHTML]="currencykey|translate">원</span>
                                                    </div>
                                                </div>

                                                <div class="order-item-coupon"
                                                    *ngIf="this.screenSize !== 2">
                                                    <input type="text" readonly="readonly"
                                                        id="couponInput-{{index}}"
                                                        class="order-item-input"
                                                        [style.width]="currency === 'USD'? '184px': '164px'"
                                                        [ngClass]="((getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid)).length === 0) || (item.tag.includes('_specialdeal'))? 'order-input-disabled': ''"
                                                        (click)="toggleDropdown(index)"
                                                        [value]="(((getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid)).length === 0) || (item.tag.includes('_specialdeal'))? 'Order.NoCoupon': 'Order.SelectCoupon')|translate">


                                                    <div class="dropdown-coupon"
                                                        *ngIf="item.show_couponlist && getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid) as coupons">
                                                        <ul *ngIf="coupons.length>0">
                                                            <li class="dropdown-coupon-type"
                                                                (click)="onCouponChange(item.options[this.getKey(item.options)]?.itemid, '', index);"
                                                                [innerHTML]="'Order.SelectCoupon'|translate">
                                                                쿠폰 선택</li>
                                                            <li *ngFor="let coupon of coupons; let i = index"
                                                                (click)="onCouponChange(item.options[this.getKey(item.options)]?.itemid, coupon.no, index, coupon.type);
                                                            " class="dropdown-coupon-type"
                                                                [innerHTML]="(coupon.type === 'Default' ? ''
                                                        : coupon.type === 'Welcome' ? 'Order.WelcomeCoupon'
                                                        : coupon.type === 'InviteFriend' ? 'Order.FriendCoupon'
                                                        : ''
                                                      ) | translate" value="{{coupon.no}}">
                                                                {{coupon.name}}</li>
                                                        </ul>
                                                    </div>

                                                </div>


                                            </div>


                                        </div>
                                    </div>

                                    <div class="order-item-price" id="order-price-{{index}}"
                                        *ngIf="this.screenSize !== 0">
                                        <div class="order-item-origin-price"
                                            *ngIf="(item.options[this.getKey(item.options)]?.itemprice) !== (item.options[this.getKey(item.options)]?.itemdcprice)">
                                            <span>{{(withComma(item.options[this.getKey(item.options)]?.itemprice))}}</span>
                                            <span [innerHTML]="currencykey|translate">원</span>
                                        </div>
                                        <div class="order-item-dc-price">
                                            <div>
                                                <img src="/assets/icon/coupon_color_24.svg"
                                                    id="couponIcon-{{index}}"
                                                    style="display:none;margin-right: 8px;   transition: all 0.5s ease-in-out;">
                                            </div>
                                            <span>{{withComma(item.options[this.getKey(item.options)]?.itemdcprice)}}</span>
                                            <span>&nbsp;</span>
                                            <span [innerHTML]="currencykey|translate">원</span>
                                        </div>
                                    </div>

                                    <div class="desktop-order-item-coupon"
                                        *ngIf="this.screenSize === 2">
                                        <input type="text" readonly="readonly"
                                            id="couponInput-{{index}}" class="order-item-input"
                                            [style.width]="currency === 'USD'? '184px': '164px'"
                                            [ngClass]="((getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid)).length === 0)|| (item.tag.includes('_specialdeal'))? 'order-input-disabled': ''"
                                            (click)="toggleDropdown(index)"
                                            [value]="(((getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid)).length === 0) || (item.tag.includes('_specialdeal'))? 'Order.NoCoupon': 'Order.SelectCoupon')|translate">


                                        <div class="dropdown-coupon"
                                            *ngIf="item.show_couponlist && getAvailableCoupon(item.options[this.getKey(item.options)]?.itemid) as coupons">
                                            <ul *ngIf="coupons.length>0">
                                                <li class="dropdown-coupon-type"
                                                    (click)="onCouponChange(item.options[this.getKey(item.options)]?.itemid, '', index);"
                                                    [innerHTML]="'Order.SelectCoupon'|translate">
                                                    쿠폰 선택</li>
                                                <li *ngFor="let coupon of coupons; let i = index"
                                                    (click)="onCouponChange(item.options[this.getKey(item.options)]?.itemid, coupon.no, index, coupon.type);
                                                    " class="dropdown-coupon-type" [innerHTML]="(coupon.type === 'Default' ? ''
                                                : coupon.type === 'Welcome' ? 'Order.WelcomeCoupon'
                                                : coupon.type === 'InviteFriend' ? 'Order.FriendCoupon'
                                                : ''
                                              ) | translate" value="{{coupon.no}}">
                                                    {{coupon.name}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="order-license-outer">
                        <div class="order-license-inner">
                            <div class="order-license-header">
                                <div class="order-license-title">
                                    <img class="order-essentail" src="/assets/icon/essential.svg" />
                                    <div [innerHTML]="'Order.LicenseInfo'|translate">사용권 정보</div>
                                    <div class="purchase-license-info"
                                        (click)="showLicensePopup = true"><img
                                            src="/assets/icon/info_24.svg"></div>
                                </div>
                            </div>

                            <div class="order-license-details">
                                <div class="order-license-type">
                                    <div class="order-license-label"
                                        [innerHTML]="'Order.LicenseType'|translate">사용권 유형</div>
                                    <div class="order-license-wrap1">
                                        <div (click)="licenseType='personal'"
                                            class="order-licetype-wrap">
                                            <input type="radio" name="paymentlicense"
                                                id="order-payment-license-personal"
                                                [checked]="licenseType==='personal'">
                                            <label for="order-payment-license-personal"
                                                style="min-width: 20px;"></label>
                                            &nbsp;<span
                                                [innerHTML]="'Order.PersonalLicense'|translate">개인사용권</span>
                                        </div>
                                        <div (click)="licenseType='group'"
                                            class="order-licetype-wrap">
                                            <input type="radio" name="paymentlicense"
                                                id="order-payment-license-group"
                                                [checked]="licenseType==='group'">
                                            <label for="order-payment-license-group"
                                                style="min-width: 20px;"></label>
                                            &nbsp;<span
                                                [innerHTML]="'Order.CompanyLicense'|translate">공동사용권</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="order-license-input">
                                    <div class="order-license-label" style="margin-bottom: 18px;"
                                        [innerHTML]="(licenseType==='personal'?'Order.PersonalName':'Order.CompanyName')|translate">
                                        팀 / 법인명</div>
                                    <div class="order-license-input-alignment">
                                        <input type="text" [(ngModel)]="licenseUsername"
                                            maxlength="40">
                                        <span #username class="order-license-error"
                                            [innerHTML]="'Order.Required'|translate"
                                            [style.opacity]="(initCheck === true) && (licenseUsername !== '') ? '0' : ''">필수
                                            입력사항입니다.</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="order-paymethod-outer">
                        <div class="order-paymethod-inner">
                            <div class="order-paymethod-header">
                                <div class="order-paymethod-title">
                                    <div [innerHTML]="'Order.PaymentMethods'|translate">결제 수단</div>
                                </div>
                            </div>

                            <div class="order-paymethod-choose">
                                <div class="order-paymethod-label"
                                    [innerHTML]="'Order.MethodSelection'|translate">결제수단 선택</div>
                                <div class="order-paymethod-list-wrap">
                                    <div *ngFor="let paymethod of paymethodlist;let index = index;"
                                        [style.pointerEvents]="paymentPrice === 0 ? 'none' : 'auto'"
                                        [class.order-paymethod-disabled]="(paymentPrice ===0)"
                                        [class.order-paymethod-checked]="(paymentPrice !==0) && (index === 0)"
                                        class="order-paymethod-list" id="order-paymethod-{{index}}"
                                        (click)="setPayMethod(paymethod.method, index)">
                                        <span [innerHTML]="paymethod.key|translate">신용카드</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <div class="order-right">
                    <div>
                        <div class="order-checkout-info">

                            <div class="order-checkout-title"
                                [innerHTML]="'Order.Summary'|translate">결제
                                금액</div>

                            <div class="order-checkout-total">
                                <div>
                                    <div class="order-checkout-label"
                                        [innerHTML]="'Price.Subtotal'|translate">주문 금액</div>
                                    <div class="order-checkout-price">
                                        {{withComma(originPrice-discountPrice)}}<span
                                            [innerHTML]="currencykey|translate">원</span>
                                    </div>
                                </div>

                                <div class="order-checkout-add">
                                    <div class="order-checkout-add-label">
                                        <img uk-svg src="/assets/icon/additional.svg"
                                            style="stroke: #757575; fill: none;">
                                        <span [innerHTML]="'Price.Items'|translate">상품 금액</span>
                                    </div>
                                    <div>
                                        {{withComma(originPrice)}}<span
                                            [innerHTML]="currencykey|translate">원</span>
                                    </div>
                                </div>
                                <div class="order-checkout-add">
                                    <div class="order-checkout-add-label">
                                        <img uk-svg src="/assets/icon/additional.svg"
                                            style="stroke: #757575; fill: none;">
                                        <span [innerHTML]="'Price.Discounts'|translate">상품 할인</span>
                                    </div>
                                    <div> <span
                                            [style.display]="discountPrice === 0? 'none': 'inline-block'">-</span>{{withComma(discountPrice)}}<span
                                            [innerHTML]="currencykey|translate">원</span></div>
                                </div>

                            </div>
                            <div class="order-checkout-discount">
                                <div class="order-checkout-label"
                                    [innerHTML]="'Price.Coupons'|translate">쿠폰 할인</div>
                                <div class="order-checkout-price">
                                    <span
                                        [style.display]="couponDcPrice === 0? 'none': 'flex'">-</span>{{withComma(couponDcPrice)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                            <div class="order-checkout-final">
                                <div class="order-checkout-label"
                                    [innerHTML]="'Price.Total'|translate">총 결제 예정 금액</div>
                                <div class="order-checkout-price order-checkout-final-price">
                                    {{withComma(paymentPrice)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>


                        </div>

                        <div class="order-checkout-info2">
                            <div [innerHTML]="'Order.PaymentNotice1'|translate">주문 내역을 확인하였으며,
                                결제 진행에 동의합니다.
                            </div>
                            <div class="order-checkout-button">
                                <button class="common-button1"
                                    [innerHTML]="'Order.MakePayment'|translate"
                                    (click)="payment()">결제하기</button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>

        <div *ngIf="showLicensePopup" class="order-license-popup-container"
            (click)="checkLicensePopupClose($event)">
            <div class="order-license-popup-outer">
                <div class="order-license-popup-inner">
                    <div class="order-license-popup">
                        <div class="order-license-popup-header">
                            <h3 [innerHTML]="'Order.LicenseNotice'|translate">사용권 안내</h3>
                            <img class="order-license-popup-close" src="/assets/icon/close_24.svg"
                                (click)="showLicensePopup = false" />
                        </div>
                        <div class="order-license-popup-type">
                            <div>
                                <div class="order-license-popup-title" style="background: #FFDB99;">
                                    <img src="/assets/icon/person_24.svg">
                                    <span [innerHTML]="'Order.PersonalLicense'|translate"
                                        style="text-align: center;">개인사용자</span>
                                </div>
                                <div class="order-license-popup-type-desc">
                                    <div [innerHTML]="'Order.PersonalDescription'|translate">등록된 사용자
                                        본인만
                                        사용
                                        가능
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="order-license-popup-title" style="background: #FFCA66;">
                                    <img src="/assets/icon/group_add_24.svg">
                                    <span [innerHTML]="'Order.CompanyLicense'|translate"
                                        style="text-align: center;">공동사용자</span>
                                </div>
                                <div class="order-license-popup-type-desc">
                                    <div [innerHTML]="'Order.CompanyDescription'|translate">등록된
                                        팀/법인의
                                        구성원 모두
                                        사용
                                        가능</div>
                                </div>
                            </div>
                            <!-- <div class="common-horizontal-alignment" style="width: 100%">
                                <button class="common-button1 order-license-popup-button"
                                    (click)="showLicensePopup = false"
                                    [innerHTML]="'Receipt.Close'|translate">닫기</button>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>


        </div>

        <!-- 주문 완료 -->
        <div class="order-inner" *ngIf="showComplete && (orders.length > 0 || redirected)">
            <div class="payment-confirm-outer">
                <div class="payment-confirm-inner">
                    <div class="payment-confirm-image">
                        <img src="/assets/image/payment_ok.png" alt="">
                    </div>
                    <div>
                        <h3 class="payment-confirm-title"
                            [innerHTML]="'Order.OrderCompleted'|translate">
                            결제가
                            완료되었습니다!</h3>
                        <div class="payment-confirm-thanks" [innerHTML]="'Order.Thanks'|translate">
                            구매해주셔서 감사합니다.
                        </div>
                    </div>

                    <div class="payment-confirm-orderNo">
                        <span [innerHTML]="'Order.OrderNo'|translate">주문번호</span>
                        <span>{{orderNo}}</span>
                    </div>
                </div>

                <div class="payment-confirm-button-wrap">
                    <button class="comment_link common-button2" routerLink="/purchases"
                        [innerHTML]="'Order.MoveToPurchases'|translate">
                        구매목록으로 이동
                    </button>
                    <button class="common-button1 payment-confirm-button"
                        [innerHTML]="'Order.DownloadNow'|translate" (click)="downloadNow()">
                        바로 다운로드</button>

                </div>

            </div>
        </div>
        <!-- 주문 완료 끝 -->

        <!-- 주문 오류 -->

        <div class="order-inner" class="order-inner" *ngIf="showError && orders.length > 0">
            <div class="payment-confirm-outer">
                <div class="payment-confirm-inner">
                    <div class="payment-confirm-image">
                        <img src="/assets/image/payment_fail.png" alt="">
                    </div>
                    <div>
                        <h3 class="payment-confirm-title"
                            [innerHTML]="'Order.OrderFailed'|translate">
                            결제 과정에 문제가 있습니다.</h3>
                    </div>

                    <ul class="payment-fail-reference">
                        <li [innerHTML]="'Order.OrderFailedMsg1'|translate">이전으로 돌아가 결제를 다시 진행해주시기
                            바랍니다.
                        </li>
                        <li [innerHTML]="'Order.OrderFailedMsg2'|translate">결제 중 페이지를 새로고침하지 않도록
                            유의해주세요.
                        </li>
                        <li *ngIf="currency === 'KRW'"
                            [innerHTML]="'Order.OrderFailedMsg3'|translate">
                            현재
                            카드사 사정으로 삼성카드 결제(간편결제
                            포함)는
                            지원되지 않습니다.</li>
                    </ul>
                </div>

                <div class="payment-confirm-button-wrap">
                    <button class="common-button2 payment-confirm-button"
                        [innerHTML]="'Common.CustomerCenter'|translate">고객센터
                        문의하기</button>
                    <button class="common-button1 error-button" (click)="reload()"
                        [innerHTML]="'Signup.GoingBack'|translate">돌아가기</button>

                </div>

            </div>
        </div>

        <div class="order-inner" class="order-inner"
            *ngIf="showComplete === false && orders.length === 0">
            <div class="payment-confirm-outer">
                <div class="payment-confirm-inner">
                    <div class="payment-confirm-image">
                        <img src="/assets/image/payment_error.png" alt="">
                    </div>
                    <div style="text-align: center;">
                        <h3 class="payment-confirm-title"
                            [innerHTML]="'Order.InvalidAccess'|translate">
                            잘못된 접근입니다.</h3>
                        <p class="payment-error-meg" [innerHTML]="'Order.ErrorMessage'|translate">
                        </p>
                    </div>
                </div>

                <div class="payment-error-button-wrap">
                    <button class="common-button1 error-button" routerLink="/"
                        [innerHTML]="'Refund.GoToMainPage'|translate"> 메인페이지로 이동하기</button>

                </div>

            </div>
        </div>


        <!-- 주문 오류  끝-->
    </div>
</main>