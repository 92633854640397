import { ResTypeBase } from './../../../server/lib/types';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { Util, UtilityService } from 'src/app/lib/util';
import { ResizeService } from 'src/app/lib/resize.service';

@Component({
    selector: 'app-modifyuser-popup',
    templateUrl: './modifyuser-popup.component.html',
    styleUrls: ['./modifyuser-popup.component.scss'],
})
export class ModifyuserPopupComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private client: ApiClientService,
        private translate: TranslateService,
        private util: UtilityService,
        private resizeSvc: ResizeService
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    @ViewChild('input_password_oldpassword') inputPasswordOldRef!: ElementRef<HTMLInputElement>;
    @ViewChild('input_password_newpassword') inputPasswordNewRef!: ElementRef<HTMLInputElement>;
    @ViewChild('input_password_passwordconfirm') inputPasswordConfirmRef!: ElementRef<HTMLInputElement>;
    @ViewChild('input_email') inputEmailRef!: ElementRef<HTMLInputElement>;
    @ViewChild('alert_message_container') alert!: ElementRef<HTMLElement>;
    @ViewChild('alert_message1') alert1!: ElementRef<HTMLElement>;
    @ViewChild('alert_message2') alert2!: ElementRef<HTMLElement>;
    @ViewChild('password_length_msg') pwLengthMsg!: ElementRef<HTMLElement>;
    @ViewChild('password_match_msg') pwMatchMsg!: ElementRef<HTMLElement>;

    item: 'email' | 'password' = 'email';

    confirmPasswordNotMatch = '';
    oldPasswordNotMatch = '';
    newPasswordWrong = '';
    invalidEmail = '';
    showResult = false;
    sendToNewEmailOnly = false;
    mailSendTimer = [-1, -1, -1];
    mailFlag = true;
    beforeInput = '';

    desktopSize = this.resizeSvc.getCurrent();

    ngOnInit(): void {

        const item = this.route.snapshot.paramMap.get('item') as any;
        if (item === null) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
        } else {
            this.item = item;
        }

        this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
            this.newPasswordWrong = value;
        });
    }

    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    async requestChangeEmail() {
        if (!this.mailFlag) {
            return;
        }
        this.mailFlag = false;
        const emailInput = this.inputEmailRef.nativeElement;
        const email = emailInput.value;

        this.alert2.nativeElement.style.opacity = '0';

        if (!Util.isValidEmail(email)) {
            console.log('여기서뭐해주나??')
            this.translate.get('Signup.EmailInvalid').subscribe(value => {
                this.invalidEmail = value;
            });
            emailInput.parentElement?.classList.add('border-warn');
            return;
        }

        const sendButton = document.getElementById('modifyuser-popup-email-button') as HTMLElement;
        sendButton.innerHTML = await this.util.getTranslation('Account.Sending') + '<div class="dots-1"></div>';
        const result = await this.client.api.ChangeEmailRequest.send({
            email, sendToNewEmailOnly: this.sendToNewEmailOnly, lang: this.util.getCurrentLang()
        });

        for (let timer of this.mailSendTimer) {
            if (timer !== -1) {
                clearTimeout(timer);
                timer = -1;
            }
        }

        this.alertSendMail(email, sendButton, emailInput, result);
        this.mailFlag = true;
    }

    async alertSendMail(email: string, sendButton: HTMLElement, emailInput: HTMLInputElement, result: ResTypeBase) {
        console.log('여기왜이렇게 잘안되징');
        if (result.message === 'ok') {
            sendButton.innerHTML = await this.util.getTranslation('Verification.Resend');
            /*
             * MERROR-143.7a 처음 변경요청메일을 보내는데 성공하면
             * 다음부터는 새 메일주소로만 보내도록
             */
            this.sendToNewEmailOnly = true;

            this.alert2.nativeElement.style.opacity = '1';

            if (this.beforeInput !== email) {
                this.alert2.nativeElement.innerHTML = await this.util.getTranslation('Verification.Sent');
            }
            else {
                this.alert2.nativeElement.innerHTML = await this.util.getTranslation('Verification.Resent');
            }
            this.beforeInput = email;

            this.mailSendTimer[0] = window.setTimeout(() => {
                this.alert2.nativeElement.style.opacity = '0'
            }, 3000)
        } else {
            if (result.message.indexOf('is same as') > -1) {
                this.translate.get('Account.NewEmailIsSameAsOldEmail').subscribe(value => {
                    this.invalidEmail = value;
                });
                emailInput.parentElement?.classList.add('border-warn');
            }
            if (result.message.indexOf('is in use') > -1) {
                this.translate.get('Account.NewEmailIsInUse').subscribe(value => {
                    this.invalidEmail = value;
                });
                emailInput.parentElement?.classList.add('border-warn');
            }
            if (result.message.indexOf('sendmail failed') > -1) {

                this.alert.nativeElement.style.opacity = '1';

                this.translate.get('Account.ResendEmail').subscribe(value => {
                    sendButton.innerHTML = value;
                });
                this.translate.get('Account.SendmailFailed').subscribe(value => {
                    this.alert1.nativeElement.innerHTML = value;
                });
                this.translate.get('Signup.InvalidUserinfo2').subscribe(value => {
                    this.alert2.nativeElement.innerHTML = value;
                });

                this.mailSendTimer[1] = window.setTimeout(() => {
                    this.alert.nativeElement.style.opacity = '0'
                }, 3000)
            }
            if (result.message.indexOf('invalid email') > -1) {
                this.alert.nativeElement.style.opacity = '1';

                this.translate.get('Account.ResendEmail').subscribe(value => {
                    sendButton.innerHTML = value;
                });
                this.translate.get('Account.CurrentEmailInvalid').subscribe(value => {
                    this.alert1.nativeElement.innerHTML = value;
                });
                this.translate.get('Common.PleaseResignin').subscribe(value => {
                    this.alert2.nativeElement.innerHTML = value;
                });

                this.mailSendTimer[2] = window.setTimeout(() => {
                    this.alert.nativeElement.style.opacity = '0'
                }, 3000)
            }

            sendButton.innerHTML = await this.util.getTranslation('Account.SendEmail');
        }
    }

    async modifyPassword() {
        const oldInput = this.inputPasswordOldRef.nativeElement;
        const newInput = this.inputPasswordNewRef.nativeElement;
        const confirmInput = this.inputPasswordConfirmRef.nativeElement;
        const oldpassword = oldInput.value;
        const newpassword = newInput.value;
        const confirmpassword = confirmInput.value;

        if (oldpassword.length === 0) {
            oldInput.parentElement?.classList.add('border-warn');
            return;
        }
        if (newpassword.length < 8 || newpassword.length > 12) {
            newInput.parentElement?.classList.add('border-warn');
            (newInput.parentElement?.nextSibling as HTMLDivElement).classList.add('password-popup-warn');
            return;
        }
        if (newpassword !== confirmpassword) {
            confirmInput.parentElement?.classList.add('border-warn');
            this.translate.get('Signup.PasswordNotMatch').subscribe(value => {
                this.confirmPasswordNotMatch = value;
            });
            return;
        }


        const result = await this.client.api.ModifyPassword.send({
            newemail: '', password: oldpassword, newpassword
        });
        console.log(result);
        if (result.message === 'ok') {
            this.client.api.GetUserinfo.send();
            this.showResult = true;

        } else if (result.message.indexOf('userinfo not found') > -1) {
            // old password id wrong
            oldInput.parentElement?.classList.add('border-warn');
            this.translate.get('Account.OldPasswordNotMatch').subscribe(value => {
                this.oldPasswordNotMatch = value;
            });
            oldInput.select();

        } else if (result.message.indexOf('nothing changed') > -1) {
            // oldpassword === newpassword
            newInput.parentElement?.classList.add('border-warn');
            (newInput.parentElement?.nextSibling as HTMLDivElement).classList.add('password-popup-warn');
            confirmInput.parentElement?.classList.add('border-warn');
            newInput.select();
            this.translate.get('Account.NewPasswordEqualsOldPassword').subscribe(value => {
                this.newPasswordWrong = value;
            });
        }
    }

    logout() {
        this.client.api.Logout.send();
    }
    async resignin() {
        await this.client.api.Logout.send();
        await this.router.navigate([{ outlets: { popup: null } }]);
        this.router.navigateByUrl('/account');
    }

    close(gotoIndex = false) {
        if (gotoIndex) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true }).then(() => {
                this.router.navigateByUrl('/');
            });
        } else {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
        }
    }

    onInput(ref: ElementRef<HTMLInputElement>) {
        const value = ref.nativeElement.value;
        const parent = ref.nativeElement.parentElement;
        const validColor = '#FFEDCC';
        const invalidColor = '#DC362E';
        if (parent === null) return;

        if (this.item === 'password') {
            const p = this.inputPasswordOldRef.nativeElement.parentElement as HTMLDivElement;
            if (this.inputPasswordOldRef.nativeElement.value.length === 0) {
                p.classList.add('border-warn');
            } else {
                p.classList.remove('border-warn');
            }

            if (ref === this.inputPasswordNewRef) {
                const value1 = this.inputPasswordConfirmRef.nativeElement.value;
                this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                    this.newPasswordWrong = value;
                });

                if ((value.length < 8) || (value.length > 12)) {
                    parent.classList.add('border-warn');
                    this.pwLengthMsg.nativeElement.style.color = invalidColor;
                    (parent.nextSibling as HTMLDivElement).classList.add('password-popup-warn');
                    this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                        this.newPasswordWrong = value;
                    });
                }

                else {
                    this.translate.get('Signup.PasswordOk').subscribe(value => {
                        this.newPasswordWrong = value;
                    });
                    this.inputPasswordNewRef.nativeElement.parentElement!.classList.remove('border-warn');
                    this.pwLengthMsg.nativeElement.style.color = validColor;

                    if ((value1 === value) && (value1.length !== 0)) {
                        parent.classList.remove('border-warn');
                        this.pwLengthMsg.nativeElement.style.color = validColor;
                        this.pwMatchMsg.nativeElement.style.color = validColor;
                        this.inputPasswordConfirmRef.nativeElement.parentElement?.classList.remove('border-warn');
                        (parent.nextSibling as HTMLDivElement).classList.remove('password-popup-warn');
                        this.translate.get('Signup.PasswordOk').subscribe(value => {
                            this.newPasswordWrong = value;
                        });
                        this.translate.get('Signup.PasswordMatch').subscribe(value => {
                            this.confirmPasswordNotMatch = value;
                        });
                    }
                }
                if ((value1 !== value) && (value1.length !== 0)) {
                    this.translate.get('Signup.PasswordNotMatch').subscribe(value => {
                        this.confirmPasswordNotMatch = value;
                    });
                    this.pwMatchMsg.nativeElement.classList.add('password-popup-warn');
                    this.pwMatchMsg.nativeElement.style.color = invalidColor;
                    this.inputPasswordConfirmRef.nativeElement.parentElement?.classList.add('border-warn');
                }

            } else if (ref === this.inputPasswordConfirmRef) {
                const value2 = this.inputPasswordNewRef.nativeElement.value;
                if (value.length < 8) {
                    this.confirmPasswordNotMatch = '';
                    parent.classList.remove('border-warn');
                    this.translate.get('Signup.PasswordNoticeMinLength').subscribe(value => {
                        this.confirmPasswordNotMatch = value;
                    });
                    this.pwMatchMsg.nativeElement.style.color = invalidColor;
                    parent.classList.add('border-warn');
                }
                else if ((value === value2) && (value.length >= 8 && value.length <= 12)) {
                    parent.classList.remove('border-warn');
                    this.pwMatchMsg.nativeElement.classList.remove('password-popup-warn');
                    this.pwMatchMsg.nativeElement.style.color = validColor;
                    this.pwLengthMsg.nativeElement.style.color = validColor;
                    this.translate.get('Signup.PasswordMatch').subscribe(value => {
                        this.confirmPasswordNotMatch = value;
                    });
                } else {
                    this.translate.get('Signup.PasswordNotMatch').subscribe(value => {
                        this.confirmPasswordNotMatch = value;
                    });
                    this.pwMatchMsg.nativeElement.classList.add('password-popup-warn');
                    this.pwMatchMsg.nativeElement.style.color = invalidColor;
                    parent.classList.add('border-warn');
                }

            } else if (ref === this.inputPasswordOldRef) {
                parent.classList.remove('border-warn');
                this.oldPasswordNotMatch = '';
            }

        } else if (this.item === 'email') {
            if (Util.isValidEmail(value)) {
                this.invalidEmail = '';
                parent.classList.remove('border-warn');
                this.mailFlag = true;
            } else {
                this.translate.get('Signup.EmailInvalid').subscribe(value => {
                    this.invalidEmail = value;
                });
                parent.classList.add('border-warn');
            }

        }
    }

    goToMain() {
        this.router.navigateByUrl('/');
    }

    isAppleSafari = () => {

        const platformExpression = /Mac|iPhone|iPod|iPad/i;
        const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i;
        const expectedExpression = /Safari/i;

        const agent = navigator.userAgent;
        if (rejectedExpression.test(agent)) {
            return false;
        }
        return platformExpression.test(agent) && expectedExpression.test(agent);
    };

    addMargin() {
        if (this.isAppleSafari()) {
            const passwordContainer = document.getElementById('modifyuser-password') as HTMLDivElement;
            passwordContainer.classList.add('bottom-box')
        }
    }

    removeMargin() {
        const passwordContainer = document.getElementById('modifyuser-password') as HTMLDivElement;
        passwordContainer.classList.remove('bottom-box')
    }
}
