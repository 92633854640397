<div class="api-test-outer">
    <div *ngIf="billingtest!==null" class="api-test-item-outer">
        <button>
            Billing Test(Card)
        </button>
        <button (click)="billingtest.test('trans')">
            Billing Test(실시간이체)
        </button>
        <button (click)="billingtest.test('vbank')">
            Billing Test(가상계좌)
        </button>
        <button (click)="billingtest.test('phone')">
            Billing Test(휴대폰)
        </button>
        <button (click)="billingtest.test('samsung')">
            Billing Test(삼성페이)
        </button>
        <button (click)="billingtest.test('kakaopay')">
            Billing Test(카카오페이)
        </button>
        <button (click)="billingtest.test('payco')">
            Billing Test(페이코)
        </button>
        <button (click)="billingtest.test('ssgpay')">
            Billing Test(SSG페이)
        </button>
        <button (click)="billingtest.test('tosspay')">
            Billing Test(토스페이)
        </button>
        <div>{{billingtest.testResult}}</div>
        <div style="white-space: break-spaces;">{{billingtest.testDetail}}</div>
    </div>
    <div *ngIf="coupontest!==null" class="api-test-item-outer">
        <div>
            <div> userno : {{coupontest.userinfo.userno}}</div>
            <div> userid : {{coupontest.userinfo.userid}}</div>
            <div> email : {{coupontest.userinfo.email}}</div>
            <div> rcmdcode : {{coupontest.userinfo.password}}</div>
            <div>
                <button (click)="coupontest.requestCoupon('Welcome')">RequestWelcomeCoupon</button>
                <button (click)="coupontest.getCouponList()">GetCouponList</button>
            </div>
        </div>
    </div>
</div>