import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from '../lib/api-client.service';
import { Data } from '../../server/lib/types';
import { AssetUtil, UtilityService } from '../lib/util';
import { CommonUtil } from 'src/server/lib/util';
import { TranslateService } from '@ngx-translate/core';
import { skip, Subscription } from 'rxjs';
import { ResizeService } from '../lib/resize.service';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
    @ViewChild('cart_restore') restoreContainer!: ElementRef;
    @ViewChild('delete_countdown') countdownTime!: ElementRef;
    @ViewChild('message1') message1!: ElementRef;
    @ViewChild('message2') message2!: ElementRef;

    withComma = this.util.withComma;

    assetlist: Data.Asset[] = [];
    optioncounter = 0;
    AssetOptions = CommonUtil.assetOptionsAsArray;
    allSelected = false;
    totalprice = 0;
    discountprice = 0;
    paymentprice = 0;
    showMoreButton = false;
    pageno = 1;
    pagesize = 100;

    condition: Data.ESearchCondition = Data.ESearchCondition.None;
    Cartlist: Data.Asset[] = [];
    assetutil = new AssetUtil(this.client.api.GetCartList.send);
    ApiUrl = this.client.baseurl;
    totalItems = 0;
    sort: Data.ESortBy = Data.ESortBy.Recent;
    openPopup = false;
    apiChecked = false;
    countDown = 3;
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;


    getKey = (options: {
        [key in Data.AssetOptionType]?: Data.AssetOption;
    }): Data.AssetOptionType => {
        const optionKeys = Object.keys(options);
        const firstOptionKey = (optionKeys[0]) as Data.AssetOptionType;
        return firstOptionKey;
    }


    private countDownTimer = -1;
    private restoreTimer = -1;
    onLangChangeSub: Subscription | null = null;
    private deleteTimer = -1;

    private hideRestoreTimer = -1;
    desktopSize = this.resizeSvc.getCurrent();

    // MERROR-245
    // 여러개를 삭제하는 중에는 로드하지 못하도록 하기 위해 사용
    // for loop를 돌면서 deleteAsset을 호출하는데 도중에 userinfoSubject가 변경되어 다시 load()를 호출하는것을 방지
    private updateflag = true;

    constructor(
        private util: UtilityService,
        private client: ApiClientService,
        private router: Router,
        private translate: TranslateService,
        private resizeSvc: ResizeService
    ) {
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.currency = this.util.getCurrentCurrency();
            await this.load();
            if (this.updateflag) this.toggleAllSelected(true);
        });

        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.client.userinfoSubject.pipe(skip(1)).subscribe(async (res) => {
            await this.load();
            if (this.updateflag) this.toggleAllSelected(true);
        });
        (async () => {
            await this.load();
            this.toggleAllSelected(true);
        })();
    }

    ngOnDestroy(): void {
        window.clearTimeout(this.restoreTimer);
        window.clearInterval(this.countDownTimer);

        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }


    async nextpage() {
        this.pageno++;
        await this.load(this.pageno);
        this.toggleAllSelected(true);
    }

    async load(pageno = 1) {
        if (this.updateflag === false) return;
        this.currency = this.util.getCurrentCurrency();
        this.currencykey = 'Common.' + this.currency;
        this.totalItems = await this.assetutil.loadCartAsset(
            this.Cartlist, this.condition, [], this.sort, pageno, this.pagesize, true, this.currency);

        this.allSelected = false;

        if (this.pagesize * (pageno - 1) + this.Cartlist.length >= this.totalItems) {
            this.showMoreButton = false;
        } else {
            this.showMoreButton = true;
        }
        if (pageno === 1) this.assetlist.length = 0;

        setTimeout(() => {
            this.apiChecked = true;
        }, 100)

    }

    toggleAssetSelected(asset: Data.Asset) {
        if (asset.cart === 'None') {
            asset.cart = 'All';
        } else {
            asset.cart = 'None';
        }
        for (const option of this.AssetOptions(asset)) {
            option.cart = asset.cart === 'All';
        }

        let allflag = true;
        for (const asset of this.assetlist) {
            if (asset.cart !== 'All') {
                allflag = false;
                break;
            }
        }
        this.allSelected = allflag;

        this.calcPrice();
    }

    toggleOptionSelected(asset: Data.Asset) {

        const key = this.getKey(asset.options)
        let option = asset.options[key] as Data.AssetOption;

        option.cart = !option.cart;
        let alloptionflag = true;
        for (const option of this.AssetOptions(asset)) {
            if (option.cart !== true) alloptionflag = false;
        }

        asset.cart = alloptionflag ? 'All' : 'None';

        let allassetflag = true;
        for (const asset of this.Cartlist) {
            if (asset.cart !== 'All') {
                allassetflag = false;
                break;
            }
        }
        this.allSelected = allassetflag;
        this.calcPrice();
    }

    toggleAllSelected(selected?: boolean) {
        this.allSelected = selected === undefined ? !this.allSelected : selected;

        for (const asset of this.Cartlist) {
            for (const option of this.AssetOptions(asset)) {
                option.cart = this.allSelected;
            }
            asset.cart = this.allSelected ? 'All' : 'None';
        }
        this.calcPrice();
    }

    calcPrice() {
        this.totalprice = 0;
        this.discountprice = 0;
        this.paymentprice = 0;
        let abc = 0;
        for (const asset of this.Cartlist) {
            for (const option of this.AssetOptions(asset)) {
                if (option.cart) {
                    this.totalprice += option.itemprice
                    this.discountprice += (option.itemprice - option.itemdcprice);
                }
            }
        }
        this.paymentprice = this.totalprice - this.discountprice;
    }

    async deleteAsset(assetindex: number, updatelist = true) {
        const asset = this.Cartlist[assetindex];
        const option = this.getKey(this.Cartlist[assetindex].options)
        const optionno = this.Cartlist[assetindex].options[option]?.optionno as number

        const res = await this.client.api.RemoveFromCart.send({
            assetno: asset.assetno, optionno: [optionno]
        });

        this.calcPrice();
    }

    async deleteSelected() {
        if (this.showMoreButton === false && this.allSelected && (this.Cartlist.length !== 1)) {
            if (this.restoreTimer === -1 && this.countDownTimer === -1) {
                this.openPopup = true;
            }

        } else {
            this.updateflag = false;
            for (let assetindex = 0; assetindex < this.Cartlist.length; assetindex++) {
                if (this.Cartlist[assetindex].cart === 'All') {
                    await this.deleteAsset(assetindex, false);
                }
            }
            this.updateflag = true;
            await this.load(1);
        }
        this.calcPrice();
    }

    async deleteAll() {
        this.updateflag = false;
        for (let assetindex = 0; assetindex < this.Cartlist.length; assetindex++) {
            await this.deleteAsset(assetindex, false);
        }
        this.updateflag = true;
        await this.load(1);
        this.calcPrice();
        this.openPopup = false;
    }

    async setEmptyCart() {
        // this.openPopup = false;
        // this.checkTimers();
        // await this.showCountDownPopup();
        // this.runTimers();
        await this.deleteAll();
    }

    order() {
        this.client.clearOrders();
        for (const asset of this.Cartlist) {
            for (const option of this.AssetOptions(asset)) {
                if (option.cart) {
                    const orderasset: Data.Asset = {
                        asset: asset.asset,
                        assetno: asset.assetno,
                        cart: asset.cart,
                        description: asset.description,
                        favorite: asset.favorite,
                        flag: asset.flag,
                        image1: asset.image1,
                        image2: asset.image2,
                        name: asset.name,
                        currency: asset.currency,
                        options: {},
                        optioncount: asset.optioncount,
                        popularity: asset.popularity,
                        price: option.itemdcprice,
                        regdate: asset.regdate,
                        tag: asset.tag,
                        tag_search: asset.tag_search,
                    };
                    orderasset.options[option.type] = option;
                    this.client.addOrder(orderasset);
                }
            }
        }
        const orders = this.client.getOrders();
        if (orders.length === 0) return;

        this.router.navigateByUrl('/order');
    }
}
