import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService } from 'src/app/lib/resize.service';

@Component({
    selector: 'app-deleteuser-popup',
    templateUrl: './deleteuser-popup.component.html',
    styleUrls: ['./deleteuser-popup.component.scss']
})
export class DeleteuserPopupComponent implements OnInit, OnDestroy, AfterViewInit {
    inputpassword = '';
    passwordAlertKey: 'Signup.InputPassword' | 'Account.DeleteAccountTypo1' = 'Signup.InputPassword';
    showAlert = false;
    showResult = false;
    countdown = 3;

    desktopSize = this.resizeSvc.getCurrent();

    @ViewChild('inputelement') inputpasswordRef!: ElementRef<HTMLInputElement>;

    constructor(
        private client: ApiClientService,
        private location: Location,
        private router: Router,
        private resizeSvc: ResizeService

    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    onChangePassword() {
        this.showAlert = false;
    }

    isAppleSafari = () => {

        const platformExpression = /Mac|iPhone|iPod|iPad/i;
        const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i;
        const expectedExpression = /Safari/i;

        const agent = navigator.userAgent;
        if (rejectedExpression.test(agent)) {
            return false;
        }
        return platformExpression.test(agent) && expectedExpression.test(agent);
    };

    addMargin() {
        if (this.isAppleSafari()) {
            const deleteUsercontainer = document.getElementById('deleteuser-password') as HTMLDivElement;
            deleteUsercontainer.classList.add('bottom-box')
        }
    }

    removeMargin() {
        const deleteUsercontainer = document.getElementById('deleteuser-password') as HTMLDivElement;
        deleteUsercontainer.classList.remove('bottom-box')
    }

    async deleteUser() {
        const inputElement = this.inputpasswordRef.nativeElement;

        if (this.inputpassword === '') {
            this.passwordAlertKey = 'Signup.InputPassword';
            this.showAlert = true;
            inputElement.focus();
            return;
        }

        const result = await this.client.api.DeleteUser.send({ email: '', password: this.inputpassword });
        console.log(result);

        if (result.message === 'ok') {
            this.showResult = true;
            this.exit();

        } else {
            this.passwordAlertKey = 'Account.DeleteAccountTypo1';
            this.showAlert = true;
            inputElement.focus();
            inputElement.select();
        }
    }

    close() {
        this.location.back();
    }

    exit() {
        window.setTimeout(() => {
            this.countdown--;
            if (this.countdown === 0) {
                this.router.navigateByUrl('/store');
            } else {
                this.exit();
            }
        }, 1000);
    }

}
