<div class="filter-outer">
    <div class="filter-inner">
        <div class="filter-mobile-nav"
            [ngClass]="(showCategory && screenSize === 0)? 'filter-mobile-header' : ''">
            <div [style.display]="(showCategory && screenSize === 0)? 'block' : 'none'">
                <div [innerHTML]="'Common.Filter'|translate">
                    Filter</div>
                <img (click)="toggleMobileFilter(false)" class="filter-mobile-close"
                    src="/assets/icon/chevron_left_24.svg" />
            </div>

        </div>

        <div class="filter-inner-left">
            <li class="filter-category">
                <input type="text" readonly="readonly" [value]="'Common.Filter'|translate"
                    [style]="searchcondition.condition !== ESC.Type_Facial? 'border: 1px solid #FFCA66;color:#FFCA66;background-image: url(/assets/icon/chevron_down_yellow_20.svg)' : '' "
                    (click)="showCategory = !showCategory">
                <div class="filter-category-options"
                    [style.display]="showCategory? 'block': 'none'">
                    <app-dropdown-filter [categoryTitle]="'human'" [screenSize]="screenSize"
                        [searchcondition]="searchcondition">
                    </app-dropdown-filter>
                </div>
            </li>
            <li class="filter-clear-all" (click)="clearAll()"
                [style.display]="(!showCategory) && (screenSize === 0)? 'none' : searchcondition.condition !== ESC.Type_Facial? 'block': 'none'">
                <button [innerHTML]="'Sidebar.ClearAll'|translate">
                    Clear All
                </button>
            </li>
        </div>
        <div class="filter-inner-right">
            <li class="filter-sort-order">
                <input type="text" readonly="readonly"
                    [ngModel]="this.sortList[this.searchcondition.sort - 1].name|translate"
                    (click)="showSortOptions = !showSortOptions">
                <div class="filter-sort-options"
                    [style.display]="screenSize !== 0 ? (showSortOptions? 'block': 'none') : (showCategory? 'block': 'none')">
                    <div class="mobile-controller-wrap" (click)="showSortOptions=!showSortOptions">
                        <div class="mobile-filter-title" [innerHTML]="'Search.Sort'|translate">
                            Sort</div>
                        <img class="mobile-filter-chevron-icon" src="{{ showSortOptions ? '/assets/icon/chevron_up_20.svg' :
                        '/assets/icon/chevron_down_20.svg'}}" />
                    </div>
                    <ul class="dropdown-filter-sort" *ngIf="showSortOptions">
                        <li *ngFor="let sort of sortList; let index = index"
                            class="dropdown-filter-sort-option" (click)="onChangeSort(index)"
                            [value]="sort.name|translate"
                            [style.color]="(this.searchcondition.sort - 1 === index )&&(screenSize === 0)? '#FFCA66' : ''">
                            {{sort.name|translate}}</li>
                    </ul>
                </div>
            </li>
        </div>

        <div class="filter-mobile-icon" *ngIf="!showCategory" (click)="toggleMobileFilter(true)">
            <img src="/assets/icon/filter_24.svg" />
            <span [innerHTML]="'Common.Filter'|translate">Filter</span>
        </div>

    </div>

</div>