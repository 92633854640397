<!-- for desktop -->
<div #typebackground class="index-item-outer" (click)="onClick()">


    <div class="index-item-thumbnail noselect"
        style="background-image:url({{ApiUrl}}{{asset.image1}}), linear-gradient(143.66deg, rgba(183, 183, 183, 0) 65%, rgba(0, 0, 0, 0.59)), linear-gradient(0deg, #424242, #424242)">
        <div class="index-item-favorite" (click)="onImgClick($event);onClickFavorite()"
            [style.color]="asset.favorite? '#FFA600' : '#D3D7DC'">
            <img class="index-item-favorite-icon" width="24" height="24"
                [src]="asset.favorite ? '/assets/icon/star_item_color_24.svg' : '/assets/icon/star_index_24.png'" />
        </div>
    </div>

    <div class="index-item-detail">
        <div class="index-item-name">{{asset.name}}</div>
        <div class="index-item-price-wrap">
            <div class="index-item-dc">
                <div class="index-item-dc-price">
                    {{asset.currency==='KRW'?'₩':'$'}}{{(withComma(asset.price) == '0' &&
                    (asset.currency==='USD'))?'0.00': withComma(asset.price)}}{{
                    (asset.currency==='USD'&&withComma(asset.price)?.indexOf('.')==-1)?'.00':''}}
                    <span *ngIf="asset.optioncount > 1"> {{asset.currency==='KRW' ?'~':'+'}} </span>
                </div>
                <div class="index-item-dc-rate" *ngIf="this.originPrice !== this.dcPrice">
                    {{dcRate}}%</div>
            </div>
            <div class="index-item-origin-price" *ngIf="this.originPrice !== this.dcPrice">
                {{asset.currency==='KRW'?'₩':'$'}}{{(withComma(originPrice) == '0' &&
                (asset.currency==='USD'))?'0.00': withComma(originPrice)}}{{
                (asset.currency==='USD'&&withComma(originPrice)?.indexOf('.')==-1)?'.00':''}}</div>
        </div>

        <!-- <div class="index-item-type">
            <span #typetag></span>
        </div> -->
    </div>

    <!-- <a (click)="onClickFavorite()">
            <img #favoriteicon style="margin: 15px; width: 21px; fill: none; stroke: #D3D7DC;"
                uk-svg class="index-item-favorite uk-position-bottom-right"
                src="/assets/icon/favorite.svg" />
        </a>
        <div class="index-item-detail">
            <div class="index-item-price" (click)="onClick()">
                {{asset.currency==='KRW'?'₩':'$'}}{{withComma(asset.price)}} ~ </div>
            <a (click)="onClickCart()" style="padding-right: 15px;">
                <img #carticon uk-svg src="/assets/icon/index-cart.svg" />
            </a>
        </div> -->


</div>