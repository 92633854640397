import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { IamportService } from 'src/app/lib/iamport';
import { ResizeService } from 'src/app/lib/resize.service';
import { CookieUtil } from 'src/app/lib/util';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {


    @Input() userinfo!: Data.User
    @Input() cartcount = 0;
    @Input() favoritecount = 0;
    @Input() desktopSize = true;
    @ViewChild('strip_banner') strip_banner!: ElementRef<HTMLDivElement>;
    @ViewChild('main_header') main_header!: ElementRef<HTMLDivElement>;

    showStripBanner = true;

    showAssetsNav = false;
    showBusinessNav = false;
    showMypage = false;
    showSearchTerm = false;

    clickClosed = false;

    showLinkMenu = false;

    placeholder = 'Search.SearchPlaceholder';

    logofilterdeg = 0;
    version = '';
    versionopacity = '1';
    mode: 'prod' | 'test' | 'dev' = 'prod';

    screenSize = this.resizeSvc.getCurrent();

    constructor(
        private router: Router,
        private client: ApiClientService,
        private iamport: IamportService,
        private route: ActivatedRoute,
        private resizeSvc: ResizeService) {
        this.client.api.Version.send().then(result => {
            this.version = result.message;
            if (this.version.startsWith('prod')) {
                this.iamport.setMode('prod');
                this.mode = 'prod';
                this.versionopacity = '0';
            } else if (this.version.startsWith('test')) {
                this.iamport.setMode('test');
                this.mode = 'test';
                this.logofilterdeg = 180;
            } else {
                this.iamport.setMode('dev');
                this.mode = 'dev';
                this.logofilterdeg = 90;
            }
            console.log('version : ' + result.message);
        });

        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
        });

    }

    ngOnInit(): void {
        if (CookieUtil.getCookie('STRIP_BANNER')) {
            this.showStripBanner = false;
        } else {
            this.showStripBanner = true;
        }
    }


    ngAfterViewInit(): void {
        const stripBanner = document.querySelector('.header-strip-banner-wrap') as HTMLDivElement;
        const headerContainer = document.querySelector('.header-container') as HTMLDivElement;
        if (!this.showStripBanner) {
            headerContainer.style.paddingTop = '0px';
            stripBanner.style.display = 'none';
        }
        this.client.openPopupSubject.subscribe((el) => {
            this.showMypage = el.openPopup;
        })
        window.addEventListener('click', this.closeSearchNav.bind(this));
        window.addEventListener('click', this.closeLinkMenu.bind(this));

        const txts = (document.querySelector(".animate-text") as HTMLParagraphElement).children,
            txtsLen = txts.length;
        let index = 0;
        const textInTimer = 5000,
            textOutTimer = 4800;

        function animateText() {

            for (let i = 0; i < txtsLen; i++) {
                txts[i].classList.remove("text-in", "text-out");
            }
            txts[index].classList.add("text-in");

            setTimeout(function () {
                txts[index].classList.add("text-out");
            }, textOutTimer)

            setTimeout(function () {

                if (index == txtsLen - 1) {
                    index = 0;
                }
                else {
                    index++;
                }
                animateText();
            }, textInTimer);
        }

        animateText();

    }

    ngOnDestroy(): void {
        window.removeEventListener('click', this.closeSearchNav);
        window.removeEventListener('click', this.closeLinkMenu);
    }

    login() {
        this.client.openPopupSubject.next({ url: '', openPopup: true });
    }

    toggleState(state: string, showNav: boolean) {

        const input = document.getElementById('searchtext') as HTMLInputElement;
        if (showNav) {
            input?.blur();
            this.closeSearchNav()
        }
        switch (state) {
            case 'mypage':
                this.showMypage = showNav;
                break;
            case 'assetNav':
                this.showAssetsNav = showNav;
                break;
            case 'businessNav':
                this.showBusinessNav = showNav;
                break;
            default:
                break;
        }
    }

    closeSearchNav(event?: Event): void {

        if (event === undefined) {
            this.showSearchTerm = false;
            this.showLinkMenu = false;
        }
        //focused도 없어지게 처리해주기;


        const target = event?.target as Node;
        const searchIcon = document.getElementById('header-search-icon') as HTMLInputElement;
        const searchContainer = document.getElementById('header-search-container') as HTMLDivElement;

        if (this.showSearchTerm && !searchContainer?.contains(target) && !searchIcon?.contains(target)) {
            this.showSearchTerm = false;
            this.placeholder = 'Search.SearchPlaceholder';
        }
    }

    closeLinkMenu(event?: Event): void {
        const target = event?.target as Node;
        const hamburgerIcon = document.getElementById('hamburger-icon') as HTMLInputElement;
        const LinkMenu = document.getElementById('dropdown') as HTMLDivElement;

        if (this.showLinkMenu && !LinkMenu?.contains(target) && !hamburgerIcon?.contains(target)) {
            this.showLinkMenu = false;
        }
    }

    async navigate(url: string) {
        // MERROR-171 상품상세 팝업이 열려있으면 닫기 위해 url 체크
        const regexpParse = new RegExp('/asset([0-9]+)$');
        if (regexpParse.test(this.router.url)) {
            await this.router.navigate([{ outlets: { productdetail: null } }]);
            this.router.navigate([url], { replaceUrl: true });
        } else {
            this.router.navigate([url]);
        }
    }

    closeBanner() {
        const key = 'STRIP_BANNER';
        CookieUtil.setCookie(key, 1);

        this.showStripBanner = false;
        this.clickClosed = true;
        const stripBanner = document.querySelector('.header-strip-banner-wrap') as HTMLDivElement;
        const headerContainer = document.querySelector('.header-container') as HTMLDivElement;

        headerContainer.classList.add('closed');

        setTimeout(() => {
            stripBanner.style.display = 'none'
        }, 1000);

    }
}
