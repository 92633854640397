import { SearchTextScore } from './../../server/lib/types';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { Data } from '../../server/lib/types';
import { AssetUtil, Util, UtilityService } from '../lib/util';
import gsap from 'gsap';
import { Subscription } from 'rxjs';
import { ClickDeleteSearchBehavior, Filter, SearchBar, SearchCondition, SidebarUtilService } from '../lib/searchtool.service';
import { ResizeService, SCREEN_SIZE } from '../lib/resize.service';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { FilterComponent } from './common/filter.component';

@Component({
    selector: 'app-asset-list',
    templateUrl: './asset-list.component.html',
    styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent implements OnInit, AfterViewInit, OnDestroy {

    searchcondition = this.client.searchSubject.value;
    ESC = Data.ESearchCondition;
    filterEmpty = false;
    searchEmpty = false;
    showAlreadyPurchased = false;
    alreadyPurchaseTimer = -1;
    apiChecked = false;
    mobileFilter = false;
    itemWidth = 268;
    gap = 20;

    @ViewChild('assetLink') assetLink!: RouterLinkActive;
    @ViewChild(FilterComponent) filterComponent!: FilterComponent;

    resizeCallback = (event?: UIEvent) => {

        setTimeout(() => {
            this.currentPage = 0;
        }, 1);

        if (this.screenSize === SCREEN_SIZE.desktop) {
            this.itemWidth = 268;
            this.gap = 20;
        } else if (this.screenSize === SCREEN_SIZE.tablet) {
            this.itemWidth = 258;
            this.gap = 16;
        } else {
            this.itemWidth = 160;
            this.gap = 8;
        }
        const columns = Util.setContainerSize('itemlist', this.itemWidth, this.gap);
        if (columns !== this.Columns) {
            this.Columns = columns;
            this.load(true);
        }
    };

    private pageno = 1;
    private Columns = 5;
    Modellist: Data.Asset[] = [];
    assetutil = new AssetUtil(this.client.api.GetAssetList.send);
    ApiUrl = this.client.baseurl;
    totalItems = 0;
    popularItems: SearchTextScore[] = [];
    url = '/asset'

    costumeIndex = 0;
    currentPage = 0;

    sliderWrapFunc: (value: number) => number = value => value;
    sliderOffset = 0;
    private timer = -1;
    play = true;
    screenSize = this.resizeSvc.getCurrent();
    initialLoad = false;
    categoryType = 'all'
    currentCategory(location: string) {
        return location.charAt(0).toUpperCase() + location.slice(1);
    }

    private searchparam = false;

    constructor(
        private client: ApiClientService,
        private resizeSvc: ResizeService,
        private util: UtilityService,
        public router: Router,
        private route: ActivatedRoute,
        private searchBar: SearchBar

    ) {



        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
            if (this.screenSize === SCREEN_SIZE.mobile) this.Columns = 2;

        });
        setTimeout(() => {
            if (this.screenSize === SCREEN_SIZE.mobile) this.Columns = 2;
        }, 1);


        //important!! params를 보고 검색기능을 넣어줘 subcategory title에 불을 켜주는 역할을 하고있다.
        this.route.params.subscribe(async (params) => {
            let data = router.getCurrentNavigation()?.extras.state;
            this.clearFilter();

            this.categoryType = params['category1'];

            if (data) {
                if (data && data['sort']) {
                    this.searchparam ||= this.setSearchConditionSort(data['sort']);
                } else if (data && data['tag']) {
                    this.searchcondition.searchtext = data['tag'];
                }
                if (data['subcategory']?.length) this.searchcondition.categoryInfo.subCategory.push(data['subcategory'])
                if (data['gameready']) this.searchcondition.categoryInfo.isGameready = data['gameready']
                if (data['flag']) {
                    this.searchcondition.condition = data['flag']
                }

            }//asian으로 들어오는것

            if ((this.categoryType === undefined) || (this.categoryType === 'all')) {
                this.searchcondition.categoryInfo.category = [];
            } else {
                this.searchcondition.categoryInfo.category = [this.categoryType];
            }

            this.searchparam = true;

            if (this.searchparam) {
                this.client.searchSubject.next(this.searchcondition);
            }
            // if (this.categoryType === undefined) {
            //     if (data && !this.initialLoad) this.searchcondition.searchtext.push(data['search'])
            //     this.load();
            //     return;
            // } else {
            //     if (this.categoryType !== 'all') {
            //         this.searchcondition.searchtext = [this.categoryType];
            //     }
            //     else {
            //         this.searchBar.setSearchBehavior(new ClearSearchBehavior());
            //         await this.searchBar.performSearch();
            //     }
            // }

            // if (data && !this.initialLoad) this.searchcondition.searchtext.push(data['search'])
            // this.searchparam = true;

            // if (this.searchparam) {
            //     this.client.searchSubject.next(this.searchcondition);
            // }

        });



    }



    favorites: number[] = [];
    selectedConditionCount = 0;
    searchSub: Subscription | null = null;
    cateogrySub: Subscription | null = null;

    ngOnInit(): void {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.resizeCallback);

        this.searchSub = this.client.searchSubject.subscribe(searchCond => {
            if (!this.initialLoad) return;
            this.UpdateCondition(searchCond);
        });

        this.client.userinfoSubject.subscribe((res) => {
            this.resizeCallback();
            this.load(true);
            this.initialLoad = true;
        });

        this.cateogrySub = this.client.categorySubject.subscribe((val) => {
            // if (val.subCategory != undefined) {
            //     this.setStyle(0);
            // }
            this.client.searchSubject.next(this.searchcondition);
        });


    }

    async load(resetpage = false) {

        if (this.Columns === 0) return;
        if (resetpage) this.pageno = 1;
        else this.pageno++;

        this.totalItems = await this.assetutil.loadAsset(
            this.Modellist, this.searchcondition.condition,
            this.searchcondition.searchtext, this.searchcondition.sort,
            this.pageno, this.Columns * 4, resetpage, this.util.getCurrentCurrency(),
            this.searchcondition.minprice, this.searchcondition.maxprice,
            this.searchcondition.minpolygon, this.searchcondition.maxpolygon, this.searchcondition.categoryInfo
        );
        // console.log('모델리스트?', this.Modellist, this.searchcondition.searchtext)
        if (this.totalItems == 0) {
            this.showEmptyAlert();
        }
        this.client.popularSubject.next(this.assetutil.getPopularKeywords());
        this.apiChecked = true;
    }

    showEmptyAlert() {
        this.client.categorySubject.subscribe((val) => {
            if (val.onCategory == true) {
                this.filterEmpty = true;
                this.searchEmpty = false;
            }
            else {
                this.filterEmpty = false;
                this.searchEmpty = true;
            }
        });
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeCallback);
        if (this.searchSub !== null) this.searchSub.unsubscribe();
        if (this.cateogrySub !== null) this.cateogrySub.unsubscribe();
        window.clearInterval(this.timer);
    }

    ngAfterViewInit(): void {

    }

    CheckCondition(flag: Data.ESearchCondition) {
        return (this.searchcondition.condition & flag) == flag;
    }

    SetCondition(flag: Data.ESearchCondition) {
        this.searchcondition.condition |= flag;
        this.selectedConditionCount++;
    }
    UnsetCondition(flag: Data.ESearchCondition) {
        this.searchcondition.condition &= ~flag;
        this.selectedConditionCount--;
    }
    UpdateCondition(newCondition: SearchCondition, scroll = false) {

        this.pageno = 1;
        Object.assign(this.searchcondition, newCondition);
        this.load(true);
        // if (scroll) Util.scrollTo(496);
    }


    // scrollBannerToNextPage() {
    //     if (this.screenSize === SCREEN_SIZE.mobile) return;

    //     const bannerHeight = this.bannerRef.nativeElement.clientHeight;
    //     const restHeight = 56;
    //     const columnsHeight = 1354;

    //     const afterFirstHeight = 1324;

    //     if (this.pageno > 1) {
    //         if (this.pageno === 2) {
    //             setTimeout(() => {
    //                 Util.scrollTo(bannerHeight + columnsHeight + restHeight);
    //             }, 1);
    //         } else {
    //             setTimeout(() => {
    //                 Util.scrollTo(bannerHeight + columnsHeight + restHeight + afterFirstHeight * (this.pageno - 2));
    //             }, 1);
    //         }
    //     }
    // }


    scrollDown() {
        // const bannerHeight = (document.getElementById('index-slider') as HTMLDivElement).clientHeight;
        // const itemlistHeight = (document.getElementById('mo-itemlist') as HTMLDivElement).clientHeight;
        // Util.scrollTo(bannerHeight + itemlistHeight)
    }

    clearFilter() {
        this.searchcondition.condition = Data.ESearchCondition.None;
        this.searchcondition.minprice = 0;
        this.searchcondition.maxprice = 0;
        this.searchcondition.minpolygon = 0;
        this.searchcondition.maxpolygon = 0;
        this.searchcondition.categoryInfo.subCategory = [];
        this.searchcondition.categoryInfo.isGameready = false;
    }

    setSearchConditionSort(sort: string) {
        const oldsort = this.searchcondition.sort;
        if (sort === 'recent') this.searchcondition.sort = Data.ESortBy.Recent;
        else if (sort === 'old') this.searchcondition.sort = Data.ESortBy.Old;
        else if (sort === 'popular') this.searchcondition.sort = Data.ESortBy.Popular;
        else if (sort === 'highprice') this.searchcondition.sort = Data.ESortBy.HighPrice;
        else if (sort === 'lowprice') this.searchcondition.sort = Data.ESortBy.LowPrice;


        if (this.searchcondition.sort !== oldsort) return true;
        return false;
    }

    async popTag(tag: string) {
        this.searchBar.setSearchBehavior(new ClickDeleteSearchBehavior());
        await this.searchBar.performSearch(tag);
    }

    openFilter(event: boolean) {
        this.mobileFilter = event
    }

    resetFilter() {
        this.filterComponent.clearFilter();
    }

}
