<div class="verification-popup-background" (click)="close($event)">
    <app-popup-nav [title]="'Verification.EmailVerification'|translate"
        style="width: 100%;"></app-popup-nav>
    <div class="verification-popup-container">
        <div class="verification-popup-close-icon" (click)="close()">
            <img class="verification-close-icon" src="/assets/icon/close.svg" />
        </div>
        <div class="verification-popup-email-icon">
            <img class="verification-email-icon" src="/assets/icon/email.svg" />
            <img *ngIf="verified === false" class="verification-alarm-icon"
                src="/assets/icon/email-alarm.svg" />
        </div>
        <h2 class="verification-headline"
            [innerHTML]="(verified === false ? 'Verification.VerifyNotice' : 'Verification.ConfirmNotice') | translate">
            이메일을 확인해주세요!</h2>
        <h3 class="verification-account">{{email}}</h3>
        <h3 class="verification-contents"
            [innerHTML]=" (verified === false ? 'Verification.ConfirmMessage' : 'Verification.SendMessage') | translate">
            인증 메일이 발송되었습니다.<br />받으신 메일을 열어 버튼을 클릭하면<br />가입이 완료됩니다.</h3>
        <h3 class="verification-resent" #resent [innerHTML]="'Verification.Resent'|translate">
            인증 메일이 재발송 되었습니다.</h3>
        <div class="verification-button-wrap">
            <button class="verification-button-resend" (click)="requestVerify()"
                [innerHTML]="'Verification.Resend'|translate">인증 메일 다시 보내기</button>
            <button class="verification-button-signin" (click)="signin()"
                [innerHTML]="'Verification.Signin'|translate">로그인 하기</button>
        </div>
        <div class="verification-omission-alert"
            [innerHTML]="'Verification.OmissionMessage'|translate">
            메일을 받지 못하신 경우 스팸메일함을 확인해주세요.
        </div>
    </div>
</div>