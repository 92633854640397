import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BillingPaymentItem, BillingPurchaseItem, Data } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { ApiClientService } from '../lib/api-client.service';
import { UtilityService } from '../lib/util';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ResizeService } from '../lib/resize.service';

export interface IPriceInfo { total: number; supplyprice: number; vat: number; };
export interface IRefundInfo { total: number; list: { time: string; amount: number; }[] };

@Component({
    selector: 'app-purchase-detail',
    templateUrl: './purchase-detail.component.html',
    styleUrls: ['./purchases.component.scss']
})
export class PurchaseDetailComponent implements OnInit, AfterViewInit {
    constructor(
        private router: Router,
        private client: ApiClientService,
        private util: UtilityService,
        private location: Location,
        public translate: TranslateService,
        private resizeSvc: ResizeService
    ) {
        const data = this.router.getCurrentNavigation()?.extras.state;
        if (data === undefined) {
            router.navigateByUrl('/purchases');
        } else {
            this.payment = data['payment'];

            console.log('아웅', this.payment);
            this.priceinfo.total = 0;
            this.refundinfo.total = 0;
            for (const item of this.payment.list) {
                const price = item.PURCHASEPRICE;
                this.priceinfo.total += price;
                if (item.refunded) {
                    this.refundinfo.total += price;
                    this.refundinfo.list.push({
                        amount: price,
                        time: item.UPDDATE
                    });
                }
            }
            if (this.payment.CCYCODE === Data.ECurrency.KRW) {
                this.currencykey = 'Common.KRW';
                this.priceinfo.vat = Math.floor(this.priceinfo.total / 11);
                this.priceinfo.supplyprice = this.priceinfo.total - this.priceinfo.vat;

            } else {
                this.currencykey = 'Common.USD';
                this.priceinfo.vat = Math.floor(this.priceinfo.total / 11 * 100) / 100;
                this.priceinfo.supplyprice = this.priceinfo.total - this.priceinfo.vat;
            }
        }

        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
        });
    }

    payment!: BillingPaymentItem;
    pginfo: Data.IamportPaymentInfo = {} as any;
    ApiUrl = this.client.baseurl;
    getLocalDatetimeStr = CommonUtil.getLocalDatetimeString;
    getOrderDate = CommonUtil.getOrderDateString;
    firstOption = CommonUtil.assetFirstOption;
    withComma = CommonUtil.withComma;
    priceinfo: IPriceInfo = { total: 0, supplyprice: 0, vat: 0 };
    refundinfo: IRefundInfo = { total: 0, list: [] };
    payMethod = '';
    bank = '';
    point = '';
    etc = '';
    applyNum = '-';
    currencykey = '';
    showLicenseInfo = false;
    currentLang: 'ko' | 'en' = this.util.getCurrentLang();
    totalPrice = 0;
    discountPrice = 0;

    getOption = (options: {
        [key in Data.AssetOptionType]?: Data.AssetOption;
    }): Data.AssetOptionType => {
        const optionKeys = Object.keys(options);
        const firstOptionKey = (optionKeys[0]) as Data.AssetOptionType;
        return firstOptionKey;
    }


    onLangChangeSub: Subscription | null = null;
    screenSize = this.resizeSvc.getCurrent();

    @ViewChild('apply_num') applyNumRef!: ElementRef;

    ngOnInit(): void {
        this.load();
        console.log('currencykey???', this.currencykey)
        //영문일시에 버튼 사이즈보다 영문내용이 길어져서 모양이 깨지는문제발생 영문일 시는 html파일에서 currentLang을 체크후 조건부로 사이즈를 늘려주고있다.
        // this.currentLang = CookieUtil.getCookie('Lang')
        this.onLangChangeSub = this.translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang as 'ko' | 'en';
        })
        // this.couponDcPrice += (((this.items[i].options[option]?.itemprice as number)) - discoutList[i]) - ((this.items[i].options[option]?.itemdcprice as number))
        this.totalPrice = this.payment.list.reduce((sum, item) => sum + item.ITEMPRICE, 0)
        this.discountPrice = this.payment.list.reduce((sum, item) => {
            if (item.COUPONID == 1 || item.COUPONID == 2) {
                return sum + (item.ITEMPRICE - item.PURCHASEPRICE);
            } else {
                return sum;
            }
        }, 0);
    }

    ngAfterViewInit() {
        window.scrollTo(0, 0);
        if (this.payment.PAYTOOLNAME.includes('카드') || (this.payment.PAYTOOLNAME === '')) {
            this.applyNumRef.nativeElement.style.opacity = '1';
        }

    }

    ngOnDestroy(): void {
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();

    }

    async load() {
        const pgresult = await this.client.api.GetPGInfo.send({ merchant_uid: this.payment.CID });
        if (pgresult.message === 'ok') {
            this.pginfo = pgresult.info;
        }
        if (this.pginfo.card_name === null) {
            console.log('..?', this.pginfo.pay_method)
            if (this.pginfo.pay_method === 'trans') {
                this.payMethod = 'Order.AccountTransfer';
                this.bank = this.pginfo.bank_name
            } else if (this.pginfo.pay_method === 'point') {
                this.point = 'Order.Points'
                if (this.pginfo.emb_pg_provider === 'kakaopay') {
                    this.payMethod = 'Order.Kakao'
                } else if (this.pginfo.emb_pg_provider === 'naverpay') {
                    this.payMethod = 'Order.Naver'
                } else if (this.pginfo.emb_pg_provider === 'samsung') {
                    this.payMethod = 'Order.Sumsung'
                } else {
                    this.etc = this.pginfo.emb_pg_provider
                }
            } else { //포인트도 계좌이체도 카드도 아닐시 payment에서 뿌려주는값으로 대체
                if (this.pginfo.pg_provider === 'paypal') {
                    this.payMethod = 'Order.Paypal'
                    this.applyNum = this.pginfo.apply_num;
                } else {
                    this.etc = this.payment.PAYTOOLNAME;
                }
            }
        } else {
            if (this.pginfo.card_name === undefined) {
                //쿠폰일때
                let flag = false;
                this.payment.list.forEach(item => {
                    if (this.payment.PAYTOOLNAME === 'coupon' && item.ITEMPRICE !== 0) {
                        flag = true;
                        return;
                    }
                })
                console.log('어후훟', this.payment, this.pginfo)
                if (flag) this.payMethod = 'Common.Coupon'
                else this.payMethod = 'Common.FreeAsset'
            } else {
                //카드일때
                this.payMethod = this.pginfo.card_name + this.pginfo.card_number;
                this.applyNum = this.pginfo.apply_num;
            }

        }
    }

    async showReceipt(item: BillingPaymentItem) {
        this.router.navigate(['/', { outlets: { popup: ['receipt-popup', item.CASHNO] } }],
            { state: { purchase: item, pgInfo: this.pginfo }, replaceUrl: true });
    }

    downloadAsset(item: BillingPurchaseItem) {
        const assetno = parseInt(item.ASSETNO);
        const optionno = parseInt(item.OPTIONNO);
        const type = item.ITEMTYPE;
        this.util.downloadAsset(assetno, optionno, type);
        if (item.firstDownload === null) {
            item.firstDownload = new Date();
            item.refundable = false;
        }
    }
    back() {
        this.location.back();
    }

    async refund(assetIndex = -1) {
        //        if (this.payment.refundable === false) return;

        let refundCount = 0;
        for (const purchase of this.payment.list) {
            if (purchase.refundable) refundCount++;
        }
        if (refundCount === 0) return;

        await this.router.navigateByUrl('/refund', { state: { payment: this.payment, assetIndex, totalPrice: this.totalPrice, discountPrice: this.discountPrice } });
    }

    checkLicenseInfoClose(event: Event) {
        if (event.composedPath().findIndex(
            e => (e as HTMLElement).className === 'purchase-detail-license-popup') > -1) return;
        this.showLicenseInfo = false;
    }

}
