<main>
    <div id="deals-header-container">
        <div class="deals-header-background">
            <div class="deals-header-outer">
                <div class="deals-header-contents-wrap">
                    <div class="deals-header-contents">
                        <h1 class="deals-header-date">{{month}} {{year}}</h1>
                        <h2 class="deals-header-price"
                            [innerHTML]="'SpecialDeal.PriceDeal'|translate">
                            $0.10 Deals</h2>
                        <h4 class="deals-header-desc1"
                            [innerHTML]="'SpecialDeal.HeaderDesc1'|translate">
                            Sign up now to download various 3D content for
                            <span>only $0.10 each!</span>
                        </h4>
                        <h5 class="deals-header-desc2"
                            [innerHTML]="'SpecialDeal.HeaderDesc2'|translate:{month:monthIndex+1}">
                            You can find Asian cultural 3D content for any creative projects
                            including
                            virtual reality (VR), augmented reality (AR), games, 3D architecture
                            visualization, and many more!
                        </h5>
                    </div>
                </div>

                <div class="deals-header-img-wrap"
                    [style.background-image]="'url(/assets/image/special/specialdeal_banner2.svg)'">
                </div>
            </div>
        </div>
    </div>

    <section id="deals-slider-container">
        <div class="deals-slider-wrap">
            <div class="deals-slider-margin">
                <div id="deals-slider-header">
                    <div [innerHTML]="'SpecialDeal.MonthlyDeals'|translate">This Month’s
                    </div>
                </div>
                <div id="deals-slider-list">
                    <div *ngFor="let freeasset of SpecialDeailList; let i = index"
                        (click)="openPopup(freeasset.assetno)">
                        <div [style.background-image]="'url('+freeasset.image1+')'"
                            [ngClass]="freeasset.name.includes('Face') ||freeasset.name.includes('페이스')  ? 'deals-slider-human':'deals-slider-object'"
                            id="deals-slider-thumbnail">
                        </div>
                        <div class="deals-slider-desc">
                            <div class="deals-slider-name">{{freeasset.name}}</div>
                            <div class="deals-slider-option"> {{('Search.'
                                +freeasset.freeAsset)|translate}}</div>
                            <div class="deals-slider-price">
                                <span class="deals-slider-origin-price">
                                    {{withComma(freeasset.itemprice)}} <span>{{freeasset.currency
                                        === 'USD'?'USD':'원'}}</span></span>
                                <span class="deals-slider-dc-price"> {{freeasset.currency === 'USD'?
                                    '0.00': '0'}} <span>{{freeasset.currency
                                        === 'USD'?'USD':'원'}}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="deals-promotion-container">
        <div class="deals-promotion-outer">
            <div class="deals-promotion-inner">
                <div class="deals-promotion-inner-animation">
                    <div class="deals-promotion-animation-group">
                        <img class="deals-promotion-animation deals-animation1"
                            src="/assets/image/special/specialdeal_asset1.png" />
                        <img class="deals-promotion-animation deals-animation2"
                            src="/assets/image/special/specialdeal_asset2.png" />
                        <img class="deals-promotion-animation deals-animation3"
                            src="/assets/image/special/specialdeal_asset3.png" />
                        <img class="deals-promotion-animation deals-animation4"
                            src="/assets/image/special/specialdeal_asset4.png" />
                        <img class="deals-promotion-animation deals-animation5"
                            src="/assets/image/special/specialdeal_asset5.png" />
                        <img class="deals-promotion-animation deals-animation6"
                            src="/assets/image/special/specialdeal_asset6.png" />
                    </div>
                </div>

                <div class="deals-promotion-inner-desc">
                    <div class="deals-promotion-text-wrap">
                        <div class="deals-promotion-moon">
                            <img src="/assets/image/special/specialdeal_moon.svg" />
                        </div>


                        <div [style]="screenSize===2? 'flex-direction: column': 'flex-direction:row'"
                            class="deals-promotion-text">
                            <span [innerHTML]="'SpecialDeal.MontlyUpdate'|translate"></span>
                        </div>
                        <div class="deals-pormotion-contents">
                            <div [innerHTML]="'SpecialDeal.PromotionDesc1'|translate"></div>
                            <div [innerHTML]="'SpecialDeal.PromotionDesc2'|translate"></div>
                        </div>
                    </div>
                    <div class="deals-promotion-color-figure"
                        [innerHTML]="'SpecialDeal.PromotionDesc3'|translate">Dont' miss out on this
                        great deal!</div>
                </div>
            </div>


            <div class="deals-promotion-logo">
                <img src="/assets/image/special/specialdeal_merror_logo.svg" />
            </div>
        </div>

    </div>
</main>