<div class="dropdown-filter-container" id="main-filter-dropdown">

    <div class="dropdown-filter-subcategory">

        <div class="dropdown-filter-subcategory-{{categoryTitle}}">
            <div class="dropdown-filter-controller"
                *ngIf="(categoryTitle !== 'all' && categoryTitle !==undefined)">
                <div class="dropdown-controller-wrap" (click)="showCategoryMenu=!showCategoryMenu">
                    <div class="dropdown-filter-title"
                        [innerHTML]="('Common.Category'|translate) + ' ' + ('Common.Filter'|translate)">
                        Category Filter</div>
                    <img class="dropdown-filter-icon" src="{{ showCategoryMenu ? '/assets/icon/chevron_up_20.svg' :
                    '/assets/icon/chevron_down_20.svg'}}" />
                </div>

            </div>
            <!-- human category dropdown 시작 -->
            <div class="dropdown-filter-subcategory-inner"
                [style.display]="(screenSize === 2 && categoryTitle === 'human') ? 'flex' : (categoryTitle === 'human' && showCategoryMenu ? 'flex' : 'none')">
                <div class="subcategory-list-container">
                    <div class="subcategory-human-list-outer"
                        *ngFor="let category of humanCategoryList; let index = index">
                        <div class="subcategory-human-title-wrap">
                            <h3 [innerHtml]="category.title|translate">
                            </h3>
                        </div>
                        <div class="subcategory-human-list-inner">
                            <ul class="subcategory-list-inner" *ngFor="let list of category.list;">
                                <li class="subcategory-list" (click)="toggleFlag(list.condition)"
                                    [style.pointerEvents]="(facialPage && list.condition === ESC.Type_Posed) || (facialPage && list.condition === ESC.Type_APosed) || (facialPage && list.condition === ESC.Type_Facial)? 'none' : 'auto'">
                                    <input id="category-{{list.name}}" type="checkbox"
                                        (click)="toggleFlag(list.condition)"
                                        class="subcategory-list-{{category.name}} dropdown-filter-checkbox"
                                        [disabled]="(facialPage && list.condition === ESC.Type_Posed) || (facialPage && list.condition === ESC.Type_APosed)"
                                        [checked]="checkFlag(list.condition)" />
                                    <label for="category-{{list.name}}"></label>
                                    <span [innerHtml]="list.name|translate"></span>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
            <!-- human category dropdown 끝 -->

            <!-- object category dropdown 시작 -->
            <div class="dropdown-filter-subcategory-inner"
                [style.display]="(screenSize === 2 && categoryTitle === 'object') ? 'flex' : (categoryTitle === 'object' && showCategoryMenu ? 'flex' : 'none')">
                <div class="subcategory-title-wrap">
                    <div *ngFor="let category of objectCategoryList; let index = index">
                        <h3 [innerHtml]="category.title|translate">
                        </h3>
                    </div>

                </div>
                <div class="subcategory-object-list-container">
                    <div *ngFor="let category of objectCategoryList; let index = index"
                        class="subcategory-object-list-outer">
                        <ul class="subcategory-list-inner" *ngFor="let list of category.list;">
                            <li style="display: flex; flex-direction: column;">
                                <div class="subcategory-list">
                                    <input id="category-{{list.name}}" type="checkbox"
                                        class="subcategory-list-input dropdown-filter-checkbox"
                                        [checked]="checkSubcategory(list.name)"
                                        (click)="list.name === 'Search.Clothings'? toggleAllClothings(true) : toggleSubCategory(list.name, false)" />
                                    <label for="category-{{list.name}}"></label>
                                    <span [innerHtml]="list.name|translate"
                                        (click)="list.name === 'Search.Clothings'? toggleAllClothings(false) : toggleSubCategory(list.name, false)"></span>
                                </div>
                                <ul class="subcategory-list-wrap" *ngIf="list.category.length !==0">
                                    <li class="subcategory-list"
                                        (click)="toggleSubCategory(sublist, true);"
                                        *ngFor="let sublist of list.category">
                                        <input id="category-{{sublist}}" type="checkbox"
                                            class="subcategory-list-input subcategory-child-input dropdown-filter-checkbox"
                                            [checked]="checkSubcategory(sublist)"
                                            (click)="toggleSubCategory(sublist, true);" />
                                        <label for="category-{{sublist}}"></label>
                                        <span [innerHtml]="sublist|translate">{{sublist}}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <!-- object category dropdown 끝 -->

            <!-- space category dropdown 시작 -->
            <div class="dropdown-filter-subcategory-inner"
                [style.display]="(screenSize === 2 && categoryTitle === 'space') ? 'flex' : (categoryTitle === 'space' && showCategoryMenu ? 'flex' : 'none')">
                <div class="subcategory-title-wrap">
                    <div *ngFor="let category of spaceategoryList; let index = index">
                        <h3 [innerHtml]="category.title|translate">
                        </h3>
                    </div>

                </div>
                <div class="subcategory-list-container">
                    <div *ngFor="let category of spaceategoryList; let index = index"
                        class="subcategory-list-outer">

                        <ul class="subcategory-list-inner" *ngFor="let list of category.list;">
                            <li class="subcategory-list">
                                <input id="category-{{list.name}}" type="checkbox"
                                    class="subcategory-list-input dropdown-filter-checkbox"
                                    [checked]="checkSubcategory(list.name)"
                                    (click)="toggleSubCategory(list.name, false)" />
                                <label for="category-{{list.name}}"></label>
                                <span (click)="toggleSubCategory(list.name, false)"
                                    [innerHtml]="list.name|translate"></span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <!-- space category dropdown 끝 -->
        </div>
    </div>
</div>