import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AdminApi, AdminApiList, Api, ApiInfo, ApiList, Data,
    ReqTypeBase, ResTypeBase, SearchTextScore
} from '../../server/lib/types';
import { BehaviorSubject, firstValueFrom, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { AdminClient, CommonUtil } from 'src/server/lib/util';
import { CookieUtil, UtilityService } from './util';
import { AppInjector } from '../app.module';
import { SearchCondition } from './searchtool.service';
import { ReqMessage } from 'src/server/app/server';

export const BASEURL = window.location.origin;

export interface OpenPopup {
    url: string;
    openPopup: boolean;
}

export interface CommonPopup {
    popupTitle: string;
    openPopup: boolean;
    currentGallery?: number;
}


export interface Favorite {
    assetno: number;
    favorite: boolean;
}

export interface Category {
    subCategory?: string;
    onCategory?: boolean;
}

export interface BannerOption {
    category: string;
    method: 'ADD' | 'DELETE' | 'CLEAR';
}

export interface AccountLocation {
    location: 'settings' | 'coupon';
}

class ApiClientInfo<ReqType extends ReqTypeBase, ResType extends ResTypeBase> extends ApiInfo<ReqType, ResType> {
    static baseurl: string;
    static http: HttpClient;
    constructor(info: ApiInfo<ReqType, ResType>) {
        super(info.path, info.reqlogin, info.reqtype, info.restype);
    }
    send: (data?: ReqType) => Promise<ResType> = async (data = {} as any) => {
        const preresult = await this.beforeSend(data);
        if (preresult === null) {
            const result = await firstValueFrom(
                ApiClientInfo.http.post<ResType>(ApiClientInfo.baseurl + '/tran' + this.path,
                    data, { withCredentials: true }));
            await this.onResult(data, result);
            return result;

        } else {
            await this.onResult(data, preresult);
            return preresult;
        }
    };
    beforeSend: (req: ReqType) => Promise<ResType | null> = async result => {
        return null;
    };
    onResult: (req: ReqType, res: ResType) => Promise<void> = async result => {
        //console.log(result);
    };
}

class ApiClientList extends ApiList {
    constructor() {
        super();
        for (const key in this) {
            const api = (this as any)[key];
            if (api instanceof ApiInfo) {
                if (api instanceof ApiClientInfo) {
                } else {
                    console.error('API NOT DEFINED : ' + key);
                }
            }
        }
    }
    setHttp(http: HttpClient, baseurl: string) {
        ApiClientInfo.http = http;
        ApiClientInfo.baseurl = baseurl;
    }
    override Version = new ApiClientInfo(Api.Version);

    override CheckLogin = new ApiClientInfo(Api.CheckLogin);
    override CheckEmailExist = new ApiClientInfo(Api.CheckEmailExist);
    override Login = new ApiClientInfo(Api.Login);
    override Logout = new ApiClientInfo(Api.Logout);

    override RequestVerifyEmail = new ApiClientInfo(Api.RequestVerifyEmail);
    override VerifyEmail = new ApiClientInfo(Api.VerifyEmail);

    override GetAssetList = new ApiClientInfo(Api.GetAssetList);
    override GetAssetinfo = new ApiClientInfo(Api.GetAssetinfo);
    //    override GetAssetinfoWithUser = new ApiClientInfo(Api.GetAssetinfoWithUser);

    override RegisterUser = new ApiClientInfo(Api.RegisterUser);
    override GetUserinfo = new ApiClientInfo(Api.GetUserinfo);
    override DeleteUser = new ApiClientInfo(Api.DeleteUser);
    override ModifyUserinfo = new ApiClientInfo(Api.ModifyUserinfo);
    override ModifyPassword = new ApiClientInfo(Api.ModifyPassword);

    override PasswordResetRequest = new ApiClientInfo(Api.PasswordResetRequest);
    override ResetPassword = new ApiClientInfo(Api.ResetPassword);
    override ChangeEmailRequest = new ApiClientInfo(Api.ChangeEmailRequest);
    override ChangeEmail = new ApiClientInfo(Api.ChangeEmail);
    override CheckUserRequest = new ApiClientInfo(Api.CheckUserRequest);

    override GetUserHistory = new ApiClientInfo(Api.GetUserHistory);
    override SetUserRecvEmail = new ApiClientInfo(Api.SetUserRecvEmail);

    override AddToFavorite = new ApiClientInfo(Api.AddToFavorite);
    override RemoveFromFavorite = new ApiClientInfo(Api.RemoveFromFavorite);
    override GetFavoriteAssetList = new ApiClientInfo(Api.GetFavoriteAssetList);

    override AddToCart = new ApiClientInfo(Api.AddToCart);
    override RemoveFromCart = new ApiClientInfo(Api.RemoveFromCart);
    override GetCartList = new ApiClientInfo(Api.GetCartList);

    override ApplyFavoriteCartCookie = new ApiClientInfo(Api.ApplyFavoriteCartCookie);

    override GetPurchaseList = new ApiClientInfo(Api.GetPurchaseList);
    override RequestPurchase = new ApiClientInfo(Api.RequestPurchase);
    override ConfirmPurchase = new ApiClientInfo(Api.ConfirmPurchase);
    override GetPGInfo = new ApiClientInfo(Api.GetPGInfo);
    override Refund = new ApiClientInfo(Api.Refund);

    override RequestCustomOrder = new ApiClientInfo(Api.RequestCustomOrder);

    override GetCouponList = new ApiClientInfo(Api.GetCouponList);
    override RequestCoupon = new ApiClientInfo(Api.RequestCoupon);
    override CheckReferralCode = new ApiClientInfo(Api.CheckReferralCode);
}

class AdminApiClientList extends AdminApiList {
    constructor() {
        super();
        for (const key in this) {
            const api = (this as any)[key];
            if (api instanceof ApiInfo) {
                if (api instanceof ApiClientInfo) {
                } else {
                    console.error('API NOT DEFINED : ' + key);
                }
            }
        }
    }
    setHttp(http: HttpClient, baseurl: string) {
        ApiClientInfo.http = http;
        ApiClientInfo.baseurl = baseurl;
    }
    override Admin_GetAssetFileInfo = new ApiClientInfo(AdminApi.Admin_GetAssetFileInfo);
    override Admin_RefreshAssetCache = new ApiClientInfo(AdminApi.Admin_RefreshAssetCache);
    override Admin_ResetSearchTextList = new ApiClientInfo(AdminApi.Admin_ResetSearchTextList);
    override Admin_GetSearchTextList = new ApiClientInfo(AdminApi.Admin_GetSearchTextList);
}

@Injectable({
    providedIn: 'root'
})
export class ApiClientService {
    baseurl: string;
    api = new ApiClientList();
    adminapi = new AdminApiClientList();
    keyBoardEvent = fromEvent<KeyboardEvent>(document, 'keydown');

    userinfoSubject: BehaviorSubject<{ userinfo: Data.User; cartcount: number; favoritecount: number }>;
    cartinfoSubject: BehaviorSubject<{ assetno: number, type: Data.CartIconType; }>;
    favoriteSubject: BehaviorSubject<Favorite>;
    searchSubject: BehaviorSubject<SearchCondition>;
    commonPopupSubject: BehaviorSubject<CommonPopup>;
    popularSubject: BehaviorSubject<SearchTextScore[]>;
    openPopupSubject: BehaviorSubject<OpenPopup>;
    accountLocationSubject: BehaviorSubject<AccountLocation>;
    categorySubject: BehaviorSubject<Category>;
    bannerSubject: BehaviorSubject<BannerOption>;
    util = AppInjector.get(UtilityService);

    constructor(private http: HttpClient, private router: Router) {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port;

        this.baseurl = BASEURL;

        this.api.setHttp(this.http, this.baseurl);
        this.adminapi.setHttp(this.http, this.baseurl);

        const userinfo: Data.User = {
            userno: 0,
            email: '',
            password: '',
            userid: 0,
            status: 'NotVerified',
            recv_email: false,
            regdate: new Date()
        };
        const favorites: number[] = [];
        this.userinfoSubject = new BehaviorSubject({ userinfo, cartcount: 0, favoritecount: 0 });
        this.cartinfoSubject = new BehaviorSubject({ assetno: 0, type: 'None' as Data.CartIconType });
        this.favoriteSubject = new BehaviorSubject<Favorite>({ assetno: 0, favorite: false });
        this.searchSubject = new BehaviorSubject<SearchCondition>({
            condition: Data.ESearchCondition.None,
            searchtext: [],
            sort: Data.ESortBy.Recent,
            minprice: 1,
            maxprice: 100000,
            minpolygon: 0,
            maxpolygon: 0,
            categoryInfo: {
                category: [],
                subCategory: [],
                isGameready: false
            }
        });
        this.popularSubject = new BehaviorSubject<SearchTextScore[]>([]);
        this.openPopupSubject = new BehaviorSubject<OpenPopup>({ url: '', openPopup: false });
        this.commonPopupSubject = new BehaviorSubject<CommonPopup>({ popupTitle: '', openPopup: false });
        this.categorySubject = new BehaviorSubject<Category>({});
        this.bannerSubject = new BehaviorSubject<BannerOption>({ category: '', method: 'ADD' });
        this.accountLocationSubject = new BehaviorSubject<AccountLocation>({ location: 'settings' });

        this.setBeforeSendEvent();
        this.setOnResultEvent();
    }

    setBeforeSendEvent() {
        this.api.AddToFavorite.beforeSend = async req => {
            if (CookieUtil.getCookie('lC') !== undefined) {
                return null;
            };

            let model: any = {};
            for (let i in req) {
                if (i === 'assetno' || i === 'image1' || i === 'flag' || i === 'price' || i === 'name' || i == 'optioncount') {
                    model[i] = (req as any)[i];
                    model['options'] = {};
                }
            }
            const result = new this.api.AddToFavorite.restype();
            const key: string | null = 'MYFAVORITE';

            let favoriteList = JSON.parse(localStorage.getItem(key)!);

            if (favoriteList !== null) {
                favoriteList.push(model);
                const cookieLimit = CookieUtil.getCookieByteSize(favoriteList);
                if (cookieLimit >= 4093) {
                    this.commonPopupSubject.next({ popupTitle: 'cookiePopup', openPopup: true });
                    result.message = 'cookieLimit';
                } else {
                    localStorage.setItem(key, JSON.stringify(favoriteList))
                }
            } else {
                localStorage.setItem(key, JSON.stringify([model]))
            }

            return result;
        };
        this.api.RemoveFromFavorite.beforeSend = async req => {
            if (CookieUtil.getCookie('lC') !== undefined) return null;

            const result = new this.api.RemoveFromFavorite.restype();
            const key = 'MYFAVORITE';
            const assetno = req.assetno;

            let favoriteList = JSON.parse(localStorage.getItem(key)!);
            favoriteList = favoriteList.filter((item: Data.Asset) => { return (item.assetno !== assetno); });
            if (favoriteList.length === 0) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(favoriteList))
            }
            return result;
        };
        this.api.GetFavoriteAssetList.beforeSend = async req => {
            if (CookieUtil.getCookie('lC') !== undefined) return null;
            const result = new this.api.GetFavoriteAssetList.restype();
            const columns = req.pagesize;
            const page = req.pageno;
            let modelList = [];

            const key = 'MYFAVORITE';
            const favoriteList = JSON.parse(localStorage.getItem(key)!) as Data.Asset[];
            const cartList = JSON.parse(localStorage.getItem('MYCART')!) as Data.Asset[];
            const currency = this.util.getCurrentCurrency();

            if (favoriteList !== null) {
                //price변경해주는 로직
                for (let i = 0; i < favoriteList.length; i++) {
                    const res = await this.api.GetAssetinfo.send({ assetno: favoriteList[i].assetno, currency });
                    favoriteList[i].price = res.asset.price;
                    favoriteList[i].currency = res.asset.currency;
                }

                // MERROR-246 즐겨찾기 카테고리 필터링
                const filteredList = favoriteList.filter((item: Data.Asset) => {
                    if (req.flag & item.flag & Data.ESearchCondition.Type_All) return true;
                    else return false;
                });

                for (let i = (columns * page - columns); i < page * columns; i++) {
                    if (filteredList[i] !== undefined) {
                        modelList.push(filteredList[i]);
                    }
                }
                result.totalcount = filteredList.length;
                result.list = modelList;

                if (cartList !== null) {
                    for (const favoriteitem of filteredList) {
                        const fitem = favoriteitem as any;
                        fitem.cartcount = 0;
                        for (const cartitem of cartList) {
                            if (favoriteitem.assetno === cartitem.assetno) {
                                fitem.cartcount++;
                            }
                        }
                        fitem.cart = fitem.cartcount === 0 ? 'None'
                            : fitem.cartcount === fitem.optioncount ? 'All' : 'Some';
                        delete fitem.cartcount;
                    }
                }
            }
            return result;
        };
        this.api.AddToCart.beforeSend = async req => {
            if (CookieUtil.getCookie('lC') !== undefined) return null;

            const result = new this.api.AddToCart.restype();

            const key = 'MYCART';
            let cartList = JSON.parse(localStorage.getItem(key)!) as Data.Asset[];
            const newCartList: Data.Asset[] = [];
            let asset: Data.Asset;
            const optionlist: number[] = [];

            if ((req as any).asset === undefined) {
                if (cartList !== null) {
                    cartList = cartList.filter(item => item.assetno !== req.assetno);
                }

                const currency = this.util.getCurrentCurrency();
                const res = await this.api.GetAssetinfo.send({ assetno: req.assetno, currency });
                if (res.message !== 'ok') {
                    // TODO error
                    return null;;
                }
                asset = res.asset;
                for (const option of CommonUtil.assetOptionsAsArray(asset)) {
                    optionlist.push(option.optionno);
                }
            } else {
                asset = (req as any).asset as Data.Asset;
                optionlist.push(...req.optionno);
                if (cartList !== null) {
                    cartList = cartList.filter(item => item.assetno !== req.assetno
                        || optionlist.includes(CommonUtil.assetFirstOption(item).optionno) === false);
                }
            }
            for (const option of CommonUtil.assetOptionsAsArray(asset)) {
                if (optionlist.includes(option.optionno)) {
                    const cartitem: Data.Asset = {
                        asset: asset.asset,
                        assetno: asset.assetno,
                        image1: asset.image1,
                        name: asset.name,
                        options: {},
                    } as any;
                    cartitem.price = option.itemprice;
                    cartitem.options[option.type] = {
                        cart: false,
                        itemdcprice: option.itemdcprice,
                        itemid: option.itemid,
                        itemprice: option.itemprice,
                        optionno: option.optionno,
                        type: option.type
                    } as any;

                    newCartList.push(cartitem);
                }
            }

            if (cartList !== null) {
                if (cartList.length + newCartList.length >= 13) {
                    this.commonPopupSubject.next({ popupTitle: 'cookiePopup', openPopup: true });
                    result.message = 'cookieLimit';
                } else {
                    cartList.push(...newCartList);

                    cartList.sort((a, b) => {
                        const optionno_a = CommonUtil.assetFirstOption(a).optionno;
                        const optionno_b = CommonUtil.assetFirstOption(b).optionno;
                        return optionno_b - optionno_a;
                    });
                    localStorage.setItem(key, JSON.stringify(cartList));
                }
            } else {
                localStorage.setItem(key, JSON.stringify(newCartList));
            }

            // MERROR-245 count에는 추가되는 갯수를 넣어줌
            result.count = newCartList.length;
            return result;

        };

        this.api.RemoveFromCart.beforeSend = async req => {


            if (CookieUtil.getCookie('lC') !== undefined) return null;
            const result = new this.api.RemoveFromCart.restype();
            const key = 'MYCART';
            const assetno = req.assetno;
            const optionlist = req.optionno;

            const cartList = JSON.parse(localStorage.getItem(key)!) as Data.Asset[];
            let newCartList: Data.Asset[];


            if (optionlist.length === 0) {
                newCartList = cartList.filter(item => item.assetno !== assetno);
            } else {
                newCartList = [];
                for (let index = 0; index < cartList.length; index++) {
                    const option = CommonUtil.assetFirstOption(cartList[index]);
                    if (optionlist.includes(option.optionno) === false) {
                        newCartList.push(cartList[index]);
                    }
                }
            }
            if (newCartList.length === 0) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(newCartList));
            }

            // MERROR-245 count에는 삭제되는 갯수를 넣어줌
            result.count = cartList.length - newCartList.length;
            return result;
        };
        this.api.GetCartList.beforeSend = async req => {

            if (CookieUtil.getCookie('lC') !== undefined) return null;
            const result = new this.api.GetCartList.restype();
            //TODO MERROR-119 : get cart from cookie

            const currency = this.util.getCurrentCurrency();

            const columns = req.pagesize;
            const page = req.pageno;
            let modelList = [];

            const key = 'MYCART';
            const cartList = JSON.parse(localStorage.getItem(key)!);

            //translate될때 변경된 정보를 저장하는 obj
            let transObj: any = {};
            let assetnoList = cartList?.map((item: any) => item.assetno).reduce((acc: string, cur: string) => acc.includes(cur) ? acc : [...acc, cur], []);

            //중복을 제거한 assetnoList를 돌면서 transobj에 name과 options를 저장한다.
            if (assetnoList !== undefined) {
                for (let i = 0; i < assetnoList.length; i++) {
                    let asset: any = {};
                    const res = await this.api.GetAssetinfo.send({ assetno: assetnoList[i], currency });
                    asset.options = res.asset.options;
                    asset.name = res.asset.name;
                    transObj[res.asset.assetno] = asset;
                }
            }

            if (cartList !== null) {

                for (let item of cartList) {
                    let transItem = transObj[item.assetno];
                    item.name = transItem.name;
                    for (let option in item.options) {
                        item.options[option] = transItem.options[option];
                        //원래 cartList에 없던 부분은 지워준다.
                        delete item.options[option].orderid;
                        delete item.options[option].size;
                    }
                }

                for (let i = (columns * page - columns); i < page * columns; i++) {
                    if (cartList[i] !== undefined) {
                        modelList.push(cartList[i]);
                    }
                }
                result.totalcount = cartList.length;
                result.list = modelList;
            }


            return result;
        };
    }
    setOnResultEvent() {
        this.api.GetUserinfo.onResult = async (req, res) => {
            // MERROR-245 GetUserinfo 는 보통 로그인 후 실행되며 cartcount와 favoritecount를 함께 받아옴
            if (res.message === 'ok') {
                this.userinfoSubject.next({
                    userinfo: res.info,
                    cartcount: res.cartcount,
                    favoritecount: res.favoritecount
                });
            } else {
                // MERROR-245 로그인을 하지 않은 경우에는 localstorage에서 가져옴
                const cartlist = localStorage.getItem('MYCART');
                let cartcount = 0;
                if (cartlist !== null) {
                    cartcount = JSON.parse(cartlist).length;
                }
                this.userinfoSubject.next({
                    userinfo: this.userinfoSubject.value.userinfo,
                    cartcount,
                    favoritecount: 0
                });
            }
        };
        this.api.Login.onResult = async (req, res) => {
            // TODO MERROR-119 : call ApplyFavoriteCartCookie
            let favoriteList = JSON.parse(localStorage.getItem('MYFAVORITE')!);
            let cartList = JSON.parse(localStorage.getItem('MYCART')!);

            if (favoriteList !== null) {
                favoriteList = favoriteList.map((item: Data.Asset) => {
                    return item.assetno;
                });
            } else {
                favoriteList = [];
            }

            if (cartList !== null) {
                cartList = cartList.map((item: any) => {
                    let firstKey = Object.keys(item.options)[0];
                    return { assetno: item.assetno, optionno: [item.options[firstKey].optionno] };
                });
            } else {
                cartList = [];
            }

            await this.api.ApplyFavoriteCartCookie.send({ favorite: favoriteList, cart: cartList });
            localStorage.removeItem('MYFAVORITE');
            localStorage.removeItem('MYCART');
        };
        this.api.Logout.onResult = async (req, res) => {
            const userinfo: Data.User = {
                userno: 0,
                email: '',
                password: '',
                userid: 0,
                status: 'NotVerified',
                recv_email: false,
                regdate: new Date()
            };
            // TODO MERROR-119 : delete all cookie about favorite and cart
            CookieUtil.deleteCookie('lC');

            this.userinfoSubject.next({ userinfo, cartcount: 0, favoritecount: 0 });
            this.router.navigateByUrl('');
        };
        this.api.DeleteUser.onResult = async (req, res) => {
            if (res.message === 'ok') {
                const userinfo: Data.User = {
                    userno: 0,
                    email: '',
                    password: '',
                    userid: 0,
                    status: 'NotVerified',
                    recv_email: false,
                    regdate: new Date()
                };
                this.userinfoSubject.next({ userinfo, cartcount: 0, favoritecount: 0 });
            }
        };
        this.api.GetAssetList.onResult = async (req, res) => {
            if (CookieUtil.getCookie('lC') !== undefined) return;
            const key = 'MYCART';
            const cartList = JSON.parse(localStorage.getItem(key)!) as Data.Asset[];
            if (cartList === null) return;

            const cartoptionlist: number[] = [];
            for (const cartasset of cartList) {
                const option = CommonUtil.assetFirstOption(cartasset);
                cartoptionlist.push(option.optionno);
            }
            for (const asset of res.list) {
                let optioncount = asset.optioncount;
                let cartcount = 0;
                for (const cartitem of cartList) {
                    if (asset.assetno === cartitem.assetno) {
                        cartcount++;
                    }
                }
                if (cartcount > 0) {
                    asset.cart = cartcount === optioncount ? 'All' : 'Some';
                }
            }

        };
        this.api.GetPurchaseList.onResult = async (req, res) => {
            for (const item of res.list) {
                const text = item.LICENSEINFO;
                if (text === '' || text === '{}' || text === '"{}"') item.licenseinfo = {} as any;
                else item.licenseinfo = JSON.parse(item.LICENSEINFO);
            }
        };
        this.api.AddToCart.onResult = async (req, res) => {
            if (res.count > 0) {
                // MERROR-245
                // cart가 변경된 경우 userinfoSubject를 변경해 주는데,
                // 이 때 userinfoSubject를 구독하는 컴포넌트에서 관련 api를 다시 호출하는 경우
                // 무한루프가 발행하므로 조심해야 함
                this.userinfoSubject.next({
                    userinfo: this.userinfoSubject.value.userinfo,
                    cartcount: this.userinfoSubject.value.cartcount + res.count,
                    favoritecount: this.userinfoSubject.value.favoritecount
                });
            }
        };
        this.api.RemoveFromCart.onResult = async (req, res) => {
            if (res.count > 0) {
                // MERROR-245
                // cart가 변경된 경우 userinfoSubject를 변경해 주는데,
                // 이 때 userinfoSubject를 구독하는 컴포넌트에서 관련 api를 다시 호출하는 경우
                // 무한루프가 발행하므로 조심해야 함
                // 그래서 CartComponent에서는 cart아이템을 삭제하는 동안에는 load()를 하지 않도록 함
                this.userinfoSubject.next({
                    userinfo: this.userinfoSubject.value.userinfo,
                    cartcount: this.userinfoSubject.value.cartcount - res.count,
                    favoritecount: this.userinfoSubject.value.favoritecount
                });
            }
        };
    }

    private orders: Data.Asset[] = [];
    addOrder(model: Data.Asset): boolean {
        const keys = Object.keys(model.options);
        if (keys.length !== 1) return false;

        this.orders.push(model);
        localStorage.setItem('OrderList', JSON.stringify(this.orders));
        return true;
    }
    clearOrders() {
        this.orders = [];
        localStorage.setItem('OrderList', JSON.stringify(this.orders));
    }
    removeOrder(model: Data.Asset): boolean {
        const keys = Object.keys(model.options);
        if (keys.length !== 1) return false;

        const optiontype = keys[0] as Data.AssetOptionType;

        const index = this.orders.findIndex((value, index, obj) =>
            value.assetno == model.assetno && value.options[optiontype] !== undefined
        );
        if (index === -1) return false;
        this.orders.splice(index, 1);
        localStorage.setItem('OrderList', JSON.stringify(this.orders));
        return true;
    }
    getOrders() {
        const str = localStorage.getItem('OrderList');
        if (str !== null) this.orders = JSON.parse(str);
        return this.orders;
    }
    getTotalPrice(): number {
        let total = 0;
        for (const order of this.orders) {
            total += CommonUtil.assetFirstOption(order).itemdcprice;
        }
        return total;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdminClientService {
    private adminclient: AdminClient;
    send: (message: ReqMessage, ondata: (data: any) => void) => Promise<unknown>;

    constructor() {
        this.adminclient = new AdminClient(window.location.hostname, 7126);
        this.send = (message: ReqMessage, ondata: (data: any) => void): Promise<unknown> => {
            return this.adminclient.send(message, ondata)
        }
    }
}