import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IamportService, PayMethodType } from '../lib/iamport';
import { ApiClientService } from '../lib/api-client.service';
import { PayMethodList } from './order.component';
import { UtilityService } from '../lib/util';

@Component({
    selector: 'custom-payment',
    templateUrl: './custom-payment.component.html',
    styleUrls: ['./custom-payment.component.scss']
})
export class CustomPaymentComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private client: ApiClientService,
        private util: UtilityService,
        private iamport: IamportService
    ) {
        this.paymentid = this.route.snapshot.paramMap.get('paymentid');
    }

    currency = this.util.getCurrentCurrency();
    paymentid: string | null;
    paymethodlist = PayMethodList.KRW;
    paymethod = this.paymethodlist[0].method;
    completed = false;
    error = false;

    ngOnInit(): void {
        this.setPayMethod(this.paymethodlist[0].method, 0);
    }

    setPayMethod(method: PayMethodType, index: number) {
        this.paymethod = method;

        const methodList = document.getElementsByClassName('custom-payment-method');

        for (let i = 0; i < methodList.length; i++) {
            if (i !== index) {
                methodList[i].classList.remove('paymethod-checked')
            } else {
                methodList[i].classList.add('paymethod-checked')
            }
        }
        console.log(this.paymethod);
    }

    private getFormattedLocalTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hour = now.getHours().toString().padStart(2, '0');
        const minute = now.getMinutes().toString().padStart(2, '0');
        const second = now.getSeconds().toString().padStart(2, '0');

        return `${year}${month}${day}${hour}${minute}${second}`;
    }

    async order() {
        const { userinfo } = this.client.userinfoSubject.getValue();
        const mid = this.paymentid + '_' + this.getFormattedLocalTime();
        const result = await this.iamport.requestPay({
            amount: 1100000,
            buyer_email: userinfo.email,
            buyer_name: userinfo.email,
            buyer_tel: '010-0000-0000',
            digital: true,
            merchant_uid: mid,
            name: '전신스캔',
            pay_method: this.paymethod
        });
        console.log(result);
        if (result.success === true) {
            this.completed = true;
        } else if (result.error_msg.startsWith('사용자가 결제를 취소')) {
            console.log('사용자가 결제를 취소했습니다.');
        } else {
            this.error = true;
        }
    }

    retry() {
        this.completed = false;
        this.error = false;
    }
}
