import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService } from 'src/app/lib/resize.service';
import { UtilityService } from 'src/app/lib/util';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-change-email-popup',
    templateUrl: './change-email-popup.component.html',
    styleUrls: ['./change-email-popup.component.scss']
})
export class ChangeEmailPopupComponent implements OnInit, OnDestroy, AfterViewInit {

    private key = '';
    email = '';

    message1 = '';
    message2 = '';

    errMsg = '';

    showResult = false;

    desktopSize = this.resizeSvc.getCurrent();

    @ViewChild('input_password') inputPasswordRef!: ElementRef<HTMLInputElement>;
    @ViewChild('error') error!: ElementRef<HTMLElement>;

    constructor(
        private router: Router,
        private client: ApiClientService,
        private route: ActivatedRoute,
        private util: UtilityService,
        private resizeSvc: ResizeService
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    ngOnInit(): void {
        const key = this.route.snapshot.paramMap.get('key');
        const email = this.route.snapshot.paramMap.get('email');
        if (key !== null) this.key = key;
        if (email !== null) this.email = email;

        this.checkKey(key, email);
    }

    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    private async checkKey(key: string | null, email: string | null) {
        console.log(key, email);
        if (key === null || email === null) {
            this.showInvalidKeyMessage();
        } else {
            const res = await this.client.api.CheckUserRequest.send({ email, key });
            console.log(res);
            if (res.message !== 'ok') this.showInvalidKeyMessage();
        }
    }

    async close() {
        await this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
    }

    async changeEmail() {
        const passwordInput = this.inputPasswordRef.nativeElement;
        const password = passwordInput.value;

        this.error.nativeElement.style.opacity = '0';

        const res = await this.client.api.ChangeEmail.send({
            email: this.email, key: this.key, password
        });

        if (res.message === 'ok') {
            // refresh user info
            const userinfo: Data.User = {
                userno: 0,
                email: '',
                password: '',
                userid: 0,
                status: 'NotVerified',
                recv_email: false,
                regdate: new Date()
            };
            this.client.userinfoSubject.next({ userinfo, cartcount: 0, favoritecount: 0 });
            this.showResult = true;
            this.message1 = await this.util.getTranslation('Verification.EmailChanged');
            this.message2 = await this.util.getTranslation('Signup.LoginAgainWithChangedInfo');

        } else if (res.message.includes('invalid key info')) {
            this.showInvalidKeyMessage();

        } else if (res.message.includes('password not match')) {
            this.inputPasswordRef.nativeElement.style.border = '1px solid #FF424C'
            this.error.nativeElement.style.opacity = '1';
            this.errMsg = await this.util.getTranslation('Signup.PasswordNotMatch');
        } else {
            this.showResult = true;
            this.message1 = await this.util.getTranslation('Signup.InvalidUserinfo3');
            this.message2 = await this.util.getTranslation('Signup.InvalidUserinfo2');
        }
    }

    private async showInvalidKeyMessage() {
        this.showResult = true;
        this.message1 = await this.util.getTranslation('Signup.InvalidEmailKey1');
        this.message2 = await this.util.getTranslation('Signup.InvalidEmailKey2');
    }

    hideMessge() {
        this.inputPasswordRef.nativeElement.style.border = '1px solid #B7BEC5';
        this.error.nativeElement.style.opacity = '0';
    }

    goToMain() {
        this.router.navigateByUrl('/');
    }
}
