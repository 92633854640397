<div class="error-outer">
    <div class="error-inner">
        <div class="error-content">
            <div class="error-image"></div>
            <div class="error-title">404 Not Found</div>
            <div class="error-description">
                페이지가 존재하지 않거나 사용할 수 없는 페이지 입니다.
                <br />
                입력하신 주소가 정확한지 다시 한 번 확인해 주시기 바랍니다.
            </div>
        </div>
        <div class="error-buttons">
            <button class="common-button2" (click)="back()">이전페이지</button>
            <button class="common-button1" routerLink="/">메인페이지</button>
        </div>
    </div>
</div>