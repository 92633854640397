<app-error *ngIf="assetNotFound"></app-error>
<div class="product-detail-outer" [style.display]="assetNotFound?'none':'block'">
    <div class="product-detail-location">
        <span [innerHTML]="'Common.Home'|translate" (click)="moveToLocation('/store')">메인</span>
        <img src="/assets/icon/chevron_right_20.svg">
        <span [innerHTML]="'Common.3DAssets'|translate" (click)="moveToLocation('/asset')">
            3D Assets</span>
        <img src="/assets/icon/chevron_right_20.svg">
        <span [innerHTML]="('Sidebar.' + assetCategory)|translate"
            (click)="moveToLocation('/asset/' + assetCategory.toLowerCase())">카테고리</span>
    </div>

    <div class="product-detail-main-outer" *ngIf="asset !== null">
        <div class="product-detail-images-outer">
            <div class="product-detail-images-viewer"
                [style.background-image]="'url(' + thumbnailList[previewindex] +'),radial-gradient(circle, #444444 0%, #1a1a1a 100%)'"
                (click)="showThumbnailZoom(thumbnailList[previewindex]);">
            </div>
            <div class="product-detail-images-list-outer">
                <div class="product-detail-images-list-control" (click)="imagelistMove(-1)">
                    <img src="/assets/icon/chevron_grey_left_24.svg" uk-svg style="cursor: pointer;"
                        [style.color]="imagelistMovePrev?'#f4f4f4':'#616161'">
                </div>
                <div class="product-detail-images-list">
                    <div class="product-detail-images-list-inner">
                        <div *ngFor="let thumbnail of thumbnailList;let index = index"
                            (click)="previewindex=index" class="product-detail-images-list-item"
                            [style.background-image]="'url(' + thumbnail +')'">
                            <div *ngIf="index===previewindex"></div>
                        </div>
                    </div>
                </div>
                <div class="product-detail-images-list-control" (click)="imagelistMove(1)">
                    <img src="/assets/icon/chevron_grey_right_24.svg" uk-svg
                        style="cursor: pointer;"
                        [style.color]="imagelistMoveNext?'#f4f4f4':'#616161'">
                </div>
            </div>
        </div>

        <div style="width:60px;"></div>
        <div class="product-detail-info-outer">
            <div class="product-detail-info-title">
                <div class="product-detail-info-name">{{asset.name}}</div>
                <div class="product-detail-info-favorite" (click)="toggleFavorite()">
                    <img *ngIf="!asset.favorite" src="/assets/icon/star_item_24.svg"
                        style="cursor: pointer; width: 28px; height: 28px;">
                    <img *ngIf="asset.favorite" style="color:#ffa600;"
                        src="/assets/icon/star_item_color_24.svg"
                        style="cursor: pointer; width: 28px; height: 28px;">
                </div>
            </div>
            <div class="product-detail-info-price-outer">
                <div class="product-detail-info-price-title">
                    {{asset.currency==='KRW'?'₩':'$'}}{{withComma(totaldcprice)}}{{
                    (asset.currency==='USD'&&withComma(totaldcprice)?.indexOf('.')==-1)?'.00':''}}
                </div>
                <div class="product-detail-info-price-discount" *ngIf="totalprice !== totaldcprice">
                    <span>{{asset.currency==='KRW'?'₩':'$'}}{{withComma(totalprice)}}{{
                        (asset.currency==='USD'&&withComma(totalprice)?.indexOf('.')==-1)?'.00':''}}</span>
                    <span>{{dcrate}}%</span>
                </div>
            </div>
            <div class="product-detail-info-divider">
            </div>
            <div class="product-detail-info-option-outer">
                <div class="product-detail-info-option-title">Option
                </div>
                <div class="product-detail-info-option-list">
                    <div class="product-detail-info-option {{option.type === selectedOptionType ? 'selected' : ''}}"
                        *ngFor="let option of optionsAsArray(asset) let last =last;"
                        style="cursor:pointer;" (click)="selectOption(option.type)">
                        <div style="width:60%;display:flex;align-items:center;">
                            <div>
                                <div class="product-detail-info-option-name">
                                    {{'Search.'+option.type|translate}}</div>
                                <div class="product-detail-info-option-size">
                                    {{calcSize(option.size)}}
                                </div>
                            </div>
                            <div>
                                <img *ngIf="option.cart" uk-svg src="/assets/icon/cart_16.svg" />
                            </div>
                        </div>
                        <div style="width:40%" *ngIf="option.orderid===0">
                            <div class="product-detail-info-option-dcprice">
                                {{asset.currency==='KRW'?'₩':'$'}}{{withComma(option.itemdcprice)}}
                            </div>
                            <div class="product-detail-info-option-price"
                                *ngIf="option.itemdcprice !== option.itemprice">
                                {{asset.currency==='KRW'?'₩':'$'}}{{withComma(option.itemprice)}}
                            </div>
                        </div>
                        <div style="width:40%" *ngIf="option.orderid>0">
                            <a (click)="download(option)" style="padding:0;margin:0;"
                                class="product-detail-info-option-download">
                                <span
                                    [innerHTML]="'Common.DownloadSelection'|translate">Download</span>
                            </a>
                        </div>
                        <div *ngIf="option.type === selectedOptionType"
                            class="product-detail-info-option-selected">
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-detail-info-buttons-outer">
                <button class="item-view-button common-button2" (click)="addToCart()"
                    [disabled]="selectedOptions.length===0||selectedOptions[0].orderid>0||selectedOptions[0].cart===true"
                    [innerHTML]="'Itemview.AddCart'|translate">
                    장바구니에 담기</button>
                <div style="min-width:16px;"></div>
                <button class="item-view-button common-button1" (click)="order()"
                    [disabled]="selectedOptions.length===0||selectedOptions[0].orderid>0"
                    [innerHTML]="'Itemview.Purchase'|translate">바로 구매하기</button>
            </div>
            <div class="product-detail-info-divider">
            </div>
            <div class="product-detail-info-tags-outer">
                <div class="product-detail-info-option-title">Tag</div>
                <div class="product-detail-info-tags">
                    <div class="product-detail-info-tag" *ngFor="let tag of tags"
                        (click)="searchTag(tag)">{{tag}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Sketchfab Viewer Test -->
    <div class="product-detail-viewer-outer" [style.display]="isFacial?'none':'block'">
        <div class="product-detail-viewer-title" [innerHTML]="'Itemview.3DViewer'|translate">
            3D Viewer
        </div>
        <div class="product-detail-viewer">
            <div class="product-detail-viewer-background" #sketchfab>
                <div id="product-detail-spinner" class="uk-position-center spinner1"
                    style="pointer-events:none;">
                    <div *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11]"></div>
                </div>
            </div>

            <div id="sketchfab-viewer-outer" style="position:absolute;width:100%;height:100%;">
            </div>
            <div *ngIf="asset !== null" class="product-detail-viewer-input">
                <div class="product-deatil-viewer-input-inner" (click)="infoShow = !infoShow">
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            <span
                                class="product-deatil-viewer-input-inner-bold">{{'Search.'+defaultOptionType|translate}}</span>
                            &nbsp; | &nbsp;
                            <span>Polygons: {{formatNum(defaultOptionPolygons)}}</span>
                        </div>
                    </div>
                    <div class="product-detail-viewer-info"
                        [style.display]="infoShow? 'flex': 'none'">
                        <div *ngFor="let option of optionsAsArray(asset);let i=index;"
                            (click)="onchangeDefaultOption(option.info, option.type)"
                            (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)"
                            class="hover-effect">
                            <span [ngStyle]="{ color: (isHovered[i] ? '#FFB833' : '#f4f4f4') }">
                                {{'Search.'+option.type|translate}}</span>
                            &nbsp; | &nbsp;
                            <span
                                [ngStyle]="{ color: (isHovered[i] ? '#FFDB99' : '#9e9e9e') }">Polygons:
                                {{formatNum(option.info.polygons)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="product-detail-files-info-outer">
        <div class="product-detail-viewer-title" [innerHTML]="'Itemview.ModelDetail'|translate">
            Model details
        </div>
        <div class="product-detail-files-info">
            <table *ngIf="asset !== null" cellpadding="0" cellspacing="0">
                <tr>
                    <td rowspan="2" style="width:340px;border-right-width: 1px;"></td>
                    <td colspan="2"
                        style="width:540px;border-right-width: 1px;border-bottom-width:1px;">Model
                    </td>
                    <td colspan="2" style="width:540px;border-bottom-width:1px;">Texture</td>
                </tr>
                <tr>
                    <td style="width:270px;">Format</td>
                    <td style="width:270px;border-right-width:1px;">Polygon</td>
                    <td style="width:270px;">Format</td>
                    <td style="width:270px;">Maps</td>
                </tr>
                <tr *ngFor="let option of optionsAsArray(asset);let i=index;">
                    <td style="border-right-width:1px;">
                        {{'Search.'+option.type|translate}}
                    </td>
                    <td>{{isFacial? 'obj\nztl\nmax\nuasset': option.info.format.join('\n')}}</td>
                    <td style="border-right-width:1px;">{{formatNum(option.info.polygons)}}</td>
                    <td>{{option.info.texture.format.join('\n').toUpperCase()}}</td>
                    <td>
                        {{optionString[i] === undefined || optionString[i].length === 0 ? '-' :
                        optionString[i].join('\n')}}
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="product-detail-viewer-outer">
        <div class="product-detail-viewer-title" [innerHTML]="'Itemview.Caution'|translate">
            Notice
        </div>
        <div class="product-detail-notice-list">
            <ul>
                <li [innerHTML]="'Itemview.Caution1'|translate">결제 완료 후 7일 이내에 콘텐츠를 다운받지 않은 경우 환불
                    가능합니다.</li>
                <li [innerHTML]="'Itemview.Caution2'|translate">디지털 콘텐츠의 특성상 다운로드를 받은 콘텐츠는 환불이 불가하니
                    유의해주시길 바랍니다.</li>
                <li [innerHTML]="'Itemview.Caution3'|translate">상품 하자 발생 시 고객센터 로 문의해주시면 신속하게
                    도와드리겠습니다.</li>
            </ul>
        </div>
    </div>

    <div *ngIf="this.asset!==null" class="main-product-outer"
        style="margin-top: 80px; position: relative;">
        <div class="product-detail-viewer-title" [innerHTML]="'Itemview.RelatedProducts'|translate">
            Notice
        </div>
        <div class="product-detail-carousel-inner">
            <app-carousel [sort]="1" [flag]="searchcondition.condition" [name]="'related'"
                [related]="asset"></app-carousel>
        </div>
    </div>

</div>

<div class="product-detail-img-popup-outer noselect" [style.display]="showImgPopup? 'flex': 'none'">
    <div class="product-detail-img-popup-inner">
        <div class="product-detail-img-zoom-controller-outer"
            [style.opacity]="showController? '1': '0.7'" (mouseenter)="showController=true"
            (mouseleave)="showController=false">
            <div class="product-detail-img-zoom-controller-inner">

                <div class="product-detail-img-zoom-icon" (click)=" closeImgPopup()">
                    <img uk-svg src="/assets/icon/close_24.svg" style="width: 80%; height: 100%;" />
                </div>
            </div>
        </div>
        <figure id="product-detail-img-popup-img" #zoomImgOuter>
            <div id="product-detail-img-zoomImg" #zoomImg class="noselect">
                <img [src]="getDetailImage(zoomtarget)" galleryimg="no" />
            </div>
            <div id="product-detail-prevent-img"
                style="height: 100vh;width: 100vw; background-color: white; opacity: 0;top:0;position: 0;display: none;z-index: 100; transform: none; translate: none;">
            </div>
        </figure>
        <div class="product-detail-img-popup-thumbnail-wrap" style="z-index: 11;"
            [style.opacity]="showThumbnailList? '1': '0.2'" (mouseenter)="showThumbnailList=true"
            (mouseleave)="showThumbnailList=false">
            <div class="product-detail-zoom-controller">
                <div class="product-detail-img-zoom-icon" (click)="toggleZoom(false)">
                    <img uk-svg src="/assets/icon/zoomout.svg" />
                </div>
                <div>{{this.scale * 100}}%</div>
                <div class="product-detail-img-zoom-icon" (click)="toggleZoom(true)">
                    <img uk-svg src="/assets/icon/zoomin.svg" />
                </div>
            </div>
            <div class="product-detail-img-popup-thumbnail-outer">

                <div [ngClass]="(isTouchScreen || isMobile) ? 'product-detail-img-popup-thumbnail-inner2' : 'product-detail-img-popup-thumbnail-inner'"
                    #controller>
                    <div *ngFor="let thumbnail of thumbnailList;let index = index"
                        [style.background-image]="'url(' + thumbnail +')'"
                        class="product-detail-img-popup-thumbnail"
                        (click)="zoomtarget = thumbnail;">
                        <div *ngIf="thumbnailList.indexOf(this.zoomtarget) === index"></div>
                    </div>
                </div>

            </div>

        </div>
        <div class="product-detail-img-popup-arrow product-detail-img-popup-arrow-left"
            (click)="changePopupImg(-1)">
            <img uk-svg src=" /assets/icon/chevron_left_24.svg" />
        </div>
        <div class="product-detail-img-popup-arrow product-detail-img-popup-arrow-right"
            (click)="changePopupImg(+1)">
            <img uk-svg src=" /assets/icon/chevron_right_24.svg" />
        </div>
    </div>

</div>

<div class="product-detail-popup-outer-header" *ngIf="showPopup" (click)="closePopup($event)"></div>
<div class="product-detail-popup-outer" *ngIf="showPopup" (click)="closePopup($event)">
    <div>
        <div class="product-detail-popup">
            <div class="product-detail-popup-title">
                <img src="/assets/icon/correct_24.svg">
                <span [innerHTML]="'Itemview.AddedToCart'|translate">Added to Cart</span>
            </div>
            <div class="product-detail-popup-content-outer">
                <div class="product-detail-popup-content-image">
                    <img src="{{asset?.image1}}">
                </div>
                <div class="product-detail-popup-content">
                    <div class="product-detail-popup-content-name">{{asset?.name}}</div>
                    <div *ngIf="selectedOptions.length>0"
                        class="product-detail-popup-content-optionname">
                        {{'Search.'+selectedOptions[0].type|translate}}
                    </div>
                    <div class="product-detail-popup-content-price"
                        *ngIf="selectedOptions.length>0">
                        <div>
                            {{asset?.currency==='KRW'?'₩':'$'}}{{withComma(selectedOptions[0].itemdcprice)}}
                        </div>
                        <div *ngIf="selectedOptions[0].itemdcprice<selectedOptions[0].itemprice">
                            <span>
                                {{asset?.currency==='KRW'?'₩':'$'}}{{withComma(selectedOptions[0].itemprice)}}
                            </span>
                            <span>
                                {{floor((selectedOptions[0].itemprice-selectedOptions[0].itemdcprice)/selectedOptions[0].itemprice*100)}}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-detail-popup-buttons-outer">
                <button class="item-view-button common-button2" (click)="closePopupMoveCart()"
                    [innerHTML]="'Itemview.ViewCart'|translate">장바구니 보기</button>
                <div style="width:8px;min-width:8px;"></div>
                <button class="item-view-button common-button1" (click)="showPopup=false"
                    [innerHTML]="'Itemview.ContinueShopping'|translate">계속 쇼핑하기</button>
            </div>
        </div>
    </div>
</div>