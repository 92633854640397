import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { CookieUtil, UtilityService } from 'src/app/lib/util';
import { Data } from '../../../server//lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { AssetViewerComponent } from '../common/asset-viewer.component';
import { SEOService } from 'src/app/lib/seo.service';
import { ResizeService } from 'src/app/lib/resize.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-itemview-popup',
    templateUrl: './itemview-popup.component.html',
    styleUrls: ['./itemview-popup.component.scss'],
})
export class ItemviewPopupComponent implements OnInit, AfterViewInit, OnDestroy {

    Object = Object;
    optionsAsArray = CommonUtil.assetOptionsAsArray;
    asset: Data.Asset | null = null;
    optionString: string[][] = [];
    purchase: Data.Payment | null = null;
    @ViewChild('addCart') addCartRef!: ElementRef;
    @ViewChild('alreadyCart') alreadyCartRef!: ElementRef;
    @ViewChild('itemviewoption') itemViewOptionRef!: ElementRef;
    @ViewChild('noOption') noOptionRef!: ElementRef;
    withComma = CommonUtil.withComma;
    formatNum = CommonUtil.formatNum;
    totalprice = 0;
    assetTotalPrice = 0;
    selectedOptions: Data.AssetOption[] = [];

    alertTimer = [[-1, -1, -1], [-1, -1, -1], [-1, -1, -1]];
    opacityDuration = 1500;
    pointerEventDuration = 2200;
    userno!: number;
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;

    desktopSize = this.resizeSvc.getCurrent();
    private subscription!: Subscription;

    @ViewChild('assetviewer') assetviewer!: AssetViewerComponent;

    constructor(
        private client: ApiClientService,
        private router: Router,
        private route: ActivatedRoute,
        private util: UtilityService,
        private seo: SEOService,
        private resizeSvc: ResizeService
    ) {
        this.subscription = this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
            if (!this.desktopSize) {
                document.body.classList.add('no-scroll');
                window.removeEventListener('mousedown', this.preventWheelClick);
            } else {
                document.body.classList.remove('no-scroll');
                window.addEventListener('mousedown', this.preventWheelClick);
            }
        });
    }

    ngOnInit(): void {

        const canvas = document.getElementById('canvas') as HTMLElement
        canvas.addEventListener('touchstart', function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }, { passive: false });

        if (this.desktopSize) {
            window.addEventListener('mousedown', this.preventWheelClick);
        } else {
            document.body.classList.add('no-scroll');
        }

        this.client.userinfoSubject.subscribe((res) => this.userno = res.userinfo.userno);

        document.getElementById('router-outlet-main')!.style.display = 'none';
        document.getElementById('router-outlet-productdetail')!.style.display = 'block';
    }


    ngOnDestroy(): void {
        window.removeEventListener('mousedown', this.preventWheelClick);
        document.body.classList.remove('no-scroll');
        this.subscription.unsubscribe();
        this.client.cartinfoSubject.next({ assetno: 0, type: 'None' });

        document.getElementById('router-outlet-main')!.style.display = 'block';
        document.getElementById('router-outlet-productdetail')!.style.display = 'none';
    }

    ngAfterViewInit(): void {
        this.loadAsset();

    }

    preventWheelClick(e: MouseEvent) {
        if (e.buttons === 4) {
            e.preventDefault();
        }
    }

    async loadAsset() {
        const assetno = this.route.snapshot.paramMap.get('modelno');
        if (assetno === null) return;
        const result = await this.client.api.GetAssetinfo.send({
            assetno: parseInt(assetno), currency: this.util.getCurrentCurrency()
        });
        if (result.message === 'ok') {
            this.asset = result.asset;
            const tags: string[] = [];
            if (Array.isArray(this.asset.tag)) {
                tags.push(...this.asset.tag);
            } else {
                tags.push(...JSON.parse(this.asset.tag));
            }
            this.seo.setInfo(this.router.url, this.asset.assetno, this.asset.name, tags);
            const options = this.optionsAsArray(this.asset);
            for (let i = 0; i < options.length; i++) {
                this.optionString[i] = [];
                for (const format of options[i].info.texture.maps) {
                    if (format === 'OP') this.optionString[i].push('opacity');
                    else if (format === 'AO') this.optionString[i].push('ambient occlusion');
                    else if (format === 'DIFF') this.optionString[i].push('diffuse');
                    else if (format === 'NORM') this.optionString[i].push('normal');
                    else if (format === 'RO') this.optionString[i].push('roughness');
                    else if (format === 'ME') this.optionString[i].push('metalness');
                    else if (format === 'COMP') this.optionString[i].push('complete');
                    else this.optionString[i].push(format);
                }
            }
            if (this.userno === 0) {
                this.setNonMember();
            }
            this.assetviewer.loadByAsset(result.asset);
        }

        this.getTotalPriceAvailable();

    }

    //비회원시, cart,favorite 초기 세팅
    setNonMember() {
        const assetno = this.route.snapshot.paramMap.get('modelno');
        const favoriteList = JSON.parse(localStorage.getItem('MYFAVORITE')!);
        const cartList = JSON.parse(localStorage.getItem('MYCART')!);

        if (favoriteList !== null) {
            favoriteList.forEach((item: Data.Asset) => {
                if (item.assetno === Number(assetno) && this.asset !== null) {
                    this.asset.favorite = true;
                }
            })
        }

        if (cartList !== null) {
            cartList.forEach((item: any) => {
                if (item.assetno === Number(assetno) && this.asset !== null) {
                    let firstKey = Object.keys(item.options)[0]
                    if (firstKey !== undefined) {
                        (this.asset.options as any)[firstKey].cart = true;
                    }
                }
            })
        }
    }

    //전체선택 클릭시 다운로드된 아이템을 제외한 가격을 가져온다
    getTotalPriceAvailable() {
        for (const option of this.optionsAsArray(this.asset as Data.Asset)) {
            if (!(this.isPurchasedOption(option.type))) {
                this.assetTotalPrice += option.itemdcprice;
            };
        };
    }

    toggleFavorite() {
        if (this.asset === null) return;
        if (this.asset.favorite) {
            this.client.api.RemoveFromFavorite.send(this.asset).then(result => {
                console.log(result);
                if (result.message === 'ok') {
                    if (this.asset !== null) {
                        this.client.favoriteSubject.next({ assetno: this.asset.assetno, favorite: false });
                        this.asset.favorite = false;
                    }
                }
            });
        } else {
            this.client.api.AddToFavorite.send(this.asset).then(result => {
                console.log(result);
                if (result.message === 'ok') {
                    if (this.asset !== null) {
                        this.client.favoriteSubject.next({ assetno: this.asset.assetno, favorite: true });
                        this.asset.favorite = true;
                    }
                }
            });
        }
    }

    onSelectionAll() {
        console.log(this.assetTotalPrice);
        const div = this.itemViewOptionRef.nativeElement as HTMLDivElement;
        const inputList = div.querySelectorAll('input')
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].checked === false) {
                inputList.forEach(value => { value.checked = true; });
                this.totalprice = this.assetTotalPrice;

                const newSelected: Data.AssetOption[] = [];
                for (const option of this.optionsAsArray(this.asset as Data.Asset)) {
                    if (!(this.isPurchasedOption(option.type))) {
                        newSelected.push(option);
                    };
                };
                this.selectedOptions = newSelected;
                return;
            }
        }
        inputList.forEach(value => { value.checked = false; });
        this.selectedOptions = [];
        this.totalprice = 0;
    }

    close(event?: Event) {
        if (!event) {
            this.router.navigate([{ outlets: { productdetail: null } }], { replaceUrl: true });
            return;
        }
        const div = event.target as HTMLDivElement;
        let flag = false;
        for (let i = 0; i < div.classList.length; i++) {
            const item = div.classList[i];
            if (item === 'item-view-background') {
                flag = true;
                break;
            }
        }
        if (flag) this.router.navigate([{ outlets: { productdetail: null } }], { replaceUrl: true });
    }

    async closePopupMoveCart(event?: Event) {
        await this.router.navigate([{ outlets: { popup: null } }]);
        this.router.navigate(['/cart']);
    }

    calcSize(size: number) {
        const result = size / 1024 / 1024;
        return '' + (Math.round(result * 100) / 100) + 'MB';
    }

    onChange(event: Event, option: Data.AssetOption) {
        console.log(option);
        const target = event.target as HTMLInputElement;
        const price = option.itemdcprice;
        const newSelected: Data.AssetOption[] = [];

        if (target.checked) {
            newSelected.push(option);
            this.totalprice += price;
        } else {
            this.totalprice -= price;
        }

        for (const item of this.selectedOptions) {
            if (item.type !== option.type && item) {
                newSelected.push(item);
            }
        }
        this.selectedOptions = newSelected;
        console.log(this.selectedOptions);
    }

    async order() {

        if (this.asset === null) return;

        this.client.clearOrders();

        //구매하려는 개수 처음에는 항상 0으로 초기화
        let orderAssetNum = 0;
        for (const option of this.optionsAsArray(this.asset as Data.Asset)) {

            let id = `item-view-${option.type}`;
            const input = document.getElementById(id) as HTMLInputElement;

            if (input.checked && !input.disabled) {
                orderAssetNum += 1;
                const model = Object.assign({}, this.asset);
                model.options = {};
                model.options[option.type] = option;
                this.client.addOrder(model);
            }
        }

        //아무것도 체크를 안했을때 넘어가지못하도록
        if (orderAssetNum === 0) {
            console.log('상품을 추가해주세요');
            this.popupAlertAppear('noOption', this.opacityDuration, this.pointerEventDuration)
            return;
        }

        this.router.navigateByUrl('/order');
    }

    async addToCart() {

        //가격 초기화
        this.totalprice = 0;

        if (this.asset === null) return;
        if (this.selectedOptions.length === 0) {
            this.popupAlertAppear('noOption', this.opacityDuration, this.pointerEventDuration);
            return;
        }

        //체크한 상품이 "모두" 담겨있는 상품 => 이미 추가된 상품입니다.
        //하나라도 새로운 상품이라면 => 해당 옵션을 장바구니에 담았습니다.
        let addCartCount = 0;

        const optionlist: number[] = [];
        for (const option of this.selectedOptions) {
            if (option.cart === false) {
                optionlist.push(option.optionno);
                addCartCount += 1;
            }
        }

        if (addCartCount === 0) {
            this.popupAlertAppear('alreadyCart', this.opacityDuration, this.pointerEventDuration);
        }

        const checkbox = document.getElementsByClassName('item-view-checkbox');
        for (let i = 0; i < checkbox.length; i++) {
            const item = checkbox[i] as HTMLInputElement;
            item.checked = false;
        }

        if (optionlist.length === 0) {
            // addToCart()를 두 번째 실행할 때 아무것도 선택되지 않았을 때도
            // selectedOptions가 남아있어 '이미 장바구니에 담은상품입니다' 라고 표시되는 문제 수정
            this.selectedOptions = [];
            return;
        }

        const result = await this.client.api.AddToCart.send({
            assetno: this.asset.assetno, optionno: optionlist, asset: this.asset
        } as any);
        console.log(result);
        if (result.message === 'ok') {
            this.popupAlertAppear('addCart', this.opacityDuration, this.pointerEventDuration);
            for (const option of this.selectedOptions) {
                option.cart = true;
            }
            const options = this.optionsAsArray(this.asset);
            let carttotal = 0;
            for (const option of options) {
                if (option.cart === true) carttotal++;
            }
            let carttype: Data.CartIconType = 'None';
            if (carttotal > 0) {
                if (carttotal === this.asset.optioncount) carttype = 'All';
                else carttype = 'Some'
            }
            this.client.cartinfoSubject.next({ assetno: this.asset.assetno, type: carttype });

        } else if (result.message.indexOf('login req') > -1) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
            this.client.openPopupSubject.next({ url: this.router.url, openPopup: true });
        }

        // addToCart()를 두 번째 실행할 때 아무것도 선택되지 않았을 때도
        // selectedOptions가 남아있어 '이미 장바구니에 담은상품입니다' 라고 표시되는 문제 수정
        this.selectedOptions = [];
    }

    popupAlertAppear(item: string, opacityTime: number, pointerEventTime: number) {
        let i = -1;
        let target: any;
        switch (item) {
            case 'alreadyCart': { target = this.alreadyCartRef.nativeElement; i = 0; break; }
            case 'addCart': { target = this.addCartRef.nativeElement; i = 1; break; }
            case 'noOption': { target = this.noOptionRef.nativeElement; i = 2; break; }
            default: { target = undefined; }
        }
        if (typeof target === undefined || i === -1) return;

        if (this.alertTimer[i][0] !== -1 || this.alertTimer[i][1] !== -1 || this.alertTimer[i][2] !== -1) {
            for (let j = 0; j < 3; j++) {
                clearTimeout(this.alertTimer[i][j]);
                this.alertTimer[i][j] = -1;
            }
        }
        this.alertTimer[i][0] = window.setTimeout(() => {
            this.alertTimer[i][0] = -1;
            target.style.opacity = 1;
            target.style.pointerEvents = 'auto';
        }, 0);

        this.alertTimer[i][1] = window.setTimeout(() => {
            this.alertTimer[i][1] = -1;
            target.style.opacity = 0;
        }, opacityTime);

        this.alertTimer[i][2] = window.setTimeout(() => {
            this.alertTimer[i][2] = -1;
            target.style.pointerEvents = 'none';
        }, pointerEventTime);
    };

    isPurchasedOption(type: Data.AssetOptionType) {
        if (this.asset === null) return false;
        const option = this.asset.options[type];
        if (option === undefined) return;
        return option.orderid > 0;
    }

    download(option: Data.AssetOption) {
        if (this.asset === null) return;
        const result = this.util.downloadAsset(this.asset.assetno, option.optionno, option.type);
        console.log(result);
    }
}