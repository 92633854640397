import { Injectable } from "@angular/core";

/*
 * https://docs.iamport.kr/sdk/javascript-sdk
 * https://docs.iamport.kr/sdk/javascript-sdk?lang=ko#request_pay
 */
export type PayMethodType = 'card' | 'trans' | 'vbank' | 'phone'
    | 'samsung' | 'kakaopay' | 'payco' | 'ssgpay' | 'tosspay' | 'naverpay' | 'paypal';
export interface RequestPayParamType {
    pg?: string;
    pay_method: PayMethodType; // 'card'
    escrow?: boolean;   // false
    merchant_uid: string;
    name: string;
    amount: number;
    custom_data?: any;
    tax_free?: number;
    currency?: string;  // 'KRW'
    language?: string;  // 'ko'
    buyer_name: string;
    buyer_tel: string;
    buyer_email?: string;
    buyer_addr?: string;
    buyer_postcode?: string;
    confirm_url?: string;
    notice_url?: string | string[];
    display?: {
        card_quota: number[];
    };

    digital: boolean;
    vbank_due?: string; //YYYYMMDDhhmm
    m_redirect_url?: string;
    app_scheme?: string;
    biz_num?: string;
}
export interface RequestPayCallbackParamType {
    success: boolean;
    error_code: string;
    error_msg: string;
    imp_uid: string;
    merchant_uid: string;

    pay_method?: string;
    paid_amount?: number;
    status?: string;
    name?: string;
    pg_provider?: string;
    emb_pg_provider?: string;
    pg_tid?: string;
    buyer_name?: string;
    buyer_email?: string;
    buyer_tel?: string;
    buyer_addr?: string;
    buyer_postcode?: string;
    custom_data?: any;
    paid_at?: number;
    receipt_url?: string;

    apply_num?: string;
    vbank_num?: string;
    vbank_holder?: string;
    vbank_date?: number;

}
export type RequestPayCallbackType = (rsp: RequestPayCallbackParamType) => void;


export interface IMPType {
    init: (client_id: string) => void;
    request_pay: (param: RequestPayParamType, callback: RequestPayCallbackType) => void;
}

@Injectable({
    providedIn: 'root'
})
export class IamportService {
    private iamport = (window as any).IMP as IMPType;
    private mode = 'test';
    constructor() {
        this.iamport.init('imp43513629');
    }

    setMode(mode: string) { this.mode = mode; }

    requestPay = (data: RequestPayParamType, extras = ''): Promise<RequestPayCallbackParamType> => {

        const l = window.location;
        if (data.pay_method === 'paypal') {
            data.m_redirect_url = l.protocol + '//' + l.host + '/order/paypal' + extras;
            data.pay_method = 'card';
            data.currency = 'USD';
            if (this.mode === 'prod') {
                data.pg = 'paypal.merror.studio_api1.gmail.com'
            } else {
                data.pg = 'paypal.sb-o2tqa22013019_api1.business.example.com';
            }

        } else {
            if (this.mode === 'prod') {
                data.pg = 'html5_inicis.^^MOI7323113^^';
            } else {
                data.pg = 'html5_inicis.INIpayTest';
                data.m_redirect_url = l.protocol + '//' + l.host + '/order/complete' + extras;
            }
        }
        return new Promise((resolve) => {
            this.iamport.request_pay(data, rsp => {
                resolve(rsp);
            });
        });
    }
}