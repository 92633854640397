<div class="main-container">

    <div class="main-outer">
        <!-- 배너 시작-->
        <div id="index-slider" class="main-banner-outer" #banner_outer>
            <div class="main-banner-inner">
                <div class="main-banner-contents-wrap">
                    <div class="main-banner-contents">
                        <p class="main-banner-paragraph">
                            #Realistic #Asia
                        </p>
                        <h1 class="main-banner-header">Make it real, <br class="mobile-br">Make it
                            fast<br><span style="color: #FFB833;">ME.RROR</span></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-controller-outer">
            <div class="main-controller-inner">
                <div class="main-controller">
                    <ul [ngClass]="(isTouchScreen || isMobile) ? 'mobile-main-controller-list' : 'main-controller-list'"
                        #controller>
                        <li class="main-controller-button"
                            (click)="moveToAssetPage('/asset/human', '',false,  ESC.Type_Facial)">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/facial.png" />
                            </div>
                            <span [innerHtml]="'Sidebar.Facial'|translate">Facial</span>
                        </li>
                        <li class="main-controller-button" routerLink="/asset/human">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/human.png" />
                            </div>
                            <span [innerHtml]="'Sidebar.Human'|translate"
                                routerLink="/asset">Human</span>
                        </li>
                        <li class="main-controller-button"
                            (click)="moveToAssetPage('/asset/object', 'oriental/traditional',false)">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/asian.png" />
                            </div>
                            <span [innerHtml]="'Sidebar.Asian'|translate">Asian</span>
                        </li>
                        <li class="main-controller-button"
                            (click)="moveToAssetPage('/asset/all', '',true )">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/game_ready.png" />
                            </div>
                            <span [innerHtml]="'Sidebar.Gameready'|translate">Game-ready</span>
                        </li>
                        <li class="main-controller-button" routerLink="/event/specialdeal">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/free.png" />
                            </div>
                            <span [innerHtml]="'Sidebar.SpecialDeal'|translate">$0.10 Deals</span>
                        </li>
                        <li class="main-controller-button" (click)="util.showChannelTalk()">
                            <div class="main-controller-img">
                                <img src="/assets/icon/mainLink/custom_order.png" />
                            </div>
                            <span [innerHtml]="'Common.Customization'|translate">Custom Order</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 배너 끝-->

        <!-- NEW PRODUCT 슬라이더 시작 -->
        <div class="main-product-outer">
            <div class="main-product-inner">
                <div class="main-product-header new-product-header">
                    <div>
                        <div (click)="goToAssetList('recent')">
                            <div>NEW PRODUCT</div>
                            <div class="main-product-link">
                                <img src="/assets/icon/chevron_right_24.svg" />
                            </div>
                        </div>
                    </div>


                </div>
                <app-carousel [sort]="1" [flag]="searchcondition.condition"
                    [name]="'new'"></app-carousel>
            </div>

        </div>
        <!-- NEW PRODUCT 슬라이더 끝-->

        <!-- NEW BEST 슬라이더 시작 -->
        <div class="main-popular-product-outer">
            <div class="main-product-outer">
                <div class="main-product-inner">
                    <div class="main-product-header">



                        <div>
                            <div (click)="goToAssetList('popular')">
                                <div>BEST PRODUCT</div>
                                <div class="main-product-link">
                                    <img src="/assets/icon/chevron_right_24.svg" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <app-carousel [sort]="3" [flag]="searchcondition.condition"
                        [name]="'best'"></app-carousel>
                </div>
            </div>
        </div>
        <!-- NEW BEST 슬라이더 끝-->

        <!-- 쿠폰배너 시작-->
        <div class="main-coupon-banner-outer">
            <div class="main-coupon-banner-inner">
                <div class="main-coupon-banner-background" (click)="navigateTo('/event/referral')">
                    <div class="main-coupon-contents">
                        <div class="main-coupon-banner-text">
                            <h3 [innerHtml]="'Banner.CouponDesc1'|translate">Sign up now and receive
                                a
                                free coupon instantly!</h3>
                            <p [innerHtml]="'Banner.CouponDesc2'|translate">Enjoy the benefits of
                                becoming a member!</p>


                        </div>
                        <div class="main-coupon-banner-img">
                            <img src="/assets/image/banner/coupon_banner.svg">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 쿠폰 배터 끝-->

        <!-- Facial 슬라이더 시작 -->
        <div class="main-popular-product-outer">
            <div class="main-product-outer">
                <div class="main-product-inner">
                    <div class="main-product-header">
                        <div>
                            <div (click)="moveToAssetPage('/asset/human', '' ,false,
                                ESC.Type_Facial)">
                                <div>FACIAL</div>
                                <div class="main-product-link">
                                    <img src="/assets/icon/chevron_right_24.svg" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <app-carousel [sort]="1" [flag]="ESC.Type_Facial"
                        [name]="'facial'"></app-carousel>
                </div>
            </div>
        </div>
        <!-- Facial 슬라이더 끝 -->

        <!-- 하단 배너 시작-->
        <div class="main-bottom-banner-outer">
            <div class="main-bottom-banner-inner">
                <div class="main-bottom-banner-background" (click)="util.showChannelTalk()">
                    <div class="main-bottom-banner-text">
                        <h3 [innerHtml]="'Banner.CustomDesc1'|translate">Turn your ideas into
                            reality!</h3>
                        <p [innerHtml]="'Banner.CustomDesc2'|translate">With custom 3D modeling,
                            we'll perfectly create the model you desire!</p>
                    </div>

                    <div class="main-bottom-banner-img main-bottom-banner-img1">
                        <img src="/assets/image/banner/bottom_banner_human.png">
                    </div>
                    <div class="main-bottom-banner-img main-bottom-banner-img2">
                        <img src="/assets/image/banner/bottom_banner_kama.png">
                    </div>
                </div>
            </div>
        </div>
        <!-- 하단 배터 끝-->
    </div>