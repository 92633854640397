<div class="asset-viewer-outer">
    <div id="canvas" style="width:100%;height:100%;"></div>
    <div class="asset-viewer-merror-logo">
        <img src="/assets/image/logo.svg" />
    </div>
    <div class="asset-viewer-icon-wrap">
        <div class="asset-viewer-icon1-outer">
            <img src="assets/icon/layers_24.svg" class="asset-viewer-icon1" />
        </div>
        <div uk-dropdown="mode:click;pos:top-left" style="color:white;" asset-viewer-outer
            class="item-popup-viewer-menu1-background">
            <div (click)="setMatCap(false);">
                <div class="item-popup-viewer-menu1-indicator" [style.opacity]="opacityDiffuse">
                </div>
                <span [innerHTML]="'Itemview.Option_FinalRender'|translate">최종 렌더링</span>
            </div>
            <span style="height:5px;width:10px;display:block;"></span>
            <div (click)="setMatCap(true)">
                <div class="item-popup-viewer-menu1-indicator" [style.opacity]="opacityMatcap">
                </div>
                <span [innerHTML]="'Itemview.Option_Matcap'|translate">Matcap</span>
            </div>
            <span style="height:5px;width:10px;display:block;"></span>
            <div (click)="setWireframe()">
                <div class="item-popup-viewer-menu1-indicator" [style.opacity]="opacityWireframe">
                </div>
                <span [innerHTML]="'Itemview.Option_Wireframe'|translate">와이어 프레임</span>
            </div>
            <span style="height:5px;width:10px;display:block;"></span>
            <div (click)="setVertexNormal()">
                <div class="item-popup-viewer-menu1-indicator"
                    [style.opacity]="opacityVertexNormal"></div>
                <span [innerHTML]="'Itemview.Option_VertexNormal'|translate">버텍스노말</span>
            </div>
        </div>
        <img src="assets/icon/set_camera.svg" (click)="three.setCamera()"
            class="asset-viewer-icon2">
    </div>
    <div class="uk-position-center spinner1" style="pointer-events:none;">
        <div *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11]"></div>
    </div>
</div>