import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from '../lib/api-client.service';
import { ResizeService } from '../lib/resize.service';
import { Util, UtilityService } from '../lib/util';
import { InputComponent } from './common/input.component';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, AfterViewInit {

    desktopSize = this.resizeSvc.getCurrent();
    referralcode = '';

    constructor(
        private client: ApiClientService,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private util: UtilityService,
        private resizeSvc: ResizeService
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });

        this.referralcode = this.route.snapshot.params['referralcode'];
        if (this.referralcode === undefined || this.referralcode.length !== 8) this.referralcode = '';

    }

    @ViewChild('signup_email') inputEmailComponent!: InputComponent;
    @ViewChild('signup_password') inputPasswordComponent!: InputComponent;
    @ViewChild('signup_password_confirm') inputPasswordConfirmComponent!: InputComponent;
    @ViewChild('signup_referralcode') inputReferralcodeComponent!: InputComponent;
    @ViewChild('signUpAgreements') signupAgreementsRef!: ElementRef;

    agreeAll = false;
    agree_recv_email = false;

    ngOnInit(): void {
        Util.scrollTo(0, 0);
    }

    ngAfterViewInit(): void {
        this.inputPasswordConfirmComponent.refInput = this.inputPasswordComponent;
        this.inputReferralcodeComponent.setValue(this.referralcode);
    }

    onFocus(event: Event) {
        const target = event.target as HTMLInputElement;
        const desc = target.nextSibling as HTMLDivElement;
        desc.style.transform = 'scale(0.8)';
    }

    onBlur(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value.trim() === '') {
            const desc = target.nextSibling as HTMLDivElement;
            desc.style.transform = '';
        }
    }

    onSelectionAgreementAll() {
        const inputAllElement = document.getElementById('signup-agreement-allow-all') as HTMLInputElement;
        let toValue = true;
        if (!inputAllElement.checked) {
            toValue = false;
        }

        if (toValue) this.agreeAll = true;
        else this.agreeAll = false;

        const div = this.signupAgreementsRef.nativeElement as HTMLDivElement;
        const checkBoxes = div.querySelectorAll('input');
        for (let i = 0; i < checkBoxes.length; i++) {
            checkBoxes[i].checked = toValue;
        }
        this.agree_recv_email = toValue;
    }
    onSelectionAgreement(event: Event) {
        const div = this.signupAgreementsRef.nativeElement as HTMLDivElement;
        const checkBoxes = div.querySelectorAll('input');
        let flag = true;
        for (let i = 1; i < checkBoxes.length; i++) {
            const element = checkBoxes[i];
            if (!element.checked) {
                flag = false;
                break;
            }
        }
        checkBoxes[0].checked = flag;
        if (checkBoxes[0].checked) this.agreeAll = true;
        else this.agreeAll = false;
    }

    checkRequiredAgreement(): boolean {
        const div = this.signupAgreementsRef.nativeElement as HTMLDivElement;
        const checkBoxes = div.querySelectorAll('input');
        for (let i = 1; i < checkBoxes.length - 1; i++) {
            if (checkBoxes[i].checked === false) return false;
        }
        return true;
    }

    async signUp() {
        console.log(this.agree_recv_email);
        const email = this.inputEmailComponent.getValue();
        const password = this.inputPasswordComponent.getValue();
        const passwordConfirm = this.inputPasswordConfirmComponent.getValue();
        const _referralcode = this.inputReferralcodeComponent.getValue();
        const referralcode = _referralcode === false ? '' : _referralcode;

        console.log(email, password, passwordConfirm);
        if (email === false || password === false || passwordConfirm === false) return;

        if (!this.checkRequiredAgreement()) {
            this.client.commonPopupSubject.next(({ popupTitle: 'termsPopup', openPopup: true }))
            return;
        };

        const res = await this.client.api.RegisterUser.send({
            email,
            password,
            lang: this.util.getCurrentLang(),
            recv_email: this.agree_recv_email,
            referralcode
        });
        console.log(res);

        if (res.message === 'ok') {
            this.router.navigate(['/', { outlets: { popup: ['mail-sent', email] } }])
        }
    }

    openSigninPopup() {
        setTimeout(() => {
            this.client.openPopupSubject.next({ url: '/signup', openPopup: true })
        }, 1)

    }
}
