<div class="referral-container">
    <div class="referral-outer">
        <div class="referral-inner">
            <div class="referral-event">
                <div [innerHTML]="'Referral.InvitationEvent'|translate">ME.RROR 친구 초대 이벤트</div>
            </div>
            <div class="referral-main-title">
                <div class="referral-main-title-invitation"
                    [innerHTML]="'Referral.ReferFriend'|translate">ME.RROR에 친구를 초대하면</div>
                <div class="referral-main-title-gradient"
                    [innerHTML]="'Referral.GetFreeCoupon'|translate">나도! 친구도!<br>전상품 FREE 쿠폰</div>
            </div>
            <div class="referral-step1">
                <div class="referral-step-order">01</div>
                <div class="referral-step1-desc1" [innerHTML]="'Referral.YouAndFriend'|translate">
                    나도! 친구도!</div>
                <div class="referral-step1-desc2" [innerHTML]="'Referral.EventBeneifts'|translate">
                    <span style="color: #f4f4f4">친구
                        초대</span><span style="color: #FFA600;">혜택</span>
                </div>
                <div class="referral-freecoupon-card">
                    <div class="referral-freecoupon-wrap">
                        <div class="referral-freecoupon referral-freecoupon1">
                            <div class="referral-freecoupon1-contents1"
                                [innerHTML]="'Referral.ForAnyItem'|translate">전상품</div>
                            <div class="referral-freecoupon1-contents2"
                                [innerHTML]="'Referral.FreeCoupon'|translate">무료 쿠폰</div>
                            <div class="referral-freecoupon1-contents3"
                                [innerHTML]="'Referral.Condition1'|translate">친구가 가입 완료시 즉시 지급</div>
                        </div>
                        <div class="referral-freecoupon1-oval1"
                            [innerHTML]="'Referral.ForYou'|translate">
                            친구 가입 시 나에게
                        </div>
                    </div>

                    <div class="referral-freecoupon-wrap">
                        <div class="referral-freecoupon referral-freecoupon2">
                            <div class="referral-freecoupon2-contents1"
                                [innerHTML]="'Referral.ForAnyItem'|translate">전상품</div>
                            <div class="referral-freecoupon2-contents2"
                                [innerHTML]="'Referral.FreeCoupon'|translate">무료 쿠폰</div>
                            <div class="referral-freecoupon2-contents3"
                                [innerHTML]="'Referral.AfterSignup'|translate">친구가 가입 완료시 즉시 지급
                            </div>
                        </div>
                        <div class="referral-freecoupon2-oval2"
                            [innerHTML]="'Referral.ForYourfriend'|translate">
                            초대받은 친구에게
                        </div>
                    </div>
                </div>

            </div>
            <div class="referral-step2">
                <div class="referral-step-order">02</div>
                <div class="referral-step2-desc1"
                    [innerHTML]="'Referral.InvitationFriend'|translate">친구 초대</div>
                <div class="referral-step2-desc2" [innerHTML]="'Referral.Easy'|translate">어렵지 않아요!
                </div>
                <div class="referral-step2-contents">
                    <div class="referral-step2-border"></div>
                    <div class="referral-step2-background">
                        <div>
                            <div>
                                <div class="referral-step2-step">STEP1.</div>
                                <div class="referral-step2-paragraph"
                                    [innerHTML]="'Referral.Step1'|translate">나의 추천인 코드를 친구에게 공유한다
                                </div>
                                <div class="referral-step2-input">
                                    <span *ngIf="userinfo.userno === 0"
                                        [innerHTML]="'Referral.CopyCode'|translate">나의 추천인 코드
                                        복사하기</span>
                                    <span *ngIf="userinfo.userno !== 0">{{userinfo.password}}</span>
                                    <div *ngIf="userinfo.userno !== 0"
                                        class="referral-step2-copybutton"
                                        [cdkCopyToClipboard]="userinfo.password"
                                        (click)="openCopyPopup()">
                                    </div>
                                    <div *ngIf="userinfo.userno === 0"
                                        class="referral-step2-copybutton"
                                        (click)="openLoginPopup()">
                                    </div>
                                </div>
                                <div class="referral-step2-account-info">
                                    <div [innerHTML]="'Referral.Location1'|translate">나의 추천인 코드는
                                    </div>
                                    <div>
                                        <span [innerHTML]="'Referral.at'|translate"
                                            *ngIf="currentLang === 'en'">at</span>
                                        <span [innerHTML]="'Referral.Location2'|translate"
                                            style="cursor: pointer;"
                                            (click)="moveToMyCoupon()">내계정(MY)>내쿠폰>내추천코드</span>
                                        <span [innerHTML]="'Referral.at'|translate"
                                            *ngIf="currentLang !== 'en'">at</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="referral-step2-step">STEP2.</div>
                                <div class="referral-step2-paragraph"
                                    [innerHTML]="'Referral.Step2'|translate">친구가 회원가입시 나의 추천인 코드를
                                    입력한다
                                </div>
                            </div>
                            <div>
                                <div class="referral-step2-step">STEP3.</div>
                                <div class="referral-step2-paragraph"
                                    [innerHTML]="'Referral.Step3'|translate">친구가 가입 완료하면 나도!친구도!즉시
                                    쿠폰이
                                    발급되어요</div>
                            </div>

                            <div class="referral-step2-alert">
                                <div [innerHTML]="'Referral.EventGuidelines'|translate">이벤트 유의사향
                                </div>
                                <ul class="referral-step2-alert-contents">
                                    <li [innerHTML]="'Referral.Guideline1'|translate">친구가 회원가입을 완료한
                                        즉시 나와 친구에게 쿠폰이 지급됩니다.</li>
                                    <li [innerHTML]="'Referral.Guideline2'|translate">친구가 회원가입을 할때
                                        나의 추천인 코드를 입력해야 나에게도 쿠폰이 지급됩니다.</li>
                                    <li [innerHTML]="'Referral.Guideline3'|translate">당사의 사정으로 이벤트는
                                        조기종료될 수 있습니다.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="referral-background-frame1">
                <img src="assets/image/referral/logo_frame.svg">
            </div>
            <div class="referral-background-frame2">
                <img src="assets/image/referral/logo_frame.svg">
            </div> -->

            <div class="referral-background-star1">
                <img src="assets/image/referral/star1.svg">
            </div>
            <div class="referral-background-star2">
                <img src="assets/image/referral/star2.svg">
            </div>
            <div class="referral-background-star3">
                <img src="assets/image/referral/star3.svg">
            </div>
            <div class="referral-background-star4">
                <img src="assets/image/referral/star4.svg">
            </div>
            <div class="referral-background-star5">
                <img src="assets/image/referral/star4.svg">
            </div>
            <div class="referral-background-circle1">
                <img src="assets/image/referral/circle.svg">
            </div>
            <div class="referral-background-circle2">
                <img src="assets/image/referral/circle.svg">
            </div>
            <div class="referral-background-circle3">
                <img src="assets/image/referral/circle.svg">
            </div>
        </div>
    </div>
    <div class="referral-background-ellipse">
        <img src="assets/image/referral/ellipse.svg">
    </div>

    <div class="copy-popup-container" #popup>
        <div class="copy-popup-contents">
            <div class="copy-popup-message">
                <div>
                    <img src="/assets/icon/check_24_color.svg" />
                </div>

                <div [innerHTML]="'Account.CopyClipboard'|translate">
                </div>
            </div>
        </div>
    </div>
</div>