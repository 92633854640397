import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { AssetUtil, Util, UtilityService } from '../../lib/util'
import { SearchCondition } from 'src/app/lib/searchtool.service';
import { Data } from 'src/server/lib/types';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import gsap from 'gsap'
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FaceKoreaFilterComponent } from './component/face-korea-filter.component';

@Component({
    selector: 'app-face-korea',
    templateUrl: './face-korea.component.html',
    styleUrls: ['./face-korea.component.scss', '../asset-list.component.scss']
})
export class FaceKoreaComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('freetrial') freetrial_container!: ElementRef<HTMLDivElement>;
    @ViewChild(FaceKoreaFilterComponent) filterComponent!: FaceKoreaFilterComponent;

    private pageno = 1;
    private Columns = 5;

    itemWidth = 268;
    gap = 20;
    mobileFilter = false;
    lang: 'ko' | 'en' = 'en';
    totalItems = 0;

    animationList = ['animation1-face', 'animation2-face', 'animation3-face', 'animation4-face']

    ApiUrl = this.client.baseurl;

    initialLoad = false;
    filterEmpty = false;
    searchEmpty = false;

    constructor(
        private router: Router,
        private client: ApiClientService,
        private resizeSvc: ResizeService,
        private util: UtilityService,
        private translate: TranslateService
    ) {

        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;

            if (this.screenSize === SCREEN_SIZE.mobile) this.Columns = 2;

        });
        setTimeout(() => {
            if (this.screenSize === SCREEN_SIZE.mobile) this.Columns = 2;
        }, 1);

    }

    assetutil = new AssetUtil(this.client.api.GetAssetList.send);
    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.Type_Facial,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: ['human'],
            subCategory: [],
            isGameready: false,
        }
    };

    assetlist: Data.Asset[] = [];
    screenSize = this.resizeSvc.getCurrent();

    searchSub: Subscription | null = null;
    categorySub: Subscription | null = null;
    onLangChangeSub: Subscription | null = null;

    ngOnInit(): void {
        this.load(true);

        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.lang = lang.lang as 'ko' | 'en';
            await this.load(true);

        });

        this.categorySub = this.client.categorySubject.subscribe((val) => {
            this.client.searchSubject.next(this.searchcondition);
        });
        this.searchSub = this.client.searchSubject.subscribe(searchCond => {
            if (!this.initialLoad) return;
            this.UpdateCondition(searchCond);
        });

    }

    UpdateCondition(newCondition: SearchCondition, scroll = false) {

        this.pageno = 1;
        Object.assign(this.searchcondition, newCondition);
        this.load(true);
    }

    resizeCallback = (event?: UIEvent) => {
        if (this.screenSize === SCREEN_SIZE.desktop) {
            this.itemWidth = 268;
            this.gap = 20;
        } else if (this.screenSize === SCREEN_SIZE.tablet) {
            this.itemWidth = 258;
            this.gap = 16;
        } else {
            this.itemWidth = 160;
            this.gap = 8;
        }
        const columns = Util.setContainerSize('faceAsset_list', this.itemWidth, this.gap);
        if (columns !== this.Columns) {
            this.Columns = columns;
            this.load(true);
        }
    };

    ngAfterViewInit(): void {
        window.addEventListener('resize', this.resizeCallback);
        window.scrollTo(0, 0);
        this.animation();
        this.resizeCallback();
    }

    ngOnDestroy(): void {
        if (this.categorySub !== null) this.categorySub.unsubscribe();
        if (this.searchSub !== null) this.searchSub.unsubscribe();
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }

    animation() {
        const setHeight = 240;
        const numBoxes = document.querySelectorAll('.animation1-face').length;

        for (let i = 0; i < this.animationList.length; i++) {
            const animationClass = `.${this.animationList[i]}`
            gsap.set(animationClass, {
                y: (i) => i * setHeight
            });
        }

        const totalHeight = numBoxes * setHeight;
        const wrapOffsetTop = setHeight / -2;
        const wrapOffsetBottom = totalHeight + wrapOffsetTop;
        var wrap = gsap.utils.wrap(wrapOffsetTop, wrapOffsetBottom);
        const yheight = "+=" + totalHeight;


        for (let i = 0; i < this.animationList.length; i++) {
            const animationClass = `.${this.animationList[i]}`
            const duration = 10 + Math.floor(Math.random() * 15) + 1;
            gsap.to(animationClass, {
                duration: duration,
                ease: "none",
                y: yheight,
                modifiers: {
                    y: gsap.utils.unitize(wrap)
                },
                repeat: -1
            });
        }




    }

    async load(resetpage = false) {

        if (resetpage) this.pageno = 1;
        else this.pageno++;

        this.totalItems = await this.assetutil.loadAsset(
            this.assetlist, this.searchcondition.condition,
            this.searchcondition.searchtext, this.searchcondition.sort,
            this.pageno, this.Columns * 4, resetpage, this.util.getCurrentCurrency(),
            this.searchcondition.minprice, this.searchcondition.maxprice,
            this.searchcondition.minpolygon, this.searchcondition.maxpolygon, this.searchcondition.categoryInfo
        );
        if (!this.initialLoad) this.client.searchSubject.next(this.searchcondition);
        if (this.totalItems == 0) {
            this.showEmptyAlert();
        }
        this.initialLoad = true;
    }

    scrollToFreeTrial() {

        const titletop = this.freetrial_container.nativeElement.offsetTop
        const nav = (document.getElementsByClassName('header-outer')[0] as HTMLDListElement).clientHeight;

        const height = titletop - nav;


        Util.scrollTo(height);
    }



    openPopup(assetno: number) {
        this.router.navigate(['/', { outlets: { productdetail: ['itemview-popup', assetno] } }])
    }

    openFilter(event: boolean) {
        this.mobileFilter = event;
    }

    showEmptyAlert() {
        this.client.categorySubject.subscribe((val) => {
            if (val.onCategory == true) {
                this.filterEmpty = true;
                this.searchEmpty = false;
            }
            else {
                this.filterEmpty = false;
                this.searchEmpty = true;
            }
        });
    }

    updateFlag(flag: SearchCondition) {
        this.searchcondition = flag;
        this.client.searchSubject.next(this.searchcondition);
    }

    resetFilter() {
        this.filterComponent.clearAll();
    }
}
