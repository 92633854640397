<div class="item-popup-background">
    <app-popup-nav [title]="item === 'email' ? (('Common.Email' | translate) + ' ' + ('Common.Modify' | translate)) : (('Common.Password' | translate) + ' ' + ('Common.Modify' | translate)) 
 "></app-popup-nav>
    <!-- change email -->
    <div *ngIf="item==='email'" class="modifyuser-popup-background">
        <div *ngIf="!showResult" class="modifysuer-popup-email-container">
            <div class="modifysuer-popup-email-icon">
                <img src="/assets/icon/email_change.svg" />
            </div>
            <div class="modifyuser-popup-title">
                <span [innerHTML]="('Common.Email'|translate) + ' '">이메일</span>
                <span [innerHTML]="'Common.Modify'|translate">변경</span>
            </div>
            <div class="modifysuer-popup-email-desc">
                <span [innerHTML]="'Account.EnterNewEmail'|translate">변경할 이메일을 입력해주세요.</span>
                <span [innerHTML]="'Account.SendVerifyEmail'|translate">새로운 이메일로 인증메일이 전송됩니다.</span>
            </div>
            <div class="signup-input-border">
                <input #input_email type="text" class="uk-input modifyuser-email-change-input"
                    style="color: #ffffff;" placeholder="example@me-rror.com"
                    (input)="onInput(inputEmailRef)" />
            </div>
            <div class="password-popup-warn modifyuser-email-error-message"
                [innerHTML]="invalidEmail">
                이메일 형식이 맞지 않습니다.
            </div>
            <div #alert_message_container class="modifysuer-popup-alert-message">
                <span #alert_message1></span>
                <span #alert_message2></span>
            </div>
            <div class="modifysuer-popup-button-wrap">
                <button id="modifyuser-popup-email-button"
                    [innerHTML]="'Account.SendEmail'|translate" class="common-button1"
                    (click)="requestChangeEmail()">인증 메일
                    보내기</button>
                <button [innerHTML]="(desktopSize ? 'Account.Cancel'
                : 'Account.GoToMainPage'
             ) | translate" class="common-button2"
                    (click)="desktopSize? close() : goToMain()">돌아가기</button>
            </div>
        </div>
    </div>

    <!-- change password -->
    <div *ngIf="item==='password'" class="modifyuser-password-popup-background">
        <div id="modifyuser-password" class="modifysuer-popup-password-container">
            <img class="password-popup-img" src="assets/icon/password_modify_account.svg">
            <h2 class="password-popup-title">
                <span [innerHTML]="('Common.Password'|translate) + ' '">비밀번호</span>
                <span [innerHTML]="'Common.Modify'|translate">변경</span>
            </h2>
            <div class="password-popup-notice"
                [innerHtml]="'Account.ResetPasswordConfirmNotice'|translate">
                비밀번호를 재설정 하시겠습니까?</div>

            <div class="password-input-outer modifyuser-password-input-wrap">
                <input #input_password_oldpassword type="password"
                    [placeholder]="'Common.OldPassword'|translate" (focus)="addMargin()"
                    (blur)="removeMargin()" (input)="onInput(inputPasswordOldRef)" maxlength="12" />
            </div>
            <div class="password-popup-warn password-popup-input-msg"
                [innerHTML]="oldPasswordNotMatch"></div>
            <div class="password-input-outer">
                <input #input_password_newpassword type="password"
                    [placeholder]="'Common.NewPassword'|translate" (focus)="addMargin()"
                    (blur)="removeMargin()" (input)="onInput(inputPasswordNewRef)" maxlength="12" />
            </div>
            <div class="password-popup-input-msg" [innerHTML]="newPasswordWrong"
                #password_length_msg>
                8자리 이상입력해 주세요.
            </div>

            <div class="password-input-outer">
                <input #input_password_passwordconfirm type="password"
                    [placeholder]="'Common.PasswordConfirm'|translate"
                    (input)="onInput(inputPasswordConfirmRef)" maxlength="12" (focus)="addMargin()"
                    (blur)="removeMargin()" />
            </div>
            <div class="password-popup-warn password-popup-input-msg"
                [innerHTML]="confirmPasswordNotMatch" #password_match_msg>
            </div>
            <div class="password-popup-button-wrap">
                <button class="common-button1" (click)="modifyPassword()">
                    <span [innerHTML]="('Signup.ModifyPassword'|translate)">비밀번호 변경하기</span>
                </button>

                <button class="common-button2" [innerHTML]="(desktopSize ? 'Account.Cancel'
                : 'Account.GoToMainPage'
             ) | translate" (click)="desktopSize? close() : goToMain()">돌아가기</button>
            </div>
        </div>
        <div *ngIf="showResult" class="modal-background-alert-popup">
            <div class="password-popup-inner modifyuser-popup-msg" style="width:auto;">
                <div></div>
                <div [innerHTML]="'Signup.PasswordChanged'|translate">비밀번호가
                    변경되었습니다.</div>
                <div class="common-button1 modifyuser-popup-close"
                    [innerHTML]="'Receipt.Close'|translate" (click)="close()">
                    닫기</div>
            </div>
        </div>
    </div>

</div>