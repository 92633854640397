import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Data } from 'src/server/lib/types';
import { UtilityService } from '../lib/util';

@Component({
    selector: 'app-eula',
    template: `
<div class="eula-outer">
    <div class="eula-title" [innerHTML]="title">이용약관</div>
    <div class="eula-divider"></div>
    <div class="eula-content-outer" [innerHTML]="html">
    </div>
</div>`
})
export class EULAComponent implements OnInit, OnDestroy {

    private lang: Data.LanguageType;
    private subscription!: Subscription;
    title = '이용약관';
    html: SafeHtml = '';
    name: 'eula' | 'faq' | 'privacypolicy' = 'eula';
    constructor(
        private translate: TranslateService,
        private util: UtilityService,
        private sanitizer: DomSanitizer,
        private router: Router,
    ) {
        this.lang = util.getCurrentLang();

        const n = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
        if (n.startsWith('faq')) this.name = 'faq';
        else if (n.startsWith('privacypolicy')) this.name = 'privacypolicy';
        else this.name = 'eula';

        this.subscription = this.translate.onLangChange.subscribe((value) => {
            if (value.lang != this.lang) {
                this.lang = value.lang as Data.LanguageType;
                this.getTermHTML();
            }
        });
    }

    ngOnInit(): void {
        this.getTermHTML();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async getTermHTML() {
        this.title = await this.util.getTranslation(`Common.Html${this.name}`);
        const markdown = await this.util.getTextAsset(`/assets/terms/${this.name}_${this.lang}.md`);
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.util.makeEulaHtml(markdown));
    }
}
