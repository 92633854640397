import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiClientService } from '../lib/api-client.service';
import { UtilityService } from '../lib/util';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private client: ApiClientService,
        private util: UtilityService
    ) { }

    email = '';
    key = '';
    verifyresult: null | 'ok' | 'error' = null;
    @ViewChild('resent') resent!: ElementRef<HTMLElement>;

    ngOnInit(): void {
        const key = this.route.snapshot.paramMap.get('key');
        const email = this.route.snapshot.paramMap.get('email');
        if (key !== null) this.key = key;
        if (email !== null) this.email = email;

        console.log(email, key);
        this.verify();
    }

    async verify() {
        const result = await this.client.api.VerifyEmail.send({ email: this.email, key: this.key });
        console.log(result);

        if (result.message === 'ok') {
            this.verifyresult = 'ok';
        } else {
            this.verifyresult = 'error';
        }
    }

    async requestVerify() {

        if (this.resent.nativeElement.style.opacity === '1') return;

        this.resent.nativeElement.style.opacity = '0';
        const result = await this.client.api.RequestVerifyEmail.send({
            email: this.email, lang: this.util.getCurrentLang(), referralcode: ''
        });
        if (result.message === 'ok') {
            this.resent.nativeElement.style.opacity = '1';
            window.setTimeout(() => {
                this.resent.nativeElement.style.opacity = '0';
            }, 3000);
        }
        console.log(result);
    }

    signin() {
        // to avoid AppComponent::clickOutside
        setTimeout(() => {
            this.client.openPopupSubject.next({ openPopup: true, url: '/store' });
        }, 1);
    }
}
