<div class="admin-storeinfo-outer">
    <div class="admin-storeinfo-list">
        <div *ngIf="lastassetno>0 && storeinfo_filtered_byasset.length===0">
            <span style="color:tomato;font-size:20px;">
                새로운 상품정보가 없습니다. 구글시트 및 파일서버를 확인해 주세요.
            </span>
            <br />
            <span style="color:tomato;font-size:20px;">
                개발서버에 등록된 마지막 상품번호는 {{lastassetno}}입니다.
                <a href="/ko/admin" target="_blank">등록된 상품 보기</a>
            </span>
        </div>
        <div *ngFor="let sl of storeinfo_filtered_byasset" class="admin-storeinfo-item-outer">
            <div class="admin-storeinfo-item-title">
                <span>번호</span>
                <span>{{sl[0].assetno}}</span>
                <span>[
                    <span *ngFor="let str of makeCategoryStrArray(sl[0].category,
                        sl[0].category_filter);let i = index;let c = count">
                        <span style="color:aquamarine;">{{str}}</span>
                        <span *ngIf="i+1<c">, </span>
                    </span>
                    ]</span>
                <span>ko [<span style="color:deepskyblue;">{{sl[0].title_ko}}</span>]</span>
                <span>en [<span style="color:greenyellow;">{{sl[0].title_en}}</span>]</span>
                <span *ngIf="isMerrorUploadable(sl)">
                    <button id="button_merror_update_{{sl[0].assetno}}"
                        (click)="updateOnMerror(sl)">Update to Merror</button>
                </span>
            </div>
            <div class="admin-storeinfo-item-tags">
                <span>태그 [
                    <span *ngFor="let str of sl[0].tags_merror;let i = index;let c = count">
                        <span style="color:orchid;">{{str}}</span>
                        <span *ngIf="i+1<c">, </span>
                    </span>
                    ]</span>
            </div>
            <div class="admin-storeinfo-item-option-list">
                <div *ngFor="let s of sl" class="admin-storeinfo-item-option-outer">
                    <div class="admin-storeinfo-item-option">
                        <span class="option">{{s.option}}</span>
                        <span class="spinner">
                            <div class="spinner1 spinner2"
                                [style.opacity]="s.status.endsWith('...') ? '1':'0'">
                                <div *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11]"></div>
                            </div>
                        </span>
                        <span class="status"
                            [style.color]="s.status.startsWith('ready')?'lime':''">{{s.status}}</span>
                        <button id="button_sk_upload_{{s.assetno}}_{{s.option}}"
                            *ngIf="s.status==='ready'" (click)="uploadToSketchfab(s)">Upload to
                            Sketchfab</button>
                        <button *ngIf="s.status.startsWith('sketchfab uploaded')"
                            (click)="viewOnSketchfab(s.sketchfab_uid)">View on Sketchfab</button>
                    </div>
                    <div class="admin-storeinfo-item-option">
                        <span class="price" [style.text-decoration]="s.isfree?'line-through':''">기본
                            <span>{{s.price_krw}}</span>
                            <span>KRW</span>
                            /
                            <span>{{s.price_usd}}</span>
                            <span>USD</span>
                        </span>
                        <span class="price">할인
                            <span>{{s.dcprice_krw}}</span>
                            <span>KRW</span>
                            /
                            <span>{{s.dcprice_usd}}</span>
                            <span>USD</span>
                        </span>
                        <span *ngIf="s.isfree" style="color:red;">&nbsp;- [무료상품]</span>
                    </div>
                    <div class="admin-storeinfo-item-option">
                        <span class="modelinfo">모델 [
                            <span *ngFor="let type of s.format_model;let i = index">
                                <span style="color:coral;">{{type}}</span>
                                <span *ngIf="i+1<s.format_model.length">, </span>
                            </span>
                            ]</span>
                        <span class="modelinfo">텍스쳐 [
                            <span *ngFor="let type of s.format_texture;let i = index">
                                <span style="color:deepskyblue;">{{type}}</span>
                                <span *ngIf="i+1<s.format_texture.length">, </span>
                            </span>
                            ]</span>
                    </div>
                    <div class="admin-storeinfo-item-option-sketchfab">
                        <div>
                            <span style="color:#1caad9;">Sketchfab {{s.sketchfab_uid.length>0
                                ? 'uid [' + s.sketchfab_uid + ']':''}}</span>
                            <span>카테고리 [
                                <span *ngIf="s.sketchfab_categories[0] as c1"
                                    style="color:aquamarine;">{{c1}}</span>
                                <span *ngIf="s.sketchfab_categories[1] as c2">,
                                    <span style="color:aquamarine;"> {{c2}}</span>
                                </span>
                                ]<span *ngIf="s.sketchfab_categories.length===0"
                                    style="color:tomato;">
                                    구글 시트의 W/X열 스케치팹 카테고리를 확인해주세요!
                                </span>
                            </span>
                            <span>제목 [<span style="color:greenyellow;">{{s.sketchfab_title}}</span>]
                                <span
                                    [style.color]="s.sketchfab_title.length>48?'orangered':'aqua'">
                                    {{s.sketchfab_title.length}} chars</span>
                            </span>
                        </div>
                        <span>태그 [ <span
                                *ngFor="let str of s.tags_sketchfab;let i = index;let c = count">
                                <span style="color:orchid;">{{str}}</span>
                                <span *ngIf="i+1<c">, </span>
                            </span> ]</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>