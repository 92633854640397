import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(
        private client: ApiClientService,
        private router: Router) { }

    ngOnInit(): void {
    }

    openNoticePopup() {
        this.client.commonPopupSubject.next(({ popupTitle: 'noticePopup', openPopup: true }))
    }

    changeLanguage() {
        this.client.commonPopupSubject.next(({ popupTitle: 'languagePopup', openPopup: true }))
    }

    async moveToLocation(location: string) {
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        this.router.navigate([location], { replaceUrl: true });
    }

}
