import { ReqAddToFavorite, SearchTextScore } from './../../server/lib/types';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, firstValueFrom, lastValueFrom } from "rxjs";
import { Data, ReqAddToCart, ReqGetAssetinfo, ReqGetAssetList, ResGetAssetList, ResGetPurchaseList, ResTypeBase } from "../../server/lib/types";
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { HttpClient } from "@angular/common/http";
import { BASEURL } from "./api-client.service";
import { CommonUtil } from 'src/server/lib/util';

gsap.registerPlugin(ScrollToPlugin);

export class Util {
    static setContainerSize(elementid: string, elementwidth: number, elementgap: number): number {
        const div = document.getElementById(elementid) as HTMLDivElement;
        if (div === null) return 0;
        const divstyle = getComputedStyle(div);
        const divpaddings =
            parseInt(divstyle.paddingRight.substring(0, divstyle.paddingRight.length - 2))
            + parseInt(divstyle.paddingLeft.substring(0, divstyle.paddingLeft.length - 2));

        const parent = div.parentElement as HTMLDivElement;
        const parentstyle = getComputedStyle(parent);
        const parentpaddings =
            parseInt(parentstyle.paddingRight.substring(0, parentstyle.paddingRight.length - 2))
            + parseInt(parentstyle.paddingLeft.substring(0, parentstyle.paddingLeft.length - 2));
        const parentwidth = parseInt(parentstyle.width.substring(0, parentstyle.width.length - 2)) - parentpaddings;

        const width = parentwidth - divpaddings + 5;

        const w1 = elementwidth;
        const w2 = elementwidth + elementgap;
        div.style.width = '' + (Math.ceil((width - w1) / w2) * w2 - elementgap) + 'px';
        return (Math.ceil((width - w1) / w2)); // column
    }

    static scrollToBottom() {
        gsap.to(window, {
            scrollTo: { y: "max" },
            duration: 1,
        });
    }
    static scrollTo(y: number, duration = 1) {
        gsap.to(window, { scrollTo: y, duration });
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    static isValidEmail(email: string): boolean {
        return this.regexp.test(email);
    }

    // E.164 최대 전화번호 길이 15자 (국가 번호 3) + (전화번호 12)
    // +이나 구분자를 넣었을 경우 최대 21자
    static phoneNumberReg = new RegExp(/^[\d\+\-\(\)]{7,21}$/);
    static isValidPhone(phone: string) {
        return this.phoneNumberReg.test(phone);
    }
}

export class AssetUtil {
    private loading = false;
    private loadfunc;
    private popularKeywords: SearchTextScore[] = [];
    constructor(loadfunc: ((data: ReqGetAssetList) => Promise<ResGetAssetList>)
        | ((data: ReqGetAssetList) => Promise<ResGetPurchaseList>)) {
        this.loadfunc = loadfunc;
    }

    async loadAsset(assetlist: Data.Asset[], flag: Data.ESearchCondition, searchtext: string[],
        sort: Data.ESortBy = Data.ESortBy.Recent, pageno = 1, pagesize = 10, resetpage = false, currency: Data.CurrencyType = 'KRW',
        minprice = 0, maxprice = 0, minpolygon = 0, maxpolygon = 0, categoryInfo: Data.Category = { category: [], subCategory: [], isGameready: false }): Promise<number> {

        if (this.loading) return -1;
        this.loading = true;

        const res = await this.loadfunc({
            pageno,
            pagesize,
            flag: flag & Data.ESearchCondition.All,
            searchtext,
            sort,
            minprice,
            maxprice,
            minpolygon,
            maxpolygon,
            currency,
            categoryInfo
        });
        console.log(res);
        let assetList = res as ResGetAssetList;
        this.popularKeywords = assetList.searchtext;

        if (res.list && res.list.length > 0) {
            if (resetpage) assetlist.length = 0;
            assetlist.push(...res.list as Data.Asset[]);
        } else if (pageno === 1 && res.totalcount === 0) {
            assetlist.length = 0;
        }

        this.loading = false;
        return res.totalcount;
    }

    getPopularKeywords() {
        return this.popularKeywords;
    }
    async loadCartAsset(assetlist: Data.Asset[], flag: Data.ESearchCondition, searchtext: string[],
        sort: Data.ESortBy = Data.ESortBy.Recent, pageno = 1, pagesize = 10, resetpage = false, currency: Data.CurrencyType = 'KRW',
        minprice = 0, maxprice = 0, minpolygon = 0, maxpolygon = 0, categoryInfo = { category: [], subCategory: [], isGameready: false }): Promise<number> {
        if (this.loading) return -1;
        this.loading = true;

        const res = await this.loadfunc({
            pageno,
            pagesize,
            flag: flag & Data.ESearchCondition.All,
            searchtext,
            sort,
            minprice, maxprice, minpolygon, maxpolygon,
            currency, categoryInfo
        });
        console.log(res);

        if (res.list && res.list.length > 0) {
            if (resetpage) assetlist.length = 0;
            assetlist.push(...res.list as Data.Asset[]);
            assetlist.forEach(asset => {
                asset.cart = 'None';
                for (const optiontype of Data.AssetOptionTypes) {
                    const option = asset.options[optiontype];
                    if (option !== undefined) option.cart = false;
                }
            });

        } else if (pageno === 1 && res.totalcount === 0) {
            assetlist.length = 0;
        }

        this.loading = false;
        return res.totalcount;
    }
}

export interface IShowMessageData {
    message: string;
    link?: string;
    duration?: number;
    type?: 'OK' | 'WARN';
}
@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    private messageSource = new BehaviorSubject<IShowMessageData>({
        message: 'message', link: 'link', duration: 0, type: 'OK'
    });
    currentMessage = this.messageSource.asObservable();

    constructor(
        private translate: TranslateService,
        private http: HttpClient
    ) { }

    showMessage(data: IShowMessageData) {
        if (data.link === undefined) data.link = '';
        if (data.duration === undefined) data.duration = 3;
        if (data.type === undefined) data.type = 'OK';
        this.messageSource.next(data);
    }

    getTranslation = async (key: string, params?: Object) => {
        return await firstValueFrom<string>(this.translate.get(key, params));
    };

    getCurrentLang = (): Data.LanguageType => this.translate.getDefaultLang() === 'ko' ? 'ko' : 'en';
    getCurrentCurrency = (): Data.CurrencyType => this.translate.getDefaultLang() === 'ko' ? 'KRW' : 'USD';

    withComma(num: number | undefined) {
        if (num === undefined) return;
        else {
            const integer = Math.floor(num);
            const decimal = Math.round((num - integer) * 100)

            let result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                + (decimal >= 0.01 ? '.' + decimal : '');

            this.translate.getDefaultLang() === 'en' && result == '0' ? result = '0.00' : result;
            return result
        }



    }

    async getTextAsset(url: string) {
        return await firstValueFrom<string>(this.http.get(url, { responseType: 'text' }));
    }

    downloadAsset(assetno: number, optionno: number, type: string) {
        const url = BASEURL + '/download/' + assetno + '/' + optionno;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    makeEulaHtml(markdown: string) {
        const lines = markdown.split('\n');
        let html = '';

        for (let line of lines) {
            if ((/^#{2} /).test(line)) {
                line = line.slice(2);
                html += '<div class="eula-subtitle0">' + line + '</div>';
            }
            else if ((/^#{3} /).test(line) || (/^[\d]\. \*\*.+\*\*$/).test(line)) {
                if (line.startsWith('###') === true) {
                    line = line.slice(3);
                }
                else {
                    line = line.replace(/\*\*/g, '');
                }
                html += '<div class="eula-subtitle1">' + line + '</div>';
            }
            else {
                html += '<div class="eula-content">' + line + '</div>';
            }
        }

        return html;
    }

    getLocalDatetimeString = (date: Date): string => {
        const d = new Date(date);
        const localtime = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
        const iso = localtime.toISOString();
        return iso.substring(0, 10) + ' ' + iso.substring(11, 19);
    };

    getSearchCondName(info: SearchItemInfo, cond: Data.ESearchCondition): string {
        if ((info.all_condition & cond) === info.all_condition) { return info.all_name; }

        for (const item of info.list) {
            if (item.condition & cond) return item.name;
        }
        return Data.ESearchCondition[Data.ESearchCondition.None];
    }

    showChannelTalk() {
        const ChannelIO = (window as any).ChannelIO;
        if (ChannelIO !== undefined) {
            ChannelIO('openChat');
        }
    }
}

export interface SearchItemInfo {
    title: string;
    all_name: string;
    all_condition: Data.ESearchCondition;
    list: {
        name: string;
        condition: Data.ESearchCondition;
    }[];
}

const ESC = Data.ESearchCondition;
export const SearchItem: {
    type: SearchItemInfo;
    sex: SearchItemInfo;
    age: SearchItemInfo;
    resolutionlevel: SearchItemInfo;
} = {
    type: {
        title: 'Search.Type',
        all_name: 'Search.TypeAll',
        all_condition: ESC.Type_All,
        list: [
            { name: 'Search.Posed', condition: ESC.Type_Posed },
            { name: 'Search.APosed', condition: ESC.Type_APosed },
            { name: 'Search.Rigged', condition: ESC.Type_Rigged },
            { name: 'Search.Rigged', condition: ESC.Type_Facial }
        ],
    },
    sex: {
        title: 'Search.Sex',
        all_name: 'Search.SexAll',
        all_condition: ESC.Sex_All,
        list: [
            { name: 'Search.Male', condition: ESC.Male },
            { name: 'Search.Female', condition: ESC.Female },
        ]
    },
    age: {
        title: 'Search.Age',
        all_name: 'Search.AgeAll',
        all_condition: ESC.Age_All,
        list: [
            { name: 'Search.Under10s', condition: ESC.Age_0 },
            { name: 'Search.10s', condition: ESC.Age_10 },
            { name: 'Search.20s', condition: ESC.Age_20 },
            { name: 'Search.30s', condition: ESC.Age_30 },
            { name: 'Search.40-50s', condition: ESC.Age_40_50 },
            { name: 'Search.Over60s', condition: ESC.Age_60_ },
        ]
    },
    resolutionlevel: {
        title: 'Search.ResolutionLevel',
        all_name: 'Search.ResolutionLevelAll',
        all_condition: ESC.Res_All,
        list: [
            { name: 'Search.CU2000', condition: ESC.Res_CU2000 },
            { name: 'Search.CU100', condition: ESC.Res_CU100 },
            { name: 'Search.CU30', condition: ESC.Res_CU30 },
        ]
    }
};

export class CookieUtil {
    //TODO : make some static methods
    static setCookie(key: string, value: ReqAddToCart[] | ReqAddToFavorite[] | boolean | number) {
        if (Array.isArray(value) && key === "MYCART") {
            this.checkCookieCapacity(key, value);
        } else {
            if (key === "STRIP_BANNER") {
                const date = new Date();
                const exp = 1
                date.setTime(date.getTime() + exp * 12 * 60 * 60 * 1000);
                document.cookie = key + "=" + JSON.stringify(value) + ";expires=" + date.toUTCString() + ";";
            } else {
                document.cookie = key + "=" + JSON.stringify(value) + "; path=/; ";
            }

        }

    }

    static getCookie(key: string) {
        const value = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        if (value !== null) {
            const result = decodeURI(value[2]);
            return JSON.parse(result);
        } else {
            return;
        }
    }

    static deleteCookie(key: string) {
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
    }

    static getCookieListOfKey(key: string) {
        const cookies = document.cookie.split(`; `).map((el) => el.split('='));
        let cookieKeyList: string[] = [];
        let cookieList: any = [];

        for (let i = 0; i < cookies.length; i++) {
            cookieKeyList.push(cookies[i][0]);
        }

        cookieKeyList = cookieKeyList.filter((item: string) => {
            return item.startsWith(key);
        });

        for (let i = 0; i < cookieKeyList.length; i++) {
            const value = document.cookie.match('(^|;) ?' + cookieKeyList[i] + '=([^;]*)(;|$)');
            if (value !== null) {
                const result = decodeURI(value[2]);
                cookieList = [...cookieList, ...JSON.parse(result)];
            }
        }

        return cookieList;
    }

    static deleteCookieList(key: string) {
        const cookies = document.cookie.split(`; `).map((el) => el.split('='));
        let cookieKeyList: string[] = [];

        for (let i = 0; i < cookies.length; i++) {
            cookieKeyList.push(cookies[i][0]);
        }

        cookieKeyList = cookieKeyList.filter((item: string) => {
            return item.startsWith(key);
        });

        for (let i = 0; i < cookieKeyList.length; i++) {
            this.deleteCookie(cookieKeyList[i]);
        }
    }

    static checkCookieCapacity(key: string, value: ReqAddToCart[] | ReqAddToFavorite[]) {

        const limit = 20;
        let num = 0;
        let cloneValue = JSON.parse(JSON.stringify(value));
        let cookieList = [];

        this.deleteCookieList(key);

        while (cloneValue.length > 0) {
            let item = cloneValue.shift();
            cookieList.push(item);
            if (cookieList.length === limit) {
                num++;
                document.cookie = key + num + "=" + JSON.stringify(cookieList) + "; path=/; ";
                cookieList = [];
            }
        }
        document.cookie = key + "=" + JSON.stringify(cookieList) + "; path=/; ";
    }

    static getCookieByteSize(cookieList: any[]) {
        const cookieToStr = JSON.stringify(cookieList);
        const cookieSize = ~-encodeURI(cookieToStr).split(/%..|./).length;
        return cookieSize;
    }
}
