import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Data } from '../server/lib/types';
import { ApiClientService } from './lib/api-client.service';
import { Subscription } from 'rxjs';
import { CookieUtil } from './lib/util';
import { SEOService } from './lib/seo.service';
import { ResizeService, SCREEN_SIZE } from './lib/resize.service';
import { Location } from '@angular/common';

declare var ChannelIO: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, OnDestroy {
    showMessageComponent = false;
    showNav = false;
    showBusinessInfo = false;
    searchFocused = false;

    userinfo: Data.User = {
        userno: 0,
        userid: 0,
        email: '',
        password: '',
        status: 'NotVerified',
        recv_email: false,
        regdate: new Date()
    };
    cartcount = 0;
    favoritecount = 0;
    translateParam1 = { email: '' };
    showLoginPopup = false;
    showPopup = false;
    navlist = ['Common.Customization']
    lang: 'ko' | 'en' = 'en';
    tabletSize = false;
    isMobile = false;
    size: SCREEN_SIZE = SCREEN_SIZE.desktop
    desktopSize = false;
    vh = window.innerHeight * 0.01;
    private landbotLiveChat: any = null;

    @ViewChild('header_menu') menu!: ElementRef<HTMLDivElement>;
    @ViewChild('header_nav') nav!: ElementRef<HTMLDivElement>;

    @HostListener("window:resize")
    private onResize() {
        const elementWidth = this.elementRef.nativeElement.offsetWidth;
        const currentWidth = elementWidth > 0 ? elementWidth : window.innerWidth;
        if (currentWidth > 1024) {
            this.size = SCREEN_SIZE.desktop
            this.desktopSize = true;
        } else if (currentWidth > 580 && currentWidth <= 1024) {
            this.size = SCREEN_SIZE.tablet
            this.desktopSize = true;
        } else {
            this.size = SCREEN_SIZE.mobile
            this.desktopSize = false;
        }

        this.resizeSvc.onResize(this.size);
    }

    constructor(
        public translate: TranslateService,
        private client: ApiClientService,

        private router: Router,
        private seo: SEOService,
        private resizeSvc: ResizeService,
        private elementRef: ElementRef,
        private location: Location
    ) {
        const cookie_lang = CookieUtil.getCookie('Lang');
        const url = new URL(window.location.href);
        const query_lang = url.pathname.startsWith('/ko') ? 'ko' : url.pathname.startsWith('/en') ? 'en' : cookie_lang;
        if (query_lang !== undefined) {
            CookieUtil.setCookie('Lang', (query_lang === 'ko' ? 'ko' : 'en') as any);
        }

        const default_lang = query_lang === undefined ? navigator.language.substring(0, 2) : query_lang;
        this.lang = default_lang;
        translate.setDefaultLang(default_lang);
        translate.use(default_lang);



        this.router.events.subscribe(event => {
            let url: string;
            if (event instanceof NavigationStart) {
                url = event.url.startsWith('/ko') || event.url.startsWith('/en') ? event.url.substring(3) : event.url;
                if (url.startsWith('/test') || url.startsWith('/admin')) {
                    if (event.id !== 1) {
                        this.router.navigateByUrl(this.router.url);
                    }
                }
            } else if (event instanceof NavigationEnd) {
                url = event.url.startsWith('/ko') || event.url.startsWith('/en') ? event.url.substring(3) : event.url;
                if (url.startsWith('/asset/') === false) this.seo.setInfo(url);
            }
        });

        this.translate.onLangChange.subscribe(value => {
            this.lang = value.lang as any;
            if (this.router.url.startsWith('/ko') || this.router.url.startsWith('/en')) {
                this.location.replaceState('/' + value.lang + this.router.url.substring(3));
            }
            this.seo.setInfo(this.router.url);
            if (this.landbotLiveChat !== null) {
                this.landbotLiveChat.destroy();
                this.landbotLiveChat = null;
                this.createChatbot();
            }

            if ((window as any).ChannelIO !== undefined) {
                ChannelIO('updateUser', { language: value.lang === 'ko' ? 'ko' : 'en' });
            }
        });


    }

    private userinfo_subs!: Subscription;

    ngOnInit(): void {

        this.onResize();

        window.addEventListener('resize', this.resize);
        window.addEventListener('load', this.resize);

        const navSigninContainer = document.getElementById('navbar-right-container') as HTMLElement

        if (this.desktopSize) navSigninContainer.style.opacity = '0';

        this.userinfo_subs = this.client.userinfoSubject.subscribe(res => {
            this.userinfo = res.userinfo;
            this.cartcount = res.cartcount;
            this.favoritecount = res.favoritecount;

            this.translateParam1.email = res.userinfo.email;
            console.log('AppComponent : userinfo updated', res);
            setTimeout(() => {
                navSigninContainer.style.opacity = '1';
            }, 500);
        });
        this.client.api.GetUserinfo.send();
        this.client.openPopupSubject.subscribe(value => {
            this.showLoginPopup = value.openPopup;
        });

        this.client.commonPopupSubject.subscribe(value => {
            this.showPopup = value.openPopup;
        });

        window.addEventListener('click', (event) => {
            this.closeUserNav(event);
        });


    }

    resize() {
        this.vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    }

    ngAfterViewInit() {

        setTimeout(() => {
            this.resize();
        }, 1)

        this.isMobile = (/Mobi/i.test(navigator.userAgent.toLowerCase()));
        if (this.location.path().includes('/admin') === false
            && this.location.path().includes('/order') === false
        ) {
            this.createChatbot();
        }

        setTimeout(() => {
            this.showMessageComponent = true;
        }, 1000);

    }

    ngOnDestroy(): void {
        this.userinfo_subs.unsubscribe();
    }

    closeSigninPopup(event: Event): void {
        const target = event.target as Node
        const signinModeless = document.getElementById('signin-ui-modal') as HTMLInputElement;
        const mobileNav = document.getElementById('mobile-popup-nav') as HTMLInputElement;

        if (signinModeless !== null) {
            if (this.showLoginPopup && !signinModeless?.contains(target) && !mobileNav?.contains(target)) {
                // this.client.openPopupSubject.next({ url: '', openPopup: false });
            }
        }
    }

    closeUserNav(event: Event) {
        const target = event.target as HTMLElement;
        const userMenu = document.getElementById('user_menu') as HTMLDivElement;
        const mypagePopup = document.getElementById('dropdown') as HTMLDivElement;
        const loginPoup = document.getElementById('signin-background') as HTMLDivElement;

        if (!target) { return };
        if (!mypagePopup?.contains(target) && !userMenu?.contains(target) && !loginPoup?.contains(target)) {
            // this.toggleUserNav(false);
        };
    }

    toggleUserNav(show?: boolean) {
        if (show === undefined) {
            this.showLoginPopup = !this.showLoginPopup;
        } else {
            this.showLoginPopup = show;
        }
    }

    logout() {
        this.client.api.Logout.send();
    }

    setLang(lang: Data.LanguageType) {
        this.lang = lang;
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        CookieUtil.setCookie('Lang', lang as any);
    }

    toggleNav() {
        this.showNav = !this.showNav
    }

    toggleFocused(focused: boolean) {
        if (focused && this.tabletSize) {
            this.menu.nativeElement.classList.add('hide-menu-bar')
            this.nav.nativeElement.classList.add('hide-menu-bar')
            this.searchFocused = true;
        } else {
            this.menu.nativeElement.classList.remove('hide-menu-bar')
            this.nav.nativeElement.classList.remove('hide-menu-bar')
            this.searchFocused = false;
        }
    }

    setSize(tableSize: boolean) {
        this.tabletSize = tableSize;
    }

    movePage(index: number) {
        if (index === 0) {
            this.router.navigateByUrl('/customorder');
        }
    }

    createChatbot() {
        var w = window as any;
        if (w.ChannelIO) {
            return w.console.error("ChannelIO script included twice.");
        }
        var ch = function () {
            ch.c(arguments);
        } as any;
        ch.q = [];
        ch.c = function (args: any) {
            ch.q.push(args);
        };
        w.ChannelIO = ch;
        function l() {
            if (w.ChannelIOInitialized) { return; }
            w.ChannelIOInitialized = true;
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
            var x = document.getElementsByTagName("script")[0];
            if (x.parentNode) { x.parentNode.insertBefore(s, x); }
        }
        if (document.readyState === "complete") {
            l();
        } else {
            w.addEventListener("DOMContentLoaded", l);
            w.addEventListener("load", l);
        }
        ChannelIO('boot', {
            pluginKey: '5f8aa5c6-4101-4d1e-a082-1b7b855989b4',
            mobileMessengerMode: 'iframe',
            language: this.lang,
        });

        // 새로운 채팅을 열 때 마다 고객의 이메일을 태그로 추가
        // 상담을 했던 이력이 있는 경우(쿠키,로컬스토리지)에 기존 정보에 추기되므로
        // 채널톡 고객정보에는 같은 사용에 여러개의 이메일태그가 달릴 수 있음
        ChannelIO('onChatCreated', () => {
            ChannelIO('addTags', [this.userinfo.email]
                , (err: any, user: any) => {
                    console.log('updateUser', err, user);
                });
        });

    }
}
