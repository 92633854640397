<div class="modal-background" (click)="close($event)">
    <app-popup-nav [title]="'Signup.ResetPassword'|translate" style="width: 100%"></app-popup-nav>
    <div class="password-reset-container">
        <img src="assets/icon/password_modify.svg">
        <div class="password-reset-title">
            <div class="password-reset-title" [innerHTML]="'Signup.ResetPassword'|translate">비밀번호
                재설정</div>
        </div>
        <div class="password-reset-notice-wrap">
            <div class="password-reset-notice"
                [innerHTML]="'Signup.PasswordResetMailSentNotice'|translate">
                비밀번호를 잃어버리셨나요?
                미러에 가입한 이메일을 정확히 입력해주세요.
                이메일을 통해 비밀번호 재설정 링크가 전송됩니다. </div>
            <app-input #email [title]="''" [type]="'EMAIL_EXIST'" placeholder="example@me-rror.com"
                [desc]="''">
            </app-input>
        </div>

        <div class="password-reset-buttons-outer">
            <div #alert class="password-reset-alert" [innerHTML]="mailSentNotice">비밀번호 재설정 링크가
                전송되었습니다. </div>
            <button class="common-button1 password-reset-buttons-link"
                [innerHTML]="mailSentButtonNotice" (click)="sendMail()" #mailsendbtn>재설정 링크
                보내기</button>
            <button class="common-button2" [innerHTML]="'Signup.GoingBack'|translate"
                (click)="close()">돌아가기</button>
        </div>
    </div>

</div>