import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { Data } from 'src/server/lib/types';
import { ApiClientService } from '../../lib/api-client.service';
import { Carousel } from '../../lib/carousel';
import { ResizeService, SCREEN_SIZE } from '../../lib/resize.service';
import { SearchCondition } from '../../lib/searchtool.service';
import { AssetUtil, Util, UtilityService } from '../../lib/util';
import { CommonUtil } from 'src/server/lib/util';

export interface specialDeal extends Data.Asset {
    freeAsset?: string;
    itemprice?: number;
    itemdcprice?: number;
}

@HostListener('window:resize', ['$event'])

@Component({
    selector: 'app-special-deal',
    templateUrl: './special-deal.component.html',
    styleUrls: ['./special-deal.component.scss']
})
export class SpecialDealComponent implements OnInit, AfterViewInit, OnDestroy {

    date = new Date();
    month!: string;
    monthIndex!: number;
    year!: number;
    monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    lastdate!: number;
    firstday!: string;
    lastday!: string;

    secs!: string;
    mins!: string;
    days!: string;
    hours!: string;


    ApiUrl = this.client.baseurl;
    assetutil = new AssetUtil(this.client.api.GetAssetList.send);

    SpecialDeailList: specialDeal[] = [];
    specailOptions: string = '';
    specialPrice: number = 0;

    onLangChangeSub: Subscription | null = null;

    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.None,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: [],
            subCategory: [],
            isGameready: false,
        }
    };

    options: any = [];
    resizeObservable$!: Observable<Event>
    resizeSubscription$!: Subscription

    init = false;

    screenSize = this.resizeSvc.getCurrent();
    private countDownTimer = -1;

    withComma = CommonUtil.withComma;
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;


    constructor(
        private client: ApiClientService,
        private translate: TranslateService,
        private util: UtilityService,
        private router: Router,
        private resizeSvc: ResizeService,
        private elementRef: ElementRef,
    ) {
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            await this.load(true);
        });

        this.resizeSubscription$ = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;

        })
    }

    ngOnInit(): void {
        this.year = this.date.getFullYear();
        this.month = this.monthList[this.date.getMonth()]
        this.monthIndex = this.date.getMonth();
        this.lastdate = new Date(this.year, this.date.getMonth() + 1, 0).getDate();
        this.contDownTimer();
        this.load();

    }

    resize() {
        const thumnailImg = document.getElementById('deals-slider-thumbnail') as HTMLDivElement;
        const list = document.getElementById('deals-slider-list') as HTMLDivElement;
        if (thumnailImg === null) return;
        const gap = thumnailImg.clientWidth * 40 / 560
        list.style.columnGap = gap + 'px';
    }

    ngAfterViewInit(): void {
        window.addEventListener('resize', this.resize)
        window.scrollTo(0, 0);
        this.countDownTimer = window.setInterval(() => this.contDownTimer(), 1000);
    }

    ngOnDestroy(): void {
        this.resizeSubscription$.unsubscribe();
        window.clearTimeout(this.countDownTimer);
    }

    async load(resetpage = false) {

        await this.assetutil.loadAsset(
            this.SpecialDeailList, this.searchcondition.condition,
            [], this.searchcondition.sort,
            1, 100, resetpage, this.util.getCurrentCurrency(),
            -1, 1,
            this.searchcondition.minpolygon, this.searchcondition.maxpolygon
        );

        if (!this.init) this.init = true;

        //리스트에 담겨있는 옵션중에 딜 옵션 구하는 로직
        for (let i = 0; i < this.SpecialDeailList.length; i++) {
            for (let key in this.SpecialDeailList[i].options) {
                const option = key as Data.AssetOptionType
                if (this.SpecialDeailList[i].options[option]?.itemdcprice === this.specialPrice) {
                    this.SpecialDeailList[i].freeAsset = option
                    this.SpecialDeailList[i].itemprice = this.SpecialDeailList[i].options[option]?.itemprice
                    this.SpecialDeailList[i].itemdcprice = this.SpecialDeailList[i].options[option]?.itemdcprice
                }
            }

        }

        setTimeout(() => {
            this.resize();
        }, 1)


    }

    onChangeContent(direction: number) {

    }

    contDownTimer() {
        const targetDate = new Date(this.year, this.monthIndex, this.lastdate);
        const currentDate = (new Date()).getTime()
        const countDate = targetDate.getTime() - currentDate;

        this.secs = String(Math.floor((countDate / 1000) % 60)).padStart(2, "0");
        this.mins = String(Math.floor((countDate / (1000 * 60)) % 60)).padStart(2, "0");
        this.hours = String(Math.floor((countDate / (1000 * 60 * 60)) % 24)).padStart(2, "0");
        this.days = String(Math.floor(countDate / (1000 * 60 * 60 * 24))).padStart(2, "0");

    }

    scrollToSlider() {
        const sliderContainer = (document.getElementById('deals-slider-container') as HTMLDivElement)
        const sliderHeader = (document.getElementById('deals-slider-header') as HTMLDivElement)
        const margin = window.getComputedStyle(sliderHeader).getPropertyValue('margin-top').slice(0, -2);
        const slidertop = sliderContainer.offsetTop
        const nav = (document.getElementsByClassName('header-outer')[0] as HTMLDListElement).clientHeight;

        const height = slidertop - Number(margin) - nav;


        Util.scrollTo(height);
    }

    openPopup(assetno: number) {
        this.router.navigate(['/', { outlets: { productdetail: ['itemview-popup', assetno] } }])
    }

}
