<div class="main-slider-outer" id="main-slider-outer-{{name}}" #sliderouter>
    <div class="main-slider-inner main-slider-inner-{{name}}">
        <div class="main-popular-slider-list" #slidelist id="carousellist-{{name}}">
            <div class="main-slider-content main-slider-content-{{name}}"
                *ngFor="let item of assetlist">
                <app-index-item style="width: 100%" [asset]="item"
                    [ApiUrl]="ApiUrl"></app-index-item>
            </div>
        </div>
    </div>
</div>

<div class="main-slider-prev-button" [style.top]="name === 'related'? '175px': '270px'"
    (click)="moveSlider(-1 * cardNum)" #prev_button style="transform: scaleX(-1);"
    (mouseenter)="hover_prev=true" (mouseleave)="hover_prev=false">
    <img uk-svg [style.fill]="hover_prev?'#FFCA66': '#BDBDBD'" src="/assets/icon/slider_arrow.svg">
</div>
<div class="main-slider-prev-next" [style.top]="name === 'related'? '175px': '270px'"
    (click)="moveSlider(cardNum)" (mouseenter)="hover_next=true" #next_button
    (mouseleave)="hover_next=false">
    <img [style.fill]="hover_next?'#FFCA66': '#BDBDBD'" uk-svg src="/assets/icon/slider_arrow.svg">
</div>