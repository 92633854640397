<div class="admin-asset-outer">
    <div class="admin-asset-control-outer">
        <button (click)="fileinput.click()">파일 선택</button>
        <input #fileinput type="file" multiple style="display:none;"
            (change)="checkFiles($event)" />
        <div *ngIf="fileinput.files !== null" class="admin-asset-control-files">
            <div *ngIf="fileinput.files.length===0">선택된 파일이 없습니다.</div>
            <div *ngFor="let item of infolistByOption|keyvalue"
                class="admin-asset-control-optioninfo" (click)="showAsset(item.key)">
                <div style="color:red;">{{item.key}}</div>
                <div *ngFor="let info of item.value">
                    <span style="color:dodgerblue;">{{info.filename}}</span>
                    <span style="color:bisque;">{{formatSize(info.file.size)}}MB</span>
                </div>
            </div>
            <div *ngIf="extras.length>0" class="admin-asset-control-fileinfo">
                <div *ngFor="let name of extras" style="color:gray;">{{name}}</div>
            </div>
        </div>
    </div>

    <div class="admin-asset-viewer-outer">
        <div>
            <button style="display:none;" (click)="setCamera(true)"
                [class]="isHuman?'activated':''">사람</button>
            <button style="display:none;" (click)="setCamera(false)"
                [class]="!isHuman?'activated':''">사람아님</button>
            <button (click)="changeBGColor()">
                <span style="display:flex;align-items:center;">배경색
                    <div style="width:12px;height:12px;margin-left:3px;"
                        [style.background-color]="viewerBGColorList[viewerBGColorIndex]">
                    </div>
                </span>
            </button>
            <button (click)="setCamera()">기본 카메라 위치 보기</button>
        </div>
        <div [style.opacity]="loading?'0':'1'" id="threeviewer"
            [style.background-color]="viewerBGColorList[viewerBGColorIndex]"></div>
        <div [style.opacity]="loading?'1':'0'" class="admin-asset-viewer-loading">
            <div>파일을 읽어오는 중입니다!</div>
            <div>(예상시간 {{loading_estimate}}초)</div>
        </div>
    </div>

</div>