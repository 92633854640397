import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-common-popup',
    templateUrl: './common-popup.component.html',
    styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit, OnDestroy {

    popupTitle = '';
    currentGallery!: number
    hover_zoom = false;
    hover_close = false;
    zoomin = false;
    screenSize: SCREEN_SIZE = this.resizeSvc.getCurrent();
    height = '1080px'

    resizeSubscription$!: Subscription

    tableData = [
        {
            Title: 'DataType.FileFormats',
            Content: ['DataType.FileFormat1', 'DataType.FileFormat2', 'DataType.FileFormat2']
        },
        {
            Title: 'DataType.Option',
            Content: ['', 'DataType.OptionCleanup', 'DataType.OptionTopology']
        },
        {
            Title: 'DataType.PolygonCount',
            Content: ['', 'DataType.PolygonCleanup', 'DataType.PolygonTopology']
        },
        {
            Title: 'DataType.TextureResolution',
            Content: ['DataType.Texture1', 'DataType.Texture2', 'DataType.Texture2']
        },
        {
            Title: 'DataType.Usage',
            Content: ['DataType.UsageMesh', 'DataType.UsageCleanup', 'DataType.UsageTopology']
        }
    ]

    @Input() lang: 'ko' | 'en' = 'en'
    @Output() changeLang = new EventEmitter<'ko' | 'en'>();

    constructor(
        private router: Router,
        private client: ApiClientService,
        private resizeSvc: ResizeService,
    ) {
        this.resizeSubscription$ = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
            this.getHeight(size)
            if ((this.screenSize === SCREEN_SIZE.mobile) && (this.popupTitle === 'enterInfoPopup')) {
                document.body.classList.add('no-scroll');
            } else if ((this.screenSize !== SCREEN_SIZE.mobile) && (this.popupTitle === 'enterInfoPopup')) {
                document.body.classList.remove('no-scroll');
            }

        });
    }

    ngOnInit(): void {

        this.client.commonPopupSubject.subscribe((value) => {
            if (value.openPopup) this.popupTitle = value.popupTitle
            if (value.currentGallery) this.currentGallery = value.currentGallery
        });

        this.getHeight(this.screenSize);
        if (this.popupTitle === 'fbPopup') document.body.classList.add('no-scroll');

    }

    ngOnDestroy(): void {
        this.resizeSubscription$.unsubscribe();
        document.body.classList.remove('no-scroll');
    }


    getHeight(size: SCREEN_SIZE) {
        if (size === 0) {
            this.height = '635px'
        } else if (size === 1) {
            this.height = '875px'
        } else {
            this.height = '1080px'
        }
    }

    close(event?: Event) {
        if (!event) {
            this.client.commonPopupSubject.next(({ popupTitle: this.popupTitle, openPopup: false }))
            return;
        }
        const div = event.target as HTMLDivElement;
        let flag = false;
        for (let i = 0; i < div.classList.length; i++) {
            const item = div.classList[i];
            if (item === 'common-popup-background') {
                flag = true;
                break;
            }
        }
        if (flag) {
            this.client.commonPopupSubject.next(({ popupTitle: this.popupTitle, openPopup: false }))
        }
    }

    gotoSignin() {
        setTimeout(() => {
            this.client.openPopupSubject.next(({ url: '', openPopup: true }))
            this.client.commonPopupSubject.next(({ popupTitle: this.popupTitle, openPopup: false }))
        }, 1)
    }

    closeAfterSelect() {
        this.changeLang.emit(this.lang);
        this.close();
    }
}
