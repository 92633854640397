import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BillingPaymentItem, Data } from '../../../server/lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { IPriceInfo, IRefundInfo } from '../purchase-detail.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { CookieUtil } from 'src/app/lib/util';
import { Subscription } from 'rxjs';
import { ResizeService } from 'src/app/lib/resize.service';


@Component({
    selector: 'app-receipt-popup',
    templateUrl: 'receipt-popup.component.html',
    styleUrls: ['./receipt-popup.component.scss'],
})

export class ReceiptPopupComponent implements OnInit, OnDestroy, AfterViewInit {

    payment!: BillingPaymentItem;
    pgInfo!: Data.IamportPaymentInfo;
    priceinfo: IPriceInfo = { total: 0, supplyprice: 0, vat: 0 };
    refundinfo: IRefundInfo = { total: 0, list: [] };
    makingpdf = false;

    getLocalDatetimeStr = CommonUtil.getLocalDatetimeString;
    firstOption = CommonUtil.assetFirstOption;
    withComma = CommonUtil.withComma;
    payMethod = '';
    bank = '';
    point = '';
    etc = '';
    applyNum = '';
    currencykey = '';
    currentLang = 'en';
    totalPrice = 0;
    discountPrice = 0;

    onLangChangeSub: Subscription | null = null;
    desktopSize = this.resizeSvc.getCurrent();

    constructor(
        private router: Router,
        public translate: TranslateService,
        private resizeSvc: ResizeService,
    ) {
        //data received from Mypage
        const data = router.getCurrentNavigation()?.extras.state;

        if (data !== undefined) {
            this.payment = data['purchase'];
            this.pgInfo = data['pgInfo'];
            this.priceinfo.total = 0;
            this.refundinfo.total = 0;

            const isEmpty = Object.keys(this.pgInfo).length === 0;


            if (isEmpty) {
                this.payMethod = 'Common.Coupon';
                this.applyNum = '-';
                let flag = false;
                this.payment.list.forEach(item => {
                    if (this.payment.PAYTOOLNAME === 'coupon' && item.ITEMPRICE !== 0) {
                        flag = true;
                        return;
                    }
                })
                if (flag) this.payMethod = 'Common.Coupon'
                else this.payMethod = 'Common.FreeAsset'
            } else if (this.pgInfo.card_name === null) {
                if (this.pgInfo.pay_method === 'trans') {
                    this.payMethod = 'Order.AccountTransfer';
                    this.bank = this.pgInfo.bank_name
                } else if (this.pgInfo.pay_method === 'point') {
                    this.point = 'Order.Points'
                    if (this.pgInfo.emb_pg_provider === 'kakaopay') {
                        this.payMethod = 'Order.Kakao'
                    } else if (this.pgInfo.emb_pg_provider === 'naverpay') {
                        this.payMethod = 'Order.Naver'
                    } else if (this.pgInfo.emb_pg_provider === 'samsung') {
                        this.payMethod = 'Order.Sumsung'
                    } else {
                        this.etc = this.pgInfo.emb_pg_provider
                    }
                } else {
                    if (this.pgInfo.pg_provider === 'paypal') {
                        this.payMethod = 'Order.Paypal'
                        this.applyNum = this.pgInfo.apply_num;
                    } else {
                        this.etc = this.payment.PAYTOOLNAME;
                    }

                }
            } else {
                this.payMethod = this.pgInfo.card_name + this.pgInfo.card_number
                this.applyNum = this.pgInfo.apply_num
            }

            for (const item of this.payment.list) {
                const price = item.PURCHASEPRICE;
                this.priceinfo.total += price;
                if (item.refunded) {
                    this.refundinfo.total += price;
                    this.refundinfo.list.push({
                        amount: price,
                        time: item.UPDDATE
                    });
                }
            }

            if (this.payment.CCYCODE === Data.ECurrency.KRW) {
                this.currencykey = 'Common.KRW';
                this.priceinfo.vat = Math.floor(this.priceinfo.total / 11);

            } else {
                this.currencykey = 'Common.USD';
                this.priceinfo.vat = Math.floor(this.priceinfo.total / 11 * 100) / 100;
            }

            this.priceinfo.total -= this.refundinfo.total;
            if (this.priceinfo.total === 0) {
                this.priceinfo.vat = 0;
            } else {
                this.priceinfo.supplyprice = this.priceinfo.total - this.priceinfo.vat;
            }
        }

        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    ngOnInit(): void {

        this.currentLang = CookieUtil.getCookie('Lang')
        this.onLangChangeSub = this.translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        })
        console.log('잉모야', this.pgInfo);

        this.totalPrice = this.payment.list.reduce((sum, item) => sum + item.ITEMPRICE, 0)
        this.discountPrice = this.payment.list.reduce((sum, item) => {
            if (item.COUPONID == 1 || item.COUPONID == 2) {
                return sum + (item.ITEMPRICE - item.PURCHASEPRICE);
            } else {
                return sum;
            }
        }, 0);
    }


    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }

    makePDF() {
        if (this.makingpdf) return;
        const max_width = this.desktopSize ? 140 : 80;
        const max_height = 260;
        this.makingpdf = true;
        const element = document.getElementsByClassName('receipt-inner')[0] as HTMLDivElement;
        const buttons = document.getElementsByClassName('receipt-buttons-outer')[0] as HTMLDivElement;
        const scale = 1.5;
        buttons.style.display = 'none';
        html2canvas(element, { scale }).then(canvas => {
            buttons.style.display = 'flex';
            console.log(canvas.width, canvas.height);
            const pdf = new jsPDF('p', 'mm', 'a4');
            let height = max_height;
            let width = canvas.width * height / canvas.height;
            if (width > max_width) {
                width = max_width;
                height = canvas.height * width / canvas.width;
            }
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG',
                (210 - width) / 2, 20, width, height);
            pdf.save(`receipt_${this.payment.CASHNO}.pdf`);
            this.makingpdf = false;
        });
    }

    close(event?: Event) {
        if (!event) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
            return;
        }
        const div = event.target as HTMLDivElement;
        let flag = false;
        for (let i = 0; i < div.classList.length; i++) {
            const item = div.classList[i];
            if (item === 'receipt-outer') {
                flag = true;
                break;
            }
        }
        if (flag) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
        }
    }
}


