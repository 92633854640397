<main class="cart-container">
    <div class="cart-outer">
        <div class="cart-inner">
            <div class="cart-title" [innerHTML]="'Common.Cart'|translate">CART</div>

            <div class="cart-align" *ngIf="Cartlist.length>0">
                <div class="cart-left">
                    <div class="cart-list">
                        <div class="cart-list-header">
                            <div class="cart-selection">
                                <div (click)="toggleAllSelected()">
                                    <input type="checkbox" [checked]="allSelected">
                                    <label></label>
                                </div>
                                <div class="cart-delete">
                                    <span (click)="deleteSelected()"
                                        [innerHTML]="'Cart.DeleteSelection'|translate">선택삭제</span>
                                </div>
                            </div>
                        </div>
                        <div class="cart-item-outer"
                            *ngFor="let asset of Cartlist;let index = index;">
                            <div class="cart-item-inner">
                                <div class="cart-item-input" (click)="toggleOptionSelected(asset)">
                                    <input type="checkbox"
                                        [checked]="asset.options[getKey(asset.options)]?.cart">
                                    <label></label>
                                </div>
                                <div class="cart-item-details">

                                    <div class="cart-item-thumbnail">
                                        <div
                                            style="background-image:url({{ApiUrl}}{{asset.image1}});">
                                        </div>
                                    </div>
                                    <div class="cart-item-desc">
                                        <div class="cart-item-info-wrap">
                                            <div class="cart-item-name">{{asset.name}}</div>
                                            <div class="cart-item-option">
                                                <span
                                                    [innerHTML]="('Search.'+getKey(asset.options))|translate"></span>

                                            </div>
                                        </div>

                                        <div class="cart-item-price">
                                            <div class="cart-item-origin-price"
                                                *ngIf="(asset.options[getKey(asset.options)]?.itemdcprice) !== (asset.options[getKey(asset.options)]?.itemprice)">
                                                {{withComma(asset.options[getKey(asset.options)]?.itemprice)}}
                                                <span [innerHTML]="currencykey|translate">원</span>
                                            </div>
                                            <div>
                                                {{withComma(asset.options[getKey(asset.options)]?.itemdcprice)}}
                                                <span [innerHTML]="currencykey|translate">원</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cart-right">
                    <div>
                        <div class="cart-checkout-info">

                            <div class="cart-checkout-title"
                                [innerHTML]="'Cart.OrderSummary'|translate">주문 금액</div>

                            <div class="cart-checkout-total">
                                <div class="cart-checkout-label"
                                    [innerHTML]="'Cart.TotalPrice'|translate">총 상품 금액</div>
                                <div class="cart-checkout-price">
                                    {{withComma(totalprice)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                            <div class="cart-checkout-discount">
                                <div class="cart-checkout-label"
                                    [innerHTML]="'Cart.DiscountPrice'|translate">할인 금액</div>
                                <div class="cart-checkout-price">
                                    <span
                                        [style.display]="discountprice === 0? 'none': 'inline-block'">-</span>{{withComma(discountprice)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                            <div class="cart-checkout-final">
                                <div class="cart-checkout-label"
                                    [innerHTML]="'Cart.TotalAmount'|translate">총 주문 금액</div>
                                <div class="cart-checkout-price cart-checkout-final-price">
                                    {{withComma(paymentprice)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </div>

                        <div class="cart-checkout-info2">
                            <div class="cart-checkout-button">
                                <button class="common-button1"
                                    [innerHTML]="'Cart.PlaceOrder'|translate"
                                    (click)="order()">주문하기</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="empty-container" [style.display]="apiChecked && totalItems === 0? 'flex': 'none'">
        <img class="empty-img" src="/assets/image/empty/empty_cart.png" />
        <div class="empty-desc" [innerHTML]="'Cart.EmptyCart'|translate">장바구니에 담은 상품이
            없습니다.</div>
        <button class="common-button1 empty-button" routerLink="/store"
            [innerHTML]="'Common.ContinueShopping'|translate">쇼핑계속하기</button>
    </div>
    <div class="ghost-page" *ngIf="!apiChecked"></div>
</main>

<!-- 장바구니 비우기확인 모달  -->
<div class="cart-popup-backdrop" *ngIf="openPopup === true">
    <div id="cart-popup" class="cart-popup-container">
        <div class="cart-popup-img">
            <img src="/assets/icon/help_24.svg">
        </div>

        <span [innerHTML]="'Cart.DeleteAllMessage'|translate">장바구니 상품을 전부
            삭제하시겠습니까?</span>

        <div class="cart-popup-buttons">
            <button class="common-button2" [innerHTML]="'Account.Cancel'|translate"
                (click)="openPopup = false">돌아가기</button>
            <button class="common-button1" [innerHTML]="'Account.DeleteAccountButton1'|translate"
                (click)="setEmptyCart()">삭제하기</button>
        </div>
    </div>
</div>

<!-- 장바구니 삭제되돌리기 모달  -->
<!-- <div id="cart-restore" class="cart-restore-container" #cart_restore>
    <div class="cart-restore-contents">
        <div class="cart-restore-message">
            <div id="delete-message" [innerHTML]="'Cart.DeleteConfirm1'|translate" #message1>
            </div>
            <span id="delete-countdown" #delete_countdown>&nbsp;{{countDown}}</span>
            <div id="delete-message"
                [innerHTML]="'Cart.DeleteConfirm2'|translate:{s:countDown > 1 ? 's':''}" #message2>
            </div>
        </div>
        <div class="cart-restore-message">
            <span [innerHTML]="'Cart.Undo'|translate" (click)="restore()"
                style="cursor: pointer;text-decoration: underline;">되돌리기</span>
        </div>
    </div>
</div> -->