<div class="deleteuser-outer">
    <app-popup-nav [title]="'Account.DeleteAccount'|translate"></app-popup-nav>
    <div *ngIf="!showResult" class="deleteuser-inner" id="deleteuser-password">
        <div class="delete-warning-title">
            <img src="/assets/icon/warning_sign.svg" style="padding: 5px; margin-bottom: 5px;">
            <span class="refund-title"
                [innerHtml]="'Account.DeleteAccountWarning1'|translate">주의하세요!</span>
        </div>
        <div class="delete-warning-explanatory">
            <div [innerHtml]="'Account.DeleteAccountWarning2'|translate"
                style="color: #FF424C; font-weight: 600; padding-bottom: 6.5px;">계정 삭제 시
            </div>
            <div [innerHtml]="'Account.DeleteAccountPopupNotice3'|translate">
                1.구매 정보가 삭제되며 에셋 환불 및 다운로드가 불가능합니다. 환불 처리 중인 에셋이 남아있다면 환불이 완료된 이후 계정을
                삭제해주세요.</div>
            <br />
            <div [innerHtml]="'Account.DeleteAccountPopupNotice4'|translate">
                2. 보유한 쿠폰이 소멸됩니다.</div>
            <br />
            <div [innerHtml]="'Account.DeleteAccountPopupNotice5'|translate">
                3. 소비자보호에 관한 법률 제6조에 의거, 계약 또는 청약철회 등에 관한 기록<br /> 은 5년, 대금결제 및 재화 등의 공급에 관한
                기록은 5년, 소비자의 불만 또는<br /> 분쟁 처리에 관한 기록은 3년 동안 보관됩니다. 등 개인정보는 법률에 의한 보유<br />
                목적 외에 다른 목적으로는 이용되지 않습니다.</div>
        </div>
        <div style="text-align:center;" class="delete-wargning-message">
            <div [innerHTML]=" (desktopSize? 'Account.DeleteAccountWarningTitle1'
                : 'Account.DeleteAccountWarningTitle'
             ) | translate
             ">삭제된 정보는 복구할 수
                없습니다. 정말 삭제하시겠습니까?</div>
            <div [innerHtml]="'Account.DeleteAccountWarningTitle2'|translate">
                아래 입력창에 '계정 삭제'를 입력하시면 계정이 삭제됩니다.</div>
            <div class="uk-width-expand uk-inline uk-margin-top deleteuser-input-outer"
                [class.deleteuser-input-outer-warn]="showAlert">
                <input type="password" class="deleteuser-input" maxlength="20" #inputelement
                    (keyup)="onChangePassword()" [(ngModel)]="inputpassword" (focus)="addMargin()"
                    (blur)="removeMargin()" />

            </div>
        </div>

        <div class="delete-typo-alert" [style.opacity]="showAlert?'1':'0'">
            <div [innerHtml]="passwordAlertKey|translate" style="color:#FF424C;">
                입력하신 내용이 정확하지 않습니다.</div>
            <div style="display: flex; justify-content: center; align-items: center;">
                <div [innerHtml]="'Account.DeleteAccountTypo2'|translate" style="color:#FFEDCC;">
                    혹시... 삭제하기 싫으신건 아니신가요?</div>
                <img src="assets/icon/delete_input_typo.svg" alt="typo error" width="20px"
                    height="20px">
            </div>
        </div>

        <div class="deleteuser-button-container">
            <div class="deleteuser-button-wrap">
                <button class="common-button2"
                    [innerHtml]="'Account.DeleteAccountButton1'|translate"
                    (click)="deleteUser();">삭제하기</button>
                <button class="common-button1"
                    [innerHtml]="'Account.DeleteAccountButton2'|translate" (click)="close()">
                    취소하기</button>
            </div>
        </div>

    </div>
    <div *ngIf="showResult" class="deleteuser-result-outer">
        <div class="deleteuser-icon-bg">
            <div class="deleteuser-icon"></div>
        </div>
        <div class="deleteuser-title" [innerHtml]="'Account.AccountDeleted1'|translate">
            계정이 삭제되었습니다.</div>
        <div class="deleteuser-desc" [innerHtml]="'Account.AccountDeleted2'|translate">
            그동안 미러를 이용해 주셔서 감사합니다.</div>
        <div>
            <button class="common-button1 deleteuser-exit-button">
                <div>
                    {{countdown}}<span [innerHtml]="'Account.MoveToMain'|translate">초 뒤 메인페이지로
                        이동합니다.</span>
                </div>
            </button>
        </div>

    </div>
</div>