import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule, customUrlSerializer } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiTestComponent } from './pages/api-test.component';
import { CartComponent } from './pages/cart.component';
import { SigninUIComponent } from './pages/common/signin-ui.component';
import { IndexItemComponent } from './pages/common/index-item.component';
import { FavoriteComponent } from './pages/favorite.component';
import { StoreIndexComponent } from './pages/store-index.component';
import { AccountComponent } from './pages/account.component';
import { ItemviewPopupComponent } from './pages/popup/itemview-popup.component';
import { ModifyuserPopupComponent } from './pages/popup/modifyuser-popup.component';
import { PurchasesComponent } from './pages/purchases.component';
import { SignupComponent } from './pages/signup.component';
import { DropdownNavComponent } from './pages/common/dropdown-nav.component';
import { OrderComponent } from './pages/order.component';
import { ShowMessageComponent } from './pages/common/show-message.component';
import { PaginationComponent } from './pages/common/pagination.component';
import { InputComponent } from './pages/common/input.component';
import { CostumeBannerComponent } from './pages/common/costume-banner.component';
import { EULAComponent } from './pages/eula.component';
import { AdminIndexComponent } from './pages/admin/admin-index.component';
import { RequestPasswordResetPopupComponent } from './pages/popup/request-password-reset-popup.component';
import { ResetPasswordPopupComponent } from './pages/popup/reset-password-popup.component';
import { MainSearchComponent } from './pages/common/main-search.component';
import { ChangeEmailPopupComponent } from './pages/popup/change-email-popup.component';
import { RefundComponent } from './pages/refund.component';
import { ReceiptPopupComponent } from './pages/popup/receipt-popup.component';
import { EmailVerifictaionPopupComponent } from './pages/popup/email-verifictaion-popup.component';
import { VerifyEmailComponent } from './pages/verify-email.component';
import { AssetViewerComponent } from './pages/common/asset-viewer.component';
import { FormsModule } from '@angular/forms';
import { AdminAssetComponent } from './pages/admin/admin-asset.component';
import { DeleteuserPopupComponent } from './pages/popup/deleteuser-popup.component';
import { PurchaseDetailComponent } from './pages/purchase-detail.component';
import { CustomOrderComponent } from './pages/custom-order.component';
import { AdminThumbnailComponent } from './pages/admin/admin-thumbnail.component';
import { UrlSerializer } from '@angular/router';
import { CommonPopupComponent } from './pages/popup/common-popup.component';
import { GlobalNavigationComponent } from './pages/common/global-navigation.component';
import { PopupNavComponent } from './pages/popup/popup-nav.component';
import { SpecialDealComponent } from './pages/event/special-deal.component';
import { FooterComponent } from './pages/common/footer.component';
import { BusinessComponent } from './pages/business/business.component';
import { AssetViewerControlComponent } from './pages/common/asset-viewer-control.component';
import { AssetListComponent } from './pages/asset-list.component';
import { HeaderComponent } from './pages/common/header.component';
import { FilterComponent } from './pages/common/filter.component';
import { DropdownFilterComponent } from './pages/common/dropdown-filter.component';
import { ProductDetailComponent } from './pages/product-detail.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { PurchaseMobilePopupComponent } from './pages/popup/purchase-mobile-popup.component';
import { ChatbotComponent } from './pages/chatbot.component';
import { CarouselComponent } from './pages/common/carousel.component';
import { ErrorComponent } from './pages/error.component';
import { environment } from 'src/environments/environment';
import { IndexComponent } from './pages/index.component';
import { BusinessScanStudioComponent } from './pages/business/business-scanstudio.component';
import { BusinessCarouselComponent } from './pages/business/common/business-carousel.component';
import { FaceKoreaComponent } from './pages/event/face-korea.component';
import { FaceKoreaFilterComponent } from './pages/event/component/face-korea-filter.component';
import { ReferralEventComponent } from './pages/event/referral-event.component';
import { BusinessModelingComponent } from './pages/business/business-modeling.component';
import { AdminStoreinfoComponent } from './pages/admin/admin-storeinfo.component';
import { CustomPaymentComponent } from './pages/custom-payment.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export let AppInjector: Injector;

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        StoreIndexComponent,
        AssetListComponent,

        ItemviewPopupComponent,
        ProductDetailComponent,
        ModifyuserPopupComponent,
        DeleteuserPopupComponent,
        RequestPasswordResetPopupComponent,
        ResetPasswordPopupComponent,
        ChangeEmailPopupComponent,
        ReceiptPopupComponent,
        ChatbotComponent,
        ErrorComponent,

        IndexItemComponent,
        FavoriteComponent,

        CartComponent,
        SignupComponent,
        AccountComponent,
        PurchasesComponent,
        PurchaseDetailComponent,
        OrderComponent,
        RefundComponent,
        EULAComponent,
        CustomOrderComponent,
        CustomPaymentComponent,
        VerifyEmailComponent,

        SigninUIComponent,
        DropdownNavComponent,
        CostumeBannerComponent,
        MainSearchComponent,
        AssetViewerComponent,
        AssetViewerControlComponent,

        ShowMessageComponent,
        PaginationComponent,
        InputComponent,
        AssetViewerComponent,

        AdminIndexComponent,
        AdminAssetComponent,
        AdminStoreinfoComponent,
        AdminThumbnailComponent,

        CommonPopupComponent,
        ApiTestComponent,
        EmailVerifictaionPopupComponent,

        GlobalNavigationComponent,
        PopupNavComponent,
        SpecialDealComponent,
        FooterComponent,
        BusinessComponent,
        HeaderComponent,
        FilterComponent,
        DropdownFilterComponent,
        PurchaseMobilePopupComponent,
        CarouselComponent,
        BusinessScanStudioComponent,
        BusinessCarouselComponent,
        FaceKoreaComponent,
        FaceKoreaFilterComponent,
        ReferralEventComponent,
        BusinessModelingComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        CdkScrollable,
        ClipboardModule,
        ...environment.optionalModules,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            //            defaultLanguage: 'ko'
        }),

    ],
    providers: [
        { provide: UrlSerializer, useClass: customUrlSerializer },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
