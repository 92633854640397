<div class="fb-container">
    <div class="fb-top-banner-outer">
        <div class="fb-top-banner-inner" #fb_banner>
            <div class="fb-top-banner fb-animation">
                <div class="fb-top-banner-p1">ME.RROR</div>
                <div class="fb-top-banner-p2">Twin <span style="color:#FFB833;">ME.
                    </span>Twin the
                    World</div>
            </div>

        </div>
    </div>
    <div class="fb-contents-container">
        <div class="fb-contents-outer">
            <div class="fb-contents-inner">
                <div class="fb-introduce introduce-animation" #fb_introduce>
                    <h2 class="fb-introudce-header">Who we are</h2>
                    <div class="fb-introduce-slogan">“We don’t change the world.
                        <br class="fb-mobile-br">We just create the
                        world”
                    </div>
                    <div class="fb-introduce-line"></div>
                    <div class="fb-introduce-details">
                        <span [innerHTML]="'ForBusiness.SlogannDesc1'|translate">A world where
                            anyone can easily access and experience 3D
                            content</span>
                        <span [innerHTML]="'ForBusiness.SlogannDesc2'|translate">ME.RROR dreams
                            of a
                            world where everyone can enjoy 3D contents and new
                            experiences.</span>
                        <span [innerHTML]="'ForBusiness.SlogannDesc3'|translate">From industry
                            to
                            entertainment, our dreams are endless</span>
                    </div>
                </div>
                <div class="fb-introduce2 introduce2-animation" #fb_introduce2>

                    <h2 class="fb-introudce-header">What We Do</h2>
                    <div class="fb-introduce-slogan"
                        [innerHTML]="'ForBusiness.WhatWeDoSubTitle'|translate">
                        world”</div>


                    <div class="fb-introduce-details2"
                        [innerHTML]="'ForBusiness.WhatWeDoDesc'|translate">
                        world”</div>

                    <div class="fb-introduce-page-link-wrap">
                        <div *ngFor="let link of linkList ; let index=index"
                            (mouseenter)="link.hover=true" (mouseleave)="link.hover=false"
                            class="fb-introduce-page-link">
                            <img class="fb-introduce-page-img"
                                [src]="'/assets/image/business/link/'+ (link.name) + '.png'" />
                            <div class="fb-introduce-page-button">
                                <div [innerHTML]="'ForBusiness.'+  link.name |translate"
                                    [style]="(link.hover === true) ? 'display:none;opaicty: 0;' : 'display:flex;opaicty: 1;'">
                                    {{link.name}}
                                </div>
                                <button class="common-button2" [disabled]="link.name==='Content'"
                                    (click)="moveToLocation(link.address)"
                                    [style]="(link.hover === true) ? 'display: flex; opaicty: 1;' : 'display:none; opaicty: 0;'"
                                    [innerHTML]="(link.name === 'Content'? 'Message.WIP' :'Common.SeeMore')|translate">View
                                    More</button>
                            </div>
                            <div class="fb-introduce-page-commingsoon"
                                *ngIf="(link.name === 'Content') && (screenSize !==2)">Coming Soon
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fb-icon-container" #fb_icon_container>
                    <div class="fb-icon-header">
                        <span>Why ME.RROR</span>
                    </div>
                    <ul class="fb-icons">

                        <li class="fb-icon-wrap">
                            <img class="fb-icon" src="/assets/icon/solution.svg" />
                            <div class="fb-icon-title"
                                [innerHTML]="'ForBusiness.AllinOneSystem'|translate">All in One
                                Solution</div>
                            <div [innerHTML]="'ForBusiness.Solution'|translate">All in one
                                Solution
                                with 3D Scanning, Modeling and Publishing
                                Services
                            </div>
                        </li>
                        <li class="fb-icon-wrap">
                            <img class="fb-icon" src="/assets/icon/consultation.svg" />
                            <div class="fb-icon-title"
                                [innerHTML]="'ForBusiness.3DConsultant'|translate">3D Expert
                                Consultation</div>
                            <div [innerHTML]="'ForBusiness.Consultation'|translate">Our 3D
                                Content
                                Experts can help to make recommendation on
                                services
                                to
                                best
                                suit your company
                            </div>
                        </li>

                        <li class="fb-icon-wrap">
                            <img class="fb-icon" src="/assets/icon/customization.svg" />
                            <div class="fb-icon-title"
                                [innerHTML]="'ForBusiness.CustomMade'|translate">Customization
                            </div>
                            <div [innerHTML]="'ForBusiness.Customization'|translate">Order
                                custom 3D
                                content, on-demand Customized 3D content
                                specifications
                                based on your needs
                            </div>
                        </li>
                        <li class="fb-icon-wrap">
                            <img class="fb-icon" src="/assets/icon/price.svg" />
                            <div class="fb-icon-title"
                                [innerHTML]="'ForBusiness.Excellence'|translate">Vast Industry</div>
                            <div [innerHTML]="'ForBusiness.ResonablePrice'|translate">We have deep
                                knowledge and experience of 3D content in vast
                                industry
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="fb-img">
                <img src="/assets/image/business/catholic.png" />
            </div>
            <div class="fb-marquee">
                <ul class="fb-marquee-content">
                    <li *ngFor="let partner of partnerList; let index=index"
                        class="fb-marquee_inner fb-marquee_inner-{{index}}">
                        <img class="fb-partner-icon" src="/assets/image/partner/{{partner}}.svg" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>