import { Data } from "./types";

export type ChangeFreqType = 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
interface MetaInfo {
    url: string[];
    priority: number;
    changefreq: ChangeFreqType;
    title: { [key in Data.LanguageType]: string };
    desc: { [key in Data.LanguageType]: string };
};

export const defaultmetainfo: MetaInfo = {
    url: ['/', '/store', '/asset'],
    priority: 0.7,
    changefreq: 'weekly',
    title: {
        en: 'ME.RROR - Asia\'s Scanned 3D Content Library',
        ko: 'ME.RROR - 아시아 기반의 3D 콘텐츠 라이브러리',
    },
    desc: {
        en: 'ME.RROR is the Asian 3D Content Library. '
            + 'Browse our scanned 3D Models now and save time on your projects. '
            + 'Download 3D Assets for your Webtoons, games, animation, VR, and other creatives.'
            + ' MERROR, 미러, me-rror',
        ko: 'ME.RROR는 아시아의 동양적인 특징을 가진 3D콘텐츠를 제공합니다. '
            + '미러의 3D에셋을 쉽고 간편하게 다운로드 하세요. '
            + '웹툰,게임,애니메이션,VR 등 여러분의 프로젝트에 3D모델을 자유롭게 사용하세요.'
            + ' MERROR, 미러, me-rror',
    }
}
export const metainfo: MetaInfo[] = [
    {
        url: ['/eula'],
        priority: 0.5,
        changefreq: 'weekly',
        title: {
            en: 'Terms of Service',
            ko: '이용약관',
        },
        desc: {
            en: 'Terms of Service',
            ko: '이용약관',
        }
    },
    {
        url: ['/faq'],
        priority: 0.5,
        changefreq: 'weekly',
        title: {
            en: 'FAQ',
            ko: '자주 묻는 질문',
        },
        desc: {
            en: 'Frequently Asker Question',
            ko: '자주 묻는 질문 - 미러는 뭐 하는 곳인가요? 구매한 3D에셋은 어떤 프로그램에서 쓸 수 있나요? 3D에셋 주문 제작 위뢰가 가능한가요?',
        }
    },
    {
        url: ['/privacypolicy'],
        priority: 0.5,
        changefreq: 'weekly',
        title: {
            en: 'Privacy Policy',
            ko: '개인정보 처리방침',
        },
        desc: {
            en: 'Privacy Policy',
            ko: '미러는 사용자의 개인정보를 안전하게 보호합니다!',
        }
    },
    {
        url: ['/favorite'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Favorites',
            ko: '즐겨찾기',
        },
        desc: {
            en: 'Add 3D items to your collection. You can see list of your favorite items.',
            ko: '좋아하는 에셋을 즐겨찾기에 추가하세요.',
        }
    },
    {
        url: ['/cart'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Add items to your cart and check out.',
            ko: '마음에 드는 에셋을 장바구니에 넣고 구매하세요.',
        },
        desc: {
            en: 'Add items to your cart and check out.',
            ko: '마음에 드는 에셋을 장바구니에 넣고 구매하세요.',
        }
    },
    {
        url: ['/signup'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Join to me-rror',
            ko: '가입하기',
        },
        desc: {
            en: 'Sign up with email',
            ko: '이메일만으로 가입할 수 있습니다',
        }
    },
    {
        url: ['/account'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Account Settings',
            ko: '계정 설정',
        },
        desc: {
            en: 'change account email, change password, delete account',
            ko: '이메일 변경, 비밀번호 변경, 계정 삭제',
        }
    },
    {
        url: ['/purchases'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Purchase List',
            ko: '구매 목록',
        },
        desc: {
            en: 'payment list, purchase dtail, receipt, download asset, refund',
            ko: '결제정보, 구매상세 정보, 영수증, 에셋 다운로드, 환불',
        }
    },
    {
        url: ['/customorder'],
        priority: 0.5,
        changefreq: 'weekly',
        title: {
            en: 'Custom Order',
            ko: '주문 제작',
        },
        desc: {
            en: 'Didn\'t you find item you wanted? We make items you want',
            ko: '미러에서 원하는 에셋을 찾지 못하셨나요? 원하시는 대로 만들어 드립니다',
        }
    },
    {
        url: ['/event/specialdeal'],
        priority: 0.6,
        changefreq: 'monthly',
        title: {
            en: 'Free Assets',
            ko: '무료 에셋',
        },
        desc: {
            en: 'Free assets are special offers that are available only for limited time.',
            ko: '이 달의 무료 에셋은 해당 월에만 만나볼 수 있는 특별 혜택입니다.',
        }
    },
    {
        url: ['/event/referral'],
        priority: 0.6,
        changefreq: 'monthly',
        title: {
            en: 'ME.RROR Referral Event',
            ko: 'ME.RROR 친구 초대 이벤트',
        },
        desc: {
            en: 'Refer a friend to ME.RROR and YOU BOTH GET FREE COUPON!.',
            ko: 'ME.RROR에 친구를 초대하면 나도 친구도 전상품 FREE 쿠폰.',
        }
    },
    {
        url: ['/event/faceofkorea'],
        priority: 0.6,
        changefreq: 'weekly',
        title: {
            en: 'Faces of Korea',
            ko: '한국의 얼굴',
        },
        desc: {
            en: 'Enrich your projects with ME.RROR\'s premium Korean facial assets.',
            ko: 'ME.RROR의 고품질 한국인 얼굴 에셋으로 여러분의 프로젝트에 생동감을 더하세요.'
        }
    },
    {
        url: ['/business/modeling'],
        priority: 0.6,
        changefreq: 'monthly',
        title: {
            en: 'Custom 3D Modeling',
            ko: 'Custom 3D Modeling',
        },
        desc: {
            en: 'Learn about our all in one solution for business. It\'s easy and high quality! but inexpensive!',
            ko: '미러가 제안하는 3D콘텐츠의 All-in-One Solution은 고퀄리티의 데이터를 합리적인 가격으로 제안합니다.'
        }
    },
    {
        url: ['/business/3dscanstudio'],
        priority: 0.6,
        changefreq: 'monthly',
        title: {
            en: '3D Scan Studio',
            ko: '3D Scan Studio',
        },
        desc: {
            en: 'Our all-in-one solution for 3D content starts with 3D scanning. We provide high-quality 3D content by capturing accurate and realistic data.',
            ko: '미러가 제안하는 3D콘텐츠 올-인-원 솔루션 그 시작은 3D 스캐닝 솔루션 입니다. 정확하고 리얼한 데이터를 획득하여 빠르고 효율적으로 데이터를 제공합니다.'
        }
    },
    {
        url: ['/business'],
        priority: 0.6,
        changefreq: 'monthly',
        title: {
            en: 'About ME.RROR',
            ko: 'About ME.RROR',
        },
        desc: {
            en: '3D and AR Content Solution, ME.RROR. Accelerate your digital transformation with the industry\'s favourite 3D partner',
            ko: '3D & AR 콘텐츠 솔루션 ME.RROR, 지금 ME.RROR와 함께 디지털 트랜스포메이션을 시작하실 수 있습니다'
        }
    },
];