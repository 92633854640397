<div class="signup-container">
    <div class="signup-outer">
        <h3 class="signup-title" [innerHTML]="'Signup.Signup'|translate">회원가입</h3>
        <div class="signup-inner-wrap">
            <div class="signup-inner">
                <div class="signup-label-wrap">
                    <label class="signup-label">
                        <img class="essentail-icon" src="/assets/icon/essential.svg" />
                        <span [innerHTML]="'Common.Email'|translate"></span>
                    </label>
                </div>

                <app-input #signup_email [title]="''" [type]="'EMAIL_NEW'"
                    [placeholder]="desktopSize? 'example@me-rror.com' : 'Signin.EmailCheck'|translate"
                    [desc]="'Signup.EmailNotice'|translate" [emailCheckSpinner]="true">
                </app-input>

                <div class="signup-label-wrap">
                    <label class="signup-label">
                        <img class="essentail-icon" src="/assets/icon/essential.svg" />
                        <span [innerHtml]="'Common.Password'|translate"></span>
                    </label>
                </div>
                <app-input #signup_password [title]="''" [type]="'PASSWORD'" [placeholder]=" (desktopSize? 'Signup.PasswordNotice'
                : 'Signin.PasswordCheck'
             ) | translate
             " [desc]="desktopSize? '' : 'Signup.PasswordNotice'|translate">
                </app-input>

                <div class="signup-label-wrap">
                    <label class="signup-label">
                        <img class="essentail-icon" src="/assets/icon/essential.svg" />
                        <span [innerHtml]="'Common.PasswordConfirm'|translate"></span>
                    </label>
                </div>
                <app-input #signup_password_confirm [title]="''" [type]="'PASSWORD_CONFIRM'"
                    [placeholder]="'Signup.SingupInputPasswordConfirm'|translate"
                    [desc]="desktopSize? '' : 'Signup.PasswordNotice'|translate">
                </app-input>


                <div class="signup-label-wrap">
                    <label class="signup-referral-label">
                        <span [innerHtml]="'Common.ReferralCode'|translate"></span>
                    </label>
                </div>
                <app-input #signup_referralcode [title]="''" [type]="'REFERRALCODE'"
                    [placeholder]="'Signup.ReferralCodeNotice'|translate"
                    [desc]="desktopSize? '' : 'Signup.ReferralCodeNotice'|translate">
                </app-input>
            </div>

            <div #signUpAgreements class="signup-agreement">
                <div class="signup-agreement-all">
                    <input id="signup-agreement-allow-all" type="checkbox"
                        (click)="onSelectionAgreementAll()" />
                    <label for="signup-agreement-allow-all" class="signup-agreement-label"></label>
                    <div [innerHTML]="'Signup.AgreeAll'|translate">
                        모두 동의합니다.</div>
                </div>

                <div>
                    <input id="signup-agreement-age-14" type="checkbox"
                        (click)="onSelectionAgreement($event)" /> <label
                        for="signup-agreement-age-14" class="signup-agreement-label"></label>
                    <div [innerHTML]="'Signup.ImAgeOver14'|translate">
                        본인은 만 14세 이상입니다.</div>
                    <span [innerHtml]="'Common.Essential'|translate">&nbsp;(필수)</span>
                </div>
                <div>
                    <input id="signup-agreement-eula" type="checkbox"
                        (click)="onSelectionAgreement($event)" /> <label for="signup-agreement-eula"
                        class="signup-agreement-label"></label>
                    <div [innerHTML]="'Signup.EULAAgree'|translate">이용약관에
                        동의합니다.</div>
                    <span [innerHtml]="'Common.Essential'|translate">&nbsp;(필수)</span>
                    <a routerLink="/eula" target="_blank">
                        <span class="signup-policy-link"
                            [innerHTML]="'Common.ViewEULA'|translate">약관보기</span>
                        <img class="signup-icon" src="/assets/icon/arrow_right.svg" />
                    </a>
                </div>

                <div>
                    <input id="signup-agreement-privacy" type="checkbox"
                        (click)="onSelectionAgreement($event)" /> <label
                        for="signup-agreement-privacy" class="signup-agreement-label"></label>
                    <div [innerHtml]="'Signup.PrivacyAgree'|translate">개인정보 수집이용에 동의합니다.</div>
                    <span [innerHtml]="'Common.Essential'|translate">&nbsp;(필수)</span>
                    <a routerLink="/privacypolicy" target="_blank">
                        <span class="signup-policy-link"
                            [innerHTML]="'Common.ViewEULA'|translate">약관보기</span>
                        <img class="signup-icon" src="/assets/icon/arrow_right.svg" />
                    </a>
                </div>
                <div>
                    <input id="signup-agreement-marketing" type="checkbox"
                        (click)="onSelectionAgreement($event)" [(ngModel)]="agree_recv_email" />
                    <label for="signup-agreement-marketing" class="signup-agreement-label"></label>
                    <div style="display:inline" [innerHTML]="'Signup.MarketingAgree'|translate">
                        마케팅 정보 수신에 동의합니다.</div>
                    <span [innerHtml]="'Common.Optional'|translate">&nbsp;(선택)</span>
                </div>

            </div>

            <button class="common-button1" [innerHTML]="'Signup.AgreeAndJoin'|translate"
                (click)="signUp()">
                가입하기
            </button>
            <div class="signup-signin-link">
                <span [innerHTML]="'Signup.AreUMember'|translate">기존
                    회원이세요?</span>
                &nbsp;
                <span id="signup-signin-button" (click)="openSigninPopup()"
                    [innerHTML]="'Common.Login'|translate">로그인</span>
            </div>
        </div>

    </div>
</div>