import { Banner, SetBannerCategory, ClearBannerBehavior, SearchBar } from './../../lib/searchtool.service';
import { Component, Input, OnInit } from '@angular/core';
import { Util, UtilityService } from 'src/app/lib/util';
import { SCREEN_SIZE } from 'src/app/lib/resize.service';

@Component({
    selector: 'app-costume-banner',
    template: `
 <div id="slider-{{costumeIndex}}" class="index-slider-item-outer">
    <div class="index-slider-item-inner" style="background-image:{{url}};">
        <div class="index-slider-item-blur" *ngIf="order !== costumeIndex"></div>
        <div class="index-slider-button-wrap common-horizontal-alignment" *ngIf="idx === 0">
            <button class="common-button1" routerLink="specialdeal" [innerHTML]="SpeacialDeals|translate">100원 딜 보기</button>
            <button class="common-button1" (click)="getContents(All)" [innerHTML]="GOtoMerror|translate">ME.RROR 둘러보기</button>
        </div>
        <div class="index-slider-content" *ngIf="idx === 1">
            <p>The Asian Meta Human<br/>for 3D Content</p>
            <h1>ME:ME</h1>
            <button class="common-button1" (click)="getContents(Human)" [innerHTML]="MoreContent|translate"></button>
        </div>
        <div class="index-slider-content" *ngIf="idx === 2">
            <p>The 3D Content<br/>for Professional</p>
            <h1>3D Content</h1>
            <button class="common-button1" (click)="getContents(All)" [innerHTML]="MoreContent|translate"></button>
        </div>
    </div>
</div>
`,
    styleUrls: ['./costume-banner.component.scss'],
})
export class CostumeBannerComponent implements OnInit {

    @Input() url = '';
    @Input() order = 0;
    @Input() costumeIndex = 0;
    @Input() screenSize!: SCREEN_SIZE;

    GOtoMerror = 'Banner.GoToMerror';
    SpeacialDeals = 'Banner.SpecialDeals';
    SpecialDeal = 'Sidebar.SpecialDeal';
    MoreContent = 'Banner.MoreContent';
    Human = 'Sidebar.Human';
    All = 'All';
    idx = 0;

    constructor(
        private util: UtilityService,
        private searchBar: SearchBar,
        private bannerSearch: Banner,
    ) { }

    ngOnInit(): void {
        const regex = /[^0-9]/g;
        this.idx = Number(this.url.replace(regex, ""));
    }

    scrollDown() {
        const height = (document.getElementById('index-slider') as HTMLDivElement).clientHeight;
        console.log(this.screenSize, height)
        if (this.screenSize === SCREEN_SIZE.mobile) Util.scrollTo(height)
        else Util.scrollTo(496);

    }

    async getContents(val: string) {
        const category = await this.util.getTranslation(val);
        if (category === 'All') {
            this.bannerSearch.setBannerBehavior(new ClearBannerBehavior());
            await this.bannerSearch.performBanner({ category: category, method: 'CLEAR' });
        }
        else {
            this.bannerSearch.setBannerBehavior(new SetBannerCategory());
            await this.bannerSearch.performBanner({ category: category, method: 'ADD' });
        }
        this.scrollDown();
    }
}
