import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IShowMessageData, UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-show-message',
    template: `
<div #root class="showmessage-bg">
    <div class="showmessage-content">
        <span style="color:white;">{{Message1}}</span>
        <span class="uk-margin-left">{{Message2}}</span>
    </div>
</div>
`,
})
export class ShowMessageComponent implements OnInit {

    constructor(private utility: UtilityService) {
        utility.currentMessage.subscribe(data => {
            if (data.duration === 0) return;
            this.ShowMessage(data);
        });
    }

    @ViewChild('root') rootelement!: ElementRef<HTMLDivElement>;
    Message1 = '장바구니에 추가되었습니다!';
    Message2 = '장바구니로 이동';

    ngOnInit(): void {
    }

    private timer = -1;
    ShowMessage(message: IShowMessageData) {
        if (this.timer > -1) {
            window.clearTimeout(this.timer);
        }
        const div = this.rootelement.nativeElement;
        div.className = message.type === 'OK' ? 'showmessage-bg-ok' : 'showmessage-bg-warn';
        this.Message1 = message.message;
        this.Message2 = message.link as string;
        div.style.opacity = '1';
        this.timer = window.setTimeout(() => {
            div.style.opacity = '0';
            this.timer = -1;
        }, message.duration as number * 1000);
    }


}

