import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { Data } from '../../server/lib/types';
import { AssetUtil, Util, UtilityService } from '../lib/util';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SearchCondition } from '../lib/searchtool.service';
import { ResizeService, SCREEN_SIZE } from '../lib/resize.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss'],
})

export class FavoriteComponent implements OnInit, OnDestroy {

    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.None,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: [],
            subCategory: [],
            isGameready: false,
        }
    };
    ESearchCondition = Data.ESearchCondition;
    Columns = 6;
    Modellist: Data.Asset[] = [];
    assetutil = new AssetUtil(this.client.api.GetFavoriteAssetList.send);
    ApiUrl = this.client.baseurl;
    pageno = 1;
    sort: Data.ESortBy = Data.ESortBy.Recent;
    apiChecked = false;
    _resizeCallback = (event?: UIEvent) => {
        if (this.screenSize === SCREEN_SIZE.mobile) {
            return;
        }
        const columns = Util.setContainerSize('itemlist', 279, 20);
        if (columns !== this.Columns) {
            this.Columns = columns;
            this.load(true);
        }
    };
    itemWidth = 268;
    gap = 20;
    resizeCallback = (event?: UIEvent) => {
        if (this.screenSize === SCREEN_SIZE.desktop) {
            this.itemWidth = 268;
            this.gap = 20;
        } else if (this.screenSize === SCREEN_SIZE.tablet) {
            this.itemWidth = 258;
            this.gap = 16;
        } else {
            this.itemWidth = 160;
            this.gap = 8;
        }
        const columns = Util.setContainerSize('itemlist', this.itemWidth, this.gap);
        if (columns !== this.Columns) {
            this.Columns = columns;
            this.load(true);
        }
    };
    categoryType = 'all';
    totalItems = 0;
    userno!: number;
    onLangChangeSub: Subscription | null = null;
    currency = this.util.getCurrentCurrency();
    screenSize = this.resizeSvc.getCurrent();

    constructor(
        private client: ApiClientService,
        private translate: TranslateService,
        private resizeSvc: ResizeService,
        public router: Router,
        private route: ActivatedRoute,
        private util: UtilityService
    ) {
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.currency = this.util.getCurrentCurrency();
            await this.load(true);
        });
        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
            if (this.screenSize === SCREEN_SIZE.mobile) {
                this.Columns = 2;
                this.load(true);
                return;
            }
        });

        this.route.params.subscribe(async (params) => {
            this.categoryType = params['category1'];
            if (this.categoryType === undefined) this.categoryType = 'all';

            this.searchcondition.condition = Data.ESearchCondition.Res_All;
            if (this.categoryType === 'human') {
                this.searchcondition.condition |= Data.ESearchCondition.Age_All;
                this.searchcondition.condition |= Data.ESearchCondition.Sex_All;
                this.searchcondition.condition |= Data.ESearchCondition.Type_Posed;
                this.searchcondition.condition |= Data.ESearchCondition.Type_APosed;
                this.searchcondition.condition |= Data.ESearchCondition.Type_Facial;

            } else if (this.categoryType === 'object') {
                this.searchcondition.condition |= Data.ESearchCondition.Age_All;
                this.searchcondition.condition |= Data.ESearchCondition.Sex_All;
                this.searchcondition.condition |= Data.ESearchCondition.Type_Object;

            } else if (this.categoryType === 'space') {
                this.searchcondition.condition |= Data.ESearchCondition.Age_All;
                this.searchcondition.condition |= Data.ESearchCondition.Sex_All;
                this.searchcondition.condition |= Data.ESearchCondition.Type_Space;
            } else {
                this.searchcondition.condition = Data.ESearchCondition.All;
            }
            this.load(true);
        });
    }

    ngOnInit(): void {
        console.log('ngoninit');
        this.client.userinfoSubject.subscribe((res) => {
            this.userno = res.userinfo.userno;
            this.resizeCallback();
            if (this.screenSize === SCREEN_SIZE.mobile) {
                this.Columns = 2;
            }
            this.load(true);
            Util.scrollTo(0);
        });
        this.client.favoriteSubject.subscribe((item) => {
            this.load(true);
        });
        window.addEventListener('resize', this.resizeCallback);
    }
    ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeCallback);
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }

    async load(resetpage = false) {
        if (resetpage) this.pageno = 1;
        else this.pageno++;

        this.totalItems = await this.assetutil.loadAsset(
            this.Modellist, this.searchcondition.condition, [], this.sort,
            this.pageno, this.Columns * 3, resetpage, this.currency,
            0, 0, 0, 0, this.searchcondition.categoryInfo);
        console.log(this.Modellist);

        this.apiChecked = true;
    }
}
