import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ResizeService } from '../lib/resize.service';
import { SCREEN_SIZE } from '../lib/resize.service';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('inner') inner!: ElementRef<HTMLDivElement>;

    screenSize = this.resizeSvc.getCurrent();
    imageList = [2, 4];
    imageindex = 0;
    imagesubindex = 0;
    imagetimer = -1;
    SCREEN_SIZE = SCREEN_SIZE;
    index = 0;

    startX = 0;
    startY = 0;

    ESC = Data.ESearchCondition;

    constructor(
        private router: Router,
        private resizeSvc: ResizeService,
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
        });
    }

    ngOnInit(): void {
        this.imagetimer = window.setInterval(() => {
            this.imagesubindex = (this.imagesubindex + 1) % this.imageList[this.imageindex];
        }, 2500);

        this.setForIndex();
    }

    ngAfterViewInit(): void {
        if (this.screenSize === SCREEN_SIZE.mobile) {
            this.registEvent()
        }
    }

    registEvent() {
        this.inner.nativeElement.addEventListener('touchstart', this.start);
        this.inner.nativeElement.addEventListener('touchend', this.end);
    }

    removeEvent() {
        this.inner.nativeElement.removeEventListener('touchstart', this.start);
        this.inner.nativeElement.removeEventListener('touchend', this.end);
    }

    start = (event: TouchEvent) => {
        this.startX = (event as TouchEvent).touches[0].screenX
        this.startY = (event as TouchEvent).touches[0].screenY;
    }

    end = (event: TouchEvent) => {
        const moveX = (event as TouchEvent).changedTouches[0].screenX - this.startX;
        const moveY = (event as TouchEvent).changedTouches[0].screenY - this.startY;

        if (Math.abs(moveY) > Math.abs(moveX) || Math.abs(moveX) < 30) return;
        if (this.index === 0) this.index = 1
        else this.index = 0;

        this.setImageIndex(this.index);
    }

    ngOnDestroy(): void {
        window.clearInterval(this.imagetimer);
        this.reset();
        this.removeEvent();
    }

    private setForIndex() {
        const header = document.getElementsByClassName('header-container')[0] as HTMLDivElement;
        if (header !== undefined)
            header.style.background = 'linear-gradient(180deg, rgba(12,12,12,0.4) 0%, rgba(13,13,13,0.15) 60%, rgba(116,116,116,0) 100%)';

        const headerIcons = document.getElementsByClassName('header-icon-wrap')[0] as HTMLDivElement;
        if (headerIcons !== undefined) headerIcons.style.display = 'none';

        const footer = document.getElementsByClassName('footer-top-outer')[0] as HTMLDivElement;
        if (footer !== undefined) {
            footer.style.zIndex = '1';
            // footer.style.backgroundColor = 'rgba(20,20,20,0.2)';
        }

        const search = document.getElementsByClassName('main-search-outer')[0] as HTMLDivElement;
        if (search !== undefined)
            search.style.opacity = '0.5';

        const gnav = document.getElementsByClassName('gnb-container')[0] as HTMLDivElement;
        if (gnav !== undefined) gnav.style.display = 'none';
    }

    private reset() {
        const header = document.getElementsByClassName('header-container')[0] as HTMLDivElement;
        if (header) header.style.background = '';

        const headerIcons = document.getElementsByClassName('header-icon-wrap')[0] as HTMLDivElement;
        if (headerIcons !== undefined) headerIcons.style.display = '';

        const footer = document.getElementsByClassName('footer-top-outer')[0] as HTMLDivElement;
        if (footer !== undefined) {
            footer.style.zIndex = '';
            // footer.style.backgroundColor = '';
        }

        const search = document.getElementsByClassName('main-search-outer')[0] as HTMLDivElement;
        if (search !== undefined) search.style.opacity = '';

        const gnav = document.getElementsByClassName('gnb-container')[0] as HTMLDivElement;
        if (gnav !== undefined) gnav.style.display = '';
    }

    setImageIndex(index: number) {
        this.imageindex = index;
        this.imagesubindex = 0;
        window.clearInterval(this.imagetimer);
        this.imagetimer = window.setInterval(() => {
            this.imagesubindex = (this.imagesubindex + 1) % this.imageList[this.imageindex];
        }, 2500);
    }

    moveTo() {
        if (this.imageindex == 0) {
            this.router.navigate(['/event/faceofkorea'], { state: { subcategory: '', gameready: false, flag: this.ESC.Type_Facial } });
        } else {
            window.open('https://merror.tistory.com/2', '_blank');
        }
    }
}
