<div class="account-outer">
    <div class="account-location">
        <div>
            <span routerLink="/store" [innerHTML]="'Common.Home'|translate">Home</span>
        </div>
        <div class="common-horizontal-alignment">
            <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
            <span [innerHTML]="'Account.MyAccount'|translate">My Account</span>
        </div>
        <div class="common-horizontal-alignment">
            <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
            <span
                [innerHTML]="(category==='settings'?'Account.AccountSetting':'Account.MyCoupon')|translate">
            </span>
        </div>


    </div>
    <div class="account-title" [innerHTML]="'Account.MyAccount'|translate">MY ACCOUNT</div>
    <div class="account-category-outer">
        <div class="account-category {{category==='settings'?'activated':''}}"
            [innerHTML]="'Account.AccountSetting'|translate" (click)="category='settings'">
            Account Settings</div>
        <div class="account-category {{category==='coupon'?'activated':''}}"
            [innerHTML]="'Account.MyCoupon'|translate" (click)="category = 'coupon'">My Coupon</div>
    </div>

    <div [style.display]="category==='settings'?'block':'none'">
        <div class="account-subtitle">
            <div [innerHTML]="'Account.AccountInfo'|translate">계정 정보</div>
        </div>
        <div class="account-info-outer">
            <div class="account-info-key" [innerHTML]="'Account.UserNo'|translate">사용자 ID</div>
            <div class="account-info-value">{{userinfo.userid}}</div>
            <div class="account-info-button"></div>
        </div>
        <div class="account-info-outer">
            <div class="account-info-key" [innerHTML]="'Common.Email'|translate">이메일</div>
            <div class="account-info-value">{{userinfo.email}}</div>
            <div class="account-info-button"></div>
        </div>
        <div class="account-info-outer">
            <div class="account-info-key" [innerHTML]="'Common.Password'|translate">Password</div>
            <div class="account-info-value">********</div>
            <div class="account-info-button" [innerHTML]="'Account.Change'|translate"
                [routerLink]="['/', {outlets:{popup:['modifyuser','password']}}]">변경하기</div>
        </div>
        <div style="width:100%;height:64px;"></div>
        <div class="account-subtitle">
            <div [innerHTML]="'Account.NotificationSettings'|translate">알림 설정</div>
        </div>
        <div class="account-info-outer">
            <div class="account-notification-switch-outer"
                (click)="setUserRecvEmail(!userinfo.recv_email)">
                <div class="account-notification-switch {{userinfo.recv_email?'activated':''}}">
                </div>
            </div>
            <div class="account-info-value" [innerHTML]="'Account.NotificationAgreement'|translate">
                마케팅 수신 동의</div>
        </div>
        <div style="width:100%;height:64px;"></div>
        <div class="account-subtitle">
            <div [innerHTML]="'Account.DeleteAccount'|translate">계정 삭제</div>
        </div>
        <div class="account-delete-outer">
            <div class="account-delete-text" [innerHTML]="'Account.DeleteAccountNotice'|translate">
                모든 데이터가 영구적으로 삭제됩니다.</div>
            <div class="account-info-button" [innerHTML]="'Common.Delete'|translate"
                [routerLink]="['/', {outlets:{popup:['deleteuser']}}]">삭제하기</div>
        </div>
    </div>

    <div [style.display]="category==='coupon'?'block':'none'">
        <div class="account-subtitle">
            <div [innerHTML]="'Account.MyRecommendCode'|translate">나의 추천인 코드</div>
        </div>
        <div class="account-info-outer">
            <div class="account-info-key account-coupon-code">
                <span>{{userinfo.password}}</span>
                <span class="account-coupon-copy" (click)="openCopyPopup()"
                    [cdkCopyToClipboard]="userinfo.password">
                </span>
            </div>
            <div class="account-info-coupon-value"></div>
            <div class="account-info-coupon-button" (click)="openCopyPopup()"
                [cdkCopyToClipboard]="copyToClipboard + userinfo.password"
                [innerHTML]="'Account.InvitationLink'|translate">초대링크</div>
        </div>
        <div style="width:100%;height:64px;"></div>
        <div class="account-subtitle">
            <div [innerHTML]="'Account.OwnedCoupons'|translate">보유 쿠폰</div>
        </div>
        <div class="account-coupon-list">
            <div *ngFor="let coupon of couponDispList" class="account-coupon-outer">
                <div class="account-coupon-left">
                    <div>{{coupon.title}}</div>
                    <div>{{coupon.subtitle}}</div>
                    <div>{{coupon.description1}}</div>
                    <div>{{coupon.description2}}</div>
                </div>
                <div class="account-coupon-right">
                    {{coupon.description3}}</div>
            </div>
        </div>
    </div>

    <div style="width:100%;height:120px;"></div>
</div>

<div class="copy-popup-container" #copy_popup>
    <div class="copy-popup-contents">
        <div class="copy-popup-message">
            <div>
                <img src="/assets/icon/check_24_color.svg" />
            </div>

            <div [innerHTML]="'Account.CopyClipboard'|translate">
            </div>
            <!-- <span id="delete-countdown" #delete_countdown>&nbsp;{{countDown}}</span>
            <div id="delete-message"
                [innerHTML]="'Cart.DeleteConfirm2'|translate:{s:countDown > 1 ? 's':''}" #message2>
            </div> -->
        </div>
        <!-- <div class="cart-restore-message">
            <span [innerHTML]="'Cart.Undo'|translate" (click)="restore()"
                style="cursor: pointer;text-decoration: underline;">되돌리기</span>
        </div> -->
    </div>
</div>