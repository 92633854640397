<div class="filter-outer">
    <ul class="filter-inner">
        <div class="filter-mobile-nav"
            [ngClass]="(showCategory && screenSize === 0)? 'filter-mobile-header' : ''">
            <div [style.display]="(showCategory && screenSize === 0)? 'block' : 'none'">
                <div [innerHTML]="'Common.Filter'|translate">
                    Filter</div>
                <img (click)="toggleMobileFilter(false)" class="filter-mobile-close"
                    src="/assets/icon/chevron_left_24.svg" />
            </div>

        </div>
        <div class="filter-inner-left">
            <li class="filter-category"
                *ngIf="(categoryType !== 'all' && categoryType !== undefined) || (screenSize !== 2)">
                <input type="text" readonly="readonly" [value]="'Common.Filter'|translate"
                    id="dropdown-main-filter" (click)="showCategory = !showCategory"
                    [style]="hasSubcategory(searchcondition, subCategoryList) || searchcondition.condition !==0? 'border: 1px solid #FFCA66;color:#FFCA66;background-image: url(/assets/icon/chevron_down_yellow_20.svg)' : '' ">
                <div class="filter-category-options"
                    [style.display]="showCategory? 'block': 'none'">
                    <app-dropdown-filter [categoryTitle]="categoryType" [screenSize]="screenSize"
                        [searchcondition]="searchcondition">
                    </app-dropdown-filter>
                </div>
            </li>
            <li class="filter-divider-vertical"
                *ngIf="(categoryType !== 'all' && categoryType !== undefined)">
            </li>
            <div class="filter-screen-align" id="dropdopwn-sub-filter"
                [style.display]="screenSize !==2 ? (showCategory? 'flex': 'none') : 'flex'">
                <li class="filter-polygon" id="main-polygon-input">
                    <input type="text" readonly="readonly" [value]="'Sidebar.PolyCount'|translate"
                        #polygoncount id="init-polygon" class="poly-input-default"
                        [style]="(currentPolygonIdx === 0 || currentPolygonIdx === 1|| currentPolygonIdx === 2)? 'border: 1px solid #FFCA66; color: #FFCA66;background-image: url(/assets/icon/chevron_down_yellow_20.svg)' : '' "
                        (click)="showPolygonOptions = !showPolygonOptions">
                    <div class="nondesk-title" [innerHTML]="'Sidebar.PolyCount'|translate">PolyCount
                    </div>
                    <div class="filter-polygon-options"
                        [style.display]="(showPolygonOptions || screenSize !== 2)? 'block': 'none'">
                        <ul class="dropdown-filter-polygon">
                            <li class="dropdown-filter-polygon-option" (click)="setPolygon(3);"
                                [innerHTML]="'Common.Unselect'|translate">선택
                                안함
                            </li>
                            <li *ngFor="let polygon of polygonList; let index = index"
                                [style.color]="(polygoncount.value === polygon.name) && (screenSize !== 2) ? '#FFCA66' : ''"
                                class="dropdown-filter-polygon-option"
                                (click)="setPolygon(index); polygoncount.value=polygon.name"
                                value={{polygon.name}}>
                                {{polygon.name}}</li>
                        </ul>
                    </div>
                </li>

                <div class="filter-price-outer">
                    <div class="nondesk-title" [innerHTML]="'Common.Price'|translate">Price</div>
                    <li class="filter-price-inner">
                        <div class="filter-price-display">
                            <span>{{withComma(minValue)}}<span
                                    [innerHTML]="currencykey|translate">원</span></span>
                            <span>{{withComma(maxValue)}}<span
                                    [innerHTML]="currencykey|translate">원</span></span>
                        </div>
                        <div class="filter-price">

                            <div id="range-slider" role="slider" class="filter-range-wrap"
                                (mouseup)="setPriceRange($event)">
                                <div class="filter-range-slider">
                                    <input (input)="setPriceRange($event)" id="rangeMin"
                                        type="range" min="0" max="400" value="0" />
                                    <input (input)="setPriceRange($event)" id="rangeMax" #rangeMax
                                        type="range" min="0" max="400" value="400" />

                                    <div class="filter-slider">
                                        <div class="filter-track"></div>
                                        <div id="filter-range"></div>
                                        <div id="thumb-left" class="filter-thumb">
                                        </div>
                                        <div id="thumb-right" class="filter-thumb"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                </div>



                <li class="filter-game-ready-outer">
                    <div class="filter-game-ready" (click)="toggleGameReady()">
                        <input id="filter-gameready" type="checkbox"
                            [checked]="searchcondition.categoryInfo.isGameready"
                            (click)="toggleGameReady()" />
                        <label for="filter-gameready" class="gameready-label"></label>
                        <span>Game-ready</span>
                    </div>

                </li>

            </div>


            <li class="filter-clear-all" (click)="clearFilter()"
                [style.display]="(!showCategory) && (screenSize === 0)? 'none' : ((searchcondition.categoryInfo.isGameready === true) || (searchcondition.maxpolygon !== 0) || (searchcondition.condition !== 0) || (searchcondition.categoryInfo.subCategory.length !== 0)) || (searchcondition.minprice !== 0 || (rangeMax.value !== '400'))? 'block': 'none'">
                <button [innerHTML]="'Sidebar.ClearAll'|translate">
                    Clear All
                </button>
            </li>

        </div>

        <div class="filter-inner-right">
            <li class="filter-sort-order" id="main-sort-input">
                <input type="text" readonly="readonly"
                    [ngModel]="this.sortList[this.searchcondition.sort - 1].name|translate"
                    (click)="showSortOptions = !showSortOptions">
                <div class="filter-sort-options"
                    [style.display]="screenSize !== 0 ? (showSortOptions? 'block': 'none') : (showCategory? 'block': 'none')">
                    <div class="mobile-controller-wrap" (click)="showSortOptions=!showSortOptions">
                        <div class="mobile-filter-title" [innerHTML]="'Search.Sort'|translate">
                            Sort</div>
                        <img class="mobile-filter-chevron-icon" src="{{ showSortOptions ? '/assets/icon/chevron_up_20.svg' :
                        '/assets/icon/chevron_down_20.svg'}}" />
                    </div>
                    <ul class="dropdown-filter-sort" *ngIf="showSortOptions">
                        <li *ngFor="let sort of sortList; let index = index"
                            class="dropdown-filter-sort-option" (click)="onChangeSort(index)"
                            [value]="sort.name|translate"
                            [style.color]="(this.searchcondition.sort - 1 === index )&&(screenSize === 0)? '#FFCA66' : ''">
                            {{sort.name|translate}}</li>
                    </ul>
                </div>
            </li>
        </div>

        <div class="filter-mobile-icon" *ngIf="!showCategory" (click)="toggleMobileFilter(true)">
            <img src="/assets/icon/filter_24.svg" />
            <span [innerHTML]="'Common.Filter'|translate">Filter</span>
        </div>

    </ul>

</div>