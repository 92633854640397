import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ThreeCanvas } from 'src/app/lib/threecanvas';
import { Data } from 'src/server/lib/types';

interface AssetFileInfo {
    filename: string;
    ext: string;
    assetno: number;
    type: Data.TextureMapType | 'FBX';
    optiontype: Data.AssetOptionType;
    textureindex: number;
    file: File;
}

@Component({
    selector: 'app-admin-asset',
    styleUrls: ['./admin-asset.component.scss'],
    templateUrl: './admin-asset.component.html',
})
export class AdminAssetComponent implements OnInit, AfterViewInit {

    threeviewer = new ThreeCanvas();
    infolist: AssetFileInfo[] = [];
    infolistByOption: { [key in Data.AssetOptionType]?: AssetFileInfo[] } = {};
    extras: string[] = [];

    loading = false;
    loading_estimate = 0;
    isHuman = false;

    viewerBGColorList = ['black', '#888', 'white'];
    viewerBGColorIndex = 0;

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.threeviewer.init(document.getElementById('threeviewer') as HTMLElement, 2);
    }

    checkFiles(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files !== null) {
            this.infolist.length = 0;
            this.infolistByOption = {};
            this.extras.length = 0;
            const filelist = event.target.files;
            for (let i = 0; i < filelist.length; i++) {
                const file = filelist.item(i) as File;
                const info = this.parseFilename(file);
                console.log(file.name, info);
                if (info === null) {
                    this.extras.push(file.name);
                } else {
                    this.infolist.push(info);
                    if (this.infolistByOption[info.optiontype] === undefined) {
                        this.infolistByOption[info.optiontype] = [];
                    }
                    this.infolistByOption[info.optiontype]?.push(info);
                }
            }
        }
        this.threeviewer.clear();
    }

    parseFilename(file: File): AssetFileInfo | null {
        const filename = file.name;
        const index = filename.lastIndexOf('.');
        const ext = filename.substring(index + 1).toUpperCase();
        const regexpstr = `([1-9][0-9]*)`
            + `-(${Data.AssetOptionTypes.join('|')})`
            + `-([T|M])`
            + `-(${Data.TextureMapTypes.join('|')}|FBX)([1-9]*)`;
        const regex = new RegExp(regexpstr);
        const match = regex.exec(filename.toUpperCase());
        if (match === null) {
            return null;
        } else {
            const assetno = parseInt(match[1]);
            const optiontype = match[2] as Data.AssetOptionType;
            return {
                filename,
                ext,
                assetno,
                optiontype,
                type: match[4] as Data.TextureMapType | 'FBX',
                textureindex: match[5] === '' ? 0 : parseInt(match[5]) - 1,
                file
            };
        }
    }

    async showAsset(optiontype: Data.AssetOptionType | any) {
        if (this.loading) return;
        if (Data.AssetOptionTypes.includes(optiontype) === false) return;
        const fbxinfo = this.infolist.find(info => info.type === 'FBX' && info.optiontype === optiontype);
        if (fbxinfo === undefined) return;
        console.log('show asset :', optiontype);

        this.isHuman = ['CU30', 'CU100', 'CU2000'].includes(optiontype);
        this.loading = true;
        let msize = fbxinfo.file.size;
        for (const info of this.infolist) {
            if (info.optiontype === optiontype && info.type !== 'FBX') {
                msize += info.file.size / 5;
            }
        }
        msize /= 1024 * 1024;
        this.loading_estimate = Math.round(msize * 0.25 + 1 + Math.log10(msize));
        let t = Date.now();
        await new Promise<void>(resolve => {
            const reader = new FileReader();
            reader.onload = e => {
                const buffer = e.target?.result as ArrayBuffer;
                this.threeviewer.parseFBX(buffer);
                resolve();
            };
            reader.readAsArrayBuffer(fbxinfo.file);
        });

        for (const info of this.infolist) {
            if (info.optiontype === optiontype && info.type !== 'FBX') {
                await new Promise<void>(resolve => {
                    const reader = new FileReader();
                    reader.onload = async e => {
                        const dataurl = e.target?.result as string;
                        await this.threeviewer.updateTexture(dataurl, info.type as Data.TextureMapType, info.textureindex);
                        resolve();
                    };
                    reader.readAsDataURL(info.file);
                });
            }
        }

        this.setCamera();

        this.threeviewer.render();
        this.loading = false;
        t = Date.now() - t;
        console.log('loading time : ', t / 1000);
    }

    formatSize(size: number) {
        size = Math.round(size / 1024 / 1024 * 100) / 100;
        return size;
    }

    setCamera(human?: boolean) {
        if (human !== undefined) this.isHuman = human;
        if (this.isHuman) this.threeviewer.setCameraForHuman(1.6, false);
        else this.threeviewer.setCamera();
    }

    changeBGColor() {
        this.viewerBGColorIndex++;
        this.viewerBGColorIndex %= this.viewerBGColorList.length;
    }
}
