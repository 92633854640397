<div class="favorite-container">
    <div class="favorite-outer">
        <div class="favorite-inner">
            <div class="favorite-data">
                <div id="itemlist">

                    <div class="favorite-top">
                        <div class="favorite-breadcrumb">
                            <div>
                                <span routerLink="/store"
                                    [innerHTML]="'Common.Home'|translate">Home</span>
                            </div>
                            <div class="common-horizontal-alignment">
                                <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
                                <span routerLink="/favorite"
                                    [innerHtml]="'Common.Favorites'|translate">Favorites</span>
                            </div>
                        </div>

                        <div class="favorite-header-wrap">
                            <h1 class="favorite-header" [innerHTML]="'Common.Favorites'|translate">
                                Favorites</h1>
                        </div>



                        <ul class="favorite-category">
                            <li class="favorite-category-label" [style]="
                            (all.isActive || router.url.includes('/favorite'))
                                && !router.url.includes('/favorite/human')
                                && !router.url.includes('/favorite/object')
                                && !router.url.includes('/favorite/space')
                                ? 'color: #FFCA66; border-bottom: 2px solid #FFCA66'
                                : 'color:#BDBDBD'" routerLinkActive="active-link"
                                #all="routerLinkActive" routerLink="/favorite/all"
                                [routerLinkActiveOptions]="{exact: false}"
                                [innerHtml]="'Search.AllAssets'|translate">All</li>
                            <li class="favorite-category-label" routerLink="/favorite/human"
                                [innerHtml]="'Sidebar.Human'|translate"
                                [class.active-link]="human.isActive" [style]="human.isActive
                                ? 'color: #FFCA66; border-bottom: 2px solid #FFCA66'
                                : 'color:#BDBDBD'" routerLinkActive="active"
                                #human="routerLinkActive">Human</li>
                            <li class="favorite-category-label" routerLink="/favorite/object"
                                [class.active-link]="object.isActive" [style]="object.isActive ?
                                'color: #FFCA66; border-bottom: 2px solid #FFCA66'
                                : 'color:#BDBDBD'" routerLinkActive="active"
                                #object="routerLinkActive" [innerHtml]="'Sidebar.Object'|translate">
                                Object</li>
                            <li class="favorite-category-label" routerLink="/favorite/space"
                                [class.active-link]="space.isActive" [style]="space.isActive ?
                                'color: #FFCA66; border-bottom: 2px solid #FFCA66'
                                : 'color:#BDBDBD'" routerLinkActive="active"
                                #space="routerLinkActive" [innerHtml]="'Sidebar.Space'|translate">
                                Space</li>
                        </ul>
                    </div>

                    <div class="index-item-container">
                        <div *ngFor="let model of Modellist" style="width:100%">
                            <app-index-item [asset]="model" [ApiUrl]="ApiUrl">
                            </app-index-item>
                        </div>

                    </div>
                </div>

                <div id="button_more" *ngIf="totalItems>Modellist.length">
                    <button class="common-button1 index-more-button"
                        [innerHTML]="'Common.SeeMore'|translate" (click)="load() ">
                        더 보기
                    </button>
                </div>

                <div class="ghost-page" *ngIf="!apiChecked"></div>

                <div class="favorite-empty-container"
                    [style.display]="apiChecked && totalItems === 0? 'flex': 'none'">
                    <img class="empty-img" src="/assets/image/empty/empty_favorite.png" />
                    <div class="empty-desc" [innerHTML]="'Common.EmptyFavorites'|translate">즐겨찾기에
                        추가한 상품이 없습니다.</div>
                    <button class="common-button1 empty-button" routerLink="/store"
                        [innerHTML]="'Common.ContinueShopping'|translate">쇼핑계속하기</button>
                </div>
            </div>
        </div>
    </div>
</div>