import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { BillingPaymentItem, BillingPurchaseItem } from '../../server/lib/types';
import { UtilityService } from '../lib/util';
import { CommonUtil } from 'src/server/lib/util';
import { Router } from '@angular/router';
import { ResizeService } from '../lib/resize.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

type SearchPeriodType = '1w' | '1m' | '3m' | 'all';

@Component({
    selector: 'app-purchase',
    templateUrl: './purchases.component.html',
    styleUrls: ['./purchases.component.scss'],
})
export class PurchasesComponent implements OnInit {

    constructor(
        private client: ApiClientService,
        private util: UtilityService,
        private router: Router,
        private resizeSvc: ResizeService,
        private translate: TranslateService
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
        });

        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.lang = lang.lang as 'ko' | 'en';
        });

    }

    ApiUrl = this.client.baseurl;
    totalItems = -1;
    apiChecked = false;
    payments: BillingPaymentItem[] = [];
    getLocalDatetimeStr = CommonUtil.getLocalDatetimeString;
    getOrderDate = CommonUtil.getOrderDateString;
    firstOption = CommonUtil.assetFirstOption;
    withComma = CommonUtil.withComma;
    searchperiod: SearchPeriodType = 'all';
    pageno = 1;
    screenSize = this.resizeSvc.getCurrent();

    onLangChangeSub: Subscription | null = null;
    lang: 'ko' | 'en' = this.util.getCurrentLang();

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.load();
    }

    async load(pageno = 1) {
        const res = await this.client.api.GetPurchaseList.send({
            pageno,
            pagesize: 5
        });
        this.apiChecked = true;
        console.log('purcahse list:', res);
        if (res.message === 'ok') {
            this.payments = res.list;
            this.totalItems = res.totalcount;
        }
        console.log('자보자', this.payments)
    };

    async loadNextpage() {
        this.pageno++;
        const res = await this.client.api.GetPurchaseList.send({
            pageno: this.pageno,
            pagesize: 5
        });
        this.apiChecked = true;
        if (res.message === 'ok') {
            this.totalItems = res.totalcount;
            for (const item of res.list) {
                this.payments.push(item);
            }
        }
    }

    setSearchPeriod(period: SearchPeriodType) {
        this.searchperiod = period;
    }

    async showReceipt(item: BillingPaymentItem) {
        const res = await this.client.api.GetPGInfo.send({ merchant_uid: item.CID });
        if (res.message === 'ok') {
            this.router.navigate(['/', { outlets: { popup: ['receipt-popup', item.CASHNO] } }], { state: { purchase: item, pgInfo: res.info } });
        } else {
            //쿠폰 혹은 무료에셋 결제 no error-pginfo
            this.router.navigate(['/', { outlets: { popup: ['receipt-popup', item.CASHNO] } }], { state: { purchase: item, pgInfo: res.info } });
        }
    }

    checkCuponPurchase(item: BillingPaymentItem) {
        for (let i = 0; i < item.list.length; i++) {
            const purchasedAsset = item.list[i];
            if ((String(purchasedAsset.COUPONID) !== '1' && String(purchasedAsset.COUPONID) !== '2')) {
                return false;
            }
        }
        return true;
    }

    showDetail(item: BillingPaymentItem) {
        this.router.navigate(['/purchases', item.CASHNO], { state: { payment: item } });
    }
    downloadAsset(item: BillingPurchaseItem) {
        const assetno = parseInt(item.ASSETNO);
        const optionno = parseInt(item.OPTIONNO);
        const type = item.ITEMTYPE;
        this.util.downloadAsset(assetno, optionno, type);
        if (item.firstDownload === null) {
            item.firstDownload = new Date();
            item.refundable = false;
        }
    }
}
