import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddSearchBehavior, CategoryFilterStyler, ClearFilter, ClearSearchBehavior, ClickDeleteSearchBehavior, Filter, SearchBar, SetPolygon, SetPrice } from 'src/app/lib/searchtool.service';

@Component({
    selector: 'app-pagination',
    template: `
<ul class="uk-pagination uk-flex-center uk-margin-remove-bottom">
    <li><a uk-icon="icon:chevron-left;ratio:1.1;" style="padding-top:7px;" (click)="prev()"></a></li>
    <li *ngIf="first>1" class="uk-disabled"><span>...</span></li>
    <li *ngFor="let page of pagelist">
        <a [style]="page === current ? 'color:white;':''" (click)="gotoPage(page)">{{page}}</a>
    </li>
    <li *ngIf="last<total" class="uk-disabled"><span>...</span></li>
    <li><a uk-icon="icon:chevron-right;ratio:1.1;" style="padding-top:7px;" (click)="next()"></a></li>
</ul>
`,
})
export class PaginationComponent implements OnInit, OnChanges {

    @Input() total = 10;
    @Input() current = 5;
    @Input() showlength = 9
    @Output() pageChange = new EventEmitter<number>();

    first = 0;
    last = 0;
    pagelist: number[] = [];

    constructor(
        private filterSearch: Filter,
        private searchBar: SearchBar
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.first = this.current - Math.floor(this.showlength / 2);
        if (this.first < 1) this.first = 1;

        this.last = this.current + Math.floor(this.showlength / 2);
        if (this.last > this.total) this.last = this.total;

        this.pagelist.length = 0;
        for (let page = this.first; page <= this.last; page++) {
            this.pagelist.push(page);
        }
    }

    prev() {
        let newpage = this.current - 1;
        if (newpage < 1) newpage = 1;
        this.gotoPage(newpage);
    }
    next() {
        let newpage = this.current + 1;
        if (newpage > this.total) newpage = this.total;
        this.gotoPage(newpage);
    }

    gotoPage(newpage: number) {
        if (newpage === this.current || newpage < 1 || newpage > this.total) return;

        this.current = newpage;
        this.pageChange.emit(newpage);
        this.ngOnChanges({});
    }

}

