<div>
    <div id="canvas" [style.width]="''+width+'px'" [style.height]="''+ height+'px'"></div>
    <button (click)="capture(4096,4906)">Capture(4096x4096)</button>
    <button (click)="capture(1920,1080)">Capture(1920x1080)</button>

    <button id="thumbnail1" (click)="thumbnail(1)">Thumbnail1</button>
    <button id="thumbnail2" (click)="thumbnail(2)">Thumbnail2</button>
    <button id="thumbnail3" (click)="thumbnail(3)">Thumbnail3</button>
    <button id="thumbnail4" (click)="thumbnail(4)">Thumbnail4</button>
    <button id="thumbnail5" (click)="thumbnail(5)">Thumbnail5</button>
    <button id="thumbnail6" (click)="thumbnail(6)">Thumbnail6</button>
    <button *ngIf="texturelist.includes('NORM')" id="thumbnail7"
        (click)="thumbnail(7)">Thumbnail7</button>
</div>