<div class="business-common-outer">
    <div class="business-common-banner-container">
        <video id="business-common-video" #videoElement class="business-common-banner-video">
            <source src="/assets/image/business/scanstudio_background.mp4" type="video/mp4">
        </video>
        <div class="business-common-banner-outer">
            <div class="business-common-banner-inner">
                <div class="business-common-banner-content-outer">
                    <div class="business-common-banner-content-inner">
                        <div class="business-common-banner-title2"
                            [innerHTML]="'ForBusiness.3DScanStudio'|translate">3D 스캐닝 솔루션
                        </div>
                        <div class="business-common-banner-title3"
                            [innerHTML]="'ForBusiness.IndustBannerDesc1'|translate">All-in-One 3D
                            콘텐츠 솔루션,
                            ME.RROR
                        </div>
                        <div class="business-common-banner-desc"
                            [innerHTML]="'ForBusiness.ScanstuidoBannerDesc1'|translate">
                            산업분야에서 디지털 트윈은 제품 또는 공정의 상태를 실시간 모니터링하고, 문제점을 파악하여 대응할 수 있게 합니다. 또한, 3D
                            시뮬레이션을
                            수행함으로써 생산라인을 최적화 할 수 있습니다.
                        </div>
                        <div class="business-common-banner-buttons">
                            <button class="common-button2" (click)="scrollToService()"
                                [innerHTML]="'ForBusiness.LearnMore2'|translate">더 알아보기</button>
                            <button class="common-button1"
                                [innerHTML]="'ForBusiness.AskQuotation'|translate"
                                routerLink="/business/quotation">견적
                                문의하기</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="business-common-title-container" #title_container>
        <div class="business-common-title-outer">
            <div class="business-common-title-inner">
                <div class="business-common-title-contents">
                    <div class="business-common-title-text1"
                        [innerHTML]="'ForBusiness.IndustTitle'|translate">3D 스캐닝 서비스</div>
                    <div class="business-common-title-text2"
                        [innerHTML]="'ForBusiness.ScanstuidoTitleDesc1'|translate">산업분야에서 디지털 트윈은 매우
                        다양하게 활용되고 있습니다.
                        디지털
                        트윈에서
                        3D
                        스캔은 중요한 구성 요소 중 하나입니다. </div>
                    <div class="business-common-title-text3"
                        [innerHTML]="'ForBusiness.ScanstuidoTitleDesc2'|translate">
                        3D 스캔을 통해 얻은 데이터는 디지털 트윈에서 물체의 모양, 크기, 구조 등의 정보를 제공하며,
                        <br />
                        다양한 센서 데이터와 결합하여 물체의 모든 측면을 정확하게 파악할 수 있게 합니다.
                        <br />
                        디지털 트윈의 정확도와 신뢰성을 보장하기 위해 3D 스캐닝 과정은 보다 정확하고 정밀하게 작업되는 것이 중요합니다.
                    </div>
                </div>

            </div>

        </div>
    </div>


    <div class="business-common-desc-container">
        <div class="business-common-desc-outer">
            <div class="business-common-desc-inner">
                <div class="business-common-desc" *ngFor="let item of descinfo;let index = index;">
                    <div class="business-common-carousel-wrap">
                        <app-business-carousel [index]="index" [page]="'entertainment'"
                            [imgList]="item.imagecount"></app-business-carousel>
                    </div>
                    <div class="business-common-desc-content">
                        <div class="business-common-desc-text1" [innerHTML]="item.title|translate">
                            {{item.title}}</div>
                        <div class="business-common-desc-text-wrap">
                            <div class="business-common-desc-text2">
                                <div *ngFor="let text of item.desc" [innerHTML]="text|translate">
                                    {{text}}</div>
                            </div>
                            <div class="business-common-desc-text3">
                                <div *ngFor="let text of item.tag; let first = first"
                                    [innerHTML]="text|translate"
                                    [ngClass]="{'business-indsutrial-tag-margin': first}">
                                    {{text}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="business-common-process-container">
        <div class="business-common-process-outer">
            <div class="business-common-process-inner">
                <div class="business-common-title-text1"
                    [innerHTML]="'ForBusiness.3DScanProcess'|translate">3D 스캐닝 과정</div>
                <section class="business-common-process-wrap">
                    <article class="business-common-process">

                        <div class="business-common-process-img-wrap">
                            <div>
                                <div class="business-process-order">
                                    1
                                </div>
                                <img class="business-common-process-img2"
                                    src="/assets/image/business/process-icon1.png" />
                            </div>

                            <div class="business-common-process-text">
                                <div class="business-common-process1"
                                    [innerHTML]="'ForBusiness.ProcessTitle1'|translate">스캔 서비스 문의
                                </div>
                                <button class="common-button1 business-common-process-button"
                                    (click)="util.showChannelTalk()"
                                    [innerHTML]="'ForBusiness.ContactUs2'|translate">문의하기</button>
                            </div>
                        </div>

                    </article>
                    <article class="business-common-process">
                        <div class="business-common-process-img-wrap">
                            <div>
                                <div class="business-process-order">
                                    2
                                </div>
                                <img class="business-common-process-img2"
                                    src="/assets/image/business/process-icon2.png" />
                            </div>

                            <div class="business-common-process-text">
                                <div class="business-common-process2"
                                    [innerHTML]="'ForBusiness.ProcessTitle2'|translate">스캔 서비스 컨설팅
                                </div>
                                <div class="business-common-process-desc"
                                    [innerHTML]="'ForBusiness.ProcessDesc2'|translate">3D 스캔 전문가가
                                    연락을 드려 상세한 상담을
                                    해드립니다.</div>
                            </div>
                        </div>

                    </article>
                    <article class="business-common-process">
                        <div class="business-common-process-img-wrap">
                            <div>
                                <div class="business-process-order">
                                    3
                                </div>
                                <img class="business-common-process-img2"
                                    src="/assets/image/business/process-icon3.png" />
                            </div>
                            <div class="business-common-process-text">
                                <div class="business-common-process3"
                                    [innerHTML]="'ForBusiness.ProcessTitle3'|translate">견적 및 스캔 서비스
                                </div>
                                <div class="business-common-process-desc"
                                    [innerHTML]="'ForBusiness.ProcessDesc3'|translate">업계 최저가 수준의
                                    견적을 받아보실 수
                                    있습니다.
                                </div>
                            </div>
                        </div>

                    </article>
                </section>
            </div>
        </div>
    </div>

    <div class="business-common-gallery-container">
        <div class="business-common-gallery-outer">
            <div class="business-common-gallery-inner">
                <div class="business-common-gallery-img-wrap"
                    *ngFor="let portfolio of portfolioList" (mouseenter)="portfolio.hover=true"
                    (mouseleave)="portfolio.hover=false">
                    <a [href]="'https://merror.tistory.com/' + portfolio.address" target="_blank">
                        <img
                            [src]="'/assets/image/business/portfolio/portfolio' + (portfolio.order) + '-' +(portfolio.hover? '2' : '1') +'.png'" />
                        <div class="business-common-gallery-desc"
                            [style.display]="portfolio.hover? 'flex': 'none'">
                            <div style="padding: 0 8px;">
                                <div class="business-common-gallery-desc1"
                                    [innerHTML]="portfolio.projectinfo[0]|translate"></div>
                                <div class="business-common-gallery-desc2"
                                    [innerHTML]="portfolio.projectinfo[1]|translate"></div>
                            </div>

                        </div>
                    </a>

                </div>
            </div>
        </div>
    </div>

    <div class="business-common-whymerror-container2">
        <div class="business-common-whymerror-outer">
            <div class="business-common-whymerror-inner">
                <div class="business-common-title-text1">Why ME.RROR?</div>
                <div class="business-common-whymerror-content-outer">
                    <ul class="business-common-whymerror-item">
                        <li *ngFor="let item of whymerrorinfo;let index = index"
                            class="business-common-whymerror-icon-wrap">
                            <img
                                [src]="'/assets/image/business/industrial-whymerror' + (index+1) + '.png'" />
                            <div class="business-common-whymerror-icon-title"
                                [innerHTML]="item.title|translate">{{item.title}}
                            </div>
                            <div class="business-common-whymerror-icon-desc"
                                [innerHTML]="item.desc|translate">{{item.desc}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>