<div class="facial-container">
    <div class="facial-banner-container">
        <div class="facial-banner-outer">
            <div class="facial-banner-inner">
                <div class="facial-banner-contents-wrap">
                    <div class="facial-banner-contents">
                        <h1 class="facial-banner-header"
                            [innerHTML]="'Sidebar.FaceKorea'|translate">
                            Faces of Korea</h1>
                        <p class="facial-banner-paragraph"
                            [innerHTML]="'Facial.TitleDesc'|translate">
                            Enrich your projects with ME.RROR's premium Korean<br>facial assets.
                        </p>
                        <button class="facial-banner-button common-button1"
                            [innerHTML]="'Facial.FreeTrial'|translate"
                            (click)="scrollToFreeTrial()">Free Trial</button>
                    </div>
                </div>
            </div>

            <div class="facial-banner-animation-container">
                <div class="facial-banner-animation-outer">
                    <div class="facial-banner-animation facial-banner-animation{{index+1}}"
                        *ngFor="let animation of animationList; let index=index">
                        <div class="facial-banner-animation-inner">
                            <ul class="facial-banner-animation-wrapper">
                                <li class="facial-banner-img {{animation}}"
                                    *ngFor="let i of [1, 2, 3, 4, 5, 6]; let order = index">
                                    <img src="/assets/image/face/{{animation+i}}.png" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="facial-whymerror-container">
        <div class="facial-whymerror-outer">
            <div class="facial-whymerror-inner">
                <div class="facial-whymerror-contents-wrap">
                    <div class="facial-whymerror-tablet-wrap">
                        <div class="facial-whymerror-title">
                            <div>WHY ME.RROR?</div>
                            <div [innerHTML]="'Facial.WhyMerrorDesc'|translate">ME.RROR의 얼굴 에셋이 특별한
                                이유</div>
                        </div>
                        <div class="facial-whymerror-tablet">
                            <span class="facial-whymerror-order">01</span>
                            <div class="facial-whymerror-inform">
                                <div>
                                    <h3 class="facial-whymerror-inform-title"
                                        [innerHTML]="'Facial.WhyMerrorTitle1'|translate">첨단 기술력과
                                        고해상도 데이터</h3>
                                    <div [innerHTML]="'Facial.WhyMerror1Content1'|translate">최첨단
                                    </div>
                                    <div class="facial-whymerror-paragraph"
                                        [innerHTML]="'Facial.WhyMerror1Content2'|translate"></div>
                                </div>
                                <div class="facial-whymerror-img">
                                    <img src="/assets/image/face/whymerror-face1.png" alt="">
                                </div>


                            </div>

                        </div>
                    </div>


                    <ul class="facial-whymerror-contents">
                        <li class="facial-whymerror-desktop">
                            <span class="facial-whymerror-order">01</span>
                            <div class="facial-whymerror-inform">
                                <div>
                                    <h3 class="facial-whymerror-inform-title"
                                        [innerHTML]="'Facial.WhyMerrorTitle1'|translate">첨단 기술력과
                                        고해상도 데이터</h3>
                                    <div [innerHTML]="'Facial.WhyMerror1Content1'|translate"></div>
                                    <div class="facial-why-merror-paragraph"
                                        [innerHTML]="'Facial.WhyMerror1Content2'|translate"></div>
                                </div>
                                <div class="facial-whymerror-img">
                                    <img src="/assets/image/face/whymerror-face1.png" alt="">
                                </div>


                            </div>
                        </li>
                        <li>
                            <span class="facial-whymerror-order">02</span>
                            <div class="facial-whymerror-inform">
                                <div>
                                    <h3 class="facial-whymerror-inform-title"
                                        [innerHTML]="'Facial.WhyMerrorTitle2'|translate">유연성과 호환성
                                    </h3>
                                    <div [innerHTML]="'Facial.WhyMerror2Content1'|translate"></div>
                                    <div class="facial-why-merror-paragraph"
                                        [innerHTML]="'Facial.WhyMerror2Content2'|translate"></div>
                                </div>
                                <div class="facial-whymerror-img">
                                    <img src="/assets/image/face/whymerror-face2.png" alt="">
                                </div>

                            </div>
                        </li>
                        <li>
                            <span class="facial-whymerror-order">03</span>
                            <div class="facial-whymerror-inform">
                                <div>
                                    <h3 class="facial-whymerror-inform-title"
                                        [innerHTML]="'Facial.WhyMerrorTitle3'|translate">독특하고 다양한
                                        캐릭터 생성</h3>
                                    <div [innerHTML]="'Facial.WhyMerror3Content1'|translate"></div>
                                    <div class="facial-why-merror-paragraph"
                                        [innerHTML]="'Facial.WhyMerror3Content2'|translate"></div>
                                </div>
                                <div class="facial-whymerror-img">
                                    <img src="/assets/image/face/whymerror-face3.png" alt="">
                                </div>


                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="facial-freetrial-container" #freetrial>
        <div class="facial-freetrial-top-background">
            <div class="facial-freetrial-title-outer">
                <div class="facial-freetrial-title-inner">
                    <div class="facial-freetrial-title">
                        <h1 [innerHTML]="'Facial.FreeTrialTitle'|translate">Free Trial</h1>
                        <p [innerHTML]="'Facial.FreeTrialDesc'|translate">Experience ME.RROR's
                            high-quality facial assets yourself!</p>
                    </div>

                </div>
            </div>
        </div>

        <div class="facial-freetrial-bottom-background">
            <div class="facial-freetrial-bottom-outer">
                <div class="facial-freetrial-bottom-inner">
                    <div class="facial-freetrial-img-wrap">
                        <div>
                            <img class="facial-freetrial-img" (click)="openPopup(393)"
                                src="/assets/image/face/freetrial-face1.png" alt="">
                            <div>{{lang === 'ko'? '페이스 12': 'Face 12'}}</div>
                        </div>
                        <div>
                            <img class="facial-freetrial-img" (click)="openPopup(386)"
                                src="/assets/image/face/freetrial-face2.png" alt="">
                            <div>{{lang === 'ko'? '페이스 5': 'Face 5'}}</div>
                        </div>

                    </div>
                    <div class="facial-freetrial-banner" routerLink="/business/3dscanstudio">
                        <div class="faical-freetrial-banner-backtext">FAC<span>IAL</span>
                        </div>
                        <div class="facial-freetrial-banner-contents">
                            <div [innerHTML]="'Facial.BannerTitle'|translate">맞춤형 캐릭터, ME.RROR와 함께
                                시작하세요!</div>
                            <div [innerHTML]="'Facial.BannerDesc'|translate">ME.RROR의 고품질 3D 얼굴 스캔
                                서비스로 여러분만의 맞춤형 캐릭터를 완성하세요.</div>
                        </div>
                        <div class="faical-freetrial-banner-background">
                            <div class="facial-freetrial-banner-img1">
                                <img src="/assets/image/face/banner-face1.png" alt="">
                            </div>
                            <div class="facial-freetrial-banner-img2">
                                <img src="/assets/image/face/banner-face2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="facial-asset-container">
        <div class="facial-asset-outer">
            <div class="facial-asset-inner">
                <div id="faceAsset_list">
                    <div class="facial-asset-title" [innerHTML]="'Facial.AllFacialAsset'|translate">
                        All Facial Assets</div>
                    <div
                        [ngClass]="mobileFilter ? 'assets-mobile-filter-bar' : 'assets-filter-bar'">
                        <app-face-korea-filter style="width: 100%;height: 100%;"
                            [categoryType]="'human'" (openFilter)="openFilter($event)"
                            (updateFlag)="updateFlag($event)"
                            [searchcondition]="searchcondition"></app-face-korea-filter>
                    </div>
                    <div class="face-item-container">
                        <div *ngFor="let model of assetlist" style="width:100%">
                            <app-index-item [asset]="model" [ApiUrl]="ApiUrl">
                            </app-index-item>
                        </div>
                    </div>

                    <div class="item-empty-page common-vertical-alignment"
                        *ngIf="totalItems === 0 && this.initialLoad">
                        <img src="/assets/image/empty/reset_filter.png" />
                        <span *ngIf="filterEmpty" class="item-empty-phrase"
                            [innerHTML]="'Message.ResetFilter'|translate">필터를
                            재설정 하여 주시기 바랍니다.</span>
                        <span *ngIf="searchEmpty" class="item-empty-phrase"
                            [innerHTML]="'Message.NoSearchResults'|translate">검색
                            결과가 없습니다.</span>
                        <button class="common-button1" (click)="resetFilter()" *ngIf="filterEmpty"
                            [innerHTML]="'Facial.FilterClearAll'|translate">필터 모두 지우기</button>
                    </div>

                    <div>
                        <button class="common-button2 facial-more-button"
                            *ngIf="totalItems>assetlist.length"
                            [innerHTML]="'Common.SeeMore'|translate" (click)="load();">
                            더 보기
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>