<div class="modal-background reset-password-container">
    <app-popup-nav [title]="'Signup.ResetPassword'|translate" style="width: 100%"></app-popup-nav>
    <div class="password-popup-inner reset-password-popup-inner">
        <img class="password-popup-img" src="assets/icon/password_modify_account.svg">
        <h2 class="password-popup-title">
            <span [innerHTML]="'Signup.ResetPassword'|translate">비밀번호 재설정</span>
        </h2>
        <div class="reset-password-popup-notice" [innerHtml]="'Account.InputNewPassword'|translate">
            비밀번호를 재설정 하시겠습니까?</div>

        <div class="password-input-outer">
            <input #input_password_newpassword type="password"
                [placeholder]="'Common.NewPassword'|translate"
                (input)="onInput(inputPasswordNewRef)" maxlength="12" />
        </div>
        <div class="password-popup-input-msg" #password_length_msg [innerHTML]="newPasswordWrong">
            8자리 이상입력해 주세요.
        </div>

        <div class="password-input-outer">
            <input #input_password_passwordconfirm type="password"
                [placeholder]="'Common.PasswordConfirm'|translate"
                (input)="onInput(inputPasswordConfirmRef)" maxlength="12" />
        </div>
        <div class="password-popup-warn password-popup-input-msg"
            [innerHTML]="confirmPasswordNotMatch" #password_match_msg>
        </div>
        <div class="password-popup-button-wrap">
            <button class="common-button1" (click)="resetPassword()">
                <span [innerHTML]="('Signup.ModifyPassword'|translate) + '&nbsp;'">비밀번호 변경하기</span>
            </button>

            <button class="common-button2" [innerHTML]="'Account.GoToMainPage'|translate"
                (click)="close()">메인페이지로 이동
            </button>
        </div>
    </div>
    <div *ngIf="getResponse" class="modal-background-alert-popup">
        <div class="password-popup-inner modifyuser-popup-msg">
            <div [innerHTML]="message1"></div>
            <div [innerHTML]="message2"></div>
            <div class="common-button1" [innerHTML]="'Receipt.Close'|translate" (click)="close()">닫기
            </div>
        </div>
    </div>
</div>