import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AdminClientService } from 'src/app/lib/api-client.service';
import { ThreeCanvas } from 'src/app/lib/threecanvas';
import { AssetFileInfo } from 'src/server/lib/assetutil';
import { StoreCategoryItem, StoreInfoItem } from 'src/server/lib/storeinfo';
import { Data, ProductInfo, SearchTextScore } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';

const ansiHTML = require('ansi-html');

type CacheAssetList = {
    [key in Data.CurrencyType]: Data.Asset[];
};
type PopupType = 'consolelog' | 'productinfo' | 'updateproduct' | 'deleteproduct';
type ViewFilter_Price = 'All' | 'SpecialDeal' | 'NotDefaultPrice';
type ViewFilter_Type = 'All' | 'Human' | 'Object' | 'Space' | 'Posed' | 'APosed';
type ViewType = 'Icon' | 'List';
@Component({
    selector: 'app-admin-index',
    templateUrl: './admin-index.component.html',
    styleUrls: ['./admin-index.component.scss']
})
export class AdminIndexComponent implements OnInit {

    @ViewChild('popup') popup!: ElementRef<HTMLDivElement>;
    @ViewChild('consolelog') consolelog!: ElementRef<HTMLDialogElement>;
    @ViewChild('productinfo') productinfo!: ElementRef<HTMLDivElement>;
    @ViewChild('updateproduct') updateproduct!: ElementRef<HTMLDivElement>;
    @ViewChild('button_ok') button_ok!: ElementRef<HTMLDivElement>;
    @ViewChild('button_cancel') button_cancel!: ElementRef<HTMLDivElement>;
    @ViewChild('thumbnail') thumbnail!: ElementRef<HTMLSpanElement>;
    @ViewChild('zip') zip!: ElementRef<HTMLSpanElement>;
    @ViewChild('update_done') update_done!: ElementRef<HTMLSpanElement>;

    private popupok: null | (() => Promise<void>) = null;
    formatDate = CommonUtil.getLocalDatetimeString;

    threeviewer: ThreeCanvas;
    canvas_wireframe = false;
    canvas_background = false;
    canvas_logo = false;
    showItemDetail = false;

    waiting = false;
    assetfilelist: AssetFileInfo[] = [];
    newassetlist: AssetFileInfo[] = [];
    assetfileinfo: AssetFileInfo | null = null;
    taglist: SearchTextScore[] = [];
    storeinfolist: StoreInfoItem[] = [];
    storecategorylist: StoreCategoryItem[] = [];

    assetlist_all: CacheAssetList = { KRW: [], USD: [] };
    assetlist_specialdeal: CacheAssetList = { KRW: [], USD: [] };
    assetlist_notdefaultprice: CacheAssetList = { KRW: [], USD: [] };
    assetlist_view: CacheAssetList = { KRW: [], USD: [] };
    assetlist_viewcount = 0;
    asset_krw: Data.Asset | null = null;
    asset_usd: Data.Asset | null = null;
    asset_tags: string[] = [];
    asset_specialdeal = false;
    asset_defaultprice: { [key in Data.AssetOptionType]?: boolean } = {};
    tmp_tag = '';
    asset_option_keys: Data.AssetOptionType[] = [];
    asset_options: { [key in Data.CurrencyType]: Data.AssetOption; }[] = [];
    ESCList = Object.values(Data.ESearchCondition) as string[];
    viewfilter_price: ViewFilter_Price = 'All';
    viewfilter_type: ViewFilter_Type = 'All';
    viewtype: ViewType = 'Icon';

    tags_not_removed: string[] = ['사람', '사물', '공간', 'human', 'space', 'object'];
    selectCategory: 'Human' | 'Object' | 'Space' = 'Human';
    selectCategoryHumanSex: 'Male' | 'Female' = 'Male';
    selectCategoryHumanAge: 'Age_0' | 'Age_10' | 'Age_20' | 'Age_30' | 'Age_40_50' | 'Age_60_' = 'Age_20';
    selectCategoryHumanType: 'Type_Posed' | 'Type_APosed' | 'Type_Rigged' | 'Type_Facial' = 'Type_Posed';

    subCategoryList: { en: string, kr: string }[] = [
        { en: 'food', kr: '음식' },
        { en: 'clothings', kr: '의류' },
        { en: 'outer', kr: '아우터' },
        { en: 'top', kr: '상의' },
        { en: 'bottom', kr: '하의' },
        { en: 'footwear', kr: '신발' },
        { en: 'accessories', kr: '악세서리/잡화' },
        { en: 'hanbok', kr: '한복' },
        { en: 'nature', kr: '자연물' },
        { en: 'electronics', kr: '전자제품' },
        { en: 'interior', kr: '인테리어' },
        { en: 'exterior', kr: '외부' },
        { en: 'oriental/traditional', kr: '동양/전통' },
        { en: 'toys/hobby', kr: '완구/취미' },
        { en: 'others', kr: '기타' }]
    showSubCategory = false;

    viewerBGColorList = ['black', '#888', 'white'];
    viewerBGColorIndex = 2;

    progressValue = 0;
    progressMaxValue = 0;
    private timer = -1;

    incompleteTH = 0;
    incompleteZip = 0;

    CurrencyType = Data.CurrencyTypes;

    private canvasflag = false;

    constructor(
        private admin: AdminClientService,
    ) {
        this.threeviewer = new ThreeCanvas();
    }

    ngOnInit(): void {
        this.refresh();
        console.log('뭔지모르겟어??', this.ESCList)
    }

    async load() {
        const popup = this.popup.nativeElement;
        popup.style.opacity = '1';
        popup.style.pointerEvents = 'auto';

        this.setPopup('consolelog');
        //만들어야할TH 수를 구하기위해


        await this.checkAssetSource();
        await this.checkAssetFiles();

        await this.updateAssetFiles();

        await this.refresh();
    }

    async refresh() {
        //refresh되고있는동안 업데이트 확인 누르지못하도록
        const updateButton = document.getElementsByClassName('admin-index-refresh-button')[1] as HTMLDivElement;
        updateButton.style.pointerEvents = 'none';

        const assetno = this.asset_krw === null ? 0 : this.asset_krw.assetno;
        this.assetfilelist.length = 0;
        this.newassetlist.length = 0;
        this.assetfileinfo = null;
        this.assetlist_all = { KRW: [], USD: [] };
        this.asset_krw = null;
        this.asset_usd = null;
        this.asset_tags.length = 0;
        this.asset_option_keys.length = 0;
        this.asset_options = [];
        //await this.getStoreInfo();
        await this.getAssetCache();
        //await this.getAssetFileInfo();
        await this.getAssetAdminInfo();

        //데이터를 받아오고서는 value의 max를 설정할 수 있게 변수에 담아준 후 , 포인터이벤트를 활성화 시킨다.
        this.progressMaxValue = Number(this.assetfilelist.length);
        updateButton.style.pointerEvents = 'auto';

        if (assetno > 0) {
            let index = this.assetlist_all.KRW.findIndex(item => item.assetno === assetno);
            if (index > -1) {
                this.canvasflag = false;
                await this.showAsset(this.assetlist_all.KRW[index]);
            } else {
                index = this.newassetlist.findIndex(item => item.assetno === assetno);
                if (index > -1) {
                    this.canvasflag = false;
                    await this.showNewAsset(index);
                }
            }
        }
    }

    async onPopupOk() {
        if (this.popupok !== null) {
            await this.popupok();
            this.popupok = null;
        }
        this.closePopup();
    }
    setPopup(type: PopupType, callback: null | (() => Promise<void>) = null) {
        this.popupok = callback;
        const popup = this.popup.nativeElement;
        popup.style.opacity = '1';
        popup.style.pointerEvents = 'auto';

        const consolelog = this.consolelog.nativeElement;
        const productinfo = this.productinfo.nativeElement;
        const updateproduct = this.updateproduct.nativeElement;

        const button_ok = this.button_ok.nativeElement
        const button_cancel = this.button_cancel.nativeElement;

        if (type === 'consolelog') {
            consolelog.style.display = 'flex';
            productinfo.style.display = 'none';
            updateproduct.style.display = 'none';
            button_cancel.style.display = 'none';
            button_ok.style.display = 'none';

        } else if (type === 'productinfo') {
            consolelog.style.display = 'none';
            productinfo.style.display = 'block';
            updateproduct.style.display = 'none';
            button_cancel.style.display = 'none';

        } else if (type === 'updateproduct') {
            button_cancel.style.display = 'block';
            consolelog.style.display = 'none';
            productinfo.style.display = 'none';
            updateproduct.style.display = 'block';
            updateproduct.innerHTML = '등록/업데이트 하시겠습니까?';

        } else if (type === 'deleteproduct') {
            button_cancel.style.display = 'block';
            consolelog.style.display = 'none';
            productinfo.style.display = 'none';
            updateproduct.style.display = 'block';
            updateproduct.innerHTML = '삭제하시겠습니까?';
        }
    }
    closePopup() {
        const popup = this.popup.nativeElement;
        this.consolelog.nativeElement.style.display = 'none';
        this.productinfo.nativeElement.style.display = 'none';
        popup.style.opacity = '0';
        popup.style.pointerEvents = 'none';
        this.update_done.nativeElement.style.opacity = '0';
        this.progressValue = 0;
        this.thumbnail.nativeElement.style.opacity = '0';
        this.zip.nativeElement.style.opacity = '0';

    }

    private adminClientCallback = (data: string) => {

        const trimString = data.trim();
        if (trimString.startsWith('for model file')) {
            const textString = trimString.substring(0, trimString.indexOf('-'));
            const regex = /[^0-9]/g;
            const result = textString.replace(regex, "").slice(2);
            this.drawProgressiveBar(result);
        } else if (trimString.startsWith('making thumbnail')) {
            this.thumbnail.nativeElement.style.opacity = '1';
            this.drawProgressiveBar();
            this.incompleteTH--;
            if (this.incompleteTH === 0) this.thumbnail.nativeElement.innerHTML = '썸네일 생성 완료'
        } else if (trimString.startsWith('making zip')) {
            this.zip.nativeElement.style.opacity = '1';
            this.drawProgressiveBar();
            this.incompleteZip--;
            if (this.incompleteZip === 0) this.zip.nativeElement.innerHTML = 'zip파일 생성 완료'
        }
    }

    private getimcompleteList = (data: string) => {
        //zip이 없을시에 thumb이 존재해도 thumb을 항상 새롭게 만들어준다. zip이 3번개라면 3개에대한 thumb은 TH, TH-OG 두번만 만든다. 
        let checkForThumb = 0;

        //[TH][TH-OG]만 나오게 자른값
        const THCheckData = data.substring(data.indexOf('TH') - 7, data.indexOf('TH-OG') + 11);
        //[SP: M|D1|G|Z]이부분부터 나오게끔 자른값
        const ZipCheckData = data.substring(data.indexOf('TH-OG') + 12);


        for (let i = 0; i < ZipCheckData.length; i++) {
            if (ZipCheckData[i] === 'Z') {
                if ((ZipCheckData.substring((i - 3), (i - 1))) === '31') {
                    this.incompleteZip++
                    checkForThumb++
                }
            }
        }

        if (checkForThumb !== 0) this.incompleteTH = this.incompleteTH + 2

        if (THCheckData.substring(THCheckData.indexOf('TH') - 3, THCheckData.indexOf('TH') - 1) === '31') {
            //TH가 없지만 만약 Zip에서 만들어지면 중복으로 체크가되므로 zip에 들어가지 않을시에만 만들어준다.
            if (checkForThumb === 0) this.incompleteTH++;
        }

        if (THCheckData.substring(THCheckData.indexOf('TH-OG') - 3, THCheckData.indexOf('TH-OG') - 1) === '31') {
            if (checkForThumb === 0) this.incompleteTH++;
        }
    }

    private drawProgressiveBar = (data?: string) => {

        if (data !== undefined) this.progressValue = Number(data);

        this.timer = window.setTimeout(() => {
            if (this.progressValue >= this.progressMaxValue && this.incompleteZip === 0 && this.incompleteTH === 0) {
                this.button_ok.nativeElement.style.display = 'block';
                this.update_done.nativeElement.style.opacity = '1';
            }
        }, 5000);
    }

    async checkAssetSource() {
        if (this.waiting) return;
        this.waiting = true;
        await this.admin.send({
            command: 'CheckAssetSource',
            data: { from: 1, to: 99999999 }
        }, this.adminClientCallback);
        this.waiting = false;
    }

    async checkAssetFiles() {
        if (this.waiting) return;
        this.waiting = true;
        this.incompleteTH = 0;
        this.incompleteZip = 0;
        await this.admin.send({ command: 'CheckAssetFiles' }, this.getimcompleteList);
        this.waiting = false;
    }

    async updateAssetFiles() {
        if (this.waiting) return;
        this.waiting = true;
        await this.admin.send(
            { command: 'UpdateAssetFiles', data: { from: 1, to: 99999999 } },
            this.adminClientCallback
        );
        this.waiting = false;
    }

    async getAssetFileInfo() {
        if (this.waiting) return;
        this.waiting = true;
        await this.admin.send(
            { command: 'GetAssetFilesInfo', data: { from: 1, to: 99999999 } },
            (result: AssetFileInfo[]) => {
                this.assetfilelist = result;
                this.assetfilelist.sort((a, b) => b.assetno - a.assetno);
                this.newassetlist.length = 0;
                for (const info of result) {
                    if (this.assetlist_all.KRW.findIndex(value => value.assetno === info.assetno) === -1) {
                        this.newassetlist.push(info);
                    }
                }
            }
        );
        this.waiting = false;
    }

    async getStoreInfo() {
        if (this.waiting) return;
        this.waiting = true;
        await this.admin.send({ command: 'GetStoreInfo' },
            (result: { storeinfo: StoreInfoItem[]; categoryinfo: StoreCategoryItem[]; }) => {
                this.storeinfolist = result.storeinfo;
                this.storecategorylist = result.categoryinfo;
                console.log(result);
            }
        );
        this.waiting = false;
    }

    async getAssetAdminInfo() {
        await this.admin.send({ command: 'GetAssetAdminInfo' }, (result: SearchTextScore[]) => {
            this.taglist = result;
            console.log(result);
        });
    }

    async getAssetCache() {
        if (this.waiting) return;
        this.waiting = true;
        await this.admin.send({ command: 'GetAssetCache' }, (result: CacheAssetList) => {
            this.assetlist_all = result;
            this.assetlist_all.KRW.sort((a, b) => b.assetno - a.assetno);
            this.assetlist_all.USD.sort((a, b) => b.assetno - a.assetno);
            this.assetlist_specialdeal.KRW.length = 0;
            this.assetlist_specialdeal.USD.length = 0;
            this.assetlist_notdefaultprice.KRW.length = 0;
            this.assetlist_notdefaultprice.USD.length = 0;

            for (let i = 0; i < this.assetlist_all.KRW.length; i++) {
                const asset_krw = this.assetlist_all.KRW[i];
                const asset_usd = this.assetlist_all.USD[i];
                if (this.assetlist_all.KRW[i].tag.includes('_specialdeal')) {
                    this.assetlist_specialdeal.KRW.push(asset_krw);
                    this.assetlist_specialdeal.USD.push(asset_usd);
                }

                let flag = false;
                for (const optiontype of Data.AssetOptionTypes) {
                    const op_krw = asset_krw.options[optiontype];
                    const op_usd = asset_usd.options[optiontype];
                    // TODO :: 100원딜은 제외하도록!!!!!!
                    if (op_krw !== undefined && asset_krw.tag.includes('_specialdeal') == false
                        && (op_krw.itemprice !== Data.DefaultPriceList[optiontype].KRW
                            || op_krw.itemdcprice !== Data.DefaultPriceList[optiontype].KRW)) {
                        flag = true;
                        break;
                    }
                    if (op_usd !== undefined && asset_usd.tag.includes('_specialdeal') == false
                        && (op_usd.itemprice !== Data.DefaultPriceList[optiontype].USD
                            || op_usd.itemdcprice !== Data.DefaultPriceList[optiontype].USD)) {
                        flag = true;
                        break;
                    }
                }
                if (flag) {
                    this.assetlist_notdefaultprice.KRW.push(this.assetlist_all.KRW[i]);
                    this.assetlist_notdefaultprice.USD.push(this.assetlist_all.USD[i]);
                }
            }
        });
        this.waiting = false;

        this.setViewFilter(null, null, true);

    }
    setViewFilter(priceflag: ViewFilter_Price | null, typeflag: ViewFilter_Type | null, force = false) {
        if (priceflag === null) priceflag = this.viewfilter_price;
        if (typeflag === null) typeflag = this.viewfilter_type;
        if (force === false && this.viewfilter_price === priceflag
            && this.viewfilter_type === typeflag) return;

        const div = document.getElementsByClassName('admin-index-itemlist-inner')[0] as HTMLDivElement;
        if (div !== undefined) (div.removeAllListeners as any)('scroll');

        const clientwidth = window.innerWidth;
        const columns = Math.floor((clientwidth - 20 * 2 - 10 * 2 - 16) / 136);
        this.assetlist_view.KRW.length = 0;
        this.assetlist_view.USD.length = 0;

        this.viewfilter_price = priceflag;
        this.viewfilter_type = typeflag;
        let assetlist: CacheAssetList;


        if (priceflag === 'NotDefaultPrice') {
            assetlist = this.assetlist_notdefaultprice;

        } else if (priceflag === 'SpecialDeal') {
            assetlist = this.assetlist_specialdeal;

        } else {
            assetlist = this.assetlist_all;
        }

        const checkTypeFlag = (asset: Data.Asset): boolean => {
            if (this.viewfilter_type === 'Posed') return asset.tag.includes('Posed');
            if (this.viewfilter_type === 'APosed') return asset.tag.includes('A-Posed');
            if (this.viewfilter_type === 'Human') return asset.tag.includes('사람');
            if (this.viewfilter_type === 'Object') return asset.tag.includes('사물');
            if (this.viewfilter_type === 'Space') return asset.tag.includes('공간');
            return true;
        }

        this.assetlist_viewcount = columns * 5;
        let i = 0;
        while (i < assetlist.KRW.length && this.assetlist_view.KRW.length < this.assetlist_viewcount) {
            if (assetlist.KRW[i] !== undefined && checkTypeFlag(assetlist.KRW[i])) {
                this.assetlist_view.KRW.push(assetlist.KRW[i]);
                this.assetlist_view.USD.push(assetlist.USD[i]);
            }
            i++;
        }
        setTimeout(() => {
            const div = document.getElementsByClassName('admin-index-itemlist-inner')[0] as HTMLDivElement;
            if (div === undefined) return;
            (div.removeAllListeners as any)('scroll');
            div.addEventListener('scroll', event => {
                const div = event.target as HTMLDivElement;

                if (div.scrollHeight - div.clientHeight - div.scrollTop < 100) {
                    const clientwidth = window.innerWidth;
                    const columns = Math.floor((clientwidth - 20 * 2 - 10 * 2 - 16) / 136);
                    const count = this.assetlist_view.KRW.length;

                    let i = 0;
                    while (i < assetlist.KRW.length && this.assetlist_view.KRW.length < count + columns * 2) {
                        if (assetlist.KRW[i] !== undefined
                            && this.assetlist_view.KRW.findIndex(item => item.assetno === assetlist.KRW[i].assetno) === -1
                            && checkTypeFlag(assetlist.KRW[i])) {
                            this.assetlist_view.KRW.push(assetlist.KRW[i]);
                            this.assetlist_view.USD.push(assetlist.USD[i]);
                        }
                        i++;
                    }
                }
            });
        }, 1);
    }

    setViewType(type: ViewType) {
        if (this.viewtype === type) return;
        this.viewtype = type;

        this.setViewFilter(null, null, true);
    }

    async updateProduct() {
        this.setPopup('updateproduct', this._updateProduct);
    }
    private async _updateProduct() {
        if (this.waiting || this.asset_krw === null || this.asset_usd === null) return;
        this.waiting = true;
        this.updateproduct.nativeElement.innerHTML = '업데이트 중입니다.';
        const productinfo: ProductInfo = {
            assetno: this.asset_krw.assetno,
            name: {
                KRW: this.asset_krw.name,
                USD: this.asset_usd.name
            },
            vieweridinfo: {},
            priceinfo: {},
            tags: [...this.asset_tags]
        }

        for (const asset of [this.asset_krw, this.asset_usd]) {
            for (const optiontype of this.asset_option_keys) {
                const option = asset.options[optiontype] as Data.AssetOption;
                const option_new = this.asset_options.find(item => item[asset.currency].type === option.type);
                if (option_new !== undefined) {
                    const v1 = productinfo.vieweridinfo[option.type];
                    const v2 = option_new[asset.currency].info.viewerid;
                    if ((v1 === undefined || v1.length == 0) && v2 !== undefined && v2.length > 0) {
                        productinfo.vieweridinfo[option.type] = option_new[asset.currency].info.viewerid;
                    }
                    let priceinfo = productinfo.priceinfo[option.type];
                    if (priceinfo === undefined) {
                        priceinfo = {
                            KRW: { originprice: 0, dcprice: 0 },
                            USD: { originprice: 0, dcprice: 0 },
                        };
                        productinfo.priceinfo[option.type] = priceinfo;
                    }
                    priceinfo[asset.currency].originprice = option_new[asset.currency].itemprice;
                    priceinfo[asset.currency].dcprice = option_new[asset.currency].itemdcprice;
                }
            }
        }

        await this.admin.send({
            command: 'UpdateProduct',
            data: { productinfo, specialdeal: this.asset_specialdeal }
        },
            (result: boolean) => { console.log('update result : ' + result); });
        this.waiting = false;
        this.refresh();
    }

    async deleteProduct(assetno: number) {
        this.setPopup('deleteproduct', async () => { await this._deleteProduct(assetno); })
    }
    private async _deleteProduct(assetno: number) {
        if (this.waiting || this.asset_krw === null || this.asset_usd === null) return;
        this.waiting = true;
        this.updateproduct.nativeElement.innerHTML = '삭제하는 중입니다.';
        await this.admin.send({ command: 'DeleteProduct', data: { assetno } },
            result => console.log('deleteProduct : ', result));
        this.waiting = false;
        this.refresh();
    }

    async showAsset(asset_krw: Data.Asset, asset_usd?: Data.Asset) {
        this.asset_krw = asset_krw;
        this.asset_usd = asset_usd !== undefined ? asset_usd
            : this.assetlist_all.USD.find(value => value.assetno === asset_krw.assetno) as Data.Asset;
        this.asset_tags.length = 0;
        for (const t of asset_krw.tag) {
            if (this.asset_tags.includes(t) === false) this.asset_tags.push(t);
        }
        this.asset_options.length = 0;
        this.asset_option_keys.length = 0;
        this.asset_defaultprice = {};
        for (const optiontype of Data.AssetOptionTypes) {
            this.asset_defaultprice[optiontype] = true;
            const option_krw = this.asset_krw.options[optiontype];
            const option_usd = this.asset_usd.options[optiontype];
            if (option_krw !== undefined) {
                this.asset_option_keys.push(optiontype);
                this.asset_options.push({
                    KRW: { ...option_krw } as Data.AssetOption,
                    USD: { ...option_usd } as Data.AssetOption
                });
                if (option_krw.itemprice !== Data.DefaultPriceList[optiontype]['KRW']
                    || option_usd?.itemprice !== Data.DefaultPriceList[optiontype]['USD']) {
                    this.asset_defaultprice[optiontype] = false;
                }
            }
        }

        if (asset_krw.flag & Data.ESearchCondition.Type_Object) this.selectCategory = 'Object'
        else if (asset_krw.flag & Data.ESearchCondition.Type_Space) this.selectCategory = 'Space'
        else {
            this.selectCategory = 'Human';
            if (asset_krw.flag & Data.ESearchCondition.Male) this.selectCategoryHumanSex = 'Male';
            else this.selectCategoryHumanSex = 'Female';

            if (asset_krw.flag & Data.ESearchCondition.Age_0) this.selectCategoryHumanAge = 'Age_0';
            else if (asset_krw.flag & Data.ESearchCondition.Age_10) this.selectCategoryHumanAge = 'Age_10';
            else if (asset_krw.flag & Data.ESearchCondition.Age_20) this.selectCategoryHumanAge = 'Age_20';
            else if (asset_krw.flag & Data.ESearchCondition.Age_30) this.selectCategoryHumanAge = 'Age_30';
            else if (asset_krw.flag & Data.ESearchCondition.Age_40_50) this.selectCategoryHumanAge = 'Age_40_50';
            else this.selectCategoryHumanAge = 'Age_60_';

            if (asset_krw.flag & Data.ESearchCondition.Type_Posed) this.selectCategoryHumanType = 'Type_Posed';
            else if (asset_krw.flag & Data.ESearchCondition.Type_APosed) this.selectCategoryHumanType = 'Type_APosed';
            else if (asset_krw.flag & Data.ESearchCondition.Type_Facial) this.selectCategoryHumanType = 'Type_Facial';
            else this.selectCategoryHumanType = 'Type_Rigged';
        }

        this.asset_specialdeal = this.asset_tags.includes('_specialdeal');

        setTimeout(() => { this.setViewer(); }, 1);

        document.body.style.overflowY = 'hidden';
        this.showItemDetail = true;

        console.log(this.asset_krw, this.asset_usd);
    }

    onTagSubmit() {
        const tag = this.tmp_tag.replace(/^[\s]+/, '').replace(/[\s]+$/, '');
        if (tag.length > 0 && this.asset_tags.includes(tag) === false) this.asset_tags.push(tag);
        this.tmp_tag = '';
    }
    removeTag(tag: string) {
        if (this.tags_not_removed.includes(tag.trim())) return;
        const index = this.asset_tags.findIndex(value => value === tag);
        if (index !== -1) this.asset_tags.splice(index, 1);
    }

    checkFlag(asset: Data.Asset, flagstring: string): boolean {
        const flag = Data.ESearchCondition[flagstring as any] as any;
        return flag !== 0 && (asset.flag & flag) === flag;
    }
    getAssetType(asset: Data.Asset) {
        if (asset.flag & Data.ESearchCondition.Type_Posed) return 'Posed';
        else if (asset.flag & Data.ESearchCondition.Type_APosed) return 'APosed';
        else if (asset.flag & Data.ESearchCondition.Type_Facial) return 'Facial';
        else if (asset.flag & Data.ESearchCondition.Type_Rigged) return 'Rigged';
        else if (asset.flag & Data.ESearchCondition.Type_Object) return 'Object';
        else return 'Space';
    }

    async showNewAsset(index: number) {
        console.log(this.newassetlist[index]);
        const info = this.newassetlist[index];
        this.assetfileinfo = info;

        const asset_krw = info.asset_krw as Data.Asset;
        const asset_usd = info.asset_usd as Data.Asset;

        const sinfo = this.storeinfolist.find(item => item.assetno === asset_krw.assetno);
        console.log(sinfo);
        if (sinfo !== undefined) {
            asset_krw.name = sinfo.title_ko;
            asset_usd.name = sinfo.title_en;
            for (const tag of [...sinfo.tags_ko, ...sinfo.tags_en]) {
                if (tag.length > 0 && asset_krw.tag.includes(tag) === false)
                    (asset_krw.tag as string[]).push(tag);
            }
            const check = this.checkTagAndFlag(asset_krw.tag as string[], sinfo);
            asset_krw.tag = check.tags;
            asset_krw.flag = check.flag;
            asset_usd.tag = [...asset_krw.tag];
            asset_usd.flag = asset_krw.flag;

            for (const key of Data.AssetOptionTypes) {
                if (asset_krw.options[key] === undefined) continue;
                const sinfo_option = this.storeinfolist.find(
                    item => item.assetno === asset_krw.assetno && item.option === key);
                if (sinfo_option === undefined) continue;
                asset_krw.options[key]!.itemprice = sinfo_option.price_krw;
                asset_krw.options[key]!.itemdcprice = sinfo_option.dcprice_krw;
                asset_usd.options[key]!.itemprice = sinfo_option.price_usd;
                asset_usd.options[key]!.itemdcprice = sinfo_option.dcprice_usd;
            }
        }

        if (asset_krw.name === '') asset_krw.name = '이름을 정해주세요';
        if (asset_usd.name === '') asset_usd.name = 'Please set name';
        this.showAsset(asset_krw, asset_usd);
    }
    private checkTagAndFlag(tags: string[], sinfo: StoreInfoItem): { tags: string[], flag: number } {
        const resulttags: string[] = [];
        const resultflag = Data.ESearchCondition.None;
        const result = { tags: resulttags, flag: resultflag };

        const tmptags = tags.filter(tag => tag.trim().length > 0).map(tag => tag.toLowerCase());
        const ignoretag = [
            '사람', '사물', '공간', 'human', 'object', 'space',
            'posed', 'aposed', 'a-posed', 'rigged', 'facial',
            'man', 'woman', 'man3d', 'woman3d', 'male', 'female',
            'noai'
        ];
        ignoretag.forEach(itag => {
            const index = tmptags.indexOf(itag);
            if (index > -1) tmptags.splice(index, 1);
        });

        if (sinfo.category === 'human') {
            tmptags.push('사람', 'human');

            if (sinfo.category_filter === 'P') {
                tmptags.push('포즈드', 'posed');
                result.flag = Data.ESearchCondition.Type_Posed;
            } else if (sinfo.category_filter === 'AP') {
                tmptags.push('A-포즈드', 'a-posed');
                result.flag = Data.ESearchCondition.Type_APosed;
            } else if (sinfo.category_filter === 'F') {
                tmptags.push('페이스', 'facial');
                result.flag = Data.ESearchCondition.Type_Facial;
            }

            if (sinfo.gender === 'male') {
                tmptags.push('남성', 'male', 'man');
                result.flag |= Data.ESearchCondition.Male;
            } else {
                tmptags.push('여성', 'female', 'woman');
                result.flag |= Data.ESearchCondition.Female;
            }

            if (sinfo.ages < 10) {
                tmptags.push('10대미만', 'kid', '어린이');
                if (sinfo.gender === 'male') {
                    tmptags.push('남자아이', 'boy');
                } else {
                    tmptags.push('여자아이', 'girl');
                }
                result.flag |= Data.ESearchCondition.Age_0;

            } else if (sinfo.ages === 10) {
                tmptags.push('10대', '10s', 'child', '청소년');
                if (sinfo.gender === 'male') {
                    tmptags.push('남자아이', 'boy');
                } else {
                    tmptags.push('여자아이', 'girl');
                }
                result.flag |= Data.ESearchCondition.Age_10

            } else if (sinfo.ages >= 40 && sinfo.ages <= 50) {
                tmptags.push('' + sinfo.ages + '대', '' + sinfo.ages + 's', 'middleage', '중년');
                result.flag |= Data.ESearchCondition.Age_40_50;
            } else if (sinfo.ages >= 60) {
                tmptags.push('' + sinfo.ages + '대이상', 'old', '노인');
                result.flag |= Data.ESearchCondition.Age_60_;
            } else {
                tmptags.push('' + sinfo.ages + '대', '' + sinfo.ages + 's');
                if (sinfo.ages === 20) result.flag |= Data.ESearchCondition.Age_20;
                else if (sinfo.ages === 30) result.flag |= Data.ESearchCondition.Age_30;
            }

        } else if (sinfo.category === 'object') {
            tmptags.push('사물', 'object');
            result.flag = Data.ESearchCondition.Type_Object;
            const category = this.storecategorylist.find(item =>
                item.category === sinfo.category && item.filter === sinfo.category_filter);
            if (category !== undefined) {
                tmptags.push(category.name.en.toLowerCase(), category.name.ko);
            }
            if (category?.filter.startsWith('2')) tmptags.push('clothings', '의류');


        } else if (sinfo.category === 'space') {
            tmptags.push('공간', 'space');
            result.flag = Data.ESearchCondition.Type_Space;
            const category = this.storecategorylist.find(item =>
                item.category === sinfo.category && item.filter === sinfo.category_filter);
            if (category !== undefined) {
                tmptags.push(category.name.en.toLowerCase(), category.name.ko);
            }
        }
        result.tags = tmptags;
        return result;
    }

    async setViewer(optiontype?: Data.AssetOptionType) {
        if (this.canvasflag === false) {
            this.canvasflag = true;
            this.threeviewer.init(document.getElementById('threeviewer') as HTMLElement, 3);
            //this.threeviewer.setTransparentBackground(false);
            //this.threeviewer.setBackgroundColor('#ffffff');
        }
        if (this.asset_krw === null) return;
        const asset = this.asset_krw;
        const defaulttype = optiontype === undefined ? CommonUtil.getDefaultOptionType(asset) : optiontype;
        const defaultoption = asset.options[defaulttype] as Data.AssetOption;
        const texturelist = defaultoption.info.texture.maps;

        this.threeviewer.clear();
        this.canvas_background = false;
        this.canvas_logo = false;
        this.canvas_wireframe = false;
        await this.threeviewer.loadFBX(asset.assetno,
            `/admin/thumbnail/model/${asset.assetno}/${defaulttype}`,
            texturelist, texturelist.length === 0, true, defaulttype);
        if (asset.tag.includes('사람')) this.threeviewer.setCameraForHuman(1.6, false);
        this.threeviewer.setPreset();
    }

    makeProductInfo() {
        const productinfolist: ProductInfo[] = [];
        for (let i = 0; i < this.assetlist_all.KRW.length; i++) {
            const asset_krw = this.assetlist_all.KRW[i];
            const asset_usd = this.assetlist_all.USD[i];
            const tags: string[] = [];
            for (const tag of [...asset_krw.tag, ...asset_usd.tag]) {
                const t = tag.trim();
                if (t.length > 0 && tags.includes(t) === false) tags.push(tag.trim());
            }
            const productinfo: ProductInfo = {
                assetno: asset_krw.assetno,
                name: { KRW: asset_krw.name, USD: asset_usd.name },
                vieweridinfo: {},
                priceinfo: {},
                tags
            };
            for (const asset of [asset_krw, asset_usd]) {
                for (const optiontype of Data.AssetOptionTypes) {
                    const option = asset.options[optiontype];
                    if (option !== undefined) {
                        const v1 = productinfo.vieweridinfo[option.type];
                        const v2 = option.info.viewerid;
                        if ((v1 === undefined || v1.length == 0) && v2 !== undefined && v2.length > 0) {
                            productinfo.vieweridinfo[optiontype] = option.info.viewerid;
                        }
                        if (option.itemprice === Data.DefaultPriceList[optiontype][asset.currency]
                            && option.itemdcprice === Data.DefaultPriceList[optiontype][asset.currency])
                            break;
                        let priceinfo = productinfo.priceinfo[optiontype];
                        if (priceinfo === undefined) {
                            productinfo.priceinfo[optiontype] = priceinfo = {
                                KRW: { originprice: 0, dcprice: 0 },
                                USD: { originprice: 0, dcprice: 0 }
                            };
                        }
                        priceinfo[asset.currency].originprice = option.itemprice;
                        priceinfo[asset.currency].dcprice = option.itemdcprice;
                    }
                }
            }
            productinfolist.push(productinfo);
        }
        productinfolist.sort((a, b) => a.assetno - b.assetno);
        let i = 0;
        let json = '';
        for (const productinfo of productinfolist) {
            i++;
            // const text = `\t{ "assetno": ${productinfo.assetno}, `
            //     + `"name": { "KRW":"${productinfo.name.KRW}", "USD": "${productinfo.name.USD}" }, `
            //     + `"tags": [ "${productinfo.tags.join('", "')}" ] }${i < productinfolist.length ? ',' : ''}\n`;
            const text = `\t{ assetno: ${productinfo.assetno}, `
                + `name: { KRW:'${productinfo.name.KRW}', USD: '${productinfo.name.USD}' }, `
                + `priceinfo: ${JSON.stringify(productinfo.priceinfo)}, `
                + `tags: [ '${productinfo.tags.join("','")}' ] }${i < productinfolist.length ? ',' : ''}\n`;
            json += text;
        }

        this.productinfo.nativeElement.innerHTML = `[\n${json}\n]\n`;
        this.setPopup('productinfo');
    }

    toggleSpecialDeal() {
        if (this.asset_krw === null || this.asset_usd === null) return;

        const newflag = !this.asset_specialdeal;
        const index = this.asset_tags.findIndex(tag => tag === '_specialdeal');
        if (newflag) {
            let flag = false;
            for (const optiontype of this.asset_option_keys) {
                // 무료 에셋이 가능한 옵션이거나,
                // 얼굴인 경우
                if (Data.SpecialDealAvailableOption.includes(optiontype)
                    || (this.asset_krw.flag & Data.ESearchCondition.Type_Facial)) {
                    flag = true;
                    break;
                }
            }
            if (flag) {
                if (index === -1) this.asset_tags.push('_specialdeal');
                for (const option of this.asset_options) {
                    // 무료 에셋이 가능한 옵션이거나,
                    // 얼굴인 경우
                    if (Data.SpecialDealAvailableOption.includes(option.KRW.type)
                        || (this.asset_krw.flag & Data.ESearchCondition.Type_Facial)) {
                        option.KRW.itemdcprice = Data.SpecialDealPrice.KRW;
                        option.USD.itemdcprice = Data.SpecialDealPrice.USD;
                        break;
                    }
                }
            } else {
                return;
            }

        } else {
            if (index > -1) this.asset_tags.splice(index, 1);
            for (const option of this.asset_options) {
                const type = option.KRW.type;
                option.KRW.itemprice = Data.DefaultPriceList[type]['KRW'];
                option.KRW.itemdcprice = Data.DefaultPriceList[type]['KRW'];
                option.USD.itemprice = Data.DefaultPriceList[type]['USD'];
                option.USD.itemdcprice = Data.DefaultPriceList[type]['USD'];
            }
        }
        this.asset_specialdeal = newflag;
    }
    toggleDefaultPrice(type: Data.AssetOptionType) {
        if (this.asset_krw === null || this.asset_usd === null
            || this.asset_defaultprice[type] === undefined) return;

        const newflag = !this.asset_defaultprice[type];
        if (newflag) {
            const option = this.asset_options.find(option => option.KRW.type === type);
            if (option !== undefined) {
                option.KRW.itemprice = Data.DefaultPriceList[type]['KRW'];
                option.KRW.itemdcprice = Data.DefaultPriceList[type]['KRW'];
                option.USD.itemprice = Data.DefaultPriceList[type]['USD'];
                option.USD.itemdcprice = Data.DefaultPriceList[type]['USD'];
            }
        }

        this.asset_defaultprice[type] = newflag;
    }

    setDefaultCamera() {
        if (this.asset_krw === null) return;
        if (this.asset_krw.tag.includes('사람')) this.threeviewer.setCameraForHuman(1.6, false);
        else this.threeviewer.setCamera();

    }

    async capture(width = 4096, height = 4096, tag: string = '') {
        if (this.asset_krw === null) return;
        const viewer = document.getElementById('threeviewer') as HTMLDivElement;
        viewer.style.opacity = '0';
        const loading = document.getElementsByClassName('admin-index-viewer-message')[0] as HTMLDivElement;
        const loadingtimer = setInterval(() => {
            loading.style.color = 'forestgreen';
            setTimeout(() => {
                loading.style.color = 'darkseagreen';

            }, 200);
        }, 400);
        await new Promise<void>((resolve) => setTimeout(resolve, 500));
        const image = await this.threeviewer.captureImageAsDataURL(width, height);
        const wireframe = this.threeviewer.wireframe.visible;
        const background = this.threeviewer.background.visible;
        const logo = this.threeviewer.logo.visible;
        const a = document.createElement('a');
        a.href = image;
        a.download = `merror-${this.asset_krw.assetno}-${width}x${height}`
            + `${tag.length > 0 ? '-' + tag : ''}`
            + `${wireframe ? '-wireframe' : ''}`
            + `${background ? '-background' : ''}`
            + `${logo ? '-logo' : ''}`;
        document.body.appendChild(a);
        a.click();
        this.threeviewer.render();
        viewer.style.opacity = '1';
        clearInterval(loadingtimer);
    }
    async captureTransparentBG(width = 4096, height = 4096, tag = '') {
        this.threeviewer.setTransparentBackground(true);
        await this.capture(width, height, 'transparent');
        this.threeviewer.setTransparentBackground(false);
    }

    async captureAll_3rdparty(background: boolean, logo: boolean, transparent_bg = false) {
        this.threeviewer.setBackground(background);
        this.threeviewer.setLogo(logo);
        if (transparent_bg) this.threeviewer.setTransparentBackground(true);

        this.threeviewer.setWireframe(false);

        this.setDefaultCamera();
        await this.capture(1920, 1080, '0' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(45);
        await this.capture(1920, 1080, '45' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(45);
        await this.capture(1920, 1080, '90' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(90);
        await this.capture(1920, 1080, '180' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(90);
        await this.capture(1920, 1080, '270' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(45);
        await this.capture(1920, 1080, '315' + (transparent_bg ? '-transparent' : ''));

        this.setDefaultCamera();
        this.threeviewer.setCameraOnTop();
        await this.capture(1920, 1080, 'top' + (transparent_bg ? '-transparent' : ''));

        this.setDefaultCamera();
        await this.capture(1200, 1200, '0' + (transparent_bg ? '-transparent' : ''));

        this.setDefaultCamera();
        this.threeviewer.setWireframe(true);
        await this.capture(1920, 1080, '0' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(45);
        await this.capture(1920, 1080, '45' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(135);
        await this.capture(1920, 1080, '180' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.setWireframe(this.canvas_wireframe);
        this.threeviewer.setBackground(this.canvas_background);
        this.threeviewer.setLogo(this.canvas_logo);
        this.threeviewer.setTransparentBackground(false);
        this.setDefaultCamera();
    }
    async captureAll_merror(background: boolean, logo: boolean, transparent_bg = false) {
        this.threeviewer.setBackground(background);
        this.threeviewer.setLogo(logo);
        if (transparent_bg) this.threeviewer.setTransparentBackground(true);

        this.threeviewer.setWireframe(false);

        this.setDefaultCamera();
        await this.capture(1200, 1200, '0' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(45);
        await this.capture(1200, 1200, '90' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(90);
        await this.capture(1200, 1200, '180' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.rotateCameraY(90);
        await this.capture(1200, 1200, '270' + (transparent_bg ? '-transparent' : ''));

        this.setDefaultCamera();
        this.threeviewer.setWireframe(true);
        await this.capture(1200, 1200, '0' + (transparent_bg ? '-transparent' : ''));

        this.threeviewer.setWireframe(this.canvas_wireframe);
        this.threeviewer.setBackground(this.canvas_background);
        this.threeviewer.setLogo(this.canvas_logo);
        this.threeviewer.setTransparentBackground(false);
        this.setDefaultCamera();
    }

    changeBGColor() {
        this.viewerBGColorIndex++;
        this.viewerBGColorIndex %= this.viewerBGColorList.length;
    }

    closeDetail() {
        document.body.style.overflowY = 'overlay';
        this.showItemDetail = false;
    }

    calcDCRate(price: number, dcprice: number) {
        return Math.round((price - dcprice) / price * 10000) / 100;
    }
    calcMB(size: number) {
        return Math.round(size / 1024 / 1024 * 100) / 100;
    }

    getTagCount(tag: string) {
        const taginfo = this.taglist.find(t => t.text === tag.trim().toLowerCase());
        return taginfo ? taginfo.score : 0;
    }

    test1() {
        this.threeviewer.pointlight1.visible = !this.threeviewer.pointlight1.visible;
        this.threeviewer.ambientlight.visible = !this.threeviewer.ambientlight.visible;
        this.threeviewer.render();
    }

    checkSubcategory(subCategory: { en: string, kr: string }) {
        if (this.asset_krw === null || this.asset_usd === null) return;

        const flag = this.asset_tags.includes(subCategory.en) || this.asset_tags.includes(subCategory.kr);
        return flag;
    }

    toggleSubcategory(subCategory: { en: string, kr: string }) {
        const flag = this.asset_tags.includes(subCategory.en) || this.asset_tags.includes(subCategory.kr);
        if (flag) {
            this.asset_tags = this.asset_tags.filter(tag => tag !== subCategory.en && tag !== subCategory.kr);
        } else {
            this.asset_tags.push(subCategory.en);
            this.asset_tags.push(subCategory.kr);
        }
    }
}
