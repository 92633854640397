import { SearchTextScore } from '../../server/lib/types';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Data } from '../../server/lib/types';
import { Subscription } from 'rxjs';
import { SearchCondition } from '../lib/searchtool.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResizeService } from '../lib/resize.service';
import { UtilityService } from '../lib/util';


@Component({
    selector: 'app-store-index',
    templateUrl: './store-index.component.html',
    styleUrls: ['./store-index.component.scss'],
})
export class StoreIndexComponent implements OnInit, AfterViewInit, OnDestroy {

    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.None,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: [],
            subCategory: [],
            isGameready: false,
        }
    };
    ESC = Data.ESearchCondition;

    ;
    popularItems: SearchTextScore[] = [];


    initialLoad = false;
    newDeltaX = 0;


    isDown = false;
    startX = 0;
    scrollLeft = 0;

    init = false;
    isMobile = (/Mobi/i.test(navigator.userAgent.toLowerCase()));
    screenSize = this.resizeSvc.getCurrent();


    onLangChangeSub: Subscription | null = null;
    isTouchScreen = typeof window !== 'undefined' && window.matchMedia('(hover: none) and (pointer: coarse)').matches;

    @ViewChild('controller') controller!: ElementRef<HTMLUListElement>;

    constructor(
        private router: Router,
        public util: UtilityService,
        private resizeSvc: ResizeService
    ) {

        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
        });


    }

    favorites: number[] = [];
    selectedConditionCount = 0;
    searchSub: Subscription | null = null;

    ngOnInit(): void {
        window.scrollTo(0, 0);

    }

    ngAfterViewInit(): void {
        if (!this.isTouchScreen && !this.isMobile) this.registEvent();
    }



    ngOnDestroy(): void {
    }

    goToAssetList(sort: 'recent' | 'popular' | 'old' | 'highprice' | 'lowprice') {
        this.router.navigate(['/asset'], { state: { sort } });
    }


    moveToAssetPage(url: string, subcategory: string, gameready: boolean, flag?: Data.ESearchCondition) {
        this.router.navigate([url], { state: { subcategory, gameready, flag: flag } });
    }
    navigateTo(url: string) {
        this.router.navigate([url]);
    }

    registEvent() {
        this.controller.nativeElement.addEventListener('mousedown', this.start);
        this.controller.nativeElement.addEventListener('mousemove', this.move);
        this.controller.nativeElement.addEventListener('mouseup', this.end);
        this.controller.nativeElement.addEventListener('mouseleave', this.end);
    }

    start = (event: MouseEvent) => {
        this.isDown = true;
        this.controller.nativeElement.classList.add('active');

        this.startX = event.pageX - this.controller.nativeElement.offsetLeft;
        this.scrollLeft = this.controller.nativeElement.scrollLeft;
    }
    move = (event: MouseEvent) => {
        if (!this.isDown) return;
        event.preventDefault();
        const x = event.pageX - this.controller.nativeElement.offsetLeft;
        const walk = (x - this.startX) * 2;
        this.controller.nativeElement.scrollLeft = this.scrollLeft - walk;
    }

    end = () => {
        this.isDown = false;
        this.controller.nativeElement.classList.remove('active');
    }

}
