import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { UtilityService } from 'src/app/lib/util';
import { Data } from '../../server//lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { SEOService } from 'src/app/lib/seo.service';
import { ResizeService } from 'src/app/lib/resize.service';
import { AssetViewerComponent } from './common/asset-viewer.component';
import { Subscription, filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SearchCondition } from '../lib/searchtool.service';

declare var Sketchfab: any;

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss', './common/spinner.scss', './store-index.component.scss'],
})
export class ProductDetailComponent implements OnInit, AfterViewInit, OnDestroy {

    Object = Object;
    optionsAsArray = CommonUtil.assetOptionsAsArray;
    asset: Data.Asset | null = null;
    optionString: string[][] = [];
    purchase: Data.Payment | null = null;
    @ViewChild('addCart') addCartRef!: ElementRef;
    @ViewChild('alreadyCart') alreadyCartRef!: ElementRef;
    @ViewChild('itemviewoption') itemViewOptionRef!: ElementRef;
    @ViewChild('noOption') noOptionRef!: ElementRef;
    @ViewChild('zoomImg') zoomImg!: ElementRef<HTMLImageElement>;
    @ViewChild('controller') controller!: ElementRef<HTMLDivElement>;
    @ViewChild('sketchfab') sketchfab!: ElementRef<HTMLDivElement>;

    withComma = CommonUtil.withComma;
    formatNum = CommonUtil.formatNum;
    totalprice = 0;
    totaldcprice = 0;
    dcrate = 0;
    assetTotalPrice = 0;
    selectedOptions: Data.AssetOption[] = [];
    thumbnailList: string[] = [];
    assetCategory: 'Human' | 'Object' | 'Space' = 'Human';

    infoShow = false;
    selectedviewerOptions!: any;
    alertTimer = [[-1, -1, -1], [-1, -1, -1], [-1, -1, -1]];
    opacityDuration = 1500;
    pointerEventDuration = 2200;
    userno!: number;
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;
    tags: string[] = [];
    category_tags: string[] = ['사람', 'human', '사물', 'object', '공간', 'space'];
    selectedOptionType: Data.AssetOptionType | null = null;
    defaultOptionType: Data.AssetOptionType | null = null;
    defaultOptionPolygons = 0;
    defaultviewerid = '';
    previewindex = 0;
    imagelistMovePrev = false;
    imagelistMoveNext = false;
    showPopup = false;
    onLangChangeSub: Subscription | null = null;
    loadflag = false;
    isFacial = false;
    assetNotFound = false;
    floor = Math.floor;
    oldScrollTop = 0;

    showImgPopup = false;
    showController = false;
    showThumbnailList = false;

    zoomtarget!: string;

    scale = 1.0;
    zoomed = false;

    isDown = false;
    startX = 0;
    scrollLeft = 0;

    clicked = false;
    xAxis = 0;
    yAxis = 0;
    x = 0;
    y = 0;

    transformOriginX: number = 0;
    transformOriginY: number = 0;

    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.None,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: [],
            subCategory: [],
            isGameready: false,
        }
    };

    isTouchScreen = typeof window !== 'undefined' && window.matchMedia('(hover: none) and (pointer: coarse)').matches;
    isMobile = false;
    screenSize = this.resizeSvc.getCurrent();
    private resizeSubscription: Subscription;
    private routerSubscription: Subscription | null = null

    isHovered: boolean[] = [];

    @ViewChild('assetviewer') assetviewer!: AssetViewerComponent;

    constructor(
        private client: ApiClientService,
        private router: Router,
        private route: ActivatedRoute,
        private util: UtilityService,
        private seo: SEOService,
        private translate: TranslateService,
        private resizeSvc: ResizeService,

    ) {
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            await this.loadAsset();
        });

        this.resizeSubscription = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
        })

        this.isMobile = (/Mobi/i.test(navigator.userAgent.toLowerCase()));
    }

    ngOnInit(): void {
        const regexpParse = new RegExp('/asset([0-9]+)$');

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            let url: string;
            if (event instanceof NavigationEnd) {
                url = event.url;
                if (url.startsWith('/en') || url.startsWith('/ko')) url = url.substring(3);

                if (regexpParse.test(url)) {
                    const test = regexpParse.exec(url)
                    if ((test !== null) && test[1] !== undefined) {
                        this.showLoading();
                        this.loadAsset(parseInt(test[1]));
                        window.scrollTo(0, 0);
                    }
                }

            }
        });

        this.client.userinfoSubject.subscribe((res) => this.userno = res.userinfo.userno);

        this.oldScrollTop = document.documentElement.scrollTop;
        document.getElementById('router-outlet-main')!.style.display = 'none';
        document.getElementById('router-outlet-productdetail')!.style.display = 'block';

        window.addEventListener('resize', this.onResize);

        window.scrollTo(0, 0);
    }


    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        document.body.classList.remove('no-scroll');
        window.removeEventListener('mousedown', this.preventWheelClick);
        window.removeEventListener('resize', this.onResize);
        this.removeEvent();
        this.resizeSubscription.unsubscribe();
        this.client.cartinfoSubject.next({ assetno: 0, type: 'None' });

        document.getElementById('router-outlet-main')!.style.display = 'block';
        document.getElementById('router-outlet-productdetail')!.style.display = 'none';
        document.documentElement.scrollTop = this.oldScrollTop;
    }

    ngAfterViewInit(): void {
        this.loadAsset();
        this.initZoomEvent();
        this.initsliderEvent();
        setTimeout(() => {
            this.imagelistMove(-1, false);
        }, 500);

    }

    onResize() {

        const thumnailImg = document.querySelector('#product-detail-img-popup-img>div>img') as HTMLImageElement;
        const zoomContainer = document.getElementById('product-detail-img-zoomImg') as HTMLDivElement;


        const originalWidth = thumnailImg.naturalWidth;
        const originalHeight = thumnailImg.naturalHeight;
        const aspectRatio = originalWidth / originalHeight;
        const newHeight = window.window.innerHeight
        const newWidth = newHeight * aspectRatio;
        zoomContainer.style.width = `${newWidth}px`;
        zoomContainer.style.top = '0px';
        zoomContainer.style.removeProperty("left");

        const items = document.getElementsByClassName('product-detail-info-option');
        const item = items[0] as HTMLDivElement;
        if (item.clientWidth < 398) {
            for (let i = 0; i < items.length; i++) {
                (items[i].childNodes[0].childNodes[0] as HTMLDivElement).style.width = '120px';
            }
        } else {
            for (let i = 0; i < items.length; i++) {
                (items[i].childNodes[0].childNodes[0] as HTMLDivElement).style.width = '';
            }
        }

    }

    preventWheelClick(e: MouseEvent) {
        if (e.buttons === 4) {
            e.preventDefault();
        }
    }

    async loadAsset(assetno = 0) {
        if (this.loadflag) return;
        this.loadflag = true;
        this.selectedOptionType = null;
        if (assetno === 0) {
            assetno = parseInt(this.route.snapshot.paramMap.get('modelno') as string);
        }
        if (assetno === null) return;
        this.asset = null;
        const result = await this.client.api.GetAssetinfo.send({
            assetno: assetno, currency: this.util.getCurrentCurrency()
        });
        if (result.message === 'ok') {
            this.asset = result.asset;
            this.isFacial = (this.asset.flag & Data.ESearchCondition.Type_Facial) > 0;
            this.defaultOptionType = CommonUtil.getDefaultOptionType(this.asset);
            this.defaultOptionPolygons = this.asset.options[this.defaultOptionType]!.info.polygons;
            this.defaultviewerid = this.asset.options[this.defaultOptionType]!.info.viewerid
            this.initSketchfabViewer(this.defaultviewerid);

            this.tags.length = 0;
            const srctags = Array.isArray(this.asset.tag) ? this.asset.tag : JSON.parse(this.asset.tag);
            const tmptags: string[] = [];
            for (const tag of srctags) {
                if (tag[0] === '_') continue;
                if (this.category_tags.includes(tag)) continue;
                tmptags.push(tag);
            }

            const regex_english = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]*$/;
            if (this.util.getCurrentLang() == 'ko') {
                this.tags.push(...tmptags);
            } else {
                for (const tag of tmptags) {
                    if (regex_english.test(tag)) this.tags.push(tag);
                }
            }

            this.seo.setInfo(this.router.url, this.asset.assetno, this.asset.name, this.tags);
            const options = this.optionsAsArray(this.asset);
            for (let i = 0; i < options.length; i++) {
                this.optionString[i] = [];
                for (const format of options[i].info.texture.maps) {
                    if (format === 'OP') this.optionString[i].push('Opacity');
                    else if (format === 'AO') this.optionString[i].push('Ambient Occlusion');
                    else if (format === 'DIFF') this.optionString[i].push('Diffuse');
                    else if (format === 'NORM') this.optionString[i].push('Normal');
                    else if (format === 'RO') this.optionString[i].push('Roughness');
                    else if (format === 'ME') this.optionString[i].push('Metalness');
                    else if (format === 'COMP') this.optionString[i].push('Complete');
                    else if (format === 'DP') this.optionString[i].push('Displacement');
                    else if (format === 'ID') this.optionString[i].push('ID');
                    else this.optionString[i].push(format);
                }
                if (this.selectedOptionType === null) this.selectOption(options[i].type);
            }
            if (this.userno === 0) {
                this.setNonMember();
            }

            if (this.asset.tag.includes('human')) this.assetCategory = 'Human';
            else if (this.asset.tag.includes('object')) this.assetCategory = 'Object';
            else this.assetCategory = 'Space';

            // if (!this.isFacial) {
            //     this.assetviewer.loadByAsset(result.asset);
            // }

            this.getTotalPriceAvailable();
        } else {
            this.assetNotFound = true;
        }
        this.hoverOptionsAsArray(this.asset);
        this.loadflag = false;
    }

    //비회원시, cart,favorite 초기 세팅
    setNonMember() {
        const assetno = this.route.snapshot.paramMap.get('modelno');
        const favoriteList = JSON.parse(localStorage.getItem('MYFAVORITE')!);
        const cartList = JSON.parse(localStorage.getItem('MYCART')!);

        if (favoriteList !== null) {
            favoriteList.forEach((item: Data.Asset) => {
                if (item.assetno === Number(assetno) && this.asset !== null) {
                    this.asset.favorite = true;
                }
            })
        }

        if (cartList !== null) {
            cartList.forEach((item: any) => {
                if (item.assetno === Number(assetno) && this.asset !== null) {
                    let firstKey = Object.keys(item.options)[0]
                    if (firstKey !== undefined) {
                        (this.asset.options as any)[firstKey].cart = true;
                    }
                }
            })
        }
    }

    //전체선택 클릭시 다운로드된 아이템을 제외한 가격을 가져온다
    getTotalPriceAvailable() {
        for (const option of this.optionsAsArray(this.asset as Data.Asset)) {
            if (!(this.isPurchasedOption(option.type))) {
                this.assetTotalPrice += option.itemdcprice;
            };
        };
    }

    toggleFavorite() {
        if (this.asset === null) return;
        if (this.asset.favorite) {
            this.client.api.RemoveFromFavorite.send(this.asset).then(result => {
                console.log(result);
                if (result.message === 'ok') {
                    if (this.asset !== null) {
                        this.client.favoriteSubject.next({ assetno: this.asset.assetno, favorite: false });
                        this.asset.favorite = false;
                    }
                }
            });
        } else {
            this.client.api.AddToFavorite.send(this.asset).then(result => {
                console.log(result);
                if (result.message === 'ok') {
                    if (this.asset !== null) {
                        this.client.favoriteSubject.next({ assetno: this.asset.assetno, favorite: true });
                        this.asset.favorite = true;
                    }
                }
            });
        }
    }

    onSelectionAll() {
        console.log(this.assetTotalPrice);
        const div = this.itemViewOptionRef.nativeElement as HTMLDivElement;
        const inputList = div.querySelectorAll('input')
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].checked === false) {
                inputList.forEach(value => { value.checked = true; });
                this.totalprice = this.assetTotalPrice;

                const newSelected: Data.AssetOption[] = [];
                for (const option of this.optionsAsArray(this.asset as Data.Asset)) {
                    if (!(this.isPurchasedOption(option.type))) {
                        newSelected.push(option);
                    };
                };
                this.selectedOptions = newSelected;
                return;
            }
        }
        inputList.forEach(value => { value.checked = false; });
        this.selectedOptions = [];
        this.totalprice = 0;
    }

    async moveToLocation(location: string) {
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        this.router.navigate([location], { replaceUrl: true });
    }

    close(event?: Event) {
        if (!event) {
            this.router.navigate([{ outlets: { productdetail: null } }], { replaceUrl: true });
            return;
        }
        const div = event.target as HTMLDivElement;
        let flag = false;
        for (let i = 0; i < div.classList.length; i++) {
            const item = div.classList[i];
            if (item === 'item-view-background') {
                flag = true;
                break;
            }
        }
        if (flag) this.router.navigate([{ outlets: { productdetail: null } }], { replaceUrl: true });
    }

    async closePopupMoveCart(event?: Event) {
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        this.router.navigate(['/cart'], { replaceUrl: true });
        this.showPopup = false;
    }

    calcSize(size: number) {
        const result = size / 1024 / 1024;
        return '' + (Math.round(result * 100) / 100) + 'MB';
    }

    onChange(event: Event, option: Data.AssetOption) {
        console.log(option);
        const target = event.target as HTMLInputElement;
        const price = option.itemdcprice;
        const newSelected: Data.AssetOption[] = [];

        if (target.checked) {
            newSelected.push(option);
            this.totalprice += price;
        } else {
            this.totalprice -= price;
        }

        for (const item of this.selectedOptions) {
            if (item.type !== option.type && item) {
                newSelected.push(item);
            }
        }
        this.selectedOptions = newSelected;
        console.log(this.selectedOptions);
    }

    selectOption(type: Data.AssetOptionType) {
        this.selectedOptionType = type;
        const option = this.asset!.options[type] as Data.AssetOption
        this.selectedOptions = [option];
        this.totaldcprice = option.itemdcprice;
        this.totalprice = option.itemprice;
        this.dcrate = Math.floor((this.totalprice - this.totaldcprice) / this.totalprice * 100);

        this.previewindex = 0;
        setTimeout(() => {
            const imagelist = document.getElementsByClassName('product-detail-images-list-inner')[0] as HTMLDivElement;
            imagelist.style.transform = 'translateX(0px)';
        }, 1);

        this.thumbnailList = [];
        if (option.info.customimage > 0) {
            for (let i = 1; i <= option.info.customimage; i++) {
                this.thumbnailList.push(`/models/${this.asset!.assetno}/${this.asset!.assetno}-TH-${type}-${i}.jpg`);
            }
        } else {
            const optionindex = this.optionsAsArray(this.asset as Data.Asset).findIndex(value => value.type === type);
            for (let i = 1; i <= 6; i++) {
                this.thumbnailList.push(`/models/${this.asset!.assetno}/${this.asset!.assetno}-TH-${type}-${i}.png`);
            }
            if (this.optionString[optionindex].includes('Normal'))
                this.thumbnailList.push(`/models/${this.asset!.assetno}/${this.asset!.assetno}-TH-${type}-7.png`);
        }
        this.imagelistMove(-1, false);
    }

    async order() {

        if (this.asset === null || this.selectedOptions.length === 0) return;

        this.client.clearOrders();
        const model = Object.assign({}, this.asset);
        model.options = {};
        const type = this.selectedOptions[0].type;
        model.options[type] = this.selectedOptions[0];
        this.client.addOrder(model);
        this.router.navigateByUrl('/order');
    }

    async addToCart() {
        if (this.asset === null || this.selectedOptions.length === 0) return;

        const optionlist = [this.selectedOptions[0].optionno];

        const result = await this.client.api.AddToCart.send({
            assetno: this.asset.assetno, optionno: optionlist, asset: this.asset
        } as any);

        console.log(result);
        if (result.message === 'ok') {
            this.showPopup = true;
            this.selectedOptions[0].cart = true;

            const options = this.optionsAsArray(this.asset);
            let carttotal = 0;
            for (const option of options) {
                if (option.cart === true) carttotal++;
            }
            let carttype: Data.CartIconType = 'None';
            if (carttotal > 0) {
                if (carttotal === this.asset.optioncount) carttype = 'All';
                else carttype = 'Some'
            }
            this.client.cartinfoSubject.next({ assetno: this.asset.assetno, type: carttype });

        } else if (result.message.indexOf('login req') > -1) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
            this.client.openPopupSubject.next({ url: this.router.url, openPopup: true });
        }
    }

    popupAlertAppear(item: string, opacityTime: number, pointerEventTime: number) {
        let i = -1;
        let target: any;
        switch (item) {
            case 'alreadyCart': { target = this.alreadyCartRef.nativeElement; i = 0; break; }
            case 'addCart': { target = this.addCartRef.nativeElement; i = 1; break; }
            case 'noOption': { target = this.noOptionRef.nativeElement; i = 2; break; }
            default: { target = undefined; }
        }
        if (typeof target === undefined || i === -1) return;

        if (this.alertTimer[i][0] !== -1 || this.alertTimer[i][1] !== -1 || this.alertTimer[i][2] !== -1) {
            for (let j = 0; j < 3; j++) {
                clearTimeout(this.alertTimer[i][j]);
                this.alertTimer[i][j] = -1;
            }
        }
        this.alertTimer[i][0] = window.setTimeout(() => {
            this.alertTimer[i][0] = -1;
            target.style.opacity = 1;
            target.style.pointerEvents = 'auto';
        }, 0);

        this.alertTimer[i][1] = window.setTimeout(() => {
            this.alertTimer[i][1] = -1;
            target.style.opacity = 0;
        }, opacityTime);

        this.alertTimer[i][2] = window.setTimeout(() => {
            this.alertTimer[i][2] = -1;
            target.style.pointerEvents = 'none';
        }, pointerEventTime);
    };

    isPurchasedOption(type: Data.AssetOptionType) {
        if (this.asset === null) return false;
        const option = this.asset.options[type];
        if (option === undefined) return;
        return option.orderid > 0;
    }

    download(option: Data.AssetOption) {
        if (this.asset === null) return;
        this.util.downloadAsset(this.asset.assetno, option.optionno, option.type);
    }

    imagelistMove(dir: 1 | -1, move = true) {
        const index = this.previewindex;
        let newindex = index + dir;
        if (newindex < 0) newindex = this.thumbnailList.length - 1;
        else if (newindex >= this.thumbnailList.length) newindex = 0;

        const imagelist = document.getElementsByClassName('product-detail-images-list-inner')[0] as HTMLDivElement;
        if (imagelist === undefined) return;

        const style = getComputedStyle(imagelist);
        const imagecount = imagelist.childElementCount;
        const width = parseInt(style.width.substring(0, style.width.length - 2));
        const size = parseInt(style.height.substring(0, style.height.length - 2));
        const gap = (width - size * imagecount) / (imagecount - 1);

        const transform = style.transform;
        const translateX = parseInt(transform.split(',')[4].trim());
        const qq = Math.round(translateX / (size + gap)) * (size + gap);
        const newTranslateX = qq - (dir * (size + gap));
        this.imagelistMovePrev = newTranslateX < 0;
        this.imagelistMoveNext = newTranslateX > - (size + gap) * (imagecount - 5);
        if (newTranslateX > 0 || newTranslateX < - (size + gap) * (imagecount - 5)) return;
        if (move) imagelist.style.transform = `translateX(${newTranslateX}px)`;
    }
    closePopup(event: Event) {
        const target = event.target as HTMLDivElement;
        if (target.classList.contains('product-detail-popup-outer-header')
            || target.classList.contains('product-detail-popup-outer')) {
            this.showPopup = false;
        }
    }

    initZoomEvent() {
        window.addEventListener('mouseup', this.mouseUp);
        this.zoomImg.nativeElement.addEventListener('mousedown', this.start);
        this.zoomImg.nativeElement.addEventListener('mousemove', this.move);
        this.zoomImg.nativeElement.addEventListener('mouseup', this.end);
        this.zoomImg.nativeElement.addEventListener('mouseleave', this.end);
        this.zoomImg.nativeElement.addEventListener('touchstart', this.start);
        this.zoomImg.nativeElement.addEventListener('touchmove', this.move);
        this.zoomImg.nativeElement.addEventListener('touchend', this.end);
        this.zoomImg.nativeElement.addEventListener('wheel', this.handleMouseWheel);
    }

    handleMouseWheel = (event: WheelEvent) => {

        this.transformOriginX = event.offsetX;
        this.transformOriginY = event.offsetY;

        const scaleChange = event.deltaY > 0 ? -0.5 : 0.5;

        // 새로운 확대 비율 계산
        const newZoom = this.scale + scaleChange;
        const prevscale = this.scale;
        this.scale = Math.min(Math.max(1.0, newZoom), 5.0);

        if (this.scale == 1) {
            this.resetZoom();
        }

        this.zoomImg.nativeElement.style.transformOrigin = `${this.transformOriginX}px ${this.transformOriginY}px`
        // if (this.scale > prevscale) {
        //     this.zoomImg.nativeElement.style.transformOrigin = `${this.transformOriginX}px ${this.transformOriginY}px`
        // }
        this.zoomImg.nativeElement.style.transform = `scale(${this.scale})`
        this.checkSize();
    };

    initsliderEvent() {
        window.addEventListener('mouseup', this.prevetRightClick);
        window.addEventListener('mouseleave', this.prevetRightClick);
        this.controller.nativeElement.addEventListener('mousedown', this.slideStart);
        this.controller.nativeElement.addEventListener('mousemove', this.slideMove);
        this.controller.nativeElement.addEventListener('mouseup', this.slideEnd);
        this.controller.nativeElement.addEventListener('mouseleave', this.slideEnd);
    }

    removeEvent() {
        window.removeEventListener('mouseup', this.mouseUp);
        window.removeEventListener('mouseup', this.prevetRightClick);
        window.removeEventListener('mouseleave', this.prevetRightClick);
        this.zoomImg.nativeElement.removeEventListener('mousedown', this.start);
        this.zoomImg.nativeElement.removeEventListener('mousemove', this.move);
        this.zoomImg.nativeElement.removeEventListener('mouseup', this.end);
        this.zoomImg.nativeElement.removeEventListener('touchstart', this.start);
        this.zoomImg.nativeElement.removeEventListener('touchmove', this.move);
        this.zoomImg.nativeElement.removeEventListener('touchend', this.end);
        this.controller.nativeElement.removeEventListener('mousedown', this.slideStart);
        this.controller.nativeElement.removeEventListener('mousemove', this.slideMove);
        this.controller.nativeElement.removeEventListener('mouseup', this.slideEnd);
        this.controller.nativeElement.removeEventListener('mouseleave', this.slideEnd);
        this.zoomImg.nativeElement.removeEventListener('wheel', this.handleMouseWheel);

    }

    slideStart = (e: MouseEvent) => {
        this.isDown = true;
        this.controller.nativeElement.classList.add('active');

        this.startX = e.pageX - this.controller.nativeElement.offsetLeft;
        this.scrollLeft = this.controller.nativeElement.scrollLeft;
    }

    slideMove = (e: MouseEvent) => {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.controller.nativeElement.offsetLeft;
        const walk = (x - this.startX) * 2;
        this.controller.nativeElement.scrollLeft = this.scrollLeft - walk;

    }
    slideEnd = () => {
        this.isDown = false;
        this.controller.nativeElement.classList.remove('active');
    }

    mouseUp = () => {
        this.clicked = false;
        this.zoomImg.nativeElement.style.cursor = '';
    }

    start = (event: MouseEvent | TouchEvent) => {
        this.clicked = true;

        if (this.isTouchScreen || this.isMobile) {
            this.xAxis = (event as TouchEvent).touches[0].screenX - this.zoomImg.nativeElement.offsetLeft;
            this.yAxis = (event as TouchEvent).touches[0].screenY - this.zoomImg.nativeElement.offsetTop;

        } else {
            this.xAxis = (event as MouseEvent).clientX - this.zoomImg.nativeElement.offsetLeft;
            this.yAxis = (event as MouseEvent).clientY - this.zoomImg.nativeElement.offsetTop;
        }

    }

    move = (event: MouseEvent | TouchEvent) => {
        if (!this.clicked) return;
        event.preventDefault();

        if (this.isTouchScreen || this.isMobile) {
            this.x = (event as TouchEvent).touches[0].screenX;
            this.y = (event as TouchEvent).touches[0].screenY;
        } else {
            this.x = (event as MouseEvent).clientX;
            this.y = (event as MouseEvent).clientY;
        }

        const containerOut = (document.getElementById('product-detail-img-popup-img') as HTMLDivElement).getBoundingClientRect();
        const Imgin = this.zoomImg.nativeElement.getBoundingClientRect();


        if ((containerOut.height >= Imgin.height) && (containerOut.width >= Imgin.width)) {
            return;
        }
        this.zoomImg.nativeElement.style.cursor = `grab`;
        if (containerOut.height <= Imgin.height) {
            this.zoomImg.nativeElement.style.top = `${this.y - this.yAxis}px`;

            this.checkSize();
        }
        if (containerOut.width <= Imgin.width) {
            this.zoomImg.nativeElement.style.left = `${this.x - this.xAxis}px`;
            this.checkSize();
        }
    }

    checkSize() {
        const containerOut = (document.getElementById('product-detail-img-popup-img') as HTMLDivElement).getBoundingClientRect();
        const Imgin = this.zoomImg.nativeElement.getBoundingClientRect();

        if (Imgin.top > 0) {
            if (this.zoomImg.nativeElement.style.transformOrigin) {
                const [originX, originY] = this.zoomImg.nativeElement.style.transformOrigin.split(' ').map(value => parseFloat(value));
                this.zoomImg.nativeElement.style.top = `${(this.scale - 1) * originY}px`
            } else {
                this.zoomImg.nativeElement.style.top = `${(Imgin.height - containerOut.height) / 2}px`
            }

        } else if (Imgin.bottom < containerOut.bottom) {

            if (this.zoomImg.nativeElement.style.transformOrigin) {
                const [originX, originY] = this.zoomImg.nativeElement.style.transformOrigin.split(' ').map(value => parseFloat(value));
                this.zoomImg.nativeElement.style.top = `-${(Imgin.height - containerOut.height) - (originY * (this.scale - 1))}px`
            } else {
                this.zoomImg.nativeElement.style.top = `${(Imgin.height - containerOut.height) / 2}px`
            }

        }

        if ((containerOut.width >= Imgin.width)) {
            this.zoomImg.nativeElement.style.removeProperty("left");
        }

        if (Imgin.left > 0) {
            const diff = Imgin.left; // 이미지가 왼쪽으로 벗어난 양의 절댓값
            this.zoomImg.nativeElement.style.left = `${parseInt(this.zoomImg.nativeElement.style.left) - diff}px`;
        } else if (Imgin.right < containerOut.right) {
            const diff = containerOut.right - Imgin.right;
            this.zoomImg.nativeElement.style.left = `${parseInt(this.zoomImg.nativeElement.style.left) + diff}px`;
        }
    }




    end = (event: MouseEvent | TouchEvent) => {
        this.zoomImg.nativeElement.style.cursor = '';
    }

    prevetRightClick(event: MouseEvent | TouchEvent) {
        if ((event as MouseEvent).button === 2) {
            const target = document.getElementById('product-detail-prevent-img') as HTMLDivElement;
            target.style.display = 'block';
        } else {
            const target = document.getElementById('product-detail-prevent-img') as HTMLDivElement;
            target.style.display = 'none';
        }
    }

    showThumbnailZoom(src: string) {
        this.showImgPopup = true;
        document.body.classList.add('no-scroll');
        this.zoomtarget = src
        const thumnailImg = document.querySelector('#product-detail-img-popup-img>div>img') as HTMLImageElement;
        const controller = document.querySelector('.product-detail-img-zoom-controller-outer') as HTMLDivElement
        const thumbnailList = document.querySelector('.product-detail-img-popup-thumbnail-wrap') as HTMLDivElement
        controller.style.opacity = '1';
        thumbnailList.style.opacity = '1';

        setTimeout(() => {
            const originalWidth = thumnailImg.naturalWidth;
            const originalHeight = thumnailImg.naturalHeight;
            const aspectRatio = originalWidth / originalHeight;
            const newHeight = window.innerHeight
            const newWidth = newHeight * aspectRatio;
            this.zoomImg.nativeElement.style.width = `${newWidth}px`;
        }, 1)

        setTimeout(() => {
            controller.style.transition = 'opacity 1s';
            thumbnailList.style.transition = 'opacity 1s';
            controller.style.opacity = '0.7';
            thumbnailList.style.opacity = '0.2';
        }, 1000)

        //안전장치
        thumnailImg.onload = () => {
            const originalWidth = thumnailImg.naturalWidth;
            const originalHeight = thumnailImg.naturalHeight;
            const aspectRatio = originalWidth / originalHeight;
            const newHeight = window.innerHeight
            const newWidth = newHeight * aspectRatio;
            this.zoomImg.nativeElement.style.width = `${newWidth}px`;
        }


    }

    toggleZoom(zoom: boolean) {
        this.zoomed = zoom;

        if (this.zoomed) {
            this.scale += 0.5;
            this.zoomImg.nativeElement.style.transform = `scale(${this.scale})`;
        } else {
            const newScale = this.scale - 0.5;
            if (newScale <= 1.0) {
                this.resetZoom();
            } else {
                this.scale = newScale;
                this.zoomImg.nativeElement.style.transform = `scale(${newScale})`;
                this.checkSize();
            }

        }
    }



    resetZoom() {
        this.scale = 1.0;
        this.zoomed = false;
        this.zoomImg.nativeElement.style.transform = `scale(${this.scale})`;
        this.zoomImg.nativeElement.style.top = '0px';
        this.zoomImg.nativeElement.style.removeProperty("left");
        this.zoomImg.nativeElement.style.removeProperty("transform-Origin");
    }


    closeImgPopup() {
        this.showImgPopup = false;
        this.resetZoom();
        document.body.classList.remove('no-scroll');
    }

    changePopupImg(dir: 1 | -1) {
        const index = this.thumbnailList.indexOf(this.zoomtarget);
        let newindex = index + dir;
        if (newindex < 0) newindex = this.thumbnailList.length - 1;
        else if (newindex >= this.thumbnailList.length) newindex = 0;
        this.zoomtarget = this.thumbnailList[newindex];
    }

    getDetailImage(src: string) {
        if (src === undefined) return '';
        else if (src.endsWith('.png')) return src.replace('.png', '-Detail.png');
        else if (src.endsWith('.jpg')) return src.replace('.jpg', '-Detail.jpg');
        else return src;
    }

    initSketchfabViewer(uid: string) {

        this.showLoading();

        this.sketchfab.nativeElement.style.zIndex = '100';
        // TODO : set uid by asset option

        const iframeOuter = document.getElementById('sketchfab-viewer-outer') as HTMLDivElement;
        iframeOuter.innerHTML = '';
        iframeOuter.innerHTML = `<iframe src="" id="sketchfab-viewer" allow="xr-spatial-tracking" xr-spatial-tracking
        execution-while-out-of-viewport execution-while-not-rendered
        mozallowfullscreen="true" webkitallowfullscreen="true"
        style="width:100%;height:100%;"></iframe>`

        const iframe = document.getElementById('sketchfab-viewer');

        const client = new Sketchfab(iframe);
        client.init(uid, {
            success: (api: any) => {

                api.start();
                api.addEventListener('viewerready', () => {

                    setTimeout(() => {
                        this.hideLoading();
                        this.sketchfab.nativeElement.style.zIndex = 'auto';
                    }, 500)
                });
            },
            error: () => {
                console.log('SketchfabViewer init error');
            },
            ui_stop: 0,
            ui_theme: 'dark',
            ui_animations: 0,
            ui_annotations: 0,
            ui_infos: 0,
            ui_fullscreen: 0,
            ui_help: 0,
            ui_settings: 0,
            ui_vr: 0,
            ui_ar: 1,
            preload: 1,
            transparent: 1,
            ui_watermark: 0
        });
    }

    showLoading() {
        const spinner = document.getElementById('product-detail-spinner') as HTMLDivElement;
        spinner.style.opacity = '1';
    }

    hideLoading() {
        const spinner = document.getElementById('product-detail-spinner') as HTMLDivElement;
        spinner.style.opacity = '0';
    }

    onMouseEnter(index: number) {
        this.isHovered[index] = true;
    }

    onMouseLeave(index: number) {
        this.isHovered[index] = false;
    }

    hoverOptionsAsArray(asset: any) {
        this.isHovered = new Array(this.optionsAsArray(asset).length).fill(false);
    }

    onchangeDefaultOption(info: Data.AssetMetadata, type: Data.AssetOptionType | null) {
        if (info.viewerid === this.defaultviewerid) return;
        this.defaultOptionType = type;
        this.defaultviewerid = info.viewerid;
        this.defaultOptionPolygons = info.polygons;
        this.initSketchfabViewer(this.defaultviewerid);
    }

    // MERROR-251 태그 클릭했을 경우 해당태그 검색
    async searchTag(tag: string) {
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        const cond: SearchCondition = {
            condition: Data.ESearchCondition.None,
            sort: Data.ESortBy.Recent,
            searchtext: [tag],
            minpolygon: 0,
            maxpolygon: 0,
            minprice: 0,
            maxprice: 0,
            categoryInfo: {
                category: [],
                subCategory: [],
                isGameready: false,
            }
        };
        this.client.searchSubject.next(cond);
        if (this.router.url.includes('/store')) {
            await this.router.navigate(['/asset/all']);
        }
    }
}