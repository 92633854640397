import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { Data, RequestPurchaseInfo } from 'src/server/lib/types';
import { UtilityService } from '../lib/util';
import { IamportService, PayMethodType } from '../lib/iamport';
import { CommonUtil } from 'src/server/lib/util';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-api-test',
    templateUrl: './api-test.component.html',
    styleUrls: ['./api-test.component.scss']
})
export class ApiTestComponent implements OnInit, AfterViewInit {

    billingtest: BillingTest | null = null;
    coupontest: CouponTest | null = null;

    constructor(
        private client: ApiClientService,
        private iamport: IamportService,
        private util: UtilityService,
        private route: ActivatedRoute
    ) {
        const mode = this.route.snapshot.queryParamMap.get('mode');
        if (mode === 'billing') this.billingtest = new BillingTest(client, util, iamport);
        else if (mode === 'coupon') this.coupontest = new CouponTest(client, util, iamport);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }
}

class TestBase {
    client: ApiClientService;
    util: UtilityService;
    iamport: IamportService;
    constructor(client: ApiClientService, util: UtilityService, iamport: IamportService) {
        this.client = client;
        this.util = util;
        this.iamport = iamport;
    }
}
class CouponTest extends TestBase {
    userinfo = {
        userno: 0, userid: 0, email: '', password: '', status: 'NotVerified', regdate: new Date()
    } as Data.User;

    constructor(client: ApiClientService, util: UtilityService, iamport: IamportService) {
        super(client, util, iamport);
        const userinfoObserver = this.client.userinfoSubject.asObservable();
        userinfoObserver.subscribe(info => {
            this.userinfo = info.userinfo;
        });
    }
    async getCouponList() {
        const result = await this.client.api.GetCouponList.send({});
        console.log(result);
    }
    async requestCoupon(type: Data.CouponType) {
        const result = await this.client.api.RequestCoupon.send({ type, code: '' });
        console.log(result);
    }
}
class BillingTest extends TestBase {

    orders: Data.Asset[];
    totalPrice: number;
    orderNo = '';
    firstOption = CommonUtil.assetFirstOption;
    withComma = CommonUtil.withComma;
    testResult = '';
    testDetail = '';

    constructor(client: ApiClientService, util: UtilityService, iamport: IamportService) {
        super(client, util, iamport);
        this.orders = this.client.getOrders();
        this.totalPrice = this.client.getTotalPrice();
    }

    async test(payMethod: PayMethodType = 'card') {
        this.testResult = '';
        this.testDetail = '';

        const assetresult = await this.client.api.GetAssetinfo.send({
            assetno: CommonUtil.RandomNum(1, 5), currency: this.util.getCurrentCurrency()
        });
        const asset = assetresult.asset;
        asset.options = { CU30: asset.options['CU30'] };
        this.client.clearOrders();
        this.client.addOrder(asset)
        this.orders = this.client.getOrders();
        this.totalPrice = this.client.getTotalPrice();

        if (this.orders.length === 0) return;
        console.log(this.orders);

        const info: RequestPurchaseInfo[] = [];
        for (const item of this.orders) {
            info.push({
                assetno: item.assetno,
                optionno: this.firstOption(item).optionno,
                itemid: this.firstOption(item).itemid,
                coupon: ''  // TODO : set coupon
            });
        }
        const requestresult = await this.client.api.RequestPurchase.send({
            list: info, currency: this.util.getCurrentCurrency(),
        });
        console.log(requestresult);

        if (requestresult.message !== 'ok') {

            // TODO 아임포트 결제모듈이 테스트 상태인 동안은 운영서버에서 결제 막음
            if (requestresult.message === 'invalid pgmode') {
                this.util.showMessage({
                    message: '결제모듈 준비중입니다.',
                    type: 'WARN'
                });
                return;
            }
            // TODO ***************************************************************

            // order request failed!!!
            return;
        }

        this.orderNo = requestresult.orderid;

        const merchant_uid = requestresult.merchant_uid;
        let name = this.orders[0].name;
        if (this.orders.length > 1) {
            name = name.substring(0, 8);
            // TODO : translation
            name += '... 외 ' + (this.orders.length - 1) + '건'
        }
        const { userinfo } = this.client.userinfoSubject.getValue();
        const pgresult = await this.iamport.requestPay({
            amount: this.totalPrice,
            buyer_email: userinfo.email,
            buyer_name: userinfo.email,
            buyer_tel: '010-0000-0000',
            digital: true,
            merchant_uid,
            name,
            pay_method: payMethod
        });
        console.log(pgresult);

        if (pgresult.success) {
            // payment success
            const confirmresult = await this.client.api.ConfirmPurchase.send({
                imp_uid: pgresult.imp_uid,
                merchant_uid: pgresult.merchant_uid,
                orderid: requestresult.orderid,
                licenseinfo: { type: 'personal', username: '' },
                currency: this.util.getCurrentCurrency(),
                list: []
            });
            console.log(confirmresult);
            if (confirmresult.message === 'ok') {
                this.testResult = '결제성공';
                this.testDetail = JSON.stringify(pgresult, null, 4);
                // confirm success
                // this.showOrder = false;
                // this.showComplete = true;

            } else {
                // confirm failed
                console.error('confirm failed : ' + confirmresult.message)
                this.testResult = '결제실패';
                this.testDetail = JSON.stringify(confirmresult, null, 4);
                // this.showOrder = false;
                // this.showError = true;
            }
        } else {
            // payment failed
            console.error('payment failed : ' + pgresult.error_msg);
            this.testResult = 'PG결제실패';
            this.testDetail = JSON.stringify(pgresult, null, 4);

            if (pgresult.error_msg.startsWith('사용자가 결제를 취소')) {
                this.testResult = '결제취소';
            } else {
                // this.showOrder = false;
                // this.showError = true;
            }
        }
    }
}