<div class="common-popup-background" *ngIf="popupTitle !== 'fbPopup'">
    <div class="common-popup-container"
        [style.display]="popupTitle === 'enterInfoPopup'? 'none': 'flex'">
        <!-- Cookiepopup start -->
        <div [style.display]="popupTitle === 'cookiePopup' ? 'flex' : 'none'"
            class="cookie-popup-container common-vertical-alignment">
            <div class="cookie-popup-message" [innerHtml]="'Message.CookieLimit'|translate">
                게스트 이용시 더 이상 추가할수 없습니다.<br />
                로그인후 이용해주세요.
            </div>
            <div class="cookie-button-wrap">
                <button class="common-button2" style="margin-right: 8.5px"
                    [innerHtml]="'Receipt.Close'|translate" (click)="close()">닫기</button>
                <button class="common-button1" [innerHtml]="'Verification.Signin'|translate"
                    (click)="gotoSignin()">로그인하기</button>
            </div>
        </div>
        <!-- Cookiepopup end -->

        <!-- MERRORR LINK popup start -->
        <div [style.display]="popupTitle === 'noticePopup' ? 'flex' : 'none'"
            class=" notice-popup-container common-vertical-alignment">
            <div class="ready-title common-vertical-alignment">
                <div class="ready-icon">
                    <img src="/assets/icon/wait_24.svg" />
                </div>
                <div class="notice-popup-message" [innerHtml]="'Message.WIP'|translate">준비 중입니다
                </div>
            </div>

            <button [innerHtml]="'Receipt.Close'|translate" class="ready-button common-button1"
                (click)="close()">닫기</button>
        </div>
        <!-- MERRORR LINK popup end -->

        <div [style.display]="popupTitle === 'termsPopup' ? 'flex' : 'none'"
            class="notice-popup-container common-vertical-alignment">
            <div class="notice-popup-message" [innerHtml]="'Signup.AgreeAlert'|translate">필수약관에
                동의해주세요</div>
            <button [innerHtml]="'Receipt.Close'|translate" class="common-button1"
                (click)="close()">닫기</button>
        </div>

        <div class="notice-popup-container common-vertical-alignment"
            [style.display]="popupTitle === 'languagePopup' ? 'flex' : 'none'">
            <div class="language-popup-outer">
                <div class="notice-popup-title">
                    <span [innerHtml]="'Common.SelectLanguage'|translate">Choose your
                        Language</span>
                </div>
                <div class="language-choice-wrap">
                    <div class="language-choice" (click)="lang='ko'">
                        <input type="radio" id="language-korea" name="language-radio"
                            [checked]="this.lang === 'ko'">
                        <label class="language-radio" for="language-korea"></label>
                        <div class="language-type">
                            <span class="language">한국어</span>
                        </div>
                    </div>
                    <div class="language-choice language-choice-eng" (click)="lang='en'">
                        <input type="radio" id="language-english" name="language-radio"
                            [checked]="this.lang === 'en'">
                        <label class="language-radio" for="language-english"></label>
                        <div class="language-type">
                            <span class="language">English</span>
                        </div>
                    </div>
                </div>
                <button class="language-button common-button1"
                    [innerHtml]="'Common.Submit'|translate" (click)="closeAfterSelect()">선택하고
                    닫기</button>
            </div>
        </div>
    </div>

    <div class="datatype-popup-container"
        [style.display]="popupTitle === 'enterInfoPopup'? 'flex': 'none'">
        <div class="datatype-mobile-popup">
            <div class="datatype-mobile-controller">
                <div style="cursor: pointer;position: absolute; left: 16px;" (click)="close()">
                    <img uk-svg src="/assets/icon/arrow_left.svg">
                </div>
                <div [innerHtml]="'DataType.Detail'|translate">상세정보</div>
            </div>
            <div>
                <div class="datatype-mobile-header" [innerHtml]="'DataType.Mesh'|translate">
                    포인트 클라우드/매쉬
                </div>
                <div class="datatype-mobile-table" *ngFor="let sort of tableData">
                    <div class="mobile-vertical-header">
                        <div [innerHtml]="sort.Title|translate">
                        </div>
                    </div>
                    <div class="datatype-mobile-contents">
                        <div [innerHtml]="sort.Content[0]|translate">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="datatype-mobile-header" [innerHtml]="'DataType.Cleanup'|translate">
                    클린업
                </div>
                <div class="datatype-mobile-table" *ngFor="let sort of tableData">
                    <div class="mobile-vertical-header">
                        <div [innerHtml]="sort.Title|translate">
                        </div>
                    </div>
                    <div class="datatype-mobile-contents">
                        <div [innerHtml]="sort.Content[1]|translate">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="datatype-mobile-header" [innerHtml]="'DataType.Topology'|translate">
                    토폴로지
                </div>
                <div class="datatype-mobile-table" *ngFor="let sort of tableData">
                    <div class="mobile-vertical-header">
                        <div [innerHtml]="sort.Title|translate">
                        </div>
                    </div>
                    <div class="datatype-mobile-contents">
                        <div [innerHtml]="sort.Content[2]|translate">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="datatype-popup-table">
            <table>
                <tr class="dataype-header">
                    <th class="top-header" colspan="1"></th>
                    <th>
                        <div class="datatype-type" [innerHtml]="'DataType.Mesh'|translate">포인트
                            클라우드/매쉬</div>
                    </th>
                    <th>
                        <div class="datatype-type" [innerHtml]="'DataType.Cleanup'|translate">클린업
                        </div>
                    </th>
                    <th>
                        <div class="datatype-type datatype-topology">
                            <div [innerHtml]="'DataType.Topology'|translate">토폴로지</div>
                        </div>

                    </th>
                </tr>
                <tr *ngFor="let sort of tableData">
                    <td class="vertical-header">
                        <div [innerHtml]="sort.Title|translate">
                        </div>

                    </td>
                    <td *ngFor="let desc of sort.Content" class="datatype-desc-wrap">
                        <div class="datatype-desc"
                            [style.height]="(desc === 'DataType.PolygonCleanup') && screenSize === 1? '164px': ''">
                            <div [innerHtml]="desc|translate">
                            </div>
                        </div>
                    </td>

                </tr>
            </table>
        </div>

        <div class="datatype-close" (click)="close()">
            <img src="/assets/icon/close_24_black.svg" />
        </div>

    </div>
</div>

<div class="fb-popup-container" [style.display]="popupTitle === 'fbPopup' ? 'flex' : 'none'">
    <div class="fb-popup-outer">
        <div class="fb-popup-gallery">
            <img id="fb-popup-img" src="/assets/image/gallery/portfolio{{currentGallery}}.png"
                [style.height]="zoomin? height: ''" [style.width]="zoomin? '80vw': ''" />

            <div class="fb-popup-icon-wrap">
                <!-- <div class="fb-popup-icon-background" (mouseenter)="hover_zoom=true"
                        style="margin-right: 15px;" (mouseleave)="hover_zoom=false"
                        (click)="zoomin = !zoomin">
                        <img uk-svg [style.fill]="hover_zoom?'#000000': '#F4F4F4'" class="fb-popup-icon"
                            [src]="zoomin? '/assets/icon/fb-zoomout.svg' : '/assets/icon/fb-zoomin.svg'"
                            src="/assets/icon/fb-zoomin.svg">
                    </div> -->
                <div class="fb-popup-icon-background" (mouseenter)="hover_close=true"
                    (mouseleave)="hover_close=false" (click)="close()">
                    <img uk-svg [style.fill]="hover_close?'#000000': '#F4F4F4'"
                        [style.width]="screenSize === 0?'20px': '24px'"
                        [style.height]="screenSize === 0?'20px': '24px'" class="fb-popup-icon"
                        src="/assets/icon/fb-close.svg">
                </div>
            </div>
        </div>


    </div>



</div>