<div class="item-view-background common-vertical-alignment" (click)="close($event)">
    <div class="item-view-container">
        <div class="item-view-asset-wrap">
            <app-asset-viewer #assetviewer></app-asset-viewer>
        </div>
        <div class="item-view-detail-outer" *ngIf="this.asset !== null">
            <div class="item-view-detail-inner">
                <div class="item-view-name-wrap">
                    <div class="item-view-name">
                        {{this.asset.name}}
                    </div>
                    <div class="item-view-icon-wrap">
                        <div class="item-view-favorite-icon" (click)="toggleFavorite()">
                            <img uk-svg src="/assets/icon/favorite.svg" style="width: 100%;"
                                [style.fill]="asset.favorite === true ?'#FFA600': 'none'"
                                [style.color]="asset.favorite === true ?'#FFA600': 'unset'" />
                        </div>
                        <div class="item-view-close-icon" (click)="close()">
                            <img src="assets/icon/close3.svg">
                        </div>
                    </div>
                </div>

                <div class="item-view-detail-optionbox-outer">
                    <div class="item-view-detail-optionbox-selection"
                        [innerHTML]="'Search.ToggleSelectAll'|translate" (click)="onSelectionAll()">
                        전체선택/해제
                    </div>
                    <div #itemviewoption class="item-view-detail-optionbox">
                        <div class="item-view-detail-option-list">
                            <div *ngFor="let option of optionsAsArray(asset) let last =last;"
                                class="item-view-detail-option"
                                [ngClass]="{'item-view-detail-option-last': last}">
                                <div>
                                    <input id="item-view-{{option.type}}" type="checkbox"
                                        class="item-view-checkbox"
                                        (change)="onChange($event, option)"
                                        [disabled]="isPurchasedOption(option.type)" />
                                    <label for="item-view-{{option.type}}"></label>
                                </div>
                                <div class="item-view-detail-option-name">
                                    {{'Search.'+option.type|translate}}
                                </div>
                                <div class="item-view-detail-option-size">
                                    {{calcSize(option.size)}}</div>
                                <div class="uk-width-expand uk-margin-small-left">
                                    <img *ngIf="option.cart" uk-svg src="/assets/icon/cart.svg"
                                        style="padding-bottom:5px;" />
                                </div>
                                <div *ngIf="isPurchasedOption(option.type)"
                                    class="uk-margin-small-left uk-text-right"
                                    style="min-width: 25%">
                                    <a (click)="download(option)" style="padding:0;margin:0;">
                                        <span class="uk-text-small color-primary1"
                                            [innerHTML]="'Common.Download'|translate">Download</span>
                                    </a>
                                </div>
                                <div *ngIf="!isPurchasedOption(option.type)"
                                    class="uk-margin-small-left uk-text-right"
                                    style="min-width: 25%">
                                    {{withComma(option.itemdcprice)}}<span
                                        [innerHtml]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item-view-price-container">
                    <div class="item-view-cart-alert-wrap">
                        <div class="item-view-already-cart-msg" #alreadyCart>
                            <div [innerHTML]="'Itemview.AlreadyCart'|translate">이미 장바구니에 담은 상품입니다.
                            </div>
                            <a (click)="closePopupMoveCart()"
                                [innerHTML]="'Itemview.MoveCart'|translate">바로 가기</a>
                        </div>
                        <div class="item-view-add-cart-msg" #addCart>
                            <div [innerHTML]="'Itemview.AddMessage'|translate">해당 옵션이 추가되었습니다.</div>
                            <a (click)="closePopupMoveCart()"
                                [innerHTML]="'Itemview.MoveCart'|translate">바로 가기</a>
                        </div>
                        <div class="item-view-nooption-msg" #noOption>
                            <div [innerHTML]="'Itemview.NoOption'|translate">옵션을 선택해주세요.</div>
                        </div>
                        <div class="item-view-priceMsg">
                            <span class="item-view-price">{{withComma(totalprice)}}</span>
                            <span class="item-view-currency-unit"
                                [innerHTML]="currencykey|translate">
                                원</span>
                        </div>
                    </div>

                    <div class="item-view-button-wrap">
                        <button class="item-view-button common-button2" (click)="addToCart()"
                            [innerHTML]="'Itemview.AddCart'|translate">장바구니에 담기</button>
                        <button class="item-view-button common-button1" (click)="order()"
                            [innerHTML]="'Itemview.Purchase'|translate">바로 구매하기</button>
                    </div>
                </div>

                <div class="item-view-caution-container">
                    <h6 class="item-view-caution" [innerHTML]="'Itemview.Caution'|translate">유의사항
                    </h6>
                    <ul class="item-view-caution-list">
                        <li [innerHTML]="'Itemview.Caution1'|translate">
                            결제완료 후 7일 이내에 콘텐츠를 다운받지 않은 경우 환불 가능합니다.</li>
                        <li [innerHTML]="'Itemview.Caution2'|translate">
                            디지털 콘텐츠의 특성상 다운로드를 받은 콘텐츠는 환불이 불가하니 유의해주시기
                            바랍니다.</li>
                        <li style="list-style: none;" [innerHTML]="'Itemview.Caution3'|translate">
                            상품 하자 발생 시 고객센터로 문의해 주시면 신속하게 도와드리겠습니다.</li>
                    </ul>
                </div>

                <div class="item-view-item-type-detail">
                    <div class="uk-width-expand item-popup-model-info-bg"
                        style="font-size: 12px;text-align:center;">
                        <div uk-grid
                            class="uk-grid-collapse uk-child-width-expand item-popup-model-info-row"
                            style="height: 25px; padding-top: 15px;">
                            <div style="text-align:left;" class="uk-width-1-6"></div>
                            <div [innerHTML]="'Common.Format'|translate">포맷</div>
                            <div [innerHTML]="'Sidebar.Polygon'|translate">Polygon 수</div>
                            <div [innerHTML]="'Common.Format'|translate">포맷</div>
                            <div>Maps</div>
                        </div>
                        <div *ngFor="let option of optionsAsArray(asset);let i=index;" uk-grid
                            class="uk-grid-collapse uk-child-width-expand item-popup-model-info-row"
                            [style.background-color]="i%2===1?'#2a2a2a':'#393939'">
                            <div class="uk-width-1-6 item-popup-model-info-cell-first">
                                <span>{{('Search.' + option.type)|translate}}</span>
                            </div>
                            <div class="item-popup-model-info-cell">
                                {{option.info.format.join(', ')}}
                            </div>
                            <div class="item-popup-model-info-cell">
                                {{formatNum(option.info.polygons)}}
                            </div>
                            <div class="item-popup-model-info-cell">
                                {{option.info.texture.format.join(', ')}}
                            </div>
                            <div class="item-popup-model-info-cell">
                                {{optionString[i].length === 0 ? '-' :
                                optionString[i].join(', ')}}
                            </div>
                        </div>
                        <div style="padding-bottom: 10px;">&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>
    </div>