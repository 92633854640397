import { Util, UtilityService } from './../../lib/util';
import { ClearSearchBehavior, ClickDeleteSearchBehavior } from './../../lib/searchtool.service';
import { Subscription, filter } from 'rxjs';
import { SearchTextScore } from './../../../server/lib/types';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { Data } from '../../../server/lib/types';
import gsap from 'gsap';
import { NavigationEnd, Router } from '@angular/router';
import { AddSearchBehavior, SearchBar, PopSearchBehavior } from 'src/app/lib/searchtool.service';
import { ResizeService } from 'src/app/lib/resize.service';
@Component({
    selector: 'app-main-search',
    templateUrl: './main-search.component.html',
    styleUrls: ['./main-search.component.scss'],
})
export class MainSearchComponent implements OnInit {

    constructor(
        private client: ApiClientService,
        private translate: TranslateService,
        private router: Router,
        private searchBar: SearchBar,
        private resizeSvc: ResizeService,
        private util: UtilityService
    ) {
        this.resizeSvc.onResize$.subscribe(size => this.screenSize = size);
    }

    @Output() toggleFocused = new EventEmitter<boolean>();

    placeholder = 'Search.SearchPlaceholder';
    @ViewChild('searchtext') searchtextRef!: ElementRef<HTMLInputElement>;
    @ViewChild('searchouter') searchouterRef!: ElementRef<HTMLDivElement>;
    @ViewChild('tablets_search_icon') tabletIconRef!: ElementRef<HTMLDivElement>;
    @ViewChild('main_search') mainSearchRef!: ElementRef<HTMLDivElement>;

    screenSize = this.resizeSvc.getCurrent();
    ESC = Data.ESearchCondition;
    SearchConditionList: { cond: Data.ESearchCondition; str: string; }[] = [
        { cond: Data.ESearchCondition.Male, str: 'Search.Male' },
        { cond: Data.ESearchCondition.Female, str: 'Search.Female' },
        { cond: Data.ESearchCondition.Age_0, str: 'Search.Under10s' },
        { cond: Data.ESearchCondition.Age_10, str: 'Search.10s' },
        { cond: Data.ESearchCondition.Age_20, str: 'Search.20s' },
        { cond: Data.ESearchCondition.Age_30, str: 'Search.30s' },
        { cond: Data.ESearchCondition.Age_40_50, str: 'Search.40-50s' },
        { cond: Data.ESearchCondition.Age_60_, str: 'Search.Over60s' },
    ];
    popularSearchList: SearchTextScore[] = [];
    keyboardSubscription!: Subscription;
    showSearchTool = false;
    showResetButton = false;
    showSearchIcon = true;
    searchByCategory = false;
    mediaTablets = false;
    onCategory = false;
    searchCondition = this.searchBar.searchConition;
    focusStyle = 'search-category';

    ngOnInit(): void {
        this.placeholder = this.isExactRoute('/') ? 'Search.SearchStore' : 'Search.SearchPlaceholder'
        this.client.searchSubject.subscribe((condition) => {
            this.searchCondition = condition;
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.placeholder = this.isExactRoute('/') ? 'Search.SearchStore' : 'Search.SearchPlaceholder'
        });

    }

    setStyle(idx: number) {
        setTimeout(() => {
            const category = document.getElementById(`search-list-${idx}`) as HTMLElement;
            if (category) {
                category.classList.add(this.focusStyle);
            }
        }, 1);
    }
    async getPlaceholder() {
        this.searchCondition.searchtext.length ? '' : await this.util.getTranslation(this.placeholder);
    }

    ngAfterViewInit() {
    }
    ngOnDestroy(): void {
        // window.removeEventListener("click", (event) => { this.clickToolOutside(event); });
        // this.client.searchSubject.unsubscribe();
    }

    // clickToolOutside(event: Event) {
    //     if (this.screenSize === SCREEN_SIZE.mobile) return;
    //     let target = event.target as Node;
    //     const searchOuter = this.searchouterRef.nativeElement;
    //     const suggestedTool = document.querySelector('.main-suggested-search-outer');
    //     const searchTag = document.getElementById('user-search-tag') as HTMLButtonElement;
    //     const input = this.searchtextRef.nativeElement;

    //     if (!searchOuter?.contains(target) && !suggestedTool?.contains(target) && !searchTag?.contains(target)) {
    //         this.hideSearchTool();
    //         if (!this.searchCondition.searchtext.length && !input.value.length) {
    //             this.showSearchIcon = true;
    //         }
    //         if (this.keyboardSubscription !== undefined) {
    //             this.keyboardSubscription.unsubscribe();
    //         }
    //     }
    // }

    inputKeyBoardEvent(event: KeyboardEvent) {
        const input = this.searchtextRef.nativeElement;
        const ENTER = 'Enter';
        const BACKSPACE = 'Backspace';
        // const DELETE = 'Delete';

        switch (event.key) {
            case ENTER: {
                this.enterKeyWords(input);
                break;
            }
            case BACKSPACE:
            // case DELETE: {
            //     this.popKeyWords(input);
            //     break;
            // }
            default:
                break;
        }
    }

    async enterKeyWords(input: HTMLInputElement) {
        const texts = input.value.split(/(\s+)/).filter(text => text.trim().length > 0);

        if (texts.length == 0) { return; }

        if (this.router.url.includes('/asset/all')) {
            if (/\/asset([0-9]+)$/.test(this.router.url)) {
                // MERROR-251 상품목록 페이지인 경우
                // 상품상세페이지가 열려있는지 한번 확인하고 열려있는경우 닫아줌
                await this.router.navigate([{ outlets: { productdetail: null } }]);
            }
            this.searchBar.setSearchBehavior(new AddSearchBehavior());
            await this.searchBar.performSearch(texts);
            this.client.categorySubject.next({ onCategory: false });
        } else {
            await this.router.navigateByUrl('/asset/all', { state: { tag: texts } });
            this.client.categorySubject.next({ onCategory: false });
        }

        this.showResetButton = true;
        input.value = '';
        this.toggleFocused.emit(false);

    }

    async popKeyWords(input: HTMLInputElement) {
        if (input.value.length === 0 && this.searchCondition.searchtext.length != 0) {
            this.searchBar.setSearchBehavior(new PopSearchBehavior());
            await this.searchBar.performSearch();
        }
    }

    async addSuggestedTerm(flag: Data.ESearchCondition) {
        for (let item of this.SearchConditionList) {
            if (flag === item.cond) {
                this.translate.get(item.str).subscribe(async keyword => {
                    this.searchBar.setSearchBehavior(new AddSearchBehavior());
                    await this.searchBar.performSearch([keyword]);
                });
            }
        }

        if (this.router.url !== '/') {
            await this.router.navigateByUrl('/');
            setTimeout(() => {
                const bannerHeight = (document.getElementById('index-slider') as HTMLDivElement).clientHeight;
                if (!this.screenSize) Util.scrollTo(bannerHeight);
                else Util.scrollTo(496);
            });
        }
    }

    async addPopularText(text: string) {
        this.searchBar.setSearchBehavior(new AddSearchBehavior());
        await this.searchBar.performSearch([text]);

        if (this.router.url !== '/') {
            await this.router.navigateByUrl('/');
            setTimeout(() => {
                const bannerHeight = (document.getElementById('index-slider') as HTMLDivElement).clientHeight;
                if (!this.screenSize) Util.scrollTo(bannerHeight);
                else Util.scrollTo(496);
            });
        }
    }

    async clickDeleteButton(item: string) {
        // if (this.screenSize !== SCREEN_SIZE.mobile) this.hideSearchTool();
        this.searchBar.setSearchBehavior(new ClickDeleteSearchBehavior);
        await this.searchBar.performSearch(item);
        this.checkCategoryDelete(item);
    }

    checkCategoryDelete(item: string) {
        this.searchBar.categoryList.map(async (category) => {
            const tmp = await this.util.getTranslation(category);
            if (tmp == item) {
                this.client.bannerSubject.next({ category: category, method: 'DELETE' });
            }
        });
    }

    async resetSearchText() {
        const input = this.searchtextRef.nativeElement;
        input.value = '';
        this.showResetButton = false;

        this.searchBar.setSearchBehavior(new ClearSearchBehavior());
        await this.searchBar.performSearch();
    }



    openSearchBar(event: MouseEvent) {
        this.tabletIconRef.nativeElement.classList.add('hide-icon');
        this.mainSearchRef.nativeElement.classList.remove('hide-icon');
        // this.focusingSearch(event);
    }


    subscribeKeyEvent() {
        const tags = document.querySelector('.user-search-tags') as HTMLElement;
        this.keyboardSubscription = this.client.keyBoardEvent.subscribe(
            (event: KeyboardEvent) => {
                if (event.key === 'ArrowRight') {
                    gsap.to(tags, { scrollTo: { x: tags.scrollLeft + 50 }, duration: 1 });
                }
                if (event.key === 'ArrowLeft') {
                    gsap.to(tags, { scrollTo: { x: tags.scrollLeft - 50 }, duration: 1 });
                }
            }
        );
    }

    setPlaceholder(innerHTML: string) {
        this.placeholder = innerHTML;
    }

    focusingSearch(focused: boolean) {
        this.toggleFocused.emit(focused);
    }

    isExactRoute(route: string): boolean {
        const currentRoute = this.router.url.startsWith('/ko') || this.router.url.startsWith('/en') ? this.router.url.substring(3) : this.router.url;
        return currentRoute === route;
    }
}
