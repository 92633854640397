import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from '../lib/api-client.service';
import { ResizeService } from '../lib/resize.service';
import { Util } from '../lib/util';

@Component({
    selector: 'app-custom-order',
    templateUrl: './custom-order.component.html',
    styleUrls: ['./custom-order.component.scss'],

})
export class CustomOrderComponent implements OnInit {

    @ViewChild('custom_content') inputRequestComponent!: ElementRef<HTMLTextAreaElement>;
    @ViewChild('custom_name') inputNameComponent!: ElementRef<HTMLInputElement>;
    @ViewChild('custom_tel') inputPhoneComponent!: ElementRef<HTMLInputElement>;
    @ViewChild('custom_email') inputEmailComponent!: ElementRef<HTMLInputElement>;

    insufficient = false;
    success = false;
    message = '';
    validPhoneFormat = true;

    desktopSize = this.resizeSvc.getCurrent();

    constructor(
        private client: ApiClientService,
        private translate: TranslateService,
        private resizeSvc: ResizeService
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
    }



    async send() {
        const content = this.inputRequestComponent.nativeElement.value;
        const name = this.inputNameComponent.nativeElement.value;
        const tel = this.inputPhoneComponent.nativeElement.value;
        const email = this.inputEmailComponent.nativeElement.value;

        this.validPhoneFormat = true;
        if (!name || !tel || !email) {
            this.translate.get('Custom.ErrorMessage').subscribe(value => {
                this.message = value;
            });
            this.insufficient = true;
            return;
        }
        else if (!Util.isValidPhone(tel)) {
            this.translate.get('Custom.IncorrectPhoneFormat').subscribe(value => {
                this.message = value;
            });
            this.insufficient = true;
            this.validPhoneFormat = false;
            return;
        }
        else if (!Util.isValidEmail(email)) {
            this.translate.get('Signup.EmailInvalid').subscribe(value => {
                this.message = value;
            });
            this.insufficient = true;
            return;
        }


        const result = await this.client.api.RequestCustomOrder.send({
            content: content,
            name: name,
            tel: tel,
            email: email,
        });

        if (result.message === 'ok') {
            this.success = true;
            this.translate.get('Custom.SuccessMessage').subscribe(value => {
                this.message = value;
            });
            this.insufficient = true;
        } else {
            this.success = true;
            this.translate.get('Custom.FailMessage').subscribe(value => {
                this.message = value;
            });
            this.insufficient = true;
        }
    }

    close() {

        this.insufficient = false;

        if (this.success) {
            this.inputRequestComponent.nativeElement.value = '';
            this.inputNameComponent.nativeElement.value = '';
            this.inputPhoneComponent.nativeElement.value = '';
            this.inputEmailComponent.nativeElement.value = '';
        }

        this.success = false;

    }

}
