import { Util, UtilityService } from './../../lib/util';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import { SearchBar, AddSearchBehavior } from 'src/app/lib/searchtool.service';
import { Data, SearchTextScore } from '../../../server/lib/types';


@Component({
    selector: 'app-dropdown-nav',
    templateUrl: './dropdown-nav.component.html',
    styleUrls: ['./dropdown-nav.component.scss'],
})
export class DropdownNavComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('root') rootelement!: ElementRef<HTMLDivElement>;

    @Input() userinfo!: Data.User;
    @Input() navTitle?: string
    @Output() closed = new EventEmitter();


    showAssetsMenu = true;
    showBusinessMenu = true;

    screenSize = this.resizeSvc.getCurrent();
    SCREEN_SIZE = SCREEN_SIZE;


    recommendLabelFocused = true;
    recommendList: { cond: Data.ESearchCondition; str: string; }[] = [
        { cond: Data.ESearchCondition.Male, str: 'Search.Male' },
        { cond: Data.ESearchCondition.Female, str: 'Search.Female' },
        { cond: Data.ESearchCondition.Age_0, str: 'Search.Under10s' },
        { cond: Data.ESearchCondition.Age_10, str: 'Search.10s' },
        { cond: Data.ESearchCondition.Age_20, str: 'Search.20s' },
        { cond: Data.ESearchCondition.Age_30, str: 'Search.30s' },
        { cond: Data.ESearchCondition.Age_40_50, str: 'Search.40-50s' },
        { cond: Data.ESearchCondition.Age_60_, str: 'Search.Over60s' },
    ];

    popularSearchList: SearchTextScore[] = [];

    private subscription!: Subscription;
    private popularSubscription!: Subscription;


    constructor(
        public translate: TranslateService,
        private client: ApiClientService,
        private router: Router,
        private resizeSvc: ResizeService,
        private searchBar: SearchBar,
        private util: UtilityService

    ) {
        this.subscription = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
            if (!this.screenSize) document.body.classList.add('no-scroll');
            else document.body.classList.remove('no-scroll');
        });
    }


    ngOnInit(): void {
        this.popularSubscription = this.client.popularSubject.subscribe((list) => {
            this.popularSearchList = list;
        });
    }

    ngAfterViewInit(): void {
        if (!this.screenSize) document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
        this.subscription.unsubscribe();
        this.popularSubscription.unsubscribe();
    }

    async moveToLocation(location: string) {
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        this.router.navigate([location], { replaceUrl: true });

        setTimeout(() => {
            this.closed.emit(false);
        }, 100)
    }

    account(mycoupon: boolean) {
        if (this.userinfo.email !== '') {
            if (mycoupon) {
                this.client.accountLocationSubject.next({ location: 'coupon' });
                this.router.navigate(['/account'], { state: { mycoupon: true } });

            } else {
                this.client.accountLocationSubject.next({ location: 'settings' });
                this.router.navigateByUrl('/account');
            }

        } else {
            this.router.navigateByUrl('/signin');
        }
        //이동 후 화면이 그려진 후 자연스럽게 닫히기 위하여
        setTimeout(() => {
            this.closed.emit(false);
        }, 100)

    }

    purchases() {
        if (this.userinfo.email !== '') {
            this.router.navigateByUrl('/purchases');
        } else {
            this.router.navigateByUrl('/signin');
        }


        setTimeout(() => {
            this.closed.emit(false);
        }, 100)

    }

    logout() {
        this.client.api.Logout.send();

        setTimeout(() => {
            this.closed.emit(false);
        }, 100)
    }

    closeNav() {
        this.closed.emit();
    }

    async addSuggestedTerm(text: string) {
        // const input = document.getElementById('searchtext') as HTMLInputElement;

        const searchText = await this.util.getTranslation(text);
        this.addText(searchText);
    }

    addPopularText(text: string) {
        this.addText(text)

    }

    async addText(text: string) {
        if (this.router.url.includes('/asset/all')) {
            this.searchBar.setSearchBehavior(new AddSearchBehavior());
            await this.searchBar.performSearch([text]);
            this.client.categorySubject.next({ onCategory: false });
        } else {
            await this.router.navigateByUrl('/asset/all', { state: { tag: [text] } });
            this.client.categorySubject.next({ onCategory: false });
        }
        setTimeout(() => {
            this.closeNav();
        }, 100)

    }


}
