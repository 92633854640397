<main class="purchase-container">
    <div class="purchase-outer">
        <div class="purchase-inner">

            <div class="purchase-breadcrumb">
                <div>
                    <span routerLink="/store" [innerHTML]="'Common.Home'|translate">Home</span>
                </div>
                <div class="common-horizontal-alignment">
                    <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
                    <span [innerHTML]="'Common.OrderHistory'|translate"></span>
                </div>
            </div>

            <div class="purchase-title" [innerHTML]="'Common.OrderHistory'|translate">Purchase List
            </div>

            <div *ngIf="totalItems > 0">
                <div class="purchase-list" *ngFor="let payment of payments">
                    <div class="purchase-list-outer">
                        <div class="purchase-list-inner">
                            <div class="purchase-list-header">
                                <div>
                                    <div class="purchase-list-orderno">
                                        {{payment.CASHNO}}
                                    </div>
                                    <div class="purchase-list-ordertime">
                                        {{payment.REGDATE}}
                                    </div>
                                </div>
                                <div>
                                    <div class="purchase-list-receipt"
                                        (click)="showReceipt(payment)">
                                        <img src=" /assets/icon/receipt_20.svg" />
                                    </div>
                                    <div class="purchase-list-detail" (click)="showDetail(payment)">
                                        <img src=" /assets/icon/chevron_right_24.svg" />
                                    </div>
                                </div>
                            </div>

                            <div class="purchase-order-item-outer"
                                *ngFor="let item of payment.list;let index = index">
                                <div class="purchase-order-item-inner">
                                    <div class="purchase-order-item">
                                        <div class="purchase-order-thumbnail">
                                            <div
                                                style="background-image:url({{ApiUrl}}{{item.IMGURL1}})">
                                            </div>
                                        </div>
                                        <div class="purchase-order-item-desc">

                                            <div>
                                                <div class="purchase-order-name">
                                                    {{lang === 'ko'? item.ITEMNAMEKOR:
                                                    item.ITEMNAMEENG}}
                                                </div>
                                                <div class="purchase-order-option">
                                                    <span
                                                        [innerHTML]="('Search.'+item.ITEMTYPE)|translate"></span>
                                                </div>
                                            </div>

                                            <div class="purchase-align">
                                                <div class="purchase-order-status">
                                                    <div *ngIf="item.refunded"
                                                        [innerHTML]="'Common.PurchaseCanceled'|translate">
                                                        구매취소
                                                    </div>
                                                    <div *ngIf="!item.refunded&&!item.refundable"
                                                        [innerHTML]="'Common.PurchaseConfirmed'|translate">
                                                        구매확정</div>
                                                    <div *ngIf="item.refundable"
                                                        [innerHTML]="'Common.Purchased'|translate">
                                                        구매완료
                                                    </div>
                                                </div>

                                                <div class="purchase-order-price-wrap">
                                                    <div class="purchase-order-price"
                                                        [style]="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)? 'text-decoration: line-through;font-weight: 400;font-size: 12px;color: #9E9E9E;' : ''">

                                                        {{(withComma((item.COUPONID == 1 ||
                                                        item.COUPONID
                                                        ==
                                                        2)?item.ITEMPRICE: item.ITEMORGPRICE)) ==
                                                        '0' &&
                                                        (item.currency === 'USD')? '0.00'
                                                        : (withComma((item.COUPONID == 1 ||
                                                        item.COUPONID == 2)?item.ITEMPRICE:
                                                        item.ITEMORGPRICE))}}
                                                        <span
                                                            [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                                    </div>
                                                    <div class="purchase-order-dcprice"
                                                        *ngIf="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)">

                                                        <img src="/assets/icon/coupon_color_24.svg"
                                                            [style.display]="(item.COUPONID == 1 || item.COUPONID == 2) ? 'block':'none'">
                                                        <span>{{withComma(item.PURCHASEPRICE) == '0'
                                                            &&
                                                            item.currency === 'USD'?
                                                            '0.00':
                                                            withComma(item.PURCHASEPRICE)}}</span>
                                                        <span>&nbsp;</span>
                                                        <span
                                                            [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="desk-purchase-order-price-wrap">
                                        <div class="desk-purchase-order-price"
                                            [style]="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)
                                        ? 'text-decoration: line-through;font-weight: 400;font-size: 12px;color: #9E9E9E;' : ''">
                                            {{(withComma((item.COUPONID == 1 ||
                                            item.COUPONID
                                            ==
                                            2)?item.ITEMPRICE: item.ITEMORGPRICE)) ==
                                            '0' &&
                                            (item.currency === 'USD')? '0.00'
                                            : (withComma((item.COUPONID == 1 ||
                                            item.COUPONID == 2)?item.ITEMPRICE:
                                            item.ITEMORGPRICE))}}
                                            <span
                                                [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                        </div>
                                        <div class="purchase-order-dcprice"
                                            *ngIf="(item.COUPONID == 1 || item.COUPONID == 2)  || (item.ITEMPRICE !== item.ITEMORGPRICE)">

                                            <img src="/assets/icon/coupon_color_24.svg"
                                                [style.display]="(item.COUPONID == 1 || item.COUPONID == 2) ? 'block':'none'">
                                            <span>{{withComma(item.PURCHASEPRICE) == '0' &&
                                                item.currency === 'USD'?
                                                '0.00':
                                                withComma(item.PURCHASEPRICE)}}</span>
                                            <span>&nbsp;</span>
                                            <span
                                                [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                        </div>
                                    </div>


                                    <div class="purchase-order-download">
                                        <button *ngIf="!item.refunded && screenSize !==0"
                                            (click)="downloadAsset(item)"
                                            class="common-button2 purchase-download-button">
                                            <span
                                                [innerHTML]="'Common.DownloadSelection'|translate">
                                                다운받기</span></button>
                                        <div *ngIf="!item.refunded && screenSize ===0"
                                            (click)="downloadAsset(item)">
                                            <img class="purcahse-order-download-icon"
                                                src=" /assets/icon/download_24.svg" />
                                        </div>
                                        <div *ngIf="item.firstDownload !== null && screenSize !==0"
                                            class="purchase-download-status">
                                            <div [innerHTML]="'Account.FirstDownload'|translate">
                                                최초 다운로드</div>
                                            <div>
                                                {{getOrderDate(item.firstDownload)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="empty-container"
                [style.display]="apiChecked && totalItems === 0? 'flex': 'none'"
                style="margin-top: 109px">
                <img class="empty-img" src="/assets/image/empty/empty_purchase.png" />
                <div class="empty-desc" [innerHTML]="'Common.EmptyShoppingList'|translate">구매한
                    상품이 없습니다.</div>
                <button class="common-button1 empty-button" routerLink="/store"
                    [innerHTML]="'Common.ContinueShopping'|translate">쇼핑계속하기</button>
            </div>

        </div>



        <button *ngIf="totalItems > payments.length" class="common-button1 purchase-button-more"
            (click)="loadNextpage()" [innerHTML]="'Common.SeeMore'|translate">더 보기</button>
    </div>
</main>