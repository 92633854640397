<div class="business-carousel-image"
    [style.background-image]="'url(/assets/image/business/carousel/'+ page + '-desc' + (index+1)+'-' + (currenIdx)+'.png)'">
    <div class="business-carousel-controller">
        <div *ngFor="let img of imgList;let dot = index" (click)="currenIdx = dot+1">
            <div
                [ngClass]="dot+1 === currenIdx ? 'business-carousel-oval' :'business-carousel-dot'">
            </div>
        </div>
    </div>

    <div class="business-carousel-button-prev" style="transform: scaleX(-1)"
        (click)="moveCarousel(-1)" [style.color]="hover_prev?'#FFCA66': '#F4F4F4'"
        (mouseenter)="hover_prev=true" (mouseleave)="hover_prev=false">
        <img uk-svg src="/assets/icon/chevron_right_20.svg" />
    </div>
    <div class=" business-carousel-button-next" [style.color]="hover_next?'#FFCA66': '#F4F4F4'"
        (click)="moveCarousel(+1)" (mouseenter)="hover_next=true" (mouseleave)="hover_next=false">
        <img uk-svg src="/assets/icon/chevron_right_20.svg" style="z-index:1;" />
    </div>
</div>