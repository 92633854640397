import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { defaultmetainfo, metainfo } from "src/server/lib/seo";
import { Data } from "src/server/lib/types";
import { AppInjector } from "../app.module";

@Injectable({
    providedIn: 'root'
})
export class SEOService {
    setInfo(url: string, assetno = 0, name = '', tags: string[] = []) {
        if (url.startsWith('/ko') || url.startsWith('/en')) url = url.substring(3);
        console.log('SEOService::setInfo', url, name, tags);
        const _meta = AppInjector.get(Meta);
        const _title = AppInjector.get(Title);
        const _translate = AppInjector.get(TranslateService);
        const lang = _translate.getDefaultLang() as Data.LanguageType;

        for (const item of metainfo) {
            for (const itemurl of item.url) {
                if (url.startsWith(itemurl)) {
                    const title = item.title[lang];
                    const desc = item.desc[lang];
                    _title.setTitle(title);
                    _meta.updateTag({ name: 'description', content: desc });

                    _meta.updateTag({ property: 'og:url', content: window.location.href });
                    _meta.updateTag({ property: 'og:title', content: title });
                    _meta.updateTag({ property: 'og:description', content: desc });
                    _meta.updateTag({ property: 'og:image', content: 'https://me-rror.com/assets/image/merror-main.png' });
                    return;
                }
            }
        }
        if (url.startsWith('/asset/') || url.startsWith('/store/')) {
            const title = `ME.RROR asset view - ${name}`;
            const desc = `${name} : ${tags.join(',')}`;
            const imagepath = `models/${assetno}/${assetno}-TH-OG.png`;
            _title.setTitle(title);
            _meta.updateTag({ name: 'description', content: desc });

            _meta.updateTag({ property: 'og:url', content: window.location.href });
            _meta.updateTag({ property: 'og:title', content: title });
            _meta.updateTag({ property: 'og:description', content: desc });
            _meta.updateTag({ property: 'og:image', content: `https://me-rror.com/${imagepath}` });

        } else {
            const title = defaultmetainfo.title[lang];
            const desc = defaultmetainfo.desc[lang];
            _title.setTitle(title);
            _meta.updateTag({ name: 'description', content: desc });

            _meta.updateTag({ property: 'og:url', content: window.location.href });
            _meta.updateTag({ property: 'og:title', content: title });
            _meta.updateTag({ property: 'og:description', content: desc });
            _meta.updateTag({ property: 'og:image', content: 'https://me-rror.com/assets/image/merror-main.png' });
        }
    }
}