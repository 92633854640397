import { Data } from './types';
import { AssetFileInfo } from './assetutil';
import { io, Socket } from 'socket.io-client';
import { ReqMessage } from '../app/server';

export class CommonUtil {
    private static now = (new Date()).getTime();

    static ProbTest = (ratio: number): boolean => {
        if (ratio < 0) ratio = 0;
        else if (ratio > 1) ratio = 1;
        return Math.random() < ratio;
    };
    static RandomNum = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return min + Math.floor(Math.random() * (max - min + 1));
    };
    static RandomDate = (daterange = 30): Date => {
        const r = CommonUtil.RandomNum(0, 24 * 3600 * 1000 * daterange);
        return new Date(CommonUtil.now - r);
    };
    static getLocalDatetimeString = (date: Date): string => {
        const d = new Date(date);
        const localtime = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
        const iso = localtime.toISOString();
        return iso.substring(0, 10) + ' ' + iso.substring(11, 19);
    };

    static getOrderDateString = (date: string | Date): string => {
        date = date instanceof Date ? date.toISOString() : date;
        let newDate = date.slice(0, 10);
        newDate = newDate.replace(/\-/g, '.');
        return newDate
    }

    static assetFirstOption(asset: Data.Asset): Data.AssetOption {
        const keys = Object.keys(asset.options) as Data.AssetOptionType[];
        return asset.options[keys[0]] as Data.AssetOption;
    }
    static assetOptionsAsArray(asset: Data.Asset): Data.AssetOption[] {
        const result: Data.AssetOption[] = [];
        for (const type of Data.AssetOptionTypes) {
            const option = asset.options[type];
            if (option !== undefined) result.push(option);
        }
        return result;
    }
    static getDefaultOptionType(asset: Data.Asset | AssetFileInfo): Data.AssetOptionType {
        if (asset.options.CU100 !== undefined) return 'CU100';
        else if (asset.options.TPO !== undefined) return 'TPO';
        else if (asset.options.SP !== undefined) return 'SP';
        else if (asset.options.RWSC !== undefined) return 'RWSC';
        else return 'CU30';
    }
    private static findTextureRegEx = new RegExp('FBX([1-9])$');
    static findTextureIndex(name: string): number {
        const match = this.findTextureRegEx.exec(name);
        return match === null ? 0 : parseInt(match[1]) - 1;
    }
    static withComma(num: number | undefined) {
        if (num === undefined) return;
        else {
            const integer = Math.floor(num);
            const decimal = Math.round((num - integer) * 100)

            return integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                + (decimal >= 0.01 ? '.' + decimal : '');
        }

    }
    static formatNum(num: number, base = 1000): string {
        const prefix = 'KMB';
        let i = 0;
        for (; i < prefix.length; i++) {
            if (num / base < 1) {
                return '' + Math.round(num * 10) / 10 + prefix.substring(i - 1, i);
            }
            num /= base;
        }
        return '' + Math.round(num * 10) / 10 + prefix.substring(i - 1, i);
    }
    static checkType(data: any, ref: any): boolean {
        if (typeof data !== typeof ref) {
            console.error(typeof data, data, typeof ref, ref);
            return false;
        } else {
            if (typeof ref === 'object') {
                for (const key in ref) {
                    if (typeof ref[key] === 'object') {
                        if (!CommonUtil.checkType(data[key], ref[key])) return false;
                    } else if (data[key] === undefined || typeof data[key] !== typeof ref[key]) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
}

export class AdminClient {
    private socket!: Socket;
    private port: number;
    private host: string;

    constructor(host: string, port: number) {
        this.host = host;
        this.port = port;
    }

    async send(message: ReqMessage, ondata: (data: any) => void) {
        this.socket = io(`ws://${this.host}:${this.port}`);
        return new Promise(resolve => {
            this.socket.send(message);
            this.socket.on('message', result => ondata(result));
            this.socket.on('disconnect', resolve);
        });
    }
}