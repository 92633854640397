<div class="custom-order-container">
    <div class="custom-order-outer">
        <article class="custom-order-desc">
            <h1 [innerHTML]="'Common.Customization'|translate">주문제작</h1>
            <h3 [innerHTML]="'Custom.Message1'|translate">저희 웹사이트에 원하는 에셋을 찾지못하였나요?<br />원하시는 에셋이
                있다면 말씀해주세요.</h3>
            <div class="custom-order-img">
                <img src="/assets/icon/idea.svg" />
            </div>
        </article>
        <div class="custom-order-form-wrap">
            <form class="custom-order-form">
                <textarea #custom_content class="custom-order-textarea"
                    [placeholder]="'Custom.Explanation'|translate"></textarea>
                <label>
                    <img src="/assets/icon/essential.svg" />
                    <span [innerHTML]="'Common.Name'|translate">이름</span>
                </label>
                <input #custom_name type="text" [placeholder]="'Custom.NameInputHolder'|translate"
                    maxlength="40" />
                <label>
                    <img src="/assets/icon/essential.svg" />
                    <span [innerHTML]="'Common.PhoneNumber'|translate">전화번호</span>
                </label>
                <input #custom_tel type="text" [placeholder]="'Custom.PhoneInputHolder'|translate"
                    maxlength="21" />
                <label>
                    <img src="/assets/icon/essential.svg" />
                    <span [innerHTML]="'Common.Email'|translate">이메일</span>
                </label>
                <input #custom_email type="text" [placeholder]="'Custom.MailInputHolder'|translate"
                    maxlength="320" />
                <!-- email max length = {64}@{255} = 64+1+255 = 320 -->
                <button class="common-button1" [innerHTML]="'Custom.Send'|translate"
                    (click)="send()">보내기</button>
            </form>
        </div>
    </div>

    <div *ngIf="insufficient" class="custom-order-popup"
        [style]="validPhoneFormat? null:'padding-bottom: 60px'">
        <div class="custom-order-message" [innerHTML]="message"></div>
        <button class="common-button1" [innerHTML]="'Receipt.Close'|translate"
            [style.margin]="success? '10px 0 0 0': null" (click)="close()"></button>
    </div>
</div>