<main class="refund-container">
    <div class="refund-outer" *ngIf="completeStatus===''">
        <div class=refund-inner>
            <div class="refund-title" [innerHTML]="'Refund.Title'|translate">환불 신청</div>

            <div class="refund-align">
                <div class="refund-left">
                    <div class="refund-list-outer">
                        <div class="refund-list-title"
                            [innerHTML]="'Refund.OrderProduct'|translate">
                            주문상품
                        </div>
                        <div class="refund-list-inner">
                            <div class="refund-list-header-outer">
                                <div class="refund-list-header">
                                    <div class="refund-header-input">
                                        <input id="refund-checkedAll" type="checkbox"
                                            [checked]="checkedAll" (click)="checkAll()">
                                        <label for="refund-checkedAll"></label>
                                    </div>
                                    <div class="refund-header-desc"
                                        [innerHTML]="'Refund.ItemName'|translate">상품명(옵션)
                                    </div>
                                    <div class="refund-header-price"
                                        [innerHTML]="'Refund.PaymentPrice'|translate">결제금액
                                    </div>
                                </div>
                            </div>

                            <div class="refund-item-outer"
                                *ngFor="let asset of payment.list;let assetIndex = index">
                                <div class="refund-item-inner">
                                    <div class="refund-item-input">
                                        <input id="refund-{{assetIndex}}" type="checkbox"
                                            [disabled]="disabled[assetIndex]"
                                            [checked]="checked[assetIndex]"
                                            (click)="check(assetIndex)">
                                        <label for="refund-{{assetIndex}}"></label>
                                    </div>
                                    <div class="refund-item-info">
                                        <div class="refund-item-thumbnail">
                                            <div
                                                style="background-image:url({{ApiUrl}}{{asset.IMGURL1}});">
                                            </div>
                                        </div>
                                        <div class="refund-item-desc">
                                            <div class="refund-item-name">
                                                <div> {{currentLang === 'ko'? asset.ITEMNAMEKOR:
                                                    asset.ITEMNAMEENG}}</div>
                                                <div>{{asset.ITEMTYPE}}
                                                </div>
                                            </div>
                                            <div class="refund-item-price-wrap">
                                                <div class="refund-order-dcprice" [style.color]="disabled[assetIndex]?
                                                '#757575':''">
                                                    <img src="/assets/icon/coupon_color_24.svg"
                                                        [style.display]="(asset.COUPONID == 1 || asset.COUPONID == 2) &&(!disabled[assetIndex]) ? 'block':'none'">
                                                    <span
                                                        [style.display]="(!disabled[assetIndex])? 'block': 'none'">{{(withComma(asset.PURCHASEPRICE)
                                                        =='0' && (asset.currency ==='USD'))? '0.00':
                                                        withComma(asset.PURCHASEPRICE)}}</span>

                                                    <span [innerHTML]=" (disabled[assetIndex]? 'Refund.NonRefundable'
                                                    : ('Common.'+asset.currency)
                                                 ) | translate"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="refund-right">
                    <div>
                        <div class="refund-info">
                            <div class="refund-info-title"
                                [innerHTML]="'Refund.RefundInfo'|translate">환불정보</div>

                            <div class="refund-info-price">
                                <div class="refund-info-price-total">
                                    <div class="refund-info-label"
                                        [innerHTML]="'Order.OrderPrice'|translate">주문 금액</div>
                                    <div class="refund-info-details">
                                        {{(withComma(totalPrice) == '0' && (currencykey
                                        ==='Common.USD'))? '0.00': withComma(totalPrice)}}
                                        <span [innerHTML]="currencykey|translate">원</span>
                                    </div>
                                </div>
                                <div class="refund-info-price-discount">
                                    <div class="refund-info-label">
                                        <span [innerHTML]="'Order.CouponDiscount'|translate">쿠폰
                                            할인</span>
                                        <div class="mo-refund-discount-price">
                                            <div *ngFor="let payment of payment.list">
                                                <div
                                                    *ngIf="payment.COUPONID == 1 || payment.COUPONID ==2">
                                                    <img uk-svg src="/assets/icon/additional.svg"
                                                        style="stroke: #757575; fill: none;">
                                                    <span
                                                        [innerHTML]="payment.COUPONID == 1 ? ('Account.WelcomeCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                        payment.ITEMNAMEENG) + ('Search.'+payment.ITEMTYPE|translate) :  ('Account.FriendCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                        payment.ITEMNAMEENG) + ('Search.'+payment.ITEMTYPE|translate)"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="refund-discount-price">
                                        <div class="refund-info-details">
                                            <span
                                                [style.display]="discountPrice === 0? 'none': 'inline-block'">-</span>{{(withComma(discountPrice)
                                            =='0' && (currencykey ===
                                            'Common.USD'))? '0.00': withComma(discountPrice)}}
                                            <span [innerHTML]="currencykey|translate">원</span>
                                        </div>
                                        <div class="refund-discount-type">
                                            <div *ngFor="let payment of payment.list">
                                                <div
                                                    *ngIf="payment.COUPONID == 1 || payment.COUPONID ==2">
                                                    <img uk-svg style="stroke: #BDBDBD; fill: none;"
                                                        src="/assets/icon/additional.svg">
                                                    <span
                                                        [innerHTML]="payment.COUPONID == 1 ? ('Account.WelcomeCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                        payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate) :  ('Account.FriendCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                        payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate)"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="refund-info-amount">
                                <div class="refund-info-label"
                                    [innerHTML]="'Refund.RefundAmount'|translate">환불 예정 금액</div>
                                <div class="refund-final-amount">
                                    {{(withComma(totalAmount) =='0' && (currencykey ===
                                    'Common.USD'))? '0.00': withComma(totalAmount)}}<span
                                        [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </div>
                        <div class="refund-reference">
                            <div class="refund-desc">
                                <div
                                    style="font-weight: 700;font-size: 14px;color: #E0E0E0;margin-bottom: 8px;">
                                    <span [innerHTML]="'Refund.DescTitle'|translate">안내사항</span>
                                </div>
                                <ul style="margin:0;">
                                    <li [innerHTML]="'Refund.Desc1'|translate"></li>
                                    <li [innerHTML]="'Refund.Desc2'|translate"></li>
                                    <li [innerHTML]="'Refund.Desc3'|translate"></li>
                                    <li [innerHTML]="'Refund.Desc4'|translate"></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="refund-guidelines" [innerHTML]="'Refund.Guidelines'|translate">환불 시 안내사항을
                확인해주세요</div>
            <div class="refund-buttons">
                <button class="common-button2" (click)="refund()"
                    [innerHTML]="'Refund.RequestRefund'|translate">
                    환불하기</button>
                <button class="common-button1" (click)="back()"
                    [innerHTML]="'Refund.GoBack'|translate">
                    돌아가기</button>
            </div>
        </div>
    </div>

    <div class="refund-ok-outer" *ngIf="completeStatus==='ok'">
        <div class="refund-ok-content">
            <div class="refund-ok-image">
                <img src="/assets/image/refund_ok.png" alt="">
            </div>
            <div class="refund-ok-title" [innerHTML]="'Refund.RefundCompleted'|translate">
                환불 신청이 완료되었습니다.</div>
            <div class="refund-ok-desc-outer">
                <ul>
                    <li [innerHTML]="'Refund.Notice1'|translate"></li>
                    <li>
                        <span [innerHTML]="'Refund.Notice2-1'|translate"></span>
                        <span [innerHTML]="'Refund.Notice2-2'|translate"
                            style="font-weight:700;"></span>
                        <span [innerHTML]="'Refund.Notice2-3'|translate"></span>
                    </li>
                    <li [innerHTML]="'Refund.Notice3'|translate"></li>
                </ul>
            </div>
        </div>
        <div class="refund-ok-buttons-outer">
            <button class="common-button1 refund-ok-button-main" routerLink="/store"
                [innerHTML]="'Refund.GoToMainPage'|translate">
                메인페이지로 이동하기</button>
        </div>
    </div>


    <div class="refund-ok-outer" *ngIf="completeStatus==='error'">
        <div class="refund-ok-content">
            <div class="refund-fail-image">
                <img src="/assets/image/refund_fail.png" alt="">
            </div>
            <div class="refund-ok-title" [innerHTML]="errorTitle|translate">
                환불 신청내용에 문제가 있습니다.</div>
            <div class="refund-ok-desc-outer">
                <ul>
                    <li [innerHTML]="errorMessage1|translate"></li>
                    <li [innerHTML]="errorMessage2|translate"></li>
                </ul>
            </div>
        </div>
        <div class="refund-ok-buttons-outer">
            <button class="common-button1 refund-ok-button-main" routerLink="/"
                [innerHTML]="'Refund.GoToMainPage'|translate">
                메인페이지로 이동하기</button>
        </div>
    </div>
</main>