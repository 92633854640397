<div class="index-outer">
    <div class="index-background-outer" [style.opacity]="index===imageindex?'1':'0'"
        *ngFor="let image of [].constructor(imageList.length);let index=index">
        <div class="index-background" *ngIf="index===imageindex"
            [style.background-image]="'url(/assets/image/main/merror-index-' +(index+1) + '-' + (imagesubindex+1)+ '.jpg)'">
        </div>
    </div>
    <div class="index-inner" #inner>
        <div [ngClass]="imageindex == 0 ? 'index-content-outer' :'index-content-outer2'">
            <div class="index-title-{{imageindex+1}}" [innerHTML]=" (imageindex == 0  ? 'Sidebar.FaceKorea'
            : 'Common.MetaverseSeoul'
         ) | translate
         "></div>
            <div class="index-desc-{{imageindex+1}}" [innerHTML]=" (imageindex == 0  ? 'Banner.GateDesc1'
            : 'Banner.GateDesc2'
         ) | translate
         ">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has
            </div>
            <div class="index-button-more" (click)="moveTo()" [innerHTML]=" (imageindex == 0  ? 'Common.TryItFree'
                : 'Common.ReadMore'
             ) | translate
             ">Learn More</div>
        </div>
        <div class="index-nav-outer">
            <div *ngFor="let item of imageList;let index = index;"
                class="index-nav-item {{index===imageindex?'selected':''}}"
                (click)="setImageIndex(index)">
            </div>
        </div>
    </div>
    <div class="index-nav-left" *ngIf="screenSize !== SCREEN_SIZE.mobile"
        (click)="imageindex = imageindex===0?imageList.length-1:imageindex-1"></div>
    <div class="index-nav-right" *ngIf="screenSize !== SCREEN_SIZE.mobile"
        (click)="imageindex = imageindex===imageList.length-1?0:imageindex+1"></div>

    <div class="index-nav-bottom-outer">
        <div class="index-nav-bottom" routerLink="/store">
            <div> ME.RROR Store</div>
            <img class="index-nav-arrow-icon" src="/assets/icon/arrow_right_40.svg" />
        </div>
        <div class="index-nav-bottom-line"></div>
        <div class="index-nav-bottom" routerLink="/business">
            <div> ME.RROR Biz</div>
            <img class="index-nav-arrow-icon" src="/assets/icon/arrow_right_40.svg" />
        </div>
    </div>
</div>