<div class="business-common-outer">
    <div class="business-common-banner-container2"
        style="background-image: url(/assets/image/main/merror-index-2-4.jpg);">
        <div class="business-common-banner-outer">
            <div class="business-common-banner-inner">
                <div class="business-common-banner-content-outer">
                    <div class="business-common-banner-content-inner">
                        <div class="business-common-banner-title2"
                            [innerHTML]="'Custom.CustomModeling'|translate">3D 스캐닝 솔루션
                        </div>
                        <div class="business-common-banner-title3"
                            [innerHTML]="'Custom.ContentsSolution'|translate">All-in-One 3D
                            콘텐츠 솔루션,
                            ME.RROR
                        </div>
                        <div class="business-common-banner-desc business-margin"
                            [innerHTML]="'Custom.BannerDesc1'|translate">
                            산업분야에서 디지털 트윈은 제품 또는 공정의 상태를 실시간 모니터링하고, 문제점을 파악하여 대응할 수 있게 합니다. 또한, 3D
                            시뮬레이션을
                            수행함으로써 생산라인을 최적화 할 수 있습니다.
                        </div>
                        <div class="business-common-banner-buttons">
                            <button class="common-button2"
                                [innerHTML]="'ForBusiness.LearnMore'|translate"
                                (click)="scrollToService()">더 알아보기</button>
                            <button class="common-button1"
                                [innerHTML]="'Custom.GetAQuote'|translate"
                                routerLink="/business/quotation">견적
                                문의하기</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="business-common-title-container" #title_container>
        <div class="business-common-title-outer">
            <div class="business-common-title-inner">
                <div class="business-common-title-contents">
                    <div class="business-common-title-text1"
                        [innerHTML]="'Custom.ModelingService'|translate">3D 모델링 서비스</div>
                    <div class="business-common-title-text2"
                        [innerHTML]="'Custom.ModelingSubTitle'|translate">산업분야에서 디지털 트윈은 매우
                        다양하게 활용되고 있습니다.
                        디지털
                        트윈에서
                        3D
                        스캔은 중요한 구성 요소 중 하나입니다. </div>
                    <div class="business-common-title-text3"
                        [innerHTML]="'Custom.ModelingDesc1'|translate">
                        3D 스캔을 통해 얻은 데이터는 디지털 트윈에서 물체의 모양, 크기, 구조 등의 정보를 제공하며,
                        <br />
                        다양한 센서 데이터와 결합하여 물체의 모든 측면을 정확하게 파악할 수 있게 합니다.
                        <br />
                        디지털 트윈의 정확도와 신뢰성을 보장하기 위해 3D 스캐닝 과정은 보다 정확하고 정밀하게 작업되는 것이 중요합니다.
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="business-common-marquee-container">
        <div class="business-common-marquee-outer">
            <div class="business-common-marquee-inner">
                <div class="business-common-marquee-wrap">
                    <div class="business-common-marquee">
                        <ul class="business-common-marquee-content">
                            <li *ngFor="let partner of partnerTop; let index=index"
                                class="business-common-marquee_inner">
                                <img class="business-common-partner-logo"
                                    src="/assets/image/business/partner/{{partner}}.png" />
                            </li>
                        </ul>
                    </div>
                    <div class="business-common-marquee">
                        <ul class="business-common-marquee-content1">
                            <li *ngFor="let partner of partnerBottom; let index=index"
                                class="business-common-marquee_inner">
                                <img class="business-common-partner-logo"
                                    src="/assets/image/business/partner/{{partner}}.png" />
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="business-common-desc-container2">
        <div class="business-common-desc-outer">
            <div class="business-common-desc-inner">
                <div class="business-common-desc1" *ngFor="let item of descinfo;let index = index;">
                    <div class="business-common-carousel-wrap2">
                        <div *ngIf="(index === 0) || (index ===1)"
                            class="business-common-beforeafter-container">
                            <div class="business-common-beforeafter-outer"
                                id="business-common-beforeafter-outer-{{index}}">
                                <div class="business-common-beforeafter-inner">
                                    <img class="beforeafter-slider-img image-before-{{index}}"
                                        src="/assets/image/business/custom/slider/{{item.imagecount[0]}}.png"
                                        alt="" />
                                    <img class="image-after"
                                        style="width: 100%; height: 100%; object-fit: cover;"
                                        src="/assets/image/business/custom/slider/{{item.imagecount[1]}}.png"
                                        alt="" />
                                </div>
                                <input type="range" min="0" max="100" value="50"
                                    aria-label="Percentage of before photo shown"
                                    class="beforeafter-slider" id="beforeafter-slider-{{index}}" />
                                <div class="slider-line slider-line-{{index}}" aria-hidden="true">
                                </div>
                                <div class="slider-button slider-button-{{index}}"
                                    aria-hidden="true">
                                    <div
                                        style="width: 40%; height: 20%; display: flex; justify-content: center;align-items: center;">
                                        <img src=" /assets/icon/chevron_left_24.svg" />
                                        <img src=" /assets/icon/chevron_right_24.svg" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="(index === 2)" class="wrapper">
                            <div>
                                <video muted autoplay loop playsinline
                                    id="business-entertainment-video" #enterVideo>
                                    <source src="/assets/image/business/custom/enterpage_video.mp4"
                                        type="video/mp4">
                                </video>
                            </div>
                        </div>
                        <div *ngIf="(index === 3)" class="wrapper">
                            <div>
                                <video muted autoplay loop playsinline id="business-modeling-video"
                                    #modelingVideo>
                                    <source
                                        src="/assets/image/business/custom/modelingpage_video.mp4"
                                        type="video/mp4">
                                </video>
                            </div>
                        </div>
                        <div *ngIf="(index === 4)">
                            <img class="business-common-beforeafter-img"
                                src="/assets/image/business/custom/{{item.imagecount[0]}}.png"
                                alt="" />
                        </div>
                    </div>
                    <div class="business-common-desc-content2">
                        <div class="business-common-desc-title" [innerHTML]="item.title|translate">
                            {{item.title}}</div>
                        <div class="business-common-desc-text-wrap2">
                            <div class="business-common-desc-subtitle">
                                <div [innerHTML]="item.desc|translate">
                                </div>
                            </div>
                            <div class="business-common-desc-paragraph">
                                <div [innerHTML]="item.paragraph|translate">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-common-whymerror-container">
        <div class="business-common-whymerror-outer">
            <div class="business-common-whymerror-inner">
                <div class="business-common-title-text1">Why ME.RROR?</div>
                <div class="business-common-whymerror-content-outer">
                    <ul class="business-common-whymerror-item">
                        <li *ngFor="let item of whymerrorinfo;let index = index"
                            class="business-common-whymerror-icon-wrap">
                            <img
                                [src]="'/assets/image/business/industrial-whymerror' + (index+1) + '.png'" />
                            <div class="business-common-whymerror-icon-title"
                                [innerHTML]="item.title|translate">{{item.title}}
                            </div>
                            <div class="business-common-whymerror-icon-desc"
                                [innerHTML]="item.desc|translate">{{item.desc}}</div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="business-common-video-container" #videoContainer>
        <div class="business-common-video-outer">
            <div class="business-common-video-inner">
                <video muted autoplay loop playsinline id="business-custom-video" #customVideo>
                    <source src="/assets/image/business/custom/custompage_video.mp4"
                        type="video/mp4">
                </video>
                <div>
                    <div class="business-common-video-text-wrap">
                        <div class="business-common-video-text"
                            [innerHTML]="'Custom.TextAnimation1'|translate">편리한
                        </div>
                    </div>
                    <div class="business-common-video-text-wrap">
                        <div class="business-common-video-text"
                            [innerHTML]="'Custom.TextAnimation2'|translate">몰입도 높은
                            현실감
                        </div>
                    </div>
                    <div class="business-common-video-text-wrap">
                        <div class="business-common-video-text"
                            [innerHTML]="'Custom.TextAnimation3'|translate">최상급 품질
                        </div>
                    </div>
                    <div class="business-common-video-text-wrap">
                        <div class="business-common-video-text"
                            [innerHTML]="'Custom.TextAnimation4'|translate">입체적 디테일
                        </div>
                    </div>
                    <div class="business-common-video-text-wrap">
                        <div class="business-common-video-text"
                            [innerHTML]="'Custom.TextAnimation5'|translate">다양한 활용성
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="businss-common-slider-container">
        <div class="business-common-slider-outer">
            <div class="business-common-slider-inner">
                <div>
                    <div class="business-img-slider-outer" #banner_slide_outer>
                        <div class="business-img-slider-inner">
                            <div *ngFor="let costume of costumeList;let i = index">
                                <div class="business-img-slider-item-outer">
                                    <div class="business-img-slider-item-inner"
                                        [style.background-image]="costume.url">
                                        <div class="business-img-slider-item-blur"
                                            *ngIf=" i !== costumeIndex">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="businss-common-slider-prev-outer" style="color: #f4f4f4;"
                            (click)="prevCostume()">
                            <img uk-svg src="/assets/icon/chevron_left_24.svg"
                                style="width: 50%;height: 50%;" />
                        </div>
                        <div class="businss-common-slider-next-outer" style="color: #f4f4f4;"
                            (click)="nextCostume()">
                            <img uk-svg src="/assets/icon/chevron_right_24.svg"
                                style="width: 50%;height: 50%;" />
                        </div>
                    </div>
                </div>
                <div class="business-common-slider-desc"
                    [innerHTML]="'Custom.CustomModeling'|translate">커스텀 3D 모델링</div>
            </div>
        </div>
    </div>

    <div class="business-common-pricing-outer">
        <div class="business-common-pricing-inner">
            <div class="business-common-title-text1">
                <div [innerHTML]="'ForBusiness.Pricing'|translate">Pricing</div>
            </div>
            <div class="business-common-pricing-text2"
                [innerHTML]="'ForBusiness.PircingDesc1'|translate">It's inexpensive! If you
                meet
                ME.RROR</div>
            <div class="fb-solution-pricing-container">
                <div class="fb-solution-pricing-wrap">
                    <div class="fb-solution-price-plan">
                        <h2 class=" fb-solution-plan-title fb-solution-plan-low"
                            [innerHTML]="'ForBusiness.Plan1'|translate">
                            3D Content Production
                        </h2>
                        <div class="fb-solution-price">
                            <div>
                                <div>
                                    from<span
                                        [innerHTML]="'ForBusiness.Plan1Price'|translate">$50</span>/Model
                                </div>
                            </div>

                        </div>
                        <ul class="fb-solution-plan-benefit">

                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.AboutPlan1'|translate">Source
                                    files
                                    included: fbx</span>
                            </li>

                            <li
                                style="align-items: center;display: flex;justify-content: center; margin: 20px 0;">
                                <img src="/assets/icon/ellipse_business.svg" />
                            </li>

                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.Foruse'|translate">For 3D
                                    Product
                                    Viewers, Metaverse, AR/VR or Games</span>
                            </li>


                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.3DExpert'|translate">3D
                                    Expert
                                    Consultation</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.3DModel'|translate">3D
                                    Model:
                                    Low-Poly / High-Poly</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.FileFormats'|translate">File
                                    formats: obj, fbx, glb, stl, usd, dae</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.TextandMat'|translate">Textures
                                    and
                                    materials: PBR workflow, 2K</span>
                            </li>
                            <div>
                                <button class="fb-solution-plan-button fb-solution-plan-low"
                                    routerLink="/business/quotation"
                                    [innerHTML]="'ForBusiness.Quote'|translate">Get
                                    a quote</button>
                            </div>

                        </ul>

                    </div>

                    <div class="fb-solution-price-plan">
                        <h2 class="fb-solution-plan-title fb-solution-plan-middle"
                            [innerHTML]="'ForBusiness.Plan2'|translate">
                            3D Content Production
                        </h2>
                        <div class="fb-solution-price">
                            <div>
                                <div>
                                    from<span
                                        [innerHTML]="'ForBusiness.Plan2Price'|translate">$120</span>/Model
                                </div>
                            </div>

                        </div>
                        <ul class="fb-solution-plan-benefit">

                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span
                                    [innerHTML]="'ForBusiness.AboutPlan2'|translate">Photogrammetric
                                    3D
                                    Scan for Hyper Realistic</span>
                            </li>

                            <li
                                style="align-items: center;display: flex;justify-content: center; margin: 20px 0;">
                                <img src="/assets/icon/ellipse_business.svg" />
                            </li>

                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.Foruse'|translate">For 3D
                                    Product
                                    Viewers, Metaverse, AR/VR or Games</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.3DExpert'|translate">3D
                                    Expert
                                    Consultation</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.3DModel'|translate">3D
                                    Model:
                                    Low-Poly / High-Poly</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.FileFormats'|translate">File
                                    formats: obj, fbx, glb, stl, usd, dae</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.TextandMat'|translate">Source
                                    files
                                    included: fbx</span>
                            </li>
                            <div>
                                <button class="fb-solution-plan-button fb-solution-plan-middle"
                                    routerLink="/business/quotation"
                                    [innerHTML]="'ForBusiness.Quote'|translate">Get
                                    a
                                    quote</button>
                            </div>

                        </ul>
                    </div>

                    <div class="fb-solution-price-plan">
                        <h2 class="fb-solution-plan-title fb-solution-plan-high"
                            [innerHTML]="'ForBusiness.Plan3'|translate">
                            3D Content Production
                        </h2>
                        <div class="fb-solution-price">
                            <div>
                                <div>
                                    <span [innerHTML]="'ForBusiness.ContactUs'|translate"
                                        style="padding:0;margin:0;">Contact
                                        us</span>
                                </div>
                            </div>

                        </div>
                        <ul class="fb-solution-plan-benefit">

                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.AboutPlan3'|translate">Order
                                    bespoke modeling or lifestyle design renders for your
                                    product</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.About2Plan3'|translate">Order
                                    bespoke modeling or lifestyle design renders for your
                                    product</span>
                            </li>

                            <li
                                style="align-items: center;display: flex;justify-content: center; margin: 20px 0;">
                                <img src="/assets/icon/ellipse_business.svg" />
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.Condition'|translate">Specific
                                    conditions are required</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.EnterForuse'|translate">Specific
                                    conditions are required</span>
                            </li>
                            <li>
                                <span class="fb-solution-plan-check"></span>
                                <span [innerHTML]="'ForBusiness.EnterBenefits'|translate">Specific
                                    conditions are required</span>
                            </li>
                            <div>
                                <button class="fb-solution-plan-button fb-solution-plan-high"
                                    (click)="util.showChannelTalk()"
                                    [innerHTML]="'ForBusiness.Contact'|translate">Contact us
                                    to order</button>
                            </div>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="business-common-gallery-container2">
        <div class="business-common-gallery-outer">
            <div class="business-common-gallery-inner-wrap">
                <div class="business-common-title-text1" [innerHTML]="'Custom.Blog'|translate">
                    ME.RROR 블로그</div>
                <div class="business-common-gallery-inner2">
                    <div class="business-common-gallery-img-wrap"
                        *ngFor="let portfolio of portfolioList" (mouseenter)="portfolio.hover=true"
                        (mouseleave)="portfolio.hover=false">
                        <a [href]="'https://merror.tistory.com/' + portfolio.address"
                            target="_blank">
                            <img
                                [src]="'/assets/image/business/portfolio/portfolio' + (portfolio.order) + '-' +(portfolio.hover? '2' : '1') +'.png'" />
                            <div class="business-common-gallery-desc"
                                [style.display]="portfolio.hover? 'flex': 'none'">
                                <div style="padding: 0 8px;">
                                    <div class="business-common-gallery-desc1"
                                        [innerHTML]="portfolio.projectinfo[0]|translate">
                                    </div>
                                    <div class="business-common-gallery-desc2"
                                        [innerHTML]="portfolio.projectinfo[1]|translate">
                                    </div>
                                </div>

                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>