<div class="custom-payment-outer">
    <div class="custom-payment-content" *ngIf="!completed && !error">
        <div class="custom-payment-image">
            <img src="assets/image/body_scan.jpg">
        </div>
        <div class="custom-payment-info">
            <div class="custom-payment-info-name">포토부스 스캔</div>
            <div class="custom-payment-info-price">₩1,100,000</div>
            <div class="custom-payment-info-divider"></div>
            <div class="custom-payment-info-text1">Option</div>
            <div class="custom-payment-info-option-outer">
                <div class="custom-payment-info-option-name">전신스캔</div>
                <div class="custom-payment-info-option-price">₩1,100,000</div>
                <div class="custom-payment-info-option-selected"></div>
            </div>
            <div class="custom-payment-info-divider"></div>
            <div class="custom-payment-method-list">
                <div class="custom-payment-method" (click)="setPayMethod('card', 0)">신용카드</div>
                <div class="custom-payment-method" (click)="setPayMethod('trans', 1)">실시간이체</div>
                <div class="custom-payment-method" (click)="setPayMethod('kakaopay', 2)">카카오페이</div>
                <div class="custom-payment-method" (click)="setPayMethod('naverpay', 3)">네이버페이</div>
            </div>
            <div class="custom-payment-info-button-outer">
                <div class="common-button1 custom-payment-info-button1" (click)="order()">바로 구매하기
                </div>
            </div>
        </div>
    </div>

    <div class="order-outer" *ngIf="completed">
        <div class="order-inner">
            <div class="payment-confirm-outer">
                <div class="payment-confirm-inner">
                    <div class="payment-confirm-image">
                        <img src="/assets/image/payment_ok.png" alt="">
                    </div>
                    <div>
                        <h3 class="payment-confirm-title"
                            [innerHTML]="'Order.OrderCompleted'|translate">
                            결제가
                            완료되었습니다!</h3>
                        <div class="payment-confirm-thanks" [innerHTML]="'Order.Thanks'|translate">
                            구매해주셔서 감사합니다.
                        </div>
                    </div>
                </div>

                <div class="payment-confirm-button-wrap">
                    <button class="comment_link common-button2" routerLink="/"
                        [innerHTML]="'Account.GoToMainPage'|translate">
                        메인화면으로 이동
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div class="order-inner" *ngIf="error">
        <div class="payment-confirm-outer">
            <div class="payment-confirm-inner">
                <div class="payment-confirm-image">
                    <img src="/assets/image/payment_fail.png" alt="">
                </div>
                <div>
                    <h3 class="payment-confirm-title" [innerHTML]="'Order.OrderFailed'|translate">
                        결제 과정에 문제가 있습니다.</h3>
                </div>

                <ul class="payment-fail-reference">
                    <li [innerHTML]="'Order.OrderFailedMsg1'|translate">이전으로 돌아가 결제를 다시 진행해주시기
                        바랍니다.
                    </li>
                    <li [innerHTML]="'Order.OrderFailedMsg2'|translate">결제 중 페이지를 새로고침하지 않도록
                        유의해주세요.
                    </li>
                    <li *ngIf="currency === 'KRW'" [innerHTML]="'Order.OrderFailedMsg3'|translate">
                        현재
                        카드사 사정으로 삼성카드 결제(간편결제
                        포함)는
                        지원되지 않습니다.</li>
                </ul>
            </div>

            <div class="payment-confirm-button-wrap">
                <button class="common-button2 payment-confirm-button"
                    [innerHTML]="'Common.CustomerCenter'|translate">고객센터
                    문의하기</button>
                <button class="common-button1 error-button" (click)="retry()"
                    [innerHTML]="'Signup.GoingBack'|translate">돌아가기</button>

            </div>

        </div>
    </div>

</div>