import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ThreeCanvas } from 'src/app/lib/threecanvas';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-asset-viewer',
    templateUrl: './asset-viewer.component.html',
    styleUrls: ['./asset-viewer.component.scss', 'spinner.scss']
})
export class AssetViewerComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() screenPercentage = 2;
    three: ThreeCanvas;
    private asset: Data.Asset | null = null;
    private loadingPhase = -1;
    private loadingSvgElment: HTMLElement | null = null;

    opacityDiffuse = 1;
    opacityMatcap = 0;
    opacityWireframe = 0;
    opacityVertexNormal = 0;

    constructor(private client: ApiClientService) {
        this.three = new ThreeCanvas();
    }

    ngOnInit(): void {
        window.addEventListener('resize', () => this.onResize());
    }

    ngAfterViewInit(): void {
        this.three.init(document.getElementById('canvas') as HTMLElement, this.screenPercentage);
        this.showLoading();
        this.three.hideCanvas();
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', () => this.onResize());
    }

    onResize() {
        this.three.render(0, 0, true);
    }

    async loadByAsset(asset: Data.Asset) {
        this.asset = asset;
        const url = this.client.baseurl + this.asset.asset;
        const type = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        const optiontype = asset.asset.split('-')[1] as Data.AssetOptionType;
        const option = asset.options[optiontype] as Data.AssetOption;
        const maps = option.info.texture.maps;
        if (type === 'gltf' || type === 'glb') {
            await this.three.loadGLTF(url);
        } else if (type === 'obj') {
            await this.three.loadOBJ(url);
        } else if (type === 'fbx') {
            await this.three.loadFBX(asset.assetno, url, maps, maps.length === 0);
            if (asset.tag.includes('사람')) this.three.setCameraForHuman();
        }
        this.hideLoading();
        setTimeout(() => {
            this.three.render(0, 0, true);
            this.three.showCanvas(0.5);
        }, 400);
    }
    loadModelByArrayBuffer(buffer: ArrayBuffer) {
        this.three.parseFBX(buffer);
    }
    updateTextureByUrl(url: string, type: Data.TextureMapType = 'DIFF') {
        this.three.updateTexture(url, type);
    }

    showLoading() {
        const spinner = document.getElementsByClassName('spinner1')[0] as HTMLDivElement;
        spinner.style.opacity = '1';
    }
    hideLoading() {
        const spinner = document.getElementsByClassName('spinner1')[0] as HTMLDivElement;
        spinner.style.opacity = '0';
    }

    setMatCap(enable?: boolean) {
        const flag = this.three.setMatCap(enable);
        this.opacityDiffuse = flag ? 0 : 1;
        this.opacityMatcap = flag ? 1 : 0;
    }
    setWireframe(enable?: boolean) {
        const flag = this.three.setWireframe(enable);
        this.opacityWireframe = flag ? 1 : 0;
    }
    setVertexNormal(enable?: boolean) {
        const flag = this.three.setVertexNormal(enable);
        this.opacityVertexNormal = flag ? 1 : 0;
    }
}