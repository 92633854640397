import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Data } from "src/server/lib/types";
import { AppInjector } from '../app.module';
import { StoreIndexComponent } from '../pages/store-index.component';
import { ApiClientService, BannerOption } from "./api-client.service";

export interface SearchCondition {
    condition: Data.ESearchCondition;
    searchtext: string[];
    sort: Data.ESortBy;
    minprice: number;
    maxprice: number;
    minpolygon: number;
    maxpolygon: number;
    categoryInfo: Data.Category;
}

interface SearchBehavior {
    updateSearchCondition(searchCondition: SearchCondition, obj?: any): Promise<SearchCondition>;
}

interface FilterBehavior {
    updateFilterCondition(searchCondition: SearchCondition, obj?: any): Promise<SearchCondition>;
}

interface BannerBehavior {
    updateBannerCondition(searchCondition: SearchCondition, val: BannerOption): Promise<SearchCondition>;
}

/* Search(SearchBar, Filter, Banner) Startegy */
class Search {
    searchConition!: SearchCondition;
    categoryList = ['Sidebar.Human', 'Sidebar.Object', 'Sidebar.Space', 'Sidebar.SpecialDeal'];

    searchBehavior!: SearchBehavior;
    filterBehavior!: FilterBehavior;
    bannerBehavior!: BannerBehavior;

    constructor(private client: ApiClientService) {
        this.client.searchSubject.subscribe((condition) => {
            this.searchConition = condition;
        });
    }

    public setSearchBehavior(searchBehavior: SearchBehavior) {
        this.searchBehavior = searchBehavior;
    }

    public setFilterBehavior(filterBehavior: FilterBehavior) {
        this.filterBehavior = filterBehavior;
    }

    public setBannerBehavior(bannerBehavior: BannerBehavior) {
        this.bannerBehavior = bannerBehavior;
    }

    public async performSearch(obj?: any) {
        this.searchConition = await this.searchBehavior.updateSearchCondition(this.searchConition, obj);
        console.log('Perform Search :: ', this.searchConition);
        this.client.searchSubject.next(this.searchConition);
    }

    public async performFilter(obj?: any) {
        this.searchConition = await this.filterBehavior.updateFilterCondition(this.searchConition, obj);
        console.log('Perfrom Filter :: ', this.searchConition);
        this.client.categorySubject.next({ subCategory: obj, onCategory: true });
    }

    public async performBanner(val: BannerOption) {
        this.searchConition = await this.bannerBehavior.updateBannerCondition(this.searchConition, val);
        console.log('Perfrom Banner :: ', this.searchConition);
        this.client.bannerSubject.next({ category: val.category, method: val.method });
    }
}

@Injectable({
    providedIn: 'root'
})
export class SearchBar extends Search {
    constructor(client: ApiClientService) {
        super(client);
        console.log('condition', this.searchConition);
    }
}

export class AddSearchBehavior implements SearchBehavior {
    async updateSearchCondition(searchCondition: SearchCondition, texts: string[]): Promise<SearchCondition> {
        // const searchtext = new Set(searchCondition.searchtext);
        // for (const text of texts) {
        //     searchtext.add(text.toLowerCase());
        // }
        return {
            ...searchCondition, searchtext: [...texts]
        };
    }
};


export class toggleSearchBehavior implements SearchBehavior {
    async updateSearchCondition(searchCondition: SearchCondition, texts: string[]): Promise<SearchCondition> {
        const searchtext = new Set(searchCondition.searchtext);
        for (const text of texts) {
            searchtext.add(text);
        }
        return {
            ...searchCondition, searchtext: [...searchtext]
        };
    }
};

export class PopSearchBehavior implements SearchBehavior {
    async updateSearchCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.searchtext.pop();
        return searchCondition;
    }
}

export class ClickDeleteSearchBehavior implements SearchBehavior {
    async updateSearchCondition(searchCondition: SearchCondition, text: string): Promise<SearchCondition> {
        searchCondition.searchtext = searchCondition.searchtext.filter((word) => text != word);
        return searchCondition;
    }
}

export class ClearSearchBehavior implements SearchBehavior {
    async updateSearchCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.searchtext = [];
        searchCondition.sort = Data.ESortBy.Recent;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;
        return searchCondition;
    }
}

@Injectable({
    providedIn: 'root'
})
export class Filter extends Search {
    constructor(client: ApiClientService) {
        super(client);
        console.log('condition', this.searchConition);
    }
}

export class SetCategory implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, category: string): Promise<SearchCondition> {
        if (searchCondition.searchtext.includes(category)) {
            searchCondition.searchtext = searchCondition.searchtext.filter((text) =>
                text != category
            );
        }
        else {
            searchCondition.searchtext = [category];
        }
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;
        return searchCondition;
    }
}

export class SetPolygon implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, index: number): Promise<SearchCondition> {
        const polygons = [10000, 100000, 999999999];

        switch (index) {
            case 0: {
                searchCondition.minpolygon = 1;
                searchCondition.maxpolygon = polygons[0];
                break;
            }
            case 1: {
                searchCondition.minpolygon = polygons[0];
                searchCondition.maxpolygon = polygons[1];
                break;
            }
            case 2: {
                searchCondition.minpolygon = polygons[1];
                searchCondition.maxpolygon = 999999999;
                break;
            }
            default: {
                searchCondition.minpolygon = 0;
                searchCondition.maxpolygon = 0;
            }
        }
        return searchCondition;
    }
}

export class ClearFilter implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;
        searchCondition.categoryInfo.subCategory = [];
        searchCondition.categoryInfo.isGameready = false;

        return searchCondition;
    }
}


export class ClearSearchFilter implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;
        searchCondition.searchtext = [];
        searchCondition.categoryInfo.category = [];
        searchCondition.categoryInfo.subCategory = [];
        searchCondition.categoryInfo.isGameready = false;

        return searchCondition;
    }
}

export class SetPrice implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, price: { minprice: number, maxprice: number; }): Promise<SearchCondition> {
        searchCondition.minprice = price.minprice;
        searchCondition.maxprice = price.maxprice;
        return searchCondition;
    }
}
export class SetCondition implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, flag: Data.ESearchCondition): Promise<SearchCondition> {
        searchCondition.condition |= flag;
        return searchCondition;
    }
}

export class UnsetCondition implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, flag: Data.ESearchCondition): Promise<SearchCondition> {
        searchCondition.condition &= flag;
        return searchCondition;
    }
}

export class ToggleCondition implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, flag: Data.ESearchCondition): Promise<SearchCondition> {
        if ((searchCondition.condition & flag) == flag) {
            searchCondition.condition &= ~flag;
        }
        else {
            searchCondition.condition |= flag;
        }
        return searchCondition;
    }
}

export class toggleGameReady implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.categoryInfo.isGameready = !searchCondition.categoryInfo.isGameready
        return searchCondition;
    }
}

export class AddSubCategory implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, newSubcategory: string[]): Promise<SearchCondition> {

        const subCategory = new Set(searchCondition.categoryInfo.subCategory);
        for (const text of newSubcategory) {
            subCategory.add(text.toLowerCase() as Data.subCateogry);
        }

        searchCondition.categoryInfo.subCategory = [...subCategory];

        return searchCondition;
    }
}

export class PopSubCategory implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition, newSubcategory: string[]): Promise<SearchCondition> {
        searchCondition.categoryInfo.subCategory = searchCondition.categoryInfo.subCategory.filter((text) => !newSubcategory.includes(text));
        return searchCondition;;
    }
}

export class DeleteClothingFilterBehavior implements FilterBehavior {
    async updateFilterCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        const clothingCategory = ['clothings', 'top', 'bottom', 'outer', 'footwear', 'accessories']

        searchCondition.categoryInfo.subCategory = searchCondition.categoryInfo.subCategory.filter((text) => !clothingCategory.includes(text));
        return searchCondition;
    }
}

@Injectable({
    providedIn: 'root'
})
export class Banner extends Search {
    constructor(client: ApiClientService) {
        super(client);
        console.log('condition', this.searchConition);
    }
}

export class SetBannerCategory implements BannerBehavior {
    async updateBannerCondition(searchCondition: SearchCondition, val: BannerOption): Promise<SearchCondition> {
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;

        searchCondition.searchtext = [val.category];
        return searchCondition;
    }
}

export class ClearBannerBehavior implements BannerBehavior {
    async updateBannerCondition(searchCondition: SearchCondition): Promise<SearchCondition> {
        searchCondition.condition = Data.ESearchCondition.None;
        searchCondition.searchtext = [];
        searchCondition.sort = Data.ESortBy.Recent;
        searchCondition.minprice = 0;
        searchCondition.maxprice = 0;
        searchCondition.minpolygon = 0;
        searchCondition.maxpolygon = 0;

        return searchCondition;
    }
}
/* Search(SearchBar, Filter, Banner) Startegy */


/* Category Filter CSS Styler */
export class CategoryFilterStyler {
    static polygonTagList = [{ name: '0 to 10k', condition: [1, 10000] }, { name: '10k to 100k', condition: [10000, 100000] }, { name: '100k~', condition: [100000, 999999999] }];

    static initCategoryStyle() {
        const categorylistElement = document.querySelectorAll('.sub-category-list') as unknown as HTMLElement[];
        const categoryFocusStyle = 'category-focused';

        for (let element of categorylistElement) {
            element.classList.remove(categoryFocusStyle);
        }
    }

    static initConditionStyle() {
        const target = document.querySelectorAll('.type-focused');
        for (let i = 0; i < target.length; i++) {
            const item = target[i] as HTMLElement;
            item.classList.remove('type-focused');
        }
    }

    static initPolygonStyle() {

    }

    static initPriceStyle() {
        const minInput = (document.getElementById('rangeMin') as HTMLInputElement);
        const maxInput = (document.getElementById('rangeMax') as HTMLInputElement);

        const thumbLeft = (document.getElementById('thumb-left') as HTMLDivElement);
        const thumbRight = (document.getElementById('thumb-right') as HTMLDivElement);
        const range = (document.getElementById('filter-range') as HTMLDivElement);

        minInput.value = '0';
        thumbLeft.style.left = 0 + "%";
        range.style.left = 0 + "%";

        maxInput.value = '400';
        thumbRight.style.right = 0 + "%";
        range.style.right = 0 + "%";
    }

    static initSubCategoryStyle() {
        const subcategoryInputs = document.querySelectorAll('.subcategory-list-input:checked');
        subcategoryInputs.forEach((input) => {
            (input as HTMLInputElement).checked = false;
        });
    }


    static initFilterStyle() {
        this.initPolygonStyle();
        this.initPriceStyle();
        this.initConditionStyle();
    }

    static initCategoryFilterStyle() {
        this.initCategoryStyle();
        this.initConditionStyle();
        this.initPriceStyle();
        this.initPolygonStyle();
    }
}

/* Category Filter CSS Styler */

@Injectable({
    providedIn: 'root'
})
export class SidebarUtilService {
    private showCategory = false;
    indexComponent: StoreIndexComponent | null = null;
    setIndexComponent(component: StoreIndexComponent | null) {
        this.indexComponent = component;
    }
    showCategoryForMobile() {
        if (this.indexComponent === null) {
            const router = AppInjector.get(Router);
            router.navigateByUrl('/');
            this.showCategory = true;
        } else {
            // this.indexComponent.sidebarRef.toggleSubNavMobile(true, 'category');
        }
    }
    hideCategoryForMobile() {
        if (this.indexComponent !== null) {
            // this.indexComponent.sidebarRef.toggleSubNavMobile(false);
        }
    }
    checkShowCategory() {
        if (this.showCategory) {
            this.showCategory = false;
            return true;
        }
        return false;
    }
}