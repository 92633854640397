import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';
import {
    AmbientLight, Box3, BufferGeometry, Color, ColorRepresentation, DoubleSide,
    Float32BufferAttribute, Group, Material, Mesh,
    MeshBasicMaterial, MeshMatcapMaterial, MeshPhongMaterial, MeshStandardMaterial,
    Object3D, PerspectiveCamera, PlaneGeometry, PointLight, SRGBColorSpace,
    Scene, SkinnedMesh, Sphere, Texture, TextureLoader, Vector3, WebGLRenderer
} from 'three';
import { Data } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';
import * as pica from 'pica';

export type ModelType = 'FBX' | 'OBJ' | 'GLTF' | 'GLB';
export type PresetType = 'Default' | 'Preset1' | 'Preset2' | 'Preset3' | 'Preset4'
    | 'Thumbnail1' | 'Thumbnail2' | 'Thumbnail3' | 'Thumbnail4'
    | 'Thumbnail5' | 'Thumbnail6' | 'Thumbnail7' | 'Thumbnail8';

interface CameraInfo {
    position: { x: number; y: number; z: number };
    quaternion: { x: number; y: number; z: number, w: number };
}
interface WorkerResult {
    object: {
        name: string;
        position: { x: number; y: number; z: number };
        quaternion: { x: number; y: number; z: number, w: number };
        scale: number;
        data: {
            position: ArrayBufferLike;
            uv: ArrayBufferLike | undefined;
            normal: ArrayBufferLike | undefined;
            diffusedataurl: string | undefined;
            normaldataurl: string | undefined;
            alphadataurl: string | undefined;
            aodataurl: string | undefined;
            rodataurl: string | undefined;
            medataurl: string | undefined;
            compdataurl: string | undefined;
            dpdataurl: string | undefined;
        }
    }[];
    camera: undefined | CameraInfo;
}
interface WorkerRequest {
    assetno: number;
    optiontype: 'CU2000' | 'CU100' | 'CU30' | 'RT' | 'SP' | 'RWSC' | 'TPO' | 'TP1' | undefined;
    url: string;
    texturelist: Data.TextureMapType[];
}

/*
 * class ThreeScene, ThreeCanvas
 * https://r105.threejsfundamentals.org/threejs/lessons/threejs-multiple-scenes.html
 */
export class ThreeCanvas {
    private static canvas: HTMLCanvasElement | null = null;
    private static renderer: WebGLRenderer;

    private static gltfLoader: GLTFLoader;
    private static fbxLoader: FBXLoader;
    private static objLoader: OBJLoader;
    private static textureLoader: TextureLoader;

    private initStatic() {
        ThreeCanvas.canvas = document.createElement('canvas');
        ThreeCanvas.renderer = new WebGLRenderer({
            canvas: ThreeCanvas.canvas,
            antialias: true,
            preserveDrawingBuffer: true,
            alpha: true
        });

        ThreeCanvas.gltfLoader = new GLTFLoader();
        ThreeCanvas.fbxLoader = new FBXLoader();
        ThreeCanvas.objLoader = new OBJLoader();
        ThreeCanvas.textureLoader = new TextureLoader();
    }

    element!: HTMLElement;
    context!: CanvasRenderingContext2D;
    scene!: Scene;
    camera!: PerspectiveCamera;
    controls!: OrbitControls;
    vertexnormal!: Group;
    wireframe!: Group;
    background!: Mesh;
    logo!: Mesh;

    ambientlight!: AmbientLight;
    pointlight1!: PointLight;
    pointlight2!: PointLight;

    object: Group | null = null;
    camerainfo: CameraInfo | null = null;
    objectSize = 0;
    material_current: { [key: string]: Material } = {};
    material_diffuse: { [key: string]: Material } = {};
    material_matcap = new MeshMatcapMaterial();
    texturelist: { [key in Data.TextureMapType]: Texture[] }
        = { AO: [], COMP: [], DIFF: [], ME: [], NORM: [], RO: [], OP: [], DP: [], ID: [] };
    preset: PresetType = 'Default';

    renderwidth = 0;
    renderheight = 0;
    objectoptiontype: Data.AssetOptionType | undefined = undefined;

    animationFrameId: null | number = null;
    isOrbitControlsActive = false;
    initOrbitActive = false;
    private orbitControlsTimeout: NodeJS.Timeout | undefined;

    constructor(element?: HTMLElement, screenPercentage = 1) {
        if (element !== undefined) this.init(element, screenPercentage);

        this.material_matcap.side = DoubleSide;
    }

    init(element: HTMLElement, screenPercentage = 1) {
        if (ThreeCanvas.canvas === null) this.initStatic();

        this.element = element;
        this.context = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
        this.element.appendChild(this.context.canvas);
        this.scene = new Scene();
        this.camera = new PerspectiveCamera(45,
            this.element.clientWidth / this.element.clientHeight, 0.1, 8000);
        this.controls = new OrbitControls(this.camera, this.element);

        this.vertexnormal = new Group();
        this.vertexnormal.visible = false;
        this.scene.add(this.vertexnormal);

        //this.scene.add(new AxesHelper(100));

        this.wireframe = new Group();
        this.wireframe.visible = false;
        this.scene.add(this.wireframe);

        const bgplane = new PlaneGeometry(2000, 2000);
        ThreeCanvas.textureLoader.load('/assets/image/viewer_background.png', texture => {
            texture.colorSpace = SRGBColorSpace;
            const material = new MeshBasicMaterial({
                color: 0xffffff,
                map: texture
            });
            this.background = new Mesh(bgplane, material);
            this.background.visible = false;
            this.background.position.set(0, 0, -2400);
            this.camera.add(this.background);
        });
        const logoplane = new PlaneGeometry(1.96, 0.60);
        ThreeCanvas.textureLoader.load('/assets/image/merror_logo.png', texture => {
            texture.colorSpace = SRGBColorSpace;
            const material = new MeshBasicMaterial({
                color: 0xffffff,
                map: texture,
                transparent: true
            });
            this.logo = new Mesh(logoplane, material);
            this.logo.visible = false;
            this.camera.add(this.logo);
        });

        this.renderwidth = this.element.clientWidth * screenPercentage;
        this.renderheight = this.element.clientHeight * screenPercentage;

        window.addEventListener('resize', () => {
            this.renderwidth = this.element.clientWidth * screenPercentage;
            this.renderheight = this.element.clientHeight * screenPercentage;
            this.render();
        });

        this.camera.position.set(10, 0, 0);
        this.scene.add(this.camera);

        this.ambientlight = new AmbientLight();
        this.scene.add(this.ambientlight);

        this.pointlight1 = new PointLight();
        this.pointlight1.visible = false;
        this.camera.add(this.pointlight1);

        this.pointlight2 = new PointLight();
        this.pointlight2.visible = false;
        this.camera.add(this.pointlight2);

        this.controls.addEventListener('change', this.handleControlsChange);
        this.controls.target.set(0, 0, 0);
        this.controls.screenSpacePanning = true;
        // this.controls.enableDamping = true;
        // this.controls.dampingFactor = 0.08;
        this.controls.update();
    }

    handleControlsChange = () => {
        this.render();
    }

    preventScrollEvent(zoom: boolean) {
        this.controls.enableZoom = zoom
    }

    setAnimation() {
        this.controls.removeEventListener('change', this.handleControlsChange);
        this.controls.addEventListener('change', () => {
            if (this.initOrbitActive) this.isOrbitControlsActive = true;

            if (this.orbitControlsTimeout) clearTimeout(this.orbitControlsTimeout);

            this.orbitControlsTimeout = setTimeout(() => {
                this.isOrbitControlsActive = false;
                this.startAnimation();
            }, 2000);
            this.render();
            this.initOrbitActive = true;
        });

        this.startAnimation();

    }

    startAnimation() {
        if (!this.animationFrameId) {
            this.animation();
        }
    }

    setTransparentBackground(transparent: boolean) {
        ThreeCanvas.renderer.setClearAlpha(transparent ? 0 : 1);
    }
    setBackgroundColor(color: ColorRepresentation) {
        ThreeCanvas.renderer.setClearColor(color);
    }

    clear() {
        this.setWireframe(false, false);
        this.setBackground(false, false);
        this.setLogo(false, false);
        this.setMatCap(false, false);
        this.setVertexNormal(false, false);
        this.preset = 'Default';
        this.texturelist = { AO: [], COMP: [], DIFF: [], ME: [], NORM: [], RO: [], OP: [], DP: [], ID: [] };
        if (this.object !== null) {
            this.scene.remove(this.object);
            this.object = null;
            this.render();
        }
    }

    setCameraVerticalRange(min = 0, max = Math.PI) {
        this.controls.minPolarAngle = min;
        this.controls.maxPolarAngle = max;
        this.controls.update();
    }

    render(renderwidth = 0, renderheight = 0, force = false) {
        const renderer = ThreeCanvas.renderer;
        const canvas = renderer.domElement;

        let { left, right, top, bottom, width, height } =
            this.element.getBoundingClientRect();

        if (this.renderwidth > 0) width = this.renderwidth;
        if (this.renderheight > 0) height = this.renderheight;

        if (renderwidth > 0) width = renderwidth;
        if (renderheight > 0) height = renderheight;

        const isOffscreen = bottom < 0 || top > window.innerHeight
            || right < 0 || left > window.innerWidth;
        if (force === false && isOffscreen) return;

        if (canvas.width != width || canvas.height != height) {
            renderer.setSize(width, height, false);
        }
        if (this.context.canvas.width !== width || this.context.canvas.height !== height) {
            this.context.canvas.width = width;
            this.context.canvas.height = height;
        }

        renderer.setScissor(0, 0, width, height);
        renderer.setViewport(0, 0, width, height);

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        if (this.logo !== undefined && this.logo.visible) {
            const ratio = width / height;
            const r22_5 = 22.5 / 180 * Math.PI;
            const r_cur = this.camera.fov * 0.5 / 180 * Math.PI;
            this.logo.position.set(5 * ratio - 1.5, -4.00, -11.90 * Math.tan(r22_5) / Math.tan(r_cur));
        }

        renderer.render(this.scene, this.camera);

        this.context.globalCompositeOperation = 'copy';
        if (canvas.width > 0 && canvas.height > 0) this.context.drawImage(canvas, 0, canvas.height - height, width, height, 0, 0, width, height);
    }

    setCamera(targetObject: Object3D | null = null, camerainfo?: CameraInfo) {

        if (targetObject === null) targetObject = this.object;
        if (camerainfo === undefined && this.camerainfo !== null) camerainfo = this.camerainfo;

        const center = new Vector3();

        if (targetObject !== null) {
            const box = new Box3().setFromObject(targetObject, true);
            //this.scene.add(new Box3Helper(box));
            box.getCenter(center);
            const dx = box.max.x - box.min.x;
            const dy = box.max.y - box.min.y;
            const dz = box.max.z - box.min.z;
            const v = [dx, dy, dz].sort((a, b) => b - a);
            if (camerainfo === undefined) {
                const f = (v[0] * v[0] + v[1] * v[1] + v[2] * v[2]) / (this.objectSize * this.objectSize);
                if (f > 1.8) {
                    this.camera.position.set(0, 0, this.objectSize * 1.6);
                } else {
                    this.camera.position.set(0, 0, v[0] / Math.tan(Math.PI / 8) * 0.52 + dz * 0.5);
                }
            } else {
                const p = camerainfo.position;
                const f = (v[0] * v[0]) / (v[1] * v[1] + v[2] + v[2]);
                center.y -= dy * (f - 1) * 0.01;
                const c = new Vector3(p.x, p.y, p.z).normalize().multiplyScalar(box.min.distanceTo(box.max) * 0.7 + this.objectSize * 0.6);
                this.camera.position.set(c.x, c.y, c.z);
            }

        } else {
            this.camera.position.set(10, 10, 10);
        }

        this.camera.position.add(center);
        this.camera.fov = 45;
        this.controls.target = center;
        this.setCameraVerticalRange();
    }

    setCameraForHuman(distance = 1.8, limitrange = true) {
        const targetObject = this.object;

        const center = new Vector3();

        if (targetObject !== null) {
            const box = new Box3().setFromObject(targetObject, true);
            box.getCenter(center);
            const dx = box.max.x - box.min.x;
            const dy = box.max.y - box.min.y;
            const dz = box.max.z - box.min.z;
            const v = [dx, dy, dz].sort((a, b) => b - a);
            const f = (v[0] * v[0] + v[1] * v[1] + v[2] * v[2]) / (this.objectSize * this.objectSize);
            console.log(f);
            let z = 0;
            if (f > 1.8) {
                z = this.objectSize * 1.6;
            } else {
                z = v[0] / Math.tan(Math.PI / 8) * 0.52 + dz * 0.5;
            }
            z *= distance;
            this.camera.position.set(Math.tan(Math.PI / 180 * 25) * z, Math.tan(Math.PI / 180 * 15) * z, z);
        }

        this.camera.position.add(center);
        this.camera.fov = 25;
        this.controls.target = center;
        if (limitrange) this.setCameraVerticalRange(Math.PI * 0.333, Math.PI * 0.667);
        else this.controls.update();
    }

    setCameraDistance(distance = 1) {
        const camera = new Vector3().subVectors(this.camera.position, this.controls.target);
        camera.multiplyScalar(distance).add(this.controls.target);
        this.camera.position.set(camera.x, camera.y, camera.z);
        this.controls.update();
    }

    hideCanvas(fadeout = 0) {
        this.context.canvas.style.transition = `opacity ${fadeout}s`;
        this.context.canvas.style.opacity = '0';
    }
    showCanvas(fadein = 0) {
        this.context.canvas.style.transition = `opacity ${fadein}s`;
        this.context.canvas.style.opacity = '1';
    }

    addObject(obj: Group, modify = true, camerainfo?: CameraInfo) {
        this.clear();
        this.vertexnormal.clear();
        this.vertexnormal.scale.set(1, 1, 1);
        this.wireframe.clear();
        this.wireframe.scale.set(1, 1, 1);
        console.log('addObject camerainfo :', camerainfo);
        if (modify) {
            obj.rotateOnAxis(new Vector3(1, 0, 0), Math.PI / -2);
            this.wireframe.setRotationFromQuaternion(obj.quaternion);
            this.vertexnormal.setRotationFromQuaternion(obj.quaternion);
        }
        const spherelist: Sphere[] = [];
        obj.traverse(object => {
            if (object instanceof Mesh || object instanceof SkinnedMesh) {
                const mesh = object as Mesh;
                mesh.material = [];

                if (mesh.geometry.attributes['position'] !== undefined) {

                    // for quad wireframe
                    const index: number[] = [];
                    const geo = mesh.geometry.clone();

                    // point 여섯개씩 묶어서 quad인지 확인
                    for (let i = 0; i < geo.attributes['position'].count; i += 6) {
                        const v1 = new Vector3(geo.attributes['position'].getX(i), geo.attributes['position'].getY(i), geo.attributes['position'].getZ(i));
                        const v3 = new Vector3(geo.attributes['position'].getX(i + 2), geo.attributes['position'].getY(i + 2), geo.attributes['position'].getZ(i + 2));
                        const v4 = new Vector3(geo.attributes['position'].getX(i + 3), geo.attributes['position'].getY(i + 3), geo.attributes['position'].getZ(i + 3));
                        const v5 = new Vector3(geo.attributes['position'].getX(i + 4), geo.attributes['position'].getY(i + 4), geo.attributes['position'].getZ(i + 4));

                        if (v1.equals(v4) && v3.equals(v5)) {
                            // quad인 경우
                            index.push(i, i + 1, i);
                            index.push(i + 1, i + 2, i + 1);
                            index.push(i + 2, i + 5, i + 2);
                            index.push(i, i + 5, i);

                        } else {
                            // quad가 아닌 경우 삼각형만 그리고 그 이후부터 다시시작
                            index.push(i, i + 1, i + 2);
                            i -= 3;
                        }
                    }
                    geo.setIndex(index);
                    const material = new MeshBasicMaterial({ color: 0x000000, wireframe: true });
                    const wireframe = new Mesh(geo, material);

                    wireframe.scale.set(mesh.scale.x, mesh.scale.y, mesh.scale.z);
                    wireframe.position.set(mesh.position.x, mesh.position.y, mesh.position.z);
                    wireframe.setRotationFromQuaternion(mesh.quaternion);
                    this.wireframe.add(wireframe);
                }

                mesh.geometry.computeBoundingSphere();
                const sphere = mesh.geometry.boundingSphere?.clone() as Sphere;
                sphere.radius *= mesh.scale.x;
                spherelist.push(sphere);

                if (mesh.geometry.attributes['normal'] !== undefined) {
                    const vertexnormal = new VertexNormalsHelper(mesh, 1, 0xffa600);
                    this.vertexnormal.add(vertexnormal);
                }

            }
        });
        const center = spherelist[0].center.clone();
        let radius = spherelist[0].radius;
        for (let i = 1; i < spherelist.length; i++) {
            const distance = center.distanceTo(spherelist[i].center);
            if (distance + radius < spherelist[i].radius) {
                radius = spherelist[i].radius;
                const s = spherelist[i].center;
                center.set(s.x, s.y, s.z);
            } else if (distance + spherelist[i].radius < radius) {

            } else {
                radius = radius + spherelist[i].radius + distance;
                radius *= 0.5;
                center.add(spherelist[i].center);
                center.multiplyScalar(0.5);
            }
        }
        this.objectSize = radius * 2;
        this.vertexnormal.traverse(obj => {
            if (obj instanceof VertexNormalsHelper) {
                obj.size = this.objectSize / 80;
                obj.update();
            }
        });

        if (camerainfo !== undefined) {
            this.camerainfo = camerainfo;
            const p = camerainfo.position;
            const t = new Vector3(p.x, p.y, p.z);
            t.applyAxisAngle(new Vector3(1, 0, 0), Math.PI / -2);
            p.x = t.x;
            p.y = t.y;
            p.z = t.z;
        } else {
            this.camerainfo = null;
        }

        const box = new Box3().setFromObject(obj);
        const size = box.max.distanceTo(box.min);
        console.log(`model size box[${size}] sphere[${this.objectSize}]`);

        if (size < 10 || size > 1000) {
            const scale = obj.scale.x;
            const newscale = 200 * scale / size;

            obj.scale.setScalar(newscale);
            this.wireframe.scale.setScalar(newscale);
            this.vertexnormal.scale.setScalar(newscale);
            this.objectSize *= newscale;
        }

        this.material_current = {};
        this.material_diffuse = {};

        this.setCamera(obj, camerainfo);
        this.object = obj;
        this.scene.add(obj);
    }

    setDefaultMaterial() {
        if (this.object === null) {
            return;
        }
        this.ambientlight.visible = false;
        const d = this.objectSize / 5;
        this.pointlight1.position.set(-d, d, 0);
        this.pointlight1.intensity = 0.7;
        this.pointlight1.visible = true;
        this.object.traverse(object => {
            if (object instanceof Mesh) {
                const mesh = object as Mesh;
                const material = new MeshPhongMaterial();
                material.side = DoubleSide;
                this.material_diffuse[mesh.uuid] = material;
                this.material_current[mesh.uuid] = material;
                mesh.material = material;
            }
        });
    }
    setMaps(maps: Data.TextureMapType[]) {
        if (maps.includes('COMP')) {
            maps.length = 0;
            maps.push('COMP');
        } if (maps.includes('DIFF')) {
            maps.length = 0;
            maps.push('DIFF');
        }
    }

    async updateTexture(data: string, type: Data.TextureMapType = 'DIFF', index = 0, apply = true) {
        return new Promise<void>((resolve, reject) => {
            if (this.object === null) {
                reject();
            } else {
                this.ambientlight.visible = true;
                this.pointlight1.visible = false;
                this.pointlight2.visible = false;
                this.object.traverse(object => {
                    if (object instanceof Mesh) {
                        const mesh = object as Mesh;
                        if (object.name.toLowerCase() === 'eye_lash') {
                            const material = new MeshStandardMaterial();
                            material.color = new Color(0x402010);
                            material.opacity = 0.6;
                            mesh.material = material;
                            return;

                        } else if (object.name.toLowerCase() === 'eye') {
                            if (mesh.material === undefined || mesh.material === null
                                || (Array.isArray(mesh.material) && mesh.material.length === 0)) {
                                const material = new MeshStandardMaterial();
                                mesh.material = material;
                                const image = new Image();
                                image.onload = () => {
                                    const texture = new Texture();
                                    texture.image = image;
                                    texture.needsUpdate = true;
                                    material.map = texture;
                                    material.needsUpdate = true;
                                    resolve();
                                };
                                image.src = '/assets/image/eye_default.jpg';
                            }
                        } else {

                            const i = CommonUtil.findTextureIndex(object.name);
                            if (i === index) {
                                const image = new Image();
                                image.onload = () => {
                                    const texture = new Texture();
                                    texture.image = image;
                                    texture.needsUpdate = true;

                                    if (Array.isArray(mesh.material) && mesh.material.length === 0) {
                                        mesh.material = new MeshStandardMaterial();
                                        mesh.material.side = DoubleSide;
                                        mesh.material.vertexColors = false;
                                    }
                                    if (apply) {
                                        const material = mesh.material as MeshStandardMaterial;

                                        // wireframe보다 뒤에 있어야 wireframe 두께가 일정하게 잘 보임
                                        material.polygonOffset = true;
                                        material.polygonOffsetFactor = 1;
                                        material.polygonOffsetUnits = 1;

                                        if (type === 'DIFF' || type === 'COMP') {
                                            texture.colorSpace = SRGBColorSpace;
                                            material.map = texture;
                                            this.material_diffuse[mesh.uuid] = material;
                                            this.material_current[mesh.uuid] = material;
                                            material.needsUpdate = true;
                                        } else if (type === 'NORM') {
                                            material.normalMap = texture;
                                            material.needsUpdate = true;
                                        } else if (type === 'OP') {
                                            material.alphaMap = texture;
                                            material.transparent = true;
                                            material.needsUpdate = true;
                                        } else if (type === 'AO') {
                                            material.aoMap = texture;
                                            material.needsUpdate = true;
                                        } else if (type === 'RO') {
                                            material.roughnessMap = texture;
                                            material.needsUpdate = true;
                                        } else if (type === 'ME') {
                                            material.metalnessMap = texture;
                                            material.metalness = 0.4;
                                            material.needsUpdate = true;
                                        } else if (type === 'DP') {
                                            material.displacementMap = texture;
                                            material.needsUpdate = true;
                                        } else if (type === 'ID') {
                                            // do nothing
                                        }
                                        this.render();
                                    }
                                    this.texturelist[type][index] = texture;
                                    resolve();
                                }
                                image.src = data;
                            }
                        }
                    }
                });
            }
        });
    }

    async loadFBX(assetno: number, url: string, maps: Data.TextureMapType[], defaultmaterial = false, admin = false, type?: Data.AssetOptionType)
        : Promise<{ names: string[] }> {
        const names: string[] = [];
        this.clear();
        this.objectoptiontype = type;
        if (typeof Worker === 'undefined') {
            ThreeCanvas.fbxLoader.load(`/models/${assetno}/model`, obj => {
                this.addObject(obj, true, {
                    position: { x: 0.3, y: -1, z: 0.5 }, quaternion: { x: 0, y: 0, z: 0, w: 0 }
                });
                if (maps !== null && maps.includes('COMP')) {
                    fetch(`/models/${assetno}/COMP`).then(async r => {
                        const texturedataurl = URL.createObjectURL(await r.blob() as any);
                        this.updateTexture(texturedataurl, 'COMP');
                        this.render();
                    });
                } else if (maps !== null && maps.includes('DIFF')) {
                    fetch(`/models/${assetno}/DIFF`).then(async r => {
                        const texturedataurl = URL.createObjectURL(await r.blob() as any);
                        this.updateTexture(texturedataurl, 'DIFF');
                        this.render();
                    });
                }
            });
            await new Promise<void>(resolve => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });

        } else {
            if (!admin) this.setMaps(maps);
            await new Promise<void>(resolve => {
                const worker = new Worker(new URL('./three.worker', import.meta.url));
                worker.onmessage = async ({ data }) => {
                    const result = data.result as WorkerResult;
                    const obj = new Group();

                    for (let i = 0; i < result.object.length; i++) {
                        names.push(result.object[i].name);
                        const geo = new BufferGeometry();
                        geo.setAttribute('position',
                            new Float32BufferAttribute(result.object[i].data.position, 3));
                        const normal = result.object[i].data.normal;
                        if (normal !== undefined) {
                            geo.setAttribute('normal', new Float32BufferAttribute(normal, 3));
                        }
                        const uv = result.object[i].data.uv;
                        if (uv !== undefined) {
                            geo.setAttribute('uv', new Float32BufferAttribute(uv, 2));
                            geo.setAttribute('uv1', new Float32BufferAttribute(uv, 2));
                        }
                        const mesh = new Mesh(geo);
                        mesh.name = result.object[i].name;
                        const p = result.object[i].position;
                        mesh.position.set(p.x, p.y, p.z)
                        const q = result.object[i].quaternion;
                        mesh.quaternion.set(q.x, q.y, q.z, q.w);
                        mesh.scale.setScalar(result.object[i].scale);
                        obj.add(mesh);
                    }

                    this.addObject(obj, true, result.camera);
                    if (defaultmaterial) {
                        this.setDefaultMaterial();
                    } else {
                        const d = this.objectSize / 5;
                        this.pointlight1.position.set(-d, d, 0);
                        this.pointlight2.position.set(-d * 10, d * 10, -d * 10);
                        for (let i = 0; i < result.object.length; i++) {
                            const comp = result.object[i].data.compdataurl;
                            const diffuse = result.object[i].data.diffusedataurl;
                            const normal = result.object[i].data.normaldataurl;
                            const alpha = result.object[i].data.alphadataurl;
                            const ao = result.object[i].data.aodataurl;
                            const ro = result.object[i].data.rodataurl;
                            const me = result.object[i].data.medataurl;
                            const dp = result.object[i].data.dpdataurl;

                            if (comp !== undefined) {
                                await this.updateTexture(comp, 'COMP', i);
                                if (diffuse !== undefined) await this.updateTexture(diffuse, 'DIFF', i, false);
                                if (normal !== undefined) await this.updateTexture(normal, 'NORM', i, false);
                                if (alpha !== undefined) await this.updateTexture(alpha, 'OP', i, false);
                                if (ao !== undefined) await this.updateTexture(ao, 'AO', i, false);
                                if (ro !== undefined) await this.updateTexture(ro, 'RO', i, false);
                                if (me !== undefined) await this.updateTexture(me, 'ME', i, false);
                                if (dp !== undefined) await this.updateTexture(dp, 'DP', i, false);
                            } else {
                                if (diffuse !== undefined) await this.updateTexture(diffuse, 'DIFF', i);
                                if (normal !== undefined) await this.updateTexture(normal, 'NORM', i);
                                if (alpha !== undefined) await this.updateTexture(alpha, 'OP', i);
                                if (ao !== undefined) await this.updateTexture(ao, 'AO', i);
                                if (ro !== undefined) await this.updateTexture(ro, 'RO', i);
                                if (me !== undefined) await this.updateTexture(me, 'ME', i);
                                if (dp !== undefined) await this.updateTexture(dp, 'DP', i, false);
                            }
                        }
                    }
                    resolve();
                };
                const req: WorkerRequest = { assetno, optiontype: type, texturelist: maps === null ? [] : maps, url }
                worker.postMessage({ req });
            });
        }
        return { names };
    }
    parseFBX(data: ArrayBuffer): { triangles: number } {
        let t = Date.now();
        const fbx = ThreeCanvas.fbxLoader.parse(data, '');
        t = Date.now() - t;
        console.log('parseFBX : ' + t + 'ms');

        const result: WorkerResult = {
            object: [],
            camera: undefined
        }
        for (let index = 0; index < fbx.children.length; index++) {
            const child = fbx.children[index];
            const p = child.position;
            const q = child.quaternion;
            if (child.name.toLowerCase().startsWith('camera')) {
                result.camera = {
                    position: { x: p.x, y: p.y, z: p.z },
                    quaternion: { x: q.x, y: q.y, z: q.z, w: q.w },
                }

            } else if (child instanceof Mesh || child instanceof SkinnedMesh) {
                const mesh = child as any;
                result.object.push({
                    name: child.name.toUpperCase(),
                    position: { x: p.x, y: p.y, z: p.z },
                    quaternion: { x: q.x, y: q.y, z: q.z, w: q.w },
                    scale: child.scale.x,
                    data: {
                        position: mesh.geometry.getAttribute('position').array.buffer as ArrayBufferLike,
                        uv: mesh.geometry.getAttribute('uv') === undefined ? undefined
                            : mesh.geometry.getAttribute('uv').array.buffer as ArrayBufferLike,
                        normal: mesh.geometry.getAttribute('normal') === undefined ? undefined
                            : mesh.geometry.getAttribute('normal').array.buffer as ArrayBufferLike,
                    } as any
                });
            }
        }

        const obj = new Group();
        for (let i = 0; i < result.object.length; i++) {
            const geo = new BufferGeometry();
            geo.setAttribute('position',
                new Float32BufferAttribute(result.object[i].data.position, 3));
            const normal = result.object[i].data.normal;
            if (normal !== undefined) {
                geo.setAttribute('normal', new Float32BufferAttribute(normal, 3));
            }
            const uv = result.object[i].data.uv;
            if (uv !== undefined) {
                geo.setAttribute('uv', new Float32BufferAttribute(uv, 2));
                geo.setAttribute('uv1', new Float32BufferAttribute(uv, 2));
            }
            const mesh = new Mesh(geo);
            mesh.name = result.object[i].name;
            const p = result.object[i].position;
            mesh.position.set(p.x, p.y, p.z)
            const q = result.object[i].quaternion;
            mesh.quaternion.set(q.x, q.y, q.z, q.w);
            mesh.scale.setScalar(result.object[i].scale);
            obj.add(mesh);
        }
        this.addObject(obj, true, result.camera);

        let triangles = ThreeCanvas.renderer.info.render.triangles;
        this.render();
        triangles = ThreeCanvas.renderer.info.render.triangles - triangles;

        return { triangles };
    }
    async loadOBJ(url: string) {
        this.clear();
        await new Promise<void>(resolve => {
            ThreeCanvas.objLoader.load(url, obj => {
                this.addObject(obj);
            });
        });
    }
    async loadGLTF(url: string) {
        this.clear();
        await new Promise<void>(resolve => {
            ThreeCanvas.gltfLoader.load(url, gltf => {
                this.addObject(gltf.scene, false);
                // TODO : set texture by seperate file
                resolve();
            });
        });
    }
    parseOBJ(data: string) {
        let t = Date.now();
        const obj = ThreeCanvas.objLoader.parse(data);
        t = Date.now() - t;
        console.log('parseOBJ : ' + t + 'ms');
        this.addObject(obj);
        this.render();

    }
    parseGLTF(data: string) {
        ThreeCanvas.gltfLoader.parse(data, '', gltf => {
            this.addObject(gltf.scene, false);
            this.render();
        });
    }

    async captureImageAsDataURL(width = 2048, height = 2048, screenPercentage = 4): Promise<string> {
        const renderer = ThreeCanvas.renderer;
        const canvas = renderer.domElement;
        this.render(width * screenPercentage, height * screenPercentage);

        const tmpcanvas = document.createElement('canvas');
        tmpcanvas.width = width;
        tmpcanvas.height = height;
        const resultcanvas = await pica().resize(canvas, tmpcanvas);

        return tmpcanvas.toDataURL('image/png');
    }

    setObjectVisible(enable?: boolean, render = true) {
        if (this.object === null) return false;
        if (enable === undefined) enable = !this.object.visible;
        this.object.visible = enable;
        if (render) this.render();
        return enable;
    }
    setWireframe(enable?: boolean, render = true) {
        if (this.object === null) return false;
        if (enable === undefined) enable = !this.wireframe.visible;
        this.wireframe.visible = enable;
        if (render) this.render();
        return enable;
    }
    setMatCap(enable?: boolean, render = true) {
        if (enable === undefined) enable = this.material_current === this.material_diffuse;
        if (this.object === null) return false;
        this.object.traverse(object => {
            if ((object as any).isMesh) {
                const mesh = object as THREE.Mesh;
                if (enable) {
                    mesh.material = this.material_matcap;
                } else {
                    mesh.material = this.material_diffuse[mesh.uuid] as Material;
                }
            }
        });
        if (render) this.render();
        return enable;
    }
    setVertexNormal(enable?: boolean, render = true) {
        if (this.object === null) return false;
        if (enable === undefined) enable = !this.vertexnormal.visible;
        this.vertexnormal.visible = enable;
        if (render) this.render();
        return enable;
    }
    setBackground(visible?: boolean, render = true) {
        if (this.background === undefined) return false;
        if (visible === undefined) visible = !this.background.visible;
        this.background.visible = visible;
        if (visible) this.background.position.set(0, 0, -1120 / Math.tan(this.camera.fov / 180 * Math.PI))
        if (render) this.render();
        return visible;
    }
    setLogo(visible?: boolean, render = true) {
        if (this.logo === undefined) return false;
        if (visible === undefined) visible = !this.logo.visible;
        this.logo.visible = visible;
        if (render) this.render();
        return visible;
    }

    rotateCameraY(angle: number) {
        const radian = angle * Math.PI / 180;
        const cameradir = this.camera.position;
        cameradir.sub(this.controls.target);
        cameradir.applyAxisAngle(new Vector3(0, 1, 0), radian);
        cameradir.add(this.controls.target);

        this.controls.update();
        this.render();
    }
    setCameraOnTop() {
        const resetposition = this.camera.position.clone();
        const cameradir = this.camera.position.clone();
        cameradir.sub(this.controls.target);
        const distance = cameradir.length();
        this.camera.position.x = this.controls.target.x;
        this.camera.position.y = this.controls.target.y + distance;
        this.camera.position.z = this.controls.target.z;
        cameradir.y = 0;
        cameradir.normalize().multiplyScalar(0.01);
        this.camera.position.add(cameradir);

        this.controls.update();
        this.render();
    }

    getTextureVisible(type: Data.TextureMapType) {
        if (this.object === null) return false;
        let result = false;
        this.object.traverse(object => {
            if (object instanceof Mesh || object instanceof SkinnedMesh) {
                const index = CommonUtil.findTextureIndex(object.name);
                const material = object.material as MeshStandardMaterial;
                if (material === undefined) return;
                if (type === 'DIFF' && material.map === this.texturelist.DIFF[index]) result = true;
                else if (type === 'COMP' && material.map === this.texturelist.COMP[index]) result = true;
                else if (type === 'OP' && material.alphaMap === this.texturelist.OP[index]) result = true;
                else if (type === 'AO' && material.aoMap === this.texturelist.AO[index]) result = true;
                else if (type === 'ME' && material.metalnessMap === this.texturelist.ME[index]) result = true;
                else if (type === 'NORM' && material.normalMap === this.texturelist.NORM[index]) result = true;
                else if (type === 'RO' && material.roughnessMap === this.texturelist.RO[index]) result = true;
                else if (type === 'DP' && material.displacementMap === this.texturelist.DP[index]) result = true;
            }
        });
        return result;
    }
    setTextureVisible(type: Data.TextureMapType, flag?: boolean) {
        this.object?.traverse(object => {
            if (object instanceof Mesh || object instanceof SkinnedMesh) {
                if (object.name.toLowerCase().startsWith('eye')) return;
                const index = CommonUtil.findTextureIndex(object.name);
                const material = object.material as MeshStandardMaterial;
                if (type === 'DIFF') {
                    if (flag === undefined) flag = material.map !== this.texturelist.DIFF[index];
                    if (flag && this.texturelist.DIFF[index] !== undefined) {
                        material.map = this.texturelist.DIFF[index];
                    } else if (material.map !== this.texturelist.COMP[index]) {
                        material.map = null;
                    }
                } else if (type === 'COMP') {
                    if (flag === undefined) flag = material.map !== this.texturelist.COMP[index];
                    if (flag && this.texturelist.COMP[index] !== undefined) {
                        material.map = this.texturelist.COMP[index];
                    } else if (material.map !== this.texturelist.DIFF[index]) {
                        material.map = null;
                    }
                } else if (type === 'OP') {
                    if (flag === undefined) flag = material.alphaMap === null;
                    if (flag && this.texturelist.OP[index] !== undefined) {
                        material.alphaMap = this.texturelist.OP[index];
                        material.transparent = true;
                    } else {
                        material.alphaMap = null;
                    }
                } else if (type === 'AO') {
                    if (flag === undefined) flag = material.aoMap === null;
                    if (flag && this.texturelist.AO[index] !== undefined) {
                        material.aoMap = this.texturelist.AO[index];
                    } else {
                        material.aoMap = null;
                    }
                } else if (type === 'ME') {
                    if (flag === undefined) flag = material.metalnessMap === null;
                    if (flag && this.texturelist.ME[index] !== undefined) {
                        material.metalnessMap = this.texturelist.ME[index];
                        material.metalness = 0.4;
                    } else {
                        material.metalnessMap = null;
                        material.metalness = 0;
                    }
                } else if (type === 'NORM') {
                    if (flag === undefined) flag = material.normalMap === null;
                    if (flag && this.texturelist.NORM[index] !== undefined) {
                        material.normalMap = this.texturelist.NORM[index];
                    } else {
                        material.normalMap = null;
                    }
                } else if (type === 'RO') {
                    if (flag === undefined) flag = material.roughnessMap === null;
                    if (flag && this.texturelist.RO[index] !== undefined) {
                        material.roughnessMap = this.texturelist.RO[index];
                    } else {
                        material.roughnessMap = null;
                    }
                } else if (type === 'DP') {
                    if (flag === undefined) flag = material.displacementMap === null;
                    if (flag && this.texturelist.DP[index] !== undefined) {
                        material.displacementMap = this.texturelist.DP[index];
                    } else {
                        material.displacementMap = null;
                    }
                }
                material.needsUpdate = true;
            }
        });
        this.render();
    }

    setPreset(type: PresetType = 'Default') {
        if (type === 'Default') {
            this.pointlight1.visible = false;
            this.pointlight1.intensity = 1;
            this.pointlight2.visible = false;
            this.ambientlight.visible = true;
            this.ambientlight.intensity = 1;

            if (this.texturelist.COMP.length > 0) {
                this.setTextureVisible('COMP', true);
                this.setTextureVisible('DIFF', false);
            } else {
                this.setTextureVisible('DIFF', true);
                this.setTextureVisible('COMP', false);
            }
            this.setTextureVisible('NORM', false);
            this.setTextureVisible('AO', false);
            this.setTextureVisible('ME', false);
            this.setTextureVisible('RO', false);
            this.setTextureVisible('OP', false);
            this.setTextureVisible('DP', false);

        } else if (type === 'Preset1') {
            this.pointlight1.visible = true;
            this.pointlight1.intensity = 1.5;
            this.pointlight2.visible = false;
            this.ambientlight.visible = true;
            this.ambientlight.intensity = 1;
            this.setTextureVisible('COMP', false);
            this.setTextureVisible('DIFF', true);
            this.setTextureVisible('NORM', true);
            this.setTextureVisible('AO', true);
            this.setTextureVisible('ME', true);
            this.setTextureVisible('RO', true);
            this.setTextureVisible('OP', true);
            this.setTextureVisible('DP', false);

        } else if (type === 'Preset2') {
            this.pointlight1.visible = true;
            this.pointlight1.intensity = 0.4;
            this.pointlight2.visible = false;
            this.ambientlight.visible = true;
            this.ambientlight.intensity = 0.8;
            this.setTextureVisible('COMP', false);
            this.setTextureVisible('DIFF', true);
            this.setTextureVisible('NORM', true);
            this.setTextureVisible('AO', true);
            this.setTextureVisible('ME', true);
            this.setTextureVisible('RO', true);
            this.setTextureVisible('OP', true);
            this.setTextureVisible('DP', false);

        } else if (type === 'Preset3') {
            this.setDefaultMaterial();
            this.render();

        } else if (type === 'Preset4') {
            this.pointlight1.visible = true;
            this.pointlight1.intensity = 0.2;
            this.pointlight2.visible = true;
            this.pointlight2.intensity = 0.3;
            this.ambientlight.visible = true;
            this.ambientlight.intensity = 0.8;
            this.setTextureVisible('COMP', false);
            this.setTextureVisible('DIFF', true);
            this.setTextureVisible('NORM', true);
            this.setTextureVisible('AO', true);
            this.setTextureVisible('ME', true);
            this.setTextureVisible('RO', true);
            this.setTextureVisible('OP', true);
            this.setTextureVisible('DP', false);

        } else if (type === 'Thumbnail1') {
            this.setPreset('Default');
            this.setWireframe(false, false);

        } else if (type === 'Thumbnail2') {
            this.setPreset('Default');
            this.rotateCameraY(90);
            this.setWireframe(false, false);

        } else if (type === 'Thumbnail3') {
            this.setPreset('Default');
            this.rotateCameraY(180);
            this.setWireframe(false, false);

        } else if (type === 'Thumbnail4') {
            this.setPreset('Default');
            this.rotateCameraY(270);
            this.setWireframe(false, false);

        } else if (type === 'Thumbnail5') {
            this.setPreset('Default');
            this.setDefaultMaterial();
            this.setWireframe(true, false);
            this.setMatCap(true);

            console.log(this.objectoptiontype);
            if (this.objectoptiontype !== undefined
                && ['SP', 'RWSC', 'CU30', 'CU100', 'CU2000'].includes(this.objectoptiontype)) {
                if (this.objectoptiontype === 'CU2000') {
                    this.setCameraDistance(0.16);
                } else {
                    this.setCameraDistance(0.4);
                }
            }

        } else if (type === 'Thumbnail6') {
            this.setPreset('Default');
            this.setDefaultMaterial();
            this.pointlight1.intensity = 0.8;
            this.pointlight2.visible = false;
            this.ambientlight.intensity = 0.2;
            this.ambientlight.visible = true;
            this.setWireframe(false, false);
            this.setTextureVisible('NORM', true);

        } else if (type === 'Thumbnail7') {
            this.setPreset('Default');
            this.setDefaultMaterial();
            this.pointlight1.intensity = 0.8;
            this.pointlight2.visible = false;
            this.ambientlight.intensity = 0.2;
            this.ambientlight.visible = true;
            this.setWireframe(false, false);
            this.setTextureVisible('NORM', false);
        }

        this.preset = type;
    }

    animation() {
        const targetObject = this.object;
        if (!this.isOrbitControlsActive) { // orbitcontrols가 작동 중이 아닐 때만 애니메이션 실행
            if (targetObject !== null) targetObject.rotation.z -= 0.002;
            this.render();
        }

        this.animationFrameId = window.requestAnimationFrame(() => this.animation());
    }

}