import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService } from 'src/app/lib/resize.service';
import { Filter, DeleteClothingFilterBehavior, ToggleCondition, AddSubCategory, PopSubCategory, } from 'src/app/lib/searchtool.service';

import { Data } from 'src/server/lib/types';


@Component({
    selector: 'app-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent implements OnInit, OnDestroy, OnChanges {

    @Input() screenSize = this.resizeSvc.getCurrent();
    @Input() categoryTitle!: string;
    @Input() searchcondition = this.client.searchSubject.value;

    ESC = Data.ESearchCondition;

    showCategoryMenu = true;
    facialPage = false;

    humanCategoryList = [
        {
            title: 'Search.Type',
            name: 'title',
            all_name: 'Search.TypeAll',
            all_condition: this.ESC.Type_All,
            list: [
                { name: 'Search.Facial', condition: this.ESC.Type_Facial },
                { name: 'Search.Posed', condition: this.ESC.Type_Posed },
                { name: 'Search.APosed', condition: this.ESC.Type_APosed },
                // { name: 'Search.Rigged', condition: this.ESC.Type_Rigged },
            ]
        },
        {
            title: 'Search.Sex',
            name: 'sex',
            all_name: 'Search.SexAll',
            all_condition: this.ESC.Sex_All,
            list: [
                { name: 'Search.Male', condition: this.ESC.Male },
                { name: 'Search.Female', condition: this.ESC.Female },
            ]
        },
        {
            title: 'Search.Age',
            name: 'age',
            all_name: 'Search.AgeAll',
            all_condition: this.ESC.Age_All,
            list: [
                { name: 'Search.Under10s', condition: this.ESC.Age_0 },
                { name: 'Search.10s', condition: this.ESC.Age_10 },
                { name: 'Search.20s', condition: this.ESC.Age_20 },
                { name: 'Search.30s', condition: this.ESC.Age_30 },
                { name: 'Search.40-50s', condition: this.ESC.Age_40_50 },
                { name: 'Search.Over60s', condition: this.ESC.Age_60_ },
            ]
        }]
    objectCategoryList = [{
        title: 'Search.Subcategory',
        list: [
            { name: 'Search.Clothings', category: ['Search.Top', 'Search.Bottom', 'Search.Outer', 'Search.Footwear', 'Search.Accessories'] },
            { name: 'Search.Oriental/Traditional', category: [] },
            { name: 'Search.Electronics', category: [] },
            { name: 'Search.Nature', category: [] },
            { name: 'Search.Food', category: [] },
            { name: 'Search.Toys/Hobby', category: [] },
            { name: 'Search.Interior', category: [] },
            { name: 'Search.Exterior', category: [] },
            { name: 'Search.Others', category: [] },
        ]
    },]
    spaceategoryList = [
        {
            title: 'Search.Subcategory',
            list: [
                { name: 'Search.Interior' },
                { name: 'Search.Exterior' },
            ]
        }
    ];
    searchSub: Subscription | null = null;

    constructor(
        private client: ApiClientService,
        private filterSearch: Filter,
        private resizeSvc: ResizeService,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.facialPage = this.isExactRoute('/event/faceofkorea');
    }

    ngOnDestroy(): void {
    }

    isExactRoute(route: string): boolean {
        const currentRoute = this.router.url.startsWith('/ko') || this.router.url.startsWith('/en') ? this.router.url.substring(3) : this.router.url;
        return currentRoute === route;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    checkFlag(flag: Data.ESearchCondition) {
        return (this.searchcondition.condition & flag) == flag;
    }

    checkSubcategory(subCategory: string) {
        const regex = /^Search\./;
        const subcategory = subCategory.replace(regex, '').toLowerCase();

        return this.searchcondition.categoryInfo.subCategory.includes(subcategory as Data.subCateogry);
    }

    //사람filter에 한정, 검색어대신 flag처리해주는아이
    toggleFlag(flag: Data.ESearchCondition) {
        this.filterSearch.setFilterBehavior(new ToggleCondition());
        this.filterSearch.performFilter(flag);
    }

    //사물 공간 카테고리에 한정, 서브카테고리 => 검색어로 넣어주는애
    async toggleSubCategory(subCategory: string, isclothing: boolean) {
        const regex = /^Search\./;
        const subcategory = [subCategory.replace(regex, '').toLowerCase()];

        const isContained = subcategory.some(element => this.searchcondition.categoryInfo.subCategory.includes(element as Data.subCateogry))

        if (isContained) {
            this.filterSearch.setFilterBehavior(new PopSubCategory());
            await this.filterSearch.performFilter(subcategory);
        } else {
            this.filterSearch.setFilterBehavior(new AddSubCategory());
            await this.filterSearch.performFilter(subcategory);
        }

        if (isclothing) {
            if (await this.hasAllElements()) {
                this.filterSearch.setFilterBehavior(new AddSubCategory())
                await this.filterSearch.performFilter(['clothings']);
            } else {
                this.filterSearch.setFilterBehavior(new PopSubCategory());
                await this.filterSearch.performFilter(['clothings']);
            }


        }

    }



    async hasAllElements(): Promise<boolean> {
        const clothingSubCategory = ['top', 'bottom', 'outer', 'footwear', 'accessories'];
        return clothingSubCategory.every(element => this.searchcondition.categoryInfo.subCategory.includes(element as Data.subCateogry))
    }


    async toggleAllClothings(inputclicked: boolean) {

        const clothings = (document.getElementById('category-Search.Clothings') as HTMLInputElement)
        const clothingList = ['clothings', 'top', 'bottom', 'outer', 'footwear', 'accessories']

        if (inputclicked) clothings.checked = !clothings.checked;
        if (clothings.checked) {
            this.filterSearch.setFilterBehavior(new DeleteClothingFilterBehavior());
            await this.filterSearch.performFilter();
        } else {
            this.filterSearch.setFilterBehavior(new AddSubCategory())
            await this.filterSearch.performFilter(clothingList);

        }
    }

}
