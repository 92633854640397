import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-email-verifictaion-popup',
    templateUrl: './email-verifictaion-popup.component.html',
    styleUrls: ['./email-verifictaion-popup.component.scss']
})

export class EmailVerifictaionPopupComponent implements OnInit, OnDestroy, AfterViewInit {

    verified = true;
    email = '';
    @ViewChild('resent') resent!: ElementRef<HTMLElement>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private client: ApiClientService,
        public translate: TranslateService,
        private util: UtilityService
    ) {
        if (this.router.url.includes('mail-sent')) {
            // verify email was sent 
            this.verified = true;

        } else {
            // please verify your email
            this.verified = false;
        }
        this.email = this.route.snapshot.paramMap.get('email') as string;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    close(event?: Event) {
        if (!event) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
            return;
        }
        const div = event.target as HTMLDivElement;
        let flag = false;
        for (let i = 0; i < div.classList.length; i++) {
            const item = div.classList[i];
            if (item === 'verification-popup-background') {
                flag = true;
                break;
            }
        }
        if (flag) {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
        }
    }

    async requestVerify() {
        if (this.resent.nativeElement.style.opacity === '1') return;

        this.resent.nativeElement.style.opacity = '0';
        const result = await this.client.api.RequestVerifyEmail.send({
            email: this.email, lang: this.util.getCurrentLang(), referralcode: ''
        });
        if (result.message === 'ok') {
            this.resent.nativeElement.style.opacity = '1';
            window.setTimeout(() => {
                this.resent.nativeElement.style.opacity = '0';
            }, 3000);
        }
        console.log(result);
    }

    async signin() {
        await this.router.navigateByUrl('/');
        // to avoid AppComponent::clickOutside
        setTimeout(() => {
            this.client.openPopupSubject.next({ openPopup: true, url: '/store' });
        }, 1);
    }
}
