import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { Data } from '../../../server/lib/types';
import UIkit from 'uikit';
import { CommonUtil } from 'src/server/lib/util';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import { UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-index-item',
    templateUrl: './index-item.component.html',
    styleUrls: ['./index-item.component.scss'],
})
export class IndexItemComponent implements OnInit {
    Object = Object;

    @Input() asset: Data.Asset = { name: 'asset', price: 0, options: {} } as any;
    @Input() ApiUrl = '';
    @Output() favoriteCountChange = new EventEmitter<number>();
    @Output() alreadypurchasedAlert = new EventEmitter<boolean>();
    @ViewChild('favoriteicon') favoriteIconRef!: ElementRef;
    @ViewChild('carticon') cartIconRef!: ElementRef;
    @ViewChild('typetag') typeTagRef!: ElementRef;
    @ViewChild('typebackground') typeBackgroundRef!: ElementRef;
    screenSize = this.resizeSvc.getCurrent();
    dcPrice = 0;
    dcRate = 0;
    originPrice = 0;

    isProductDetailOpen: boolean | null = false;

    constructor(
        private router: Router,
        private resizeSvc: ResizeService,
        private client: ApiClientService,
        private util: UtilityService,
        private route: ActivatedRoute
    ) { }

    withComma = CommonUtil.withComma;

    userno!: number;

    async ngOnInit(): Promise<void> {
        const keys = Object.keys(this.asset.options)
        let cheap = 0;
        let key = '';
        for (let i = 0; i < keys.length; i++) {
            const keys = Object.keys(this.asset.options)[i] as 'CU2000' | 'CU100' | 'CU30' | 'RT' | 'SP' | 'RWSC' | 'TPO' | 'TP1'
            if (cheap === 0) {
                cheap = this.asset.options[keys]?.itemprice as number
                key = Object.keys(this.asset.options)[i]
            } else {
                if (cheap > (this.asset.options[keys]?.itemprice as number)) {
                    cheap = (this.asset.options[keys]?.itemprice as number)
                    key = Object.keys(this.asset.options)[i]
                }
            }
        }
        this.originPrice = this.asset.options[key as 'CU2000' | 'CU100' | 'CU30' | 'RT' | 'SP' | 'RWSC' | 'TPO' | 'TP1']?.itemprice as number
        this.dcPrice = this.asset.options[key as 'CU2000' | 'CU100' | 'CU30' | 'RT' | 'SP' | 'RWSC' | 'TPO' | 'TP1']?.itemdcprice as number

        this.dcRate = Math.floor(((this.originPrice - this.dcPrice) / this.originPrice * 100));


        this.client.userinfoSubject.subscribe((res) => this.userno = res.userinfo.userno);
        this.setNonMember();
        // this.setTypeTag();

        this.client.cartinfoSubject.subscribe((value) => {
            if (this.asset.assetno === value.assetno) {
                this.asset.cart = value.type;
                // this.setCartIcon();
            }
        });

        this.client.favoriteSubject.subscribe((value) => {
            if (this.asset.assetno === value.assetno) {
                this.asset.favorite = value.favorite;
                // this.setFavoriteIcon();
            }
        });

        const outletSnapshot = this.findOutletSnapshot(this.route.snapshot.root);
        this.isProductDetailOpen = outletSnapshot && outletSnapshot.outlet === 'productdetail';
    }

    findOutletSnapshot(routeSnapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot | null {
        if (routeSnapshot.outlet === 'productdetail') {
            return routeSnapshot;
        }

        for (const child of routeSnapshot.children) {
            const outletSnapshot = this.findOutletSnapshot(child);
            if (outletSnapshot) {
                return outletSnapshot;
            }
        }

        return null;
    }

    getAllStringUnionValues<TStringUnion extends string>(valuesAsKeys: { [K in TStringUnion]: 0 }): TStringUnion[] {
        const result = Object.getOwnPropertyNames(valuesAsKeys);
        return result as TStringUnion[];
    }

    //비회원시, cart,favorite 초기 세팅
    setNonMember() {
        if (this.userno === 0) {
            const favoriteList = JSON.parse(localStorage.getItem('MYFAVORITE')!);
            const cartList = JSON.parse(localStorage.getItem('MYCART')!);

            if (favoriteList !== null) {
                favoriteList.forEach((item: Data.Asset) => {
                    if (item.assetno === this.asset.assetno) {
                        this.asset.favorite = true;
                    }
                });
            }
        }
    }

    setTypeTag() {
        if (this.screenSize === SCREEN_SIZE.mobile) return;
        setTimeout(() => {
            const typebackground = this.typeBackgroundRef.nativeElement as HTMLDivElement;
            const typetag = this.typeTagRef.nativeElement as HTMLDivElement;
            if (this.asset.flag & Data.ESearchCondition.Type_Posed) {
                typebackground.classList.add('item-background-posed');
                typetag.classList.add('item-type-posed');
                typetag.innerHTML = 'Posed';

            } else if (this.asset.flag & Data.ESearchCondition.Type_Rigged) {
                typebackground.classList.add('item-background-rigged');
                typetag.classList.add('item-type-rigged');
                typetag.innerHTML = 'Rigged';

            } else if (this.asset.flag & Data.ESearchCondition.Type_APosed) {
                typebackground.classList.add('item-background-A-Posed');
                typetag.classList.add('item-type-A-Posed');
                typetag.innerHTML = 'A-Posed';
            } else if (this.asset.flag & Data.ESearchCondition.Type_Facial) {
                typetag.classList.add('item-type-A-Posed');
                typetag.innerHTML = 'Facial';
            }
        }, 1);

    }

    async setFavoriteIcon() {
        // setTimeout(async () => {
        //     const div = this.favoriteIconRef.nativeElement as HTMLDivElement;
        //     const svg = await UIkit.svg(div).svg as SVGElement;
        //     if (svg === undefined || svg === null) return;
        //     const path = svg.querySelector('path');
        //     if (path !== null) {
        //         path.style.fill = (this.asset.favorite ? '#FFA600' : '#D3D7DC');
        //     }
        // }, 1);
    }


    onClickFavorite() {

        if (this.asset.favorite) {
            // remove from favorite
            this.client.api.RemoveFromFavorite.send(this.asset).then(async result => {
                console.log(result);
                if (result.message === 'ok') {
                    this.asset.favorite = false;
                    this.favoriteCountChange.emit(-1);
                    this.setFavoriteIcon();
                }
            });

        } else {
            //add to favorite
            this.client.api.AddToFavorite.send(this.asset).then(async result => {
                console.log(result);
                if (result.message === 'ok') {
                    this.asset.favorite = true;
                    this.favoriteCountChange.emit(1);
                    this.setFavoriteIcon();
                } else if (result.message.indexOf('login req') > -1) {
                    this.client.openPopupSubject.next({ url: this.router.url, openPopup: true });
                }
            });
        }
    }
    // onClickCart() {
    //     //add all options to cart
    //     const cloneAsset: any = JSON.parse(JSON.stringify(this.asset));
    //     cloneAsset.optionno = [];

    //     if (this.asset.cart === 'All') {
    //         // remove all options from cart
    //         this.client.api.RemoveFromCart.send(cloneAsset).then(async result => {
    //             if (result.message === 'ok') {
    //                 this.asset.cart = 'None';
    //                 this.setCartIcon();
    //             }
    //         });
    //     } else {
    //         this.client.api.AddToCart.send({
    //             assetno: this.asset.assetno, optionno: []
    //         }).then(async result => {
    //             if (result.message === 'ok') {
    //                 this.asset.cart = 'All';
    //                 this.setCartIcon();
    //             } else if (result.message === 'login required') {
    //                 // this.client.openPopupSubject.next({ url: this.router.url, openPopup: true });
    //             } else if (result.message.startsWith('3300')) {
    //                 this.alreadypurchasedAlert.emit(true);
    //             } else if (result.message.indexOf('item exist')) {
    //             }
    //         });
    //     }
    // }

    onClick() {
        this.router.navigate([{ outlets: { productdetail: ['itemview-popup', this.asset.assetno] } }]);
    }

    ShowItemPreview(event: MouseEvent) {
        const element = event.target as HTMLDivElement;
        const preview = (element.nextSibling === null ? element.parentElement?.nextSibling : element.nextSibling) as HTMLDivElement;
        preview.style.display = 'inline';
    }
    HideItemPreview(event: MouseEvent) {
        const element = event.target as HTMLDivElement;
        element.style.display = 'none';

    }
    // 이벤트 전파 중지, 썸네일 ONCLICK() 실행X
    onImgClick(event: Event) {
        event.stopPropagation();
    }
}