import { Component, Input, OnInit, ElementRef, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { AssetUtil, UtilityService } from '../../lib/util'
import { Data } from 'src/server/lib/types';
import { SearchCondition } from 'src/app/lib/searchtool.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ResizeService } from 'src/app/lib/resize.service';
import { Console } from 'console';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy, AfterViewInit {


    @Input() sort!: number;
    @Input() flag!: Data.ESearchCondition;
    @Input() name!: string;

    @Input() related: Data.Asset | null = null; // MERROR-271 연관상품인 경우 원 상품의 정보

    @ViewChild('slidelist') slidelist!: ElementRef<HTMLDivElement>;
    @ViewChild('prev_button') prev_button!: ElementRef<HTMLDivElement>;
    @ViewChild('next_button') next_button!: ElementRef<HTMLDivElement>;

    assetutil = new AssetUtil(this.client.api.GetAssetList.send);
    searchcondition: SearchCondition = {
        condition: Data.ESearchCondition.None,
        sort: Data.ESortBy.Recent,
        searchtext: [],
        minpolygon: 0,
        maxpolygon: 0,
        minprice: 0,
        maxprice: 0,
        categoryInfo: {
            category: [],
            subCategory: [],
            isGameready: false,
        }
    };

    assetlist: Data.Asset[] = [];
    private relatedTagList = [
        '사람', '남성', '여성', 'Posed', 'A-Posed', 'Rigged', 'Facial',
        '사물', '의류', '상의', '하의', '아우터', '신발', '악세사리',
        '동양/전통', '전자제품', '자연물', '음식', '완구/취미',
        '공간', '내부', '외부', '기타',
    ];

    cardNum = 5;
    minWidth = 230;
    screenSize = this.resizeSvc.getCurrent();
    moveIndex = 0;
    currentAsset = 0;
    slideWidth = 267;

    dist = 0;
    Ydist = 0;
    gap!: number;

    hover_prev = false;
    hover_next = false;

    ApiUrl = this.client.baseurl;
    isMobile = false;

    isDown = false;
    startX!: number;
    startY!: number;

    scrollLeft!: number;


    private resizeSubscription: Subscription;
    private userinfoSubscription!: Subscription;
    onLangChangeSub: Subscription | null = null;
    isTouchScreen = typeof window !== 'undefined' && window.matchMedia('(hover: none) and (pointer: coarse)').matches;

    constructor(private client: ApiClientService,
        private util: UtilityService,
        private translate: TranslateService,
        private resizeSvc: ResizeService,
        private elementRef: ElementRef
    ) {
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            await this.load(true);
        });
        this.resizeSubscription = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
        })
    }

    ngOnInit(): void {
        if (this.name === 'new') {
            this.searchcondition.categoryInfo.isGameready = true;
        }
        window.addEventListener('resize', this.reSize);
        this.isMobile = (/Mobi/i.test(navigator.userAgent.toLowerCase()));

        this.userinfoSubscription = this.client.userinfoSubject.subscribe((res) => {
            this.load(true);
        });
    }

    ngAfterViewInit(): void {
        this.registEvent();
    }


    ngOnDestroy(): void {
        window.removeEventListener('resize', this.reSize);
        this.slidelist.nativeElement.removeEventListener('touchstart', this.start);
        this.slidelist.nativeElement.removeEventListener('touchmove', this.move);
        this.slidelist.nativeElement.removeEventListener('touchend', this.end);
        this.slidelist.nativeElement.removeEventListener('mousedown', this.start);
        this.slidelist.nativeElement.removeEventListener('mousemove', this.move);
        this.slidelist.nativeElement.removeEventListener('mouseup', this.end);
        this.resizeSubscription.unsubscribe();
        this.userinfoSubscription.unsubscribe();
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }



    moveSlider(pageDelta: number) {
        const moveWidth = (this.slideWidth) + (Number(this.gap));

        const lastAssetIndex = this.assetlist.length - 1;
        const newAssetIndex = this.currentAsset + pageDelta

        let test = 1;
        // const nextSvg = document.getElementById('deals_slider_next') as HTMLDivElement;
        // const prevSvg = document.getElementById('deals_slider_prev') as HTMLDivElement;

        if (newAssetIndex < 0) {
            this.currentAsset = 0;
        } else if ((pageDelta < 0) && (this.currentAsset + this.cardNum > lastAssetIndex) && (this.assetlist.length % this.cardNum)) {
            this.currentAsset = newAssetIndex + (this.assetlist.length % this.cardNum) - 1
            // this.prev_button.nativeElement.style.pointerEvents = 'auto';
            // this.next_button.nativeElement.style.pointerEvents = 'auto';
        } else if (newAssetIndex + this.cardNum > lastAssetIndex) {
            this.currentAsset = lastAssetIndex - this.cardNum + 1;
            // this.prev_button.nativeElement.style.pointerEvents = 'auto';
            // this.next_button.nativeElement.style.pointerEvents = 'auto';
        } else {
            this.currentAsset = newAssetIndex;
            // this.prev_button.nativeElement.style.pointerEvents = 'auto';
            // this.next_button.nativeElement.style.pointerEvents = 'auto';
        }
        if (pageDelta > 0) {
            test = 1
        } else {
            test = -1
        }

        this.slidelist.nativeElement.style.transform = `translateX(${(this.currentAsset * -(moveWidth))}px)`
        this.slidelist.nativeElement.style.transition = `transform ${0.3}s ease-in-out`;

        if (newAssetIndex + this.moveIndex === lastAssetIndex) {
            // this.next_button.nativeElement.style.border = '2px solid grey';
            // nextSvg.style.fill = 'grey';
            // this.next_button.nativeElement.style.pointerEvents = 'none';
        } else if (newAssetIndex === 0) {
            // prevSvg.style.fill = 'grey';
            // this.prev_button.nativeElement.style.border = '2px solid grey';
            // this.prev_button.nativeElement.style.pointerEvents = 'none';
        }
    }


    async load(resetpage = false) {

        let pagesize = 20;
        // MERROR-271 연관상품인 경우 검색 결과에 자기 자신이 포함될 확률이 있으므로
        // 연관상품인 경우 검색 결과에서 자기 자신을 제외하기 위해 하나 더 가져온다
        // 연관상품을 가져오도록 검색 조건을 수정한다
        if (this.related !== null) {
            pagesize = 21;
            this.searchcondition.searchtext = this.related.name.split(' ');
            const tags = JSON.parse(this.related.tag as string) as string[];
            for (const tag of tags) {
                if (this.relatedTagList.includes(tag)) {
                    // MERROR-251 카테고리 등 중요 태그관련 검색 점수를 더 높이기 위해 두번씩 넣음
                    this.searchcondition.searchtext.push(tag);
                    this.searchcondition.searchtext.push(tag);
                }
            }
            this.searchcondition.searchtext.push(...tags);
            console.log('search related assets by ', this.searchcondition.searchtext);
        }

        await this.assetutil.loadAsset(
            this.assetlist, this.flag,
            this.searchcondition.searchtext, this.sort,
            1, pagesize, resetpage, this.util.getCurrentCurrency(),
            this.searchcondition.minprice, this.searchcondition.maxprice,
            this.searchcondition.minpolygon, this.searchcondition.maxpolygon,
            this.searchcondition.categoryInfo
        );

        // MERROR-271 검색 결과에 자기 자신이 포함되어 있다면 빼고
        // 결과를 20개 이하로 맞춘다
        if (this.related !== null) {
            for (const item of this.assetlist) {
                if (item.assetno === this.related.assetno) {
                    this.assetlist.splice(this.assetlist.findIndex(asset => asset.assetno === item.assetno), 1);
                    break;
                }
            }
            while (this.assetlist.length > 20) this.assetlist.pop();
        }

        this.reSize();


    }

    reSize = () => {
        const elementWidth = this.elementRef.nativeElement.offsetWidth;
        const currentWidth = elementWidth > 0 ? elementWidth : window.innerWidth;
        const sizeContainer = (document.getElementById(`main-slider-outer-${this.name}`) as HTMLDListElement).clientWidth;

        if (this.screenSize === 0) {
            this.gap = 8
            this.minWidth = 160;
        } else if (this.screenSize === 1) {
            this.gap = 16
            this.minWidth = 230;
        } else {
            this.gap = 20
            this.minWidth = 230;
        }

        const list = document.getElementsByClassName(`main-slider-content-${this.name}`)

        if (currentWidth < 1480) {
            this.setSize(sizeContainer);
        } else {
            const sliderInner = document.getElementsByClassName(`main-slider-inner-${this.name}`);
            this.cardNum = 5;
            this.slideWidth = 267;

            for (let i = 0; i < sliderInner.length; i++) {
                (sliderInner[i] as HTMLDListElement).style.height = `420px`;
            }
            for (let i = 0; i < list.length; i++) {
                (list[i] as HTMLDListElement).style.width = `267px`;
                (list[i] as HTMLDListElement).style.marginRight = `20px`;
            }

            this.setTranslate();
        }

    }

    setSize(container: number) {
        this.cardNum = Math.floor(container / (this.minWidth + this.gap));
        if (this.cardNum >= 6) this.cardNum = 5;
        this.moveIndex = this.cardNum - 1;

        const width = (((container - this.gap * (this.cardNum - 1)) / this.cardNum)) - 2;
        this.slideWidth = width;

        const list = document.getElementsByClassName(`main-slider-content-${this.name}`);
        const sliderInner = document.getElementsByClassName(`main-slider-inner-${this.name}`);



        setTimeout(() => {
            for (let i = 0; i < sliderInner.length; i++) {
                (sliderInner[i] as HTMLDListElement).style.height = `${(420 / 267) * width}px`;
            }
            for (let i = 0; i < list.length; i++) {
                (list[i] as HTMLDListElement).style.width = `${width}px`;
                (list[i] as HTMLDListElement).style.marginRight = `${this.gap}px`;
            }

            this.setTranslate();
        }, 1)

        if (this.cardNum === 2 && this.screenSize === 0 && this.isMobile) {
            window.removeEventListener('resize', this.reSize);
        }
    }

    setTranslate() {

        const moveWidth = (this.slideWidth) + (Number(this.gap));
        const lastAssetIndex = this.assetlist.length - 1;

        if ((this.currentAsset + (this.cardNum - 1) > lastAssetIndex) && (lastAssetIndex !== -1)) {
            this.currentAsset = lastAssetIndex - (this.cardNum - 1);
        } else if ((lastAssetIndex - this.currentAsset < (this.cardNum - 1)) && (lastAssetIndex !== -1)) {
            this.currentAsset = lastAssetIndex - (this.cardNum - 1);
        }
        this.slidelist.nativeElement.style.transition = 'none'
        this.slidelist.nativeElement.style.transform = `translateX(${(this.currentAsset * -(moveWidth))}px)`

    }



    registEvent() {

        window.addEventListener('click', this.clickOutside);
        if (this.isTouchScreen || this.isMobile) {
            this.slidelist.nativeElement.addEventListener('touchstart', this.start);
            this.slidelist.nativeElement.addEventListener('touchmove', this.move);
            this.slidelist.nativeElement.addEventListener('touchend', this.end);
        } else {
            this.slidelist.nativeElement.addEventListener('mousedown', this.start);
            this.slidelist.nativeElement.addEventListener('mousemove', this.move);
            this.slidelist.nativeElement.addEventListener('mouseup', this.end);
        }
    }

    clickOutside = (event: Event) => {
        let target = event.target as Node;
        const moveWidth = (this.slideWidth) + (Number(this.gap));

        if (!this.slidelist.nativeElement?.contains(target) && this.isDown) {
            this.isDown = false;
            this.slidelist.nativeElement.style.transform = `translateX(${(this.currentAsset * -(moveWidth))}px)`
            this.slidelist.nativeElement.style.transition = `transform ${0.3}s ease-in-out`;
        }
    }


    start = (event: MouseEvent | TouchEvent) => {
        this.isDown = true;

        if (this.isTouchScreen || this.isMobile) {
            this.startX = (event as TouchEvent).touches[0].screenX;
            this.startY = (event as TouchEvent).touches[0].screenY;
        } else {
            this.startX = (event as MouseEvent).clientX;
            this.startY = (event as MouseEvent).clientY;
        }

        const moveWidth = (this.slideWidth) + (Number(this.gap));
        const translate = (this.currentAsset * -(moveWidth));

        this.scrollLeft = translate
    }


    move = (event: MouseEvent | TouchEvent) => {
        if (!this.isDown) return;

        if (this.isTouchScreen || this.isMobile) {
            this.dist = (event as TouchEvent).changedTouches[0].screenX - this.startX;
            this.Ydist = (event as TouchEvent).changedTouches[0].screenY - this.startY;
        } else {
            this.dist = (event as MouseEvent).clientX - this.startX;
            this.Ydist = (event as MouseEvent).clientY - this.startY;
        }


        if (this.isMobile || this.isTouchScreen) {
            if (Math.abs(this.Ydist) > Math.abs(this.dist)) {
                return;
            }
        }
        event.preventDefault();
        if (this.currentAsset === 0 && this.dist > 0) {

        } else if (this.currentAsset >= this.assetlist.length - 1 - this.cardNum - 1 && this.dist < 0) {

        }
        else {
            this.slidelist.nativeElement.style.transition = 'none';
            this.slidelist.nativeElement.style.transform = `translateX(${this.scrollLeft + this.dist}px)`;
        }

        if (!this.isMobile && !this.isTouchScreen) {
            const slidelist = document.querySelectorAll(`.index-item-outer`)
            slidelist.forEach((el) => {
                (el as HTMLDivElement).style.pointerEvents = 'none';
            })
        }


    }

    end = (event: MouseEvent | TouchEvent) => {
        this.isDown = false;

        const moveWidth = (this.slideWidth) + (Number(this.gap));
        const movement = this.screenSize === 0 ? 40 : 80;
        let moveX;
        let moveY;

        if (this.isTouchScreen || this.isMobile) {
            moveX = (event as TouchEvent).changedTouches[0].screenX - this.startX;
        } else {
            moveX = (event as MouseEvent).clientX - this.startX;
        }

        if (moveX === 0) {
            const slidelist = document.querySelectorAll(`.index-item-outer`)
            slidelist.forEach((el) => {
                (el as HTMLDivElement).style.pointerEvents = 'auto';
            })
            return;
        } else {
            if (moveX < -movement) {
                this.moveSlider(+1 * this.cardNum)
            }
            else if (moveX > movement) {
                this.moveSlider(-1 * this.cardNum)
            } else {
                this.slidelist.nativeElement.style.transform = `translateX(${(this.currentAsset * -(moveWidth))}px)`
            }


            const slidelist = document.querySelectorAll(`.index-item-outer`)
            slidelist.forEach((el) => {
                (el as HTMLDivElement).style.pointerEvents = 'auto';
            })
        }
    }
}
