<nav class="gnb-container" id="gnb">
    <div class="gnb-outer">
        <ul class="gnb-inner">
            <li class="gnb-list" routerLink="/store" (click)="closePopupMovePage($event, '/store')"
                [style.color]=" rlhome.isActive? '#FFCA66' : '#F4F4F4'"
                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
                #rlhome="routerLinkActive">
                <div [ngClass]="rlhome.isActive? 'gnb-focused': ''">
                    <img style="width:100%;" class="gnb-icon"
                        [src]="rlhome.isActive? '/assets/icon/home_color_24.svg' : '/assets/icon/home_24.svg'" />
                </div>
                <span class="gnb-location" [innerHTML]="'Common.Home'|translate">홈</span>
            </li>
            <li class="gnb-list" routerLink="/favorite"
                (click)="closePopupMovePage($event, '/favorite')"
                [style.color]="rlfavorite.isActive? '#FFCA66': '#F4F4F4'" routerLinkActive="active"
                #rlfavorite="routerLinkActive">
                <div [ngClass]="rlfavorite.isActive? 'gnb-focused': ''">
                    <img style="width:100%" class="gnb-icon"
                        [src]="rlfavorite.isActive? '/assets/icon/star_color_24.svg' : '/assets/icon/star_24.svg'" />
                </div>
                <span class="gnb-location" [innerHTML]="'Common.Favorites'|translate">즐겨찾기</span>
            </li>
            <li id="gnb-cart" class="gnb-list" routerLink="/cart"
                (click)="closePopupMovePage($event, '/cart')"
                [style.color]="rlcart.isActive? '#FFCA66': '#F4F4F4'" routerLinkActive="active"
                #rlcart="routerLinkActive">
                <div [ngClass]="rlcart.isActive? 'gnb-focused': ''">
                    <img style="width:100%;" class="gnb-icon"
                        [src]="rlcart.isActive? '/assets/icon/cart_color_24.svg' : '/assets/icon/cart_24.svg'" />
                    <div *ngIf="cartcount>0" class="header-icon-count">
                        {{cartcount>9 ? '9+' : cartcount}}
                    </div>
                </div>
                <span class="gnb-location" [innerHTML]="'Common.Cart'|translate">장바구니</span>
            </li>
            <li class="gnb-custom gnb-list" (click)="util.showChannelTalk()">
                <div>
                    <img style="width:100%" class="gnb-icon"
                        src="/assets/icon/custom_order_20.svg" />
                </div>
                <span class="gnb-location" [innerHTML]="'Common.Custom'|translate">주문제작</span>
            </li>
        </ul>
        <div style="display:none" routerLink="/order" routerLinkActive="active"
            #rlorder="routerLinkActive"></div>
        <!-- <button [style.display]="rlcart.isActive || rlorder.isActive? 'none': 'block'"
            class="gnb-up-button common-horizontal-alignment" (click)="scrollToTop()">
            <img class="gnb-icon" src="/assets/icon/gnb_arrow.svg" />
        </button> -->
    </div>


</nav>