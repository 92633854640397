<div class="receipt-outer" (click)="close($event)">
    <div>
        <div class="receipt-inner">
            <div class="receipt-header">
                <h1 class="receipt-title" [innerHtml]="'Order.Receipt'|translate"></h1>
                <div class="receipt-logo"></div>
            </div>

            <div class="receipt-body">
                <div class="receipt-assets">
                    <div class="receipt-common-header">
                        <div class="receipt-orderno">{{payment.CASHNO}}</div>
                        <div class="receipt-ordertime">{{payment.REGDATE}}</div>
                    </div>
                    <ul class="receipt-asset-wrap">
                        <li class="receipt-asset"
                            *ngFor="let item of payment.list;let index = index let last = last">
                            <div class="receipt-asset-desc">
                                <div class="receipt-asset-name">{{currentLang === 'ko'?
                                    item.ITEMNAMEKOR:
                                    item.ITEMNAMEENG}}</div>
                                <div class="receipt-asset-option">
                                    <span [innerHTML]="('Search.'+item.ITEMTYPE)|translate"></span>
                                </div>
                            </div>
                            <div class="receipt-asset-price">
                                <img src="/assets/icon/coupon_color_24.svg"
                                    [style.display]="(item.COUPONID == 1 || item.COUPONID == 2)? 'block': 'none'">
                                <div>{{(withComma(item.PURCHASEPRICE) == '0' && (currencykey ===
                                    'Common.USD'))? '0.00': withComma(item.PURCHASEPRICE)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="receipt-payment">
                    <div class="receipt-common-header">
                        <div class="receipt-subtitle" [innerHTML]="'Common.PaymentInfo' |translate">
                            결제 정보</div>


                    </div>

                    <div class="receipt-payment-contents">
                        <div>
                            <div class="receipt-payment-label"
                                [innerHTML]="'Price.Subtotal'|translate">주문 금액</div>
                            <div class="receipt-payment-price">
                                {{withComma(totalPrice)}}
                                <span [innerHTML]="currencykey|translate">원</span>
                            </div>
                        </div>
                        <div>
                            <div class="receipt-payment-label"
                                [innerHTML]="'Price.Coupons'|translate">쿠폰 할인</div>

                            <div class="receipt-payment-price">
                                <div style="text-align: left;">
                                    -{{withComma(discountPrice)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>


                                <div class="receipt-coupon-type">
                                    <div *ngFor="let payment of payment.list">
                                        <div *ngIf="payment.COUPONID == 1 || payment.COUPONID ==2">
                                            <img uk-svg style="stroke: #BDBDBD; fill: none;"
                                                src="/assets/icon/additional.svg">
                                            <span
                                                [innerHTML]="payment.COUPONID == 1 ? ('Account.WelcomeCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                            payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate) :  ('Account.FriendCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                            payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="receipt-final-price">
                        <div class="receipt-payment-label" [innerHTML]="'Price.Total'|translate">최종
                            결제 금액</div>
                        <div class="receipt-payment-fianlprice">
                            {{((totalPrice-discountPrice === 0) && (currencykey === 'Common.USD'))?
                            '0.00': withComma(totalPrice-discountPrice) }}
                            <span [innerHTML]="currencykey|translate">원</span>
                        </div>
                    </div>

                    <div class="receipt-payment-method">
                        <div>
                            <div class="receipt-payment-label"
                                [innerHTML]="'Receipt.FinalPrice'|translate"
                                [innerHTML]="'Receipt.PaymentMethod'|translate">결제 수단</div>

                            <div class="receipt-payment-details"><span [innerHTML]="(payMethod !== '') ? (payMethod| translate)
                                    :  'Order.Etc'|translate:{etc:etc}">페이팔</span>
                                <span *ngIf="bank !== ''"> {{bank}}</span>
                                <span *ngIf="point !== ''" [innerHTML]="point|translate"></span>
                            </div>

                        </div>
                        <div>
                            <div class="receipt-payment-label"
                                [innerHTML]="'Receipt.ApprovalNo'|translate">승인번호</div>
                            <div class="receipt-payment-details">{{applyNum}}</div>
                        </div>
                    </div>



                </div>

                <div class="receipt-refund" *ngIf="refundinfo.list.length>0">
                    <div class="receipt-common-header">
                        <div class="receipt-subtitle" [innerHTML]="'Refund.RefundInfo'|translate">
                            환불 정보</div>
                    </div>

                    <div class="receipt-refund-contents">
                        <div class="receipt-refund-list"
                            *ngFor="let item of refundinfo.list;let index=index">
                            <div>
                                <div class="receipt-refund-label"
                                    [innerHTML]="'Refund.PurchaseCanceled'|translate">구매 취소</div>
                                <div class="receipt-refund-date">{{item.time}}</div>
                            </div>

                            <div class="receipt-refund-price">
                                <div>
                                    <span
                                        *ngIf="item.amount !== 0">-</span>{{(withComma(item.amount)
                                    == '0' && (currencykey === 'Common.USD'))? '0.00':
                                    withComma(item.amount)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>
                                <div class="purchase-refund-coupon" *ngIf="item.amount === 0">
                                    <img src="/assets/icon/coupon_24.svg">
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="receipt-refund-final-price">
                        <div>
                            <div class="receipt-refund-label"
                                [innerHTML]="'Refund.RefundTotal'|translate">총 환불 금액</div>
                            <div class="receipt-refund-total"> <span
                                    *ngIf="refundinfo.total !== 0">-</span>
                                {{(withComma(
                                refundinfo.total) == '0' && (currencykey === 'Common.USD'))?'0.00':
                                withComma(refundinfo.total)}}
                                <span [innerHTML]="currencykey|translate">원</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="receipt-buttons-outer">
                <button class="common-button2" (click)="close()"
                    [innerHTML]="'Receipt.Close'|translate">닫기</button>
                <button class="common-button1" style="margin-left:15px;" (click)="makePDF()"
                    [innerHTML]="'Receipt.SaveAsPDF'|translate">
                    PDF로 저장하기</button>
            </div>
        </div>
    </div>
</div>