import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import { Filter } from 'src/app/lib/searchtool.service';
import { SearchCondition } from 'src/app/lib/searchtool.service';
import { Data } from 'src/server/lib/types';

@Component({
    selector: 'app-face-korea-filter',
    templateUrl: './face-korea-filter.component.html',
    styleUrls: ['../../common/filter.component.scss']
})
export class FaceKoreaFilterComponent implements OnInit, OnDestroy {
    @Input() categoryType = 'human';
    @Input() searchcondition!: SearchCondition;
    @Output() openFilter = new EventEmitter<boolean>();
    @Output() updateFlag = new EventEmitter();

    showCategory = false;
    showSortOptions = false;
    screenSize = this.resizeSvc.getCurrent();
    resizeSubscription!: Subscription;

    ESC = Data.ESearchCondition;
    sortList: { name: string, value: string }[] = [{ name: 'Search.Newest', value: 'recent' }, { name: 'Search.Oldest', value: 'old' }, { name: 'Search.Popularity', value: 'popular' }, { name: 'Search.HighestPrice', value: 'highprice' }, { name: 'Search.LowestPrice', value: 'lowprice' }]

    constructor(
        private client: ApiClientService,
        private resizeSvc: ResizeService,
        private filterSearch: Filter
    ) {
        this.resizeSubscription = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
            if (this.screenSize !== SCREEN_SIZE.mobile) {
                document.body.classList.remove('no-scroll');
                if (this.showCategory) {
                    this.openFilter.emit(false);
                } else {
                    this.openFilter.emit(true);
                }

            } else {
                if (this.showCategory) {
                    this.openFilter.emit(true);
                    document.body.classList.add('no-scroll');
                } else {
                    this.openFilter.emit(false);
                    document.body.classList.remove('no-scroll');
                }

            }
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
    }

    onChangeSort(idx: number) {
        if (this.setSearchConditionSort(this.sortList[idx].value)) {
            this.client.searchSubject.next(this.searchcondition);
        }
    }

    setSearchConditionSort(sort: string) {
        const oldsort = this.searchcondition.sort;
        switch (sort) {
            case 'recent':
                this.searchcondition.sort = Data.ESortBy.Recent;
                break;
            case 'old':
                this.searchcondition.sort = Data.ESortBy.Old;
                break;
            case 'popular':
                this.searchcondition.sort = Data.ESortBy.Popular;
                break;
            case 'lowprice':
                this.searchcondition.sort = Data.ESortBy.LowPrice;
                break;

            case 'highprice':
                this.searchcondition.sort = Data.ESortBy.HighPrice;
                break;
            default:
                // handle default case
                break;
        }

        if (this.screenSize !== 0) this.showSortOptions = false;


        if (this.searchcondition.sort !== oldsort) return true;
        return false;
    }

    toggleMobileFilter(open: boolean) {

        this.openFilter.emit(open);

        if (open) {
            this.showCategory = true;
            document.body.classList.add('no-scroll');

        } else {
            this.showCategory = false;
            document.body.classList.remove('no-scroll');

        }

    }

    clearAll() {
        const clearFilter = {
            condition: Data.ESearchCondition.Type_Facial,
            sort: Data.ESortBy.Recent,
            searchtext: [],
            minpolygon: 0,
            maxpolygon: 0,
            minprice: 0,
            maxprice: 0,
            categoryInfo: {
                category: ['human'],
                subCategory: [],
                isGameready: false,
            }
        }
        this.updateFlag.emit(clearFilter);
    }
}
