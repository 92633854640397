<footer class="footer-container">
    <div class="footer-top-outer">
        <div class="footer-top-inner">
            <div>
                <div class="footer-link-words">
                    <ul>
                        <div class="footer-link-header">STORE</div>
                        <a (click)="moveToLocation('/asset/all')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Search.AllAssets'|translate">All</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/human')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.Human'|translate">Human</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/object')">
                            <li class="dropdown-menu-item">

                                <div [innerHtml]="'Sidebar.Object'|translate">Object</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/space')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.Space'|translate">Space</div>
                            </li>
                        </a>
                    </ul>
                    <ul>
                        <div class="footer-link-header">BIZ</div>
                        <a (click)="moveToLocation('/business/3dscanstudio')">
                            <li class="dropdown-menu-item">
                                <div [innerHTML]="'ForBusiness.3DScanStudio'|translate">3D SCcan
                                    Studio - 엔터</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/business/modeling')">
                            <li class="dropdown-menu-item">
                                <div [innerHTML]="'ForBusiness.3DCustom'|translate">Custom 3D
                                    Modelling</div>
                            </li>
                        </a>
                    </ul>
                    <ul>
                        <div class="footer-link-header">ABOUT</div>
                        <a (click)="moveToLocation('/business')">
                            <li class="dropdown-menu-item">
                                <div>ABOUT ME.RROR</div>
                            </li>
                        </a>
                        <a href="https://merror.tistory.com" target="_blank">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.Blog'|translate">블로그</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/faq')">
                            <li>
                                <div [innerHTML]="'Common.Htmlfaq'|translate"></div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/eula')">
                            <li>
                                <div [innerHTML]="'Common.EULA'|translate"></div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/privacypolicy')">
                            <li>
                                <div [innerHTML]="'Common.Htmlprivacypolicy'|translate"></div>
                            </li>
                        </a>
                    </ul>
                </div>

                <ul class="footer-link-icons">
                    <li class="footer-link-icon">
                        <a href="https://www.facebook.com/Me.rror3D/" target=”_blank”>
                            <img class="footer-icon" src="/assets/icon/facebook.svg" />
                        </a>
                    </li>
                    <li class="footer-link-icon">
                        <a href="https://www.instagram.com/me.rror_official/" target=”_blank”>
                            <img class="footer-icon" src="/assets/icon/instagram.svg" />
                        </a>
                    </li>
                    <li class="footer-link-icon" (click)="changeLanguage()">
                        <img class="footer-icon" src="/assets/icon/global.svg" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-bottom-outer">
        <div class="footer-bottom-inner">
            <div>
                <div class="footer-merror-logo">
                    <img src="/assets/image/logo.svg" />
                </div>
                <div class="footer-description">
                    <div class="footer-desc-1">
                        <span [innerHTML]="'Business.Corporation'|translate">(주)브릴라</span>
                        <span [innerHTML]="'Business.CEO'|translate">대표이사:이원교</span>
                        <br class="tablet-footer-word-break">
                        <span [innerHTML]="'Common.Address'|translate" style="margin:0;">주소</span>:
                        <span [innerHTML]="'Business.Office'|translate">서울특별시 서초구 서초대로60길 18, 교대
                            정인빌딩 4층
                        </span>
                        <br class="footer-word-break">
                        <span>TEL : 02-582-1330</span>
                        <span [innerHTML]="'Business.BusinessLicense'|translate">사업자등록번호 :
                            454-81-00472</span>
                        <br class="tablet-footer-word-break">
                        <span [innerHTML]="'Business.ReportNumber'|translate">
                            통신판매업신고 : 제2022-서울서초-1906호</span>
                        <br class="tablet-footer-word-break">
                        <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4548100472"
                            target="_blank">
                            <span [innerHTML]="'Business.BusinessInfo'|translate"
                                class="footer-businessInfo">통신판매자정보확인</span>
                        </a>
                    </div>
                    <div class="footer-copyright">
                        <span>© 2023 VRillAR Co., Ltd. All Rights Reserved.</span>

                    </div>

                </div>
                <div class="footer-desc-2">
                    <span [innerHTML]="'Business.TransactionsInform'|translate"></span>
                    <br class="mo-footer-word-break">
                    <a href="https://mark.inicis.com/mark/popup_v3.php?mid=MOI7323113"
                        target=”_blank”>
                        <span class="footer-confirmation-link"
                            [innerHTML]="'Business.ServiceSubscription'|translate">
                            서비스 가입사실 확인</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>