import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ResizeService } from 'src/app/lib/resize.service';
import { SidebarUtilService } from 'src/app/lib/searchtool.service';
import { Util, UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-global-navigation',
    templateUrl: './global-navigation.component.html',
    styleUrls: ['./global-navigation.component.scss']
})
export class GlobalNavigationComponent implements OnInit, OnDestroy {


    prevScrollPos = window.pageYOffset;
    desktopSize = this.resizeSvc.getCurrent();
    scrollGnb = true;
    @Input() cartcount = 0;

    constructor(
        private resizeSvc: ResizeService,
        private sidebarutil: SidebarUtilService,
        public util: UtilityService,
        private router: Router
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.desktopSize = size
        });

        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            const url = event.url.startsWith('/ko') || event.url.startsWith('/en') ? event.url.substring(3) : event.url;
            if (url !== '/cart' && url !== '/order') {
                this.scrollGnb = false;
                window.addEventListener("scroll", this.scrollEvent)
            } else {
                this.scrollGnb = true;
                window.removeEventListener("scroll", this.scrollEvent)
            }
        });
    }


    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        window.removeEventListener("scroll", this.scrollEvent)
    }


    showCategory() {
        this.sidebarutil.showCategoryForMobile();
    }

    scrollToTop() {
        Util.scrollTo(0);
    }

    onScroll() {
        const currentScrollPos = window.scrollY;
        const gnb = (document.getElementById("gnb") as HTMLDListElement)

        const gnbBoxHeight = gnb.offsetHeight;
        if (Math.abs(this.prevScrollPos - currentScrollPos) <= 0) {
            return;
        }
        if (currentScrollPos > this.prevScrollPos && currentScrollPos > gnbBoxHeight) {
            //Scroll down
            gnb.classList.remove('show');
        } else {
            if (currentScrollPos + window.innerHeight < document.body.offsetHeight) {
                //Scroll up
                gnb.classList.add('show');
            }
        }
        this.prevScrollPos = currentScrollPos;
    }

    scrollEvent = () => {
        this.onScroll();
    }

    async closePopupMovePage(event: Event, url: string) {
        event.preventDefault(); // 이벤트 기본 동작 방지
        await this.router.navigate([{ outlets: { productdetail: null } }]);
        this.router.navigate([url], { replaceUrl: true });
    }
}
