import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiClientService } from '../lib/api-client.service';
import { Data } from '../../server/lib/types';
import { UtilityService } from '../lib/util';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

interface CouponDisplayInfo {
    title: string;
    subtitle: string;
    description1: string;
    description2: string;
    description3: string;
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('copy_popup') copy_popup!: ElementRef;

    constructor(
        private router: Router,
        private client: ApiClientService,
        private util: UtilityService,
        private translate: TranslateService
    ) {

        const state = this.router.getCurrentNavigation()?.extras.state as any;
        if (state?.mycoupon === true) {
            this.category = 'coupon'
        }

        this.getCouponList();
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async lang => {
            this.lang = lang.lang as any;
            this.makeCouponDispInfo();
        });
    }

    recv_email_last_modified = '';
    userinfo: Data.User = {
        userno: 0,
        userid: 0,
        email: '',
        password: '',
        status: 'NotVerified',
        recv_email: false,
        regdate: new Date()
    };
    cartcount = 0;
    favoritecount = 0;
    couponList: Data.Coupon[] = [];
    couponDispList: CouponDisplayInfo[] = [];
    category: 'settings' | 'coupon' = 'settings';
    lang = this.util.getCurrentLang();
    onLangChangeSub: Subscription | null = null;
    locationSubejct: Subscription | null = null;

    copyToClipboard = window.location.href + '/';

    ngOnInit(): void {
        const locationSubejct = this.client.accountLocationSubject.subscribe(async (val) => {
            this.category = val.location;
        })
        const userinfo = this.client.userinfoSubject.asObservable();
        userinfo.subscribe(info => {
            console.log(info);
            const userinfo = info.userinfo;
            if (userinfo.email === '') {
                //this.router.navigateByUrl('/');
            } else {
                this.userinfo = userinfo;
                this.cartcount = info.cartcount
                this.favoritecount = info.favoritecount;
            }
        });
        this.getUserHistory();
        this.copyToClipboard = this.copyToClipboard.replace("/account", "/signup")
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);

    }

    ngOnDestroy(): void {
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();
    }

    async getCouponList() {
        const res = await this.client.api.GetCouponList.send({});
        console.log(res);
        if (res.message === 'ok') {
            this.couponList = res.list;
            this.makeCouponDispInfo();
        }
    }

    makeCouponDispInfo() {
        this.couponDispList = [];
        for (const coupon of this.couponList) {
            const info: CouponDisplayInfo = {
                title: '',
                subtitle: '100% OFF',
                description1: this.lang === 'ko' ? '모든 상품 대상 옵션 1개 무료' : 'on any ONE item',
                description2: '',
                description3: ''
            }
            if (coupon.name.startsWith('친구초대')) {
                if (coupon.recommenduserno > 0) {
                    info.title = this.lang === 'ko' ? '친구초대 쿠폰 - 추천인 등록'
                        : 'Referral Coupon - Referee';
                } else {
                    info.title = this.lang === 'ko' ? '친구초대 쿠폰 - 친구 초대'
                        : 'Referral Coupon- Referrer';
                }
            } else if (coupon.name.startsWith('신규가입')) {
                info.title = this.lang === 'ko' ? '신규가입 축하쿠폰'
                    : 'Welcome Coupon';
            }
            const expdate = new Date(coupon.expdate);
            expdate.setMinutes(expdate.getMinutes() - 1);
            info.description2 = `~ ${expdate.getFullYear()}.${expdate.getMonth() + 1}.${expdate.getDate()}`;
            info.description2 = this.lang === 'ko' ? info.description2 + ' 까지' : 'until ' + info.description2;

            const left = Math.floor((new Date(coupon.expdate).getTime() - Date.now()) / 1000 / 60 / 60 / 24);
            if (left === 0) {
                info.description3 = this.lang === 'ko' ? `오늘\n만료` : `Expires\nToday`;
            } else {
                info.description3 = this.lang === 'ko' ? `${left}일\n남음` : `${left} days\nleft`;
            }

            if (coupon.state === 'Available') this.couponDispList.push(info);
        }
    }

    async setUserRecvEmail(flag: boolean) {
        const result = await this.client.api.SetUserRecvEmail.send({ recv_email: flag });
        console.log(result);
        if (result.message === 'ok') {
            this.getUserHistory();
            this.userinfo.recv_email = flag;
            this.client.userinfoSubject.next({
                userinfo: this.userinfo,
                cartcount: this.cartcount,
                favoritecount: this.favoritecount
            });
        }
    }

    async getUserHistory() {
        this.client.api.GetUserHistory.send({ type: undefined }).then(result => {
            console.log(result);
            if (result.message === 'ok') {
                for (const item of result.list) {
                    if (item.type === 'ConsentMail' || item.type === 'RefuseMail') {
                        this.recv_email_last_modified = this.util.getLocalDatetimeString(item.regdate);
                        break;
                    }
                }
            }
        });
    }

    openCopyPopup() {
        this.copy_popup.nativeElement.style.opacity = '1';

        setTimeout(() => {
            this.copy_popup.nativeElement.style.opacity = '0';
        }, 2000);
    }
}