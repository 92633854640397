<div #root id="dropdown"
    [ngClass]="(navTitle ==='search') || (navTitle === 'mypage' && screenSize === 0)? '': 'dropdown-boxshadow'">
    <div [ngClass]="navTitle === undefined? 'dropdown-outer': 'desktop-dropdown-outer'">
        <div class="dropdown-mobile-header"
            [style.display]="((navTitle === undefined) && (screenSize === 0))? 'flex': 'none'">
            <img class="dropdown-mobile-close-icon" src="/assets/icon/close_24.svg"
                (click)="closeNav()" />
            <img class="dropdown-mobile-logo" src="/assets/image/logo.svg" />
        </div>

        <!-- tablet/mobile일경우 link header 시작-->
        <div *ngIf="navTitle === undefined" class="dropdown-link-header dropdown-link-margin">
            <div class="dropdown-link-title" (click)="moveToLocation('/store')">STORE</div>
            <img class="dropdown-icon" (click)="showAssetsMenu = !showAssetsMenu"
                [style.background]="showAssetsMenu? '#4D4A44': ''" src="{{ showAssetsMenu ? '/assets/icon/chevron_up_20.svg' :
            '/assets/icon/chevron_down_20.svg'}}" />
        </div>
        <!-- tablet/mobile일경우 link header 끝-->

        <!-- assets dropdown시작 -->
        <div class="dropdown-inner dropdown-{{navTitle}}"
            [style.paddingBottom]="screenSize === SCREEN_SIZE.desktop? '12px' : ''"
            *ngIf="(navTitle === 'assets') || (navTitle === undefined && showAssetsMenu)">
            <div>
                <div class="dropdown-title-wrap">
                    <span class="dropdown-title">BY CATEGORY</span>
                </div>
                <div class="dropdown-menu-wrap">
                    <ul class="dropdown-menu">
                        <a (click)="moveToLocation('/asset/all')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Search.AllAssets'|translate">All</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/human')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.Human'|translate">Human</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/object')">
                            <li class="dropdown-menu-item">

                                <div [innerHtml]="'Sidebar.Object'|translate">Object</div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/asset/space')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.Space'|translate">Space</div>
                            </li>
                        </a>
                    </ul>
                </div>

                <div class="dropdown-title-wrap">
                    <span class="dropdown-title">COLLECTION</span>
                </div>
                <div class="dropdown-menu-wrap">
                    <ul class="dropdown-menu">
                        <a (click)="moveToLocation('event/faceofkorea')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.FaceKorea'|translate">한국의 얼굴</div>
                            </li>
                        </a>
                    </ul>
                </div>

                <div class="dropdown-title-wrap">
                    <span class="dropdown-title">EVENT</span>
                </div>
                <div class="dropdown-menu-wrap">
                    <ul class="dropdown-menu">
                        <a (click)="moveToLocation('event/specialdeal')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.SpecialDeal'|translate">$0.10 Deals</div>
                            </li>
                        </a>

                        <a (click)="moveToLocation('event/referral')">
                            <li class="dropdown-menu-item">
                                <div [innerHtml]="'Sidebar.FriendReferralEvent'|translate">친구 초대 이벤트
                                </div>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
        <!-- assets dropdown끝 -->

        <!-- tablet/mobile일경우 link header 시작-->
        <div *ngIf="navTitle === undefined" class="dropdown-link-header">
            <div class="dropdown-link-title" (click)="moveToLocation('/business')">ME.RROR BIZ</div>
            <img class="dropdown-icon" (click)="showBusinessMenu = !showBusinessMenu"
                [style.background]="showBusinessMenu? '#4D4A44': ''" src="{{ showBusinessMenu ? '/assets/icon/chevron_up_20.svg' :
            '/assets/icon/chevron_down_20.svg'}}" />
        </div>
        <!-- tablet/mobile일경우 link header 끝-->

        <!-- fb dropdown 시작 -->
        <div class="dropdown-inner dropdown-{{navTitle}}"
            [style.paddingBottom]="screenSize === SCREEN_SIZE.desktop? '12px' : ''"
            *ngIf="(navTitle === 'business') || (navTitle === undefined && showBusinessMenu)">
            <div>
                <div class="dropdown-menu-wrap">
                    <ul class="dropdown-menu">
                        <a (click)="moveToLocation('/business')">
                            <li class="dropdown-menu-item">
                                <div>About ME.RROR
                                </div>
                            </li>
                        </a>
                    </ul>
                </div>

                <div class="dropdown-title-wrap">
                    <span class="dropdown-title">SERVICES</span>
                </div>
                <div class="dropdown-menu-wrap">
                    <ul class="dropdown-menu">
                        <a (click)="moveToLocation('/business/3dscanstudio')">
                            <li class="dropdown-menu-item">
                                <div [innerHTML]="'ForBusiness.3DScanStudio'|translate">3D 스캔스튜디오
                                </div>
                            </li>
                        </a>
                        <a (click)="moveToLocation('/business/modeling')">
                            <li class="dropdown-menu-item">
                                <div [innerHTML]="'ForBusiness.3DCustom'|translate">3D 모델링</div>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
        <!-- fb dropdown 끝 -->

        <!-- tablet/mobile일경우 link header 시작-->
        <div *ngIf="navTitle === undefined" class="dropdown-link-header"
            style="padding-bottom: 40px;">
            <div class="dropdown-link-title">
                <a href="https://merror.tistory.com" target="_blank">BLOG</a>
            </div>
        </div>
        <!-- tablet/mobile일경우 link header 끝-->

        <!-- mypage dropdown시작 -->
        <div class="mypage-dropdown-inner dropdown-{{navTitle}}" *ngIf="navTitle === 'mypage'"
            [style.paddingBottom]="screenSize === SCREEN_SIZE.desktop? '12px' : ''">
            <div>
                <div class="mypage-dropdown-title-wrap">
                    <span class="mypage-dropdown-greeting"
                        [innerHtml]="'Common.Hello'|translate">안녕하세요!>HELLO!</span>
                    <span class="mypage-dropdown-title mypage-email">{{userinfo.email}}</span>
                </div>
                <div class="mypage-dropdown-menu-wrap">
                    <ul class="mypage-dropdown-menu">
                        <li class="mypage-dropdown-menu-item" (click)="purchases()">
                            <div [innerHtml]="'Common.MyOrder'|translate">Purchase List</div>
                        </li>
                        <li class="mypage-dropdown-menu-item" (click)="account(false)">
                            <div [innerHtml]="'Account.AccountSetting'|translate">Account Settings
                            </div>
                        </li>
                        <li class="mypage-dropdown-menu-item" (click)="account(true)">
                            <div [innerHtml]="'Account.MyCoupon'|translate">My coupon
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mypage-dropdown-signout" (click)="logout()">
                    <div class="mypage-dropdown-menu-item" style="border-top:1px solid #9E9E9E;">
                        <div [innerHtml]="'Common.Logout'|translate">Sign Out</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- mypage dropdown끝 -->

        <!-- search dropdown시작 -->
        <div class="dropdown-inner dropdown-{{navTitle}}" *ngIf="navTitle === 'search'">
            <div class="dropdown-search-title">
                <div class="dropdown-title-label dropdown-search-recommend"
                    [style]="recommendLabelFocused? 'border-bottom:1px solid #FFCA66; color: #FFCA66;font-weight: 600;': ''"
                    (click)="recommendLabelFocused = true"
                    [innerHTML]="'Search.SuggestedSearch'|translate">추천검색어</div>
                <div class="dropdown-title-label dropdown-search-popular"
                    [style]="recommendLabelFocused? '': 'border-bottom:1px solid #FFCA66;color: #FFCA66;font-weight: 600;'"
                    (click)="recommendLabelFocused = false"
                    [innerHTML]="'Search.PopularSearch'|translate">인기검색어</div>
            </div>
            <div class="dropdown-search-list-wrap">
                <ul class="dropdown-recommend-list dropdown-menu" *ngIf="recommendLabelFocused">
                    <li *ngFor=" let recommendTag of recommendList" class="dropdown-search-item"
                        style="cursor:pointer" (click)="addSuggestedTerm(recommendTag.str);">
                        <span [innerHTML]="recommendTag.str|translate"></span>
                    </li>
                </ul>

                <ul class="dropdown-popular-list dropdown-menu" *ngIf="!recommendLabelFocused">
                    <li *ngFor="let item of popularSearchList | slice:0:10; index as i"
                        class="dropdown-search-item" style="cursor:pointer"
                        (click)="addPopularText(item.text);">
                        <span class="dropdown-search-item-order">{{i+1}}.&nbsp;</span>
                        <div>{{item.text}}</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- search dropdown끝 -->

    </div>


</div>