import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiClientService } from '../../lib/api-client.service';
import { Util } from '../../lib/util';
import { Router } from '@angular/router';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';


@Component({
    selector: 'app-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(CdkScrollable) scrollable!: CdkScrollable;
    @ViewChild('fb_banner') fb_banner!: ElementRef;
    @ViewChild('fb_icon_container') fb_icon_container!: ElementRef;
    @ViewChild('fb_introduce') fb_introduce!: ElementRef;
    @ViewChild('fb_introduce2') fb_introduce2!: ElementRef;

    partnerList = ['Corners', 'SUL', 'SKT', 'NaviWorks', 'Dmoa', 'Krafton', 'SpaceElvis', 'Vacorporation', 'Adererror', 'Nudake', 'IsraelEmbassy', 'GCA', 'KoreanPolice', 'Yonsei'];

    linkList = [
        { name: 'Store', hover: false, address: '/store' },
        { name: 'Scan', hover: false, address: '/business/3dscanstudio' },
        { name: 'Modeling', hover: false, address: '/business/modeling' },
        { name: 'Content', hover: false, address: '' },
    ]
    marqueeInners!: HTMLElement[];
    thumbnailList: string[] = [];

    screenSize = this.resizeSvc.getCurrent();

    constructor(private client: ApiClientService,
        private resizeSvc: ResizeService,
        private router: Router) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size;
        });

    }


    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.setForIndex();

    }

    ngAfterViewInit(): void {
        const second = this.screenSize === SCREEN_SIZE.mobile ? 0.2 : 0.4;
        this.addIntersectionObserver(this.fb_banner, 'fb-animation', 0.2);
        this.addIntersectionObserver(this.fb_icon_container, 'fb-icon', second);
        this.addIntersectionObserver(this.fb_introduce, 'introduce-animation', 0.5);
        this.addIntersectionObserver(this.fb_introduce2, 'introduce2-animation', 0.4);
        this.setMarquee();
    }

    private setForIndex() {
        const header = document.getElementsByClassName('header-container')[0] as HTMLDivElement;
        if (header !== undefined)
            header.style.background = 'linear-gradient(180deg, rgba(12,12,12,0.4) 0%, rgba(13,13,13,0.15) 60%, rgba(116,116,116,0) 100%)';

        const headerIcons = document.getElementsByClassName('header-icon-wrap')[0] as HTMLDivElement;
        if (headerIcons !== undefined) headerIcons.style.display = 'none';

        const footer = document.getElementsByClassName('footer-top-outer')[0] as HTMLDivElement;
        if (footer !== undefined) {
            footer.style.zIndex = '1';
        }

        const search = document.getElementsByClassName('main-search-outer')[0] as HTMLDivElement;
        if (search !== undefined)
            search.style.opacity = '0.5';
    }

    private reset() {
        const header = document.getElementsByClassName('header-container')[0] as HTMLDivElement;
        if (header) header.style.background = '';

        const headerIcons = document.getElementsByClassName('header-icon-wrap')[0] as HTMLDivElement;
        if (headerIcons !== undefined) headerIcons.style.display = '';

        const footer = document.getElementsByClassName('footer-top-outer')[0] as HTMLDivElement;
        if (footer !== undefined) {
            footer.style.zIndex = '';
        }

        const search = document.getElementsByClassName('main-search-outer')[0] as HTMLDivElement;
        if (search !== undefined) search.style.opacity = '';
    }

    ngOnDestroy(): void {
        this.reset();
    }

    animateText(classname: string) {
        const delay = 0.3;
        const iconlist = document.querySelectorAll(`.${classname}`)

        for (let i = 0; i < iconlist.length; i++) {
            let order = i + 1;
            const slide = iconlist[i] as HTMLDivElement;
            setTimeout(() => {
                slide.classList.add(`${classname}${order}`);
            }, delay * 1000 * order);
        }
    }

    addIntersectionObserver(container: ElementRef, classname: string, height: number): void {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const intersectionRatio = entry.intersectionRatio;
                    if (intersectionRatio >= height) {
                        this.animateText(classname);
                    }
                }
            });
        }, {
            threshold: [height]
        });

        observer.observe(container.nativeElement);

    }


    setMarquee() {
        const root = document.documentElement;
        const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
        const marqueeContent = document.querySelector("ul.fb-marquee-content") as HTMLUListElement;

        root.style.setProperty("--marquee-elements", String(marqueeContent.children.length));

        for (let i = 0; i < Number(marqueeElementsDisplayed); i++) {
            marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
        }

    }

    openPopup(galley: number) {
        this.client.commonPopupSubject.next(({ popupTitle: 'fbPopup', openPopup: true, currentGallery: galley }))
    }

    scrollToGallery() {
        const galleryContainer = (document.getElementById('fb-gallery') as HTMLDivElement)
        const gallerytop = galleryContainer.offsetTop
        const nav = (document.getElementsByClassName('header-outer')[0] as HTMLDListElement).clientHeight;

        const height = gallerytop - nav;


        Util.scrollTo(height);
    }


    async moveToLocation(location: string) {
        this.router.navigate([location]);
    }
}
