/* eslint-disable @typescript-eslint/no-explicit-any */

import { CashItem, PurchaseItem } from "./billing";
import { AssetFileInfo } from "./assetutil";
export type ServiceMode = 'dev' | 'test' | 'prod';

export type ProductPriceInfo = {
    [key in Data.AssetOptionType]?: {
        [key in Data.CurrencyType]: {
            originprice: number;
            dcprice: number;
        }
    }
};
export interface ProductInfo {
    assetno: number,
    name: { [key in Data.CurrencyType]: string },
    tags: string[],
    vieweridinfo: { [key in Data.AssetOptionType]?: string },
    priceinfo: ProductPriceInfo;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Data {
    export type UserStatus = 'NotVerified' | 'Normal' | 'Deleted';
    export interface User {
        userno: number;
        userid: number;
        email: string;
        password: string;
        regdate: Date;
        recv_email: boolean;
        status: UserStatus;
    }
    export type UserHistoryType = 'ConsentMail' | 'RefuseMail';
    export interface UserHistoryItem {
        hno: number;
        userno: number;
        regdate: Date;
        type: UserHistoryType;
        data: string;
    }

    export enum ESearchCondition {
        None = 0,
        Male = 1 << 0,
        Female = 1 << 1,
        Sex_All = (1 << 0) | (1 << 1),

        Age_0 = 1 << 2,
        Age_10 = 1 << 3,
        Age_20 = 1 << 4,
        Age_30 = 1 << 5,
        Age_40_50 = 1 << 6,
        Age_60_ = 1 << 7,
        Age_All = (1 << 2) | (1 << 3) | (1 << 4) | (1 << 5) | (1 << 6) | (1 << 7),

        Type_Posed = 1 << 11,
        Type_APosed = 1 << 12,
        Type_Rigged = 1 << 13,
        Type_Object = 1 << 14,
        Type_Space = 1 << 15,
        Type_Facial = 1 << 16,
        Type_All = (1 << 11) | (1 << 12) | (1 << 13) | (1 << 14) | (1 << 15) | (1 << 16),

        Res_CU2000 = 1 << 20,       // Clean-up 2M, 25000 KRW
        Res_CU100 = 1 << 21,     // Clean-up 100K, 25000 KRW
        Res_CU30 = 1 << 22,      // Clean-up 30K, 15000 KRW
        Res_RT = 1 << 23,       // Real-time, 60000 KRW
        Res_SP = 1 << 24,   // for object, Simplified, 4900 KRW
        Res_RWSC = 1 << 25,     // for space, Raw(Scan), 4900 KRW
        Res_TPO = 1 << 26,      // for object, Topologized, 11900 KRW
        Res_TP1 = 1 << 27,  // Topologized lv.1, 40000 KRW
        Res_All = (1 << 20) | (1 << 21) | (1 << 22) | (1 << 23) | (1 << 24) | (1 << 25) | (1 << 26) | (1 << 27),

        All = Sex_All | Age_All | Type_All | Res_All,
    }
    export enum ECurrency { KRW = 1, USD = 2 };

    export type subCateogry = "clothings" | "top" | "bottom" | "outer" | "footwear" | "accessories" | "oriental/traditional" | "electronics" | "nature" | "food" | "toys/hobby" | "interior" | "exterior" | "others"
    export interface Category {
        category: string[];
        subCategory: subCateogry[];
        isGameready: boolean;
    }

    export interface Asset {
        assetno: number;
        name: string;
        description: string;
        asset: string;
        image1: string;
        image2: string;
        flag: ESearchCondition;
        regdate: Date;
        regdate_favorite?: Date;
        regdate_cart?: Date;
        regdate_payment?: Date;
        tag: string | string[];
        tag_search: string;
        currency: CurrencyType;
        price: number;
        options: {
            [key in AssetOptionType]?: AssetOption;
        }
        optioncount: number;
        download?: number;
        first_download?: Date;
        billing_chargeno?: string;
        purchase_status?: Data.PurchaseStatus;
        purchaseno?: number;
        refund_date?: Date;
        favorite: boolean;
        cart: CartIconType;
        popularity: number;
    }

    export interface OrderAsset extends Asset {
        show_couponlist?: boolean;
        dc_price?: number;
        coupon_dc?: boolean;
    }
    export type CartIconType = 'All' | 'Some' | 'None';
    export const AssetTypes = ['Posed', 'APosed', 'Rigged', 'Facial', 'Object', 'Space'] as const;
    export type AssetType = typeof AssetTypes[number];
    export const AssetOptionTypes = ['RT', 'TP1', 'TPO', 'CU2000', 'CU100', 'CU30', 'RWSC', 'SP'] as const;
    export type AssetOptionType = typeof AssetOptionTypes[number];
    export const CurrencyTypes = ['KRW', 'USD'/*, 'EUR'*/] as const;
    export type CurrencyType = typeof CurrencyTypes[number];
    export const LanguageTypes = ['ko', 'en'/*, 'EUR'*/] as const;
    export type LanguageType = typeof LanguageTypes[number];
    export type AssetFormat = 'fbx' | 'obj' | 'glb' | 'gltf';
    export type TextureFormat = 'png' | 'jpeg' | 'tga';
    // * diffuse, normal, alpha, ambient occlusion, metalic, roughness, complete, displacement, id
    export const TextureMapTypes = ['DIFF', 'NORM', 'AO', 'ME', 'RO', 'COMP', 'OP', 'DP', 'ID'] as const;
    export type TextureMapType = typeof TextureMapTypes[number];
    export const TextureMapTypeNames = ['Diffuse', 'Normal', 'Ambient Occlusion', 'Metallic', 'Roughness', 'COMP', 'Opacity', 'Displacement', 'ID'] as const;
    export type TextureMapTypeName = typeof TextureMapTypeNames[number];
    export interface AssetMetadata {
        format: AssetFormat[];
        polygons: number;
        texture: {
            format: TextureFormat[];
            maps: TextureMapType[];
            count: number;
        },
        customimage: number;
        viewerid: string;
    }
    export interface AssetOption {
        itemid: number;
        optionno: number;
        type: AssetOptionType;
        size: number;
        info: AssetMetadata;
        cart: boolean;
        orderid: number;
        itemprice: number;
        itemdcprice: number;
    }
    export type AssetOptions = {
        [key in AssetOptionType]: AssetOption;
    }

    export type PaymentStatus = 'Created' | 'Paid' | 'Refunded' | 'Pending' | 'PartiallyRefunded';
    export type PurchaseStatus = 'Purchased' | 'Refunded' | 'RefundRequested';
    export interface Payment {
        paymentno: number;
        orderid: number;
        regdate: Date;
        status: PaymentStatus;
        imp_uid: string;
        merchant_uid: string;
        asset: Data.Asset[];
    }
    export enum ESortBy {
        Recent = 1,
        Old = 2,
        Popular = 3,
        HighPrice = 4,
        LowPrice = 5
    }
    export const DefaultPriceList: {
        [key in Data.AssetOptionType]: { [key in Data.CurrencyType]: number };
    } = {
        CU2000: { KRW: 25000, USD: 19.99 },
        CU100: { KRW: 25000, USD: 19.99 },
        CU30: { KRW: 15000, USD: 11.99 },
        RT: { KRW: 60000, USD: 47.99 },
        RWSC: { KRW: 4900, USD: 3.99 },
        SP: { KRW: 4900, USD: 3.99 },
        TP1: { KRW: 40000, USD: 31.99 },
        TPO: { KRW: 11900, USD: 9.99 },
    };
    export const SpecialDealPrice: { [key in Data.CurrencyType]: number } = { KRW: 0, USD: 0 };
    export const SpecialDealAvailableOption: Data.AssetOptionType[] = ['CU30', 'TPO', 'SP', 'RWSC'];
    export const LicenseTypes = ['personal', 'group'] as const;
    export type LicenseType = typeof LicenseTypes[number];
    export interface LicenseInfo {
        type: LicenseType;
        username: string;
    };

    export const CouponTypes = ['Default', 'Welcome', 'InviteFriend'] as const;
    export type CouponType = typeof CouponTypes[number];
    export const CouponUseTypes = ['Default', 'Cash', 'Item', 'CashAndItem', 'ItemDiscount'] as const;
    export type CouponUseType = typeof CouponUseTypes[number];
    export const CouponStates = ['Default', 'Available', 'Used', 'NotAvailable', 'Allocated'] as const;
    export type CouponState = typeof CouponStates[number];
    export interface Coupon {
        no: string;
        type: CouponType;
        name: string;
        recommenduserno: number;
        itemid: number; //0일경우 모든 아이템에 적용가능, 특정값일때는 해당 아이템에 적용하가능함
        applieditemid: number; //페이지에서만 사용함.현재 적용 여부 (0이면 다른곳 사용가능, 0이아니면 적용된상태)
        usetype: CouponUseType;
        state: CouponState;
        regdate: Date;
        expdate: Date;
        upddate: Date;
        usedate: Date;
    }

    // https://api.iamport.kr/#!/payments/getPaymentByMerchantUid
    export interface IamportPaymentInfo {
        amount: number;
        apply_num: string;
        bank_code: string;
        bank_name: string;
        buyer_addr: string;
        buyer_email: string;
        buyer_name: string;
        buyer_postcode: string;
        buyer_tel: string;
        cancel_amount: number;
        cancel_history: {
            pg_tid: string;
            amount: number;
            cancelled_at: number;
            reason: string;
            receipt_url: string
        }[];
        cancel_reason: string;
        cancel_receipt_urls: string[];
        cancelled_at: number;
        card_code: string;
        card_name: string;
        card_number: string;
        card_quota: number;
        card_type: string;
        cash_receipt_issued: boolean;
        channel: string;
        currency: string;
        custom_data: string;
        customer_uid: string;
        customer_uid_usage: string
        emb_pg_provider: string;
        escrow: boolean;
        fail_reason: string;
        failed_at: number;
        imp_uid: string;
        merchant_uid: string;
        name: string;
        paid_at: number;
        pay_method: string;
        pg_id: string;
        pg_provider: string;
        pg_tid: string;
        receipt_url: string;
        started_at: number;
        status: string;
        user_agent: string;
        vbank_code: string;
        vbank_date: number;
        vbank_holder: string;
        vbank_issued_at: number;
        vbank_name: string;
        vbank_num: string;
    }
    export interface IamportCancelResponse {
        imp_uid: string,
        merchant_uid: string,
        pay_method: string,
        channel: string,
        pg_provider: string,
        emb_pg_provider: string,
        pg_tid: string,
        pg_id: string,
        escrow: true,
        apply_num: string,
        bank_code: string,
        bank_name: string,
        card_code: string,
        card_name: string,
        card_quota: number,
        card_number: string,
        card_type: string,
        vbank_code: string,
        vbank_name: string,
        vbank_num: string,
        vbank_holder: string,
        vbank_date: number,
        vbank_issued_at: number,
        name: string,
        amount: number,
        cancel_amount: number,
        currency: string,
        buyer_name: string,
        buyer_email: string,
        buyer_tel: string,
        buyer_addr: string,
        buyer_postcode: string,
        custom_data: string,
        user_agent: string,
        status: string,
        started_at: number,
        paid_at: number,
        failed_at: number,
        cancelled_at: number,
        fail_reason: string,
        cancel_reason: string,
        receipt_url: string,
        cancel_history: {
            pg_tid: string,
            amount: number,
            cancelled_at: number,
            reason: string,
            receipt_url: string
        }[],
        cancel_receipt_urls: string[],
        cash_receipt_issued: boolean,
        customer_uid: string,
        customer_uid_usage: string
    }
}

/*
 * ***************************************************
 */

class ApiBase { }
export class ReqTypeBase extends ApiBase { }
export class ReqTypeByPage extends ReqTypeBase {
    pagesize = 10;
    pageno = 1;
}
export class ResTypeBase extends ApiBase {
    message = 'ok';
    count = 0;  // MERROR-245 일단은 cart와favorite관련 갯수를 넘겨주기위해 추가
}
export class ApiInfo<ReqType extends ReqTypeBase, ResType extends ResTypeBase> {
    path = '';
    reqlogin = false;
    reqtype: new () => ReqType;
    restype: new () => ResType;
    constructor(path: string, require_login: boolean, reqtype: new () => ReqType, restype: new () => ResType) {
        this.path = path;
        this.reqlogin = require_login;
        this.reqtype = reqtype;
        this.restype = restype;
    }
}

export class ReqCheckEmailExist extends ReqTypeBase {
    email = '';
}
export class ReqChangeEmailRequest extends ReqCheckEmailExist {
    lang: Data.LanguageType = 'ko';
    sendToNewEmailOnly = false;
}

export class ReqDeleteUser extends ReqTypeBase {
    email = '';
    password = '';
}
export class ReqLogin extends ReqTypeBase {
    email = '';
    password = '';
}
export class ReqRegisterUser extends ReqLogin {
    lang: Data.LanguageType = 'ko';
    recv_email = false;
    referralcode = '';
}
export class ReqResetPassword extends ReqTypeBase {
    email = '';
    password = '';
    key = '';
}
export class ReqCheckUserRequest extends ReqTypeBase {
    email = '';
    key = '';
}
export class ResGetUserinfo extends ResTypeBase {
    info: Data.User = {
        userno: 0,
        userid: 0,
        email: '',
        password: '',
        regdate: new Date(),
        recv_email: false,
        status: 'NotVerified'
    };
    cartcount = 0;  // MERROR-245 cart갯수
    favoritecount = 0;  // MERROR-245 cart객수를 추가하면서 함께 추가함, 현재를 사용하지 않음
}
export class ReqModifyUserinfo extends ReqTypeBase {
    password = '';
    newemail = '';
    newpassword = '';
}
export class ReqGetAssetinfo extends ReqTypeBase {
    currency: Data.CurrencyType = 'KRW';
    assetno = 0;
}

export class ResGetAssetinfo extends ResTypeBase {
    asset: Data.Asset = {} as any;
}
export class ReqAddToFavorite extends ReqTypeBase {
    assetno = 0;
}
export class ResGetPurchaseinfo extends ResTypeBase {
    asset: Data.Payment = {} as any;
}
export class ReqGetAssetList extends ReqTypeByPage {
    flag: Data.ESearchCondition = Data.ESearchCondition.All;
    searchtext: string[] = [];
    sort: Data.ESortBy = Data.ESortBy.Recent;
    minprice = 0;
    maxprice = 0;
    minpolygon = 0;
    maxpolygon = 0;
    currency: Data.CurrencyType = 'KRW';
    categoryInfo: Data.Category = {
        category: [],
        subCategory: [],
        isGameready: false
    }
    override pageno = 1;
    override pagesize = 20;
}
export interface SearchTextScore {
    text: string;
    score: number;
}
export class ResGetAssetList extends ResTypeBase {
    list: Data.Asset[] = [];
    searchtext: SearchTextScore[] = [];
    totalcount = 0;
}
export class ReqAddToCart extends ReqTypeBase {
    assetno = 0;
    optionno: number[] = [];
}

export interface RequestPurchaseInfo {
    assetno: number,
    optionno: number,
    itemid: number,
    coupon: string
}
export class ReqRequestPurchase extends ReqTypeBase {
    list: RequestPurchaseInfo[] = [];
    currency: Data.CurrencyType = 'KRW';
}
export class ResRequestPurchase extends ResTypeBase {
    merchant_uid = '';
    orderid = '';
    list: {
        itemid: number;
        price: number;
        dcprice: number;
    }[] = [];
}
export interface ConfirmPurchaseInfo extends RequestPurchaseInfo { name: string; price: number }
export class ReqConfirmPurchase extends ReqTypeBase {
    orderid = '';
    imp_uid = '';
    merchant_uid = '';
    licenseinfo: Data.LicenseInfo = { type: 'personal', username: '' };
    currency: Data.CurrencyType = 'KRW';
    list: ConfirmPurchaseInfo[] = [];
}
export type BillingPurchaseItem = PurchaseItem & {
    firstDownload: Date | null;
    refundable: boolean;
    refunded: boolean;
    currency: Data.CurrencyType;
}
export type BillingPaymentItem = CashItem & {
    licenseinfo: Data.LicenseInfo;
    currency: Data.CurrencyType;
    list: BillingPurchaseItem[]
};
export class ResGetPurchaseList extends ResTypeBase {
    list: BillingPaymentItem[] = [];
    totalcount = 0;
}

export class ReqRequestDownload extends ReqTypeBase {
    assetno = 0;
    optionino = 0;
}

export class ReqGetPGInfo extends ReqTypeBase {
    merchant_uid = '';
}
export class ResGetPGInfo extends ResTypeBase {
    info: Data.IamportPaymentInfo = {} as any;
}

export class ReqRefund extends ReqTypeBase {
    orderid = '';
    currency: Data.CurrencyType = 'KRW';
    chargelist: string[] = [];
}
export class ResRefund extends ResTypeBase { }

export class ReqRequestVerifyEmail extends ReqCheckEmailExist {
    lang: Data.LanguageType = 'ko';
    referralcode = '';
}
export class ResRequestVerifyEmail extends ResTypeBase { }
export class ReqVerifyEmail extends ReqCheckEmailExist {
    key = '';
}
export class ResVerifyEmail extends ResTypeBase { }

export class ReqCustomOrder extends ReqTypeBase {
    content = '';
    name = '';
    tel = '';
    email = '';
}

export class ResGetCouponList extends ResTypeBase {
    list: Data.Coupon[] = [];
}
export class ReqRequestCoupon extends ReqTypeBase {
    type: Data.CouponType = 'InviteFriend';
    code = '';
}
export class ResRequestCoupon extends ResTypeBase {
    couponno = '';
}
export class ReqCheckReferralCode extends ReqTypeBase {
    code = '';
}
export class ResCheckReferralCode extends ResTypeBase { }

export class ReqApplyFavoriteCartCookie extends ReqTypeBase {
    favorite: number[] = [];
    cart: {
        assetno: number; optionno: number[];
    }[] = [];
}

export class ReqGetUserHistory extends ReqTypeBase {
    type: Data.UserHistoryType | undefined = undefined;
}
export class ResGetUserHistory extends ResTypeBase {
    list: Data.UserHistoryItem[] = [];
}
export class ReqSetUserRecvEmail extends ReqTypeBase {
    recv_email = false;
}

export class ApiList {
    /*
     * example api
    ExsmapleApi = new ApiInfo('/test', true,
        class extends ReqTypeBase { },
        class extends ResTypeBase { }
    );
    */
    Version = new ApiInfo('/version', false, ReqTypeBase, ResTypeBase);

    CheckLogin = new ApiInfo('/checklogin', false, ReqTypeBase, ResTypeBase);
    CheckEmailExist = new ApiInfo('/checkexist', false, ReqCheckEmailExist, ResTypeBase);
    Login = new ApiInfo('/login', false, ReqLogin, ResTypeBase);
    Logout = new ApiInfo('/logout', false, ReqTypeBase, ResTypeBase);
    RegisterUser = new ApiInfo('/registeruser', false, ReqRegisterUser, ResTypeBase);
    GetUserinfo = new ApiInfo('/userinfo', true, ReqTypeBase, ResGetUserinfo);
    ModifyUserinfo = new ApiInfo('/modifyuserinfo', true, ReqModifyUserinfo, ResTypeBase);
    ModifyPassword = new ApiInfo('/modifypassword', true, ReqModifyUserinfo, ResTypeBase);
    DeleteUser = new ApiInfo('/deleteuser', true, ReqDeleteUser, ResTypeBase);
    RequestVerifyEmail = new ApiInfo('/registeruser/requestverify', false, ReqRequestVerifyEmail, ResRequestVerifyEmail);
    VerifyEmail = new ApiInfo('/registeruser/verify', false, ReqVerifyEmail, ResVerifyEmail);

    SetUserRecvEmail = new ApiInfo('/userinfo/setrecvemail', true, ReqSetUserRecvEmail, ResTypeBase)
    GetUserHistory = new ApiInfo('/userinfo/history', true, ReqGetUserHistory, ResGetUserHistory);

    PasswordResetRequest = new ApiInfo('/resetpassword/request', false, ReqRequestVerifyEmail, ResTypeBase);
    ResetPassword = new ApiInfo('/resetpassword', false, ReqResetPassword, ResTypeBase);
    ChangeEmailRequest = new ApiInfo('/changeemail/request', true, ReqChangeEmailRequest, ResTypeBase);
    ChangeEmail = new ApiInfo('/changeemail', false, ReqResetPassword, ResTypeBase);
    CheckUserRequest = new ApiInfo('/checkrequest', false, ReqCheckUserRequest, ResTypeBase);

    GetAssetinfo = new ApiInfo('/assetinfo', false, ReqGetAssetinfo, ResGetAssetinfo);
    //GetAssetinfoWithUser = new ApiInfo('/assetinfo/withuser', true, ReqGetAssetinfo, ResGetPurchaseinfo);
    GetAssetList = new ApiInfo('/assetlist', false, ReqGetAssetList, ResGetAssetList);

    AddToFavorite = new ApiInfo('/favorite/add', true, ReqAddToFavorite, ResTypeBase);
    RemoveFromFavorite = new ApiInfo('/favorite/remove', true, ReqAddToFavorite, ResTypeBase);
    GetFavoriteAssetList = new ApiInfo('/favorite/list', true, ReqGetAssetList, ResGetAssetList);

    AddToCart = new ApiInfo('/cart/add', true, ReqAddToCart, ResTypeBase);
    RemoveFromCart = new ApiInfo('/cart/remove', true, ReqAddToCart, ResTypeBase);
    GetCartList = new ApiInfo('/cart/list', true, ReqGetAssetList, ResGetAssetList);

    ApplyFavoriteCartCookie = new ApiInfo('/userinfo/applycookie', true, ReqApplyFavoriteCartCookie, ResTypeBase);

    RequestPurchase = new ApiInfo('/purchase/request', true, ReqRequestPurchase, ResRequestPurchase);
    ConfirmPurchase = new ApiInfo('/purchase/confirm', true, ReqConfirmPurchase, ResRequestPurchase);
    GetPurchaseList = new ApiInfo('/purchase/list', true, ReqTypeByPage, ResGetPurchaseList);
    GetPGInfo = new ApiInfo('/purchase/pginfo', true, ReqGetPGInfo, ResGetPGInfo);
    Refund = new ApiInfo('/purchase/refund', true, ReqRefund, ResRefund);

    RequestCustomOrder = new ApiInfo('/customorder/request', false, ReqCustomOrder, ResTypeBase);

    GetCouponList = new ApiInfo('/userinfo/couponlist', true, ReqTypeBase, ResGetCouponList);
    RequestCoupon = new ApiInfo('/userinfo/requestcoupon', true, ReqRequestCoupon, ResRequestCoupon);
    CheckReferralCode = new ApiInfo('/checkreferralcode', false, ReqCheckReferralCode, ResCheckReferralCode);
}

export class ReqAdmin_GetAssetFileInfo extends ReqTypeBase {
    assetno = 0;
    all = false;
}
export class ResAdmin_GetAssetFileInfo extends ResTypeBase {
    list: AssetFileInfo[] = [];
}
export class ResAdmin_GetSearchTextList extends ResTypeBase {
    searchtextlist: SearchTextScore[] = [];
}

export class AdminApiList {
    Admin_GetAssetFileInfo = new ApiInfo('/admin/assetfileinfo', false, ReqAdmin_GetAssetFileInfo, ResAdmin_GetAssetFileInfo);
    Admin_RefreshAssetCache = new ApiInfo('/admin/assetcache/refresh', false, ReqTypeBase, ResTypeBase);
    Admin_ResetSearchTextList = new ApiInfo('/admin/searchtextlist/reset', false, ReqTypeBase, ResTypeBase);
    Admin_GetSearchTextList = new ApiInfo('/admin/searchtextlist', false, ReqTypeBase, ResAdmin_GetSearchTextList);
}

export const Api = new ApiList();
export const AdminApi = new AdminApiList();
