import { NgModule } from '@angular/core';
import {
    DefaultUrlSerializer,
    RouterModule, Routes, UrlSerializer, UrlTree
} from '@angular/router';
import { AuthGuard } from './lib/auth.guard';
import { AdminIndexComponent } from './pages/admin/admin-index.component';
import { ApiTestComponent } from './pages/api-test.component';
import { CartComponent } from './pages/cart.component';
import { EULAComponent } from './pages/eula.component';
import { FavoriteComponent } from './pages/favorite.component';
import { StoreIndexComponent } from './pages/store-index.component';
import { AccountComponent } from './pages/account.component';
import { OrderComponent } from './pages/order.component';
import { RefundComponent } from './pages/refund.component';
import { ChangeEmailPopupComponent } from './pages/popup/change-email-popup.component';
import { ModifyuserPopupComponent } from './pages/popup/modifyuser-popup.component';
import { ReceiptPopupComponent } from './pages/popup/receipt-popup.component';
import { RequestPasswordResetPopupComponent } from './pages/popup/request-password-reset-popup.component';
import { ResetPasswordPopupComponent } from './pages/popup/reset-password-popup.component';
import { PurchasesComponent } from './pages/purchases.component';
import { SignupComponent } from './pages/signup.component';
import { EmailVerifictaionPopupComponent } from './pages/popup/email-verifictaion-popup.component';
import { VerifyEmailComponent } from './pages/verify-email.component';
import { AdminAssetComponent } from './pages/admin/admin-asset.component';
import { DeleteuserPopupComponent } from './pages/popup/deleteuser-popup.component';
import { PurchaseDetailComponent } from './pages/purchase-detail.component';
import { AdminThumbnailComponent } from './pages/admin/admin-thumbnail.component';
import { AppInjector } from './app.module';
import { TranslateService } from '@ngx-translate/core';
import { SpecialDealComponent } from './pages/event/special-deal.component';
import { BusinessComponent } from './pages/business/business.component';
import { AssetListComponent } from './pages/asset-list.component';
import { ProductDetailComponent } from './pages/product-detail.component';
import { ChatbotComponent } from './pages/chatbot.component';
import { ErrorComponent } from './pages/error.component';
import { IndexComponent } from './pages/index.component';
import { BusinessScanStudioComponent } from './pages/business/business-scanstudio.component';
import { FaceKoreaComponent } from './pages/event/face-korea.component';
import { ReferralEventComponent } from './pages/event/referral-event.component';
import { BusinessModelingComponent } from './pages/business/business-modeling.component';
import { AdminStoreinfoComponent } from './pages/admin/admin-storeinfo.component';
import { CustomPaymentComponent } from './pages/custom-payment.component';

export class customUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
    private regexpParse = new RegExp('/asset([0-9]+)$');
    private regexpSerialize = new RegExp('\\(productdetail:itemview-popup/([0-9]+)\\)$');

    parse(url: string): UrlTree {
        const translate = AppInjector.get(TranslateService);
        if (url.startsWith('/en') || url.startsWith('/ko')) url = url.substring(3);
        if (this.regexpParse.test(url)) url = url.replace(this.regexpParse, '(productdetail:itemview-popup/$1)')
        return this._defaultUrlSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        const translate = AppInjector.get(TranslateService);
        let url = this._defaultUrlSerializer.serialize(tree);
        if (this.regexpSerialize.test(url)) url = url.replace(this.regexpSerialize, '/asset$1').replace('//', '/');
        url = '/' + translate.getDefaultLang() + url;
        return url;
    }
}

const routes: Routes = [
    { path: 'test', component: ApiTestComponent },

    { path: 'store', component: StoreIndexComponent },
    { path: 'asset', component: AssetListComponent },
    { path: 'asset/:category1', component: AssetListComponent },
    { path: 'asset/:category1/:category2', component: AssetListComponent },
    { path: 'asset', component: AssetListComponent },
    { path: 'eula', component: EULAComponent },
    { path: 'faq', component: EULAComponent },
    { path: 'privacypolicy', component: EULAComponent },
    { path: 'favorite/:category1', component: FavoriteComponent },
    { path: 'favorite', component: FavoriteComponent },
    { path: 'cart', component: CartComponent },
    { path: 'signup/:referralcode', component: SignupComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'verifyemail/:email/:key', component: VerifyEmailComponent },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
    { path: 'order/:confirmcode', component: OrderComponent, canActivate: [AuthGuard] },
    { path: 'order', component: OrderComponent, canActivate: [AuthGuard] },
    { path: 'refund', component: RefundComponent, canActivate: [AuthGuard] },
    { path: 'event/specialdeal', component: SpecialDealComponent },
    { path: 'event/faceofkorea', component: FaceKoreaComponent },
    { path: 'event/referral', component: ReferralEventComponent },
    { path: 'business/modeling', component: BusinessModelingComponent },
    { path: 'business/quotation', component: ChatbotComponent },
    { path: 'business/customproduct', component: ChatbotComponent },
    { path: 'business/contact', component: ChatbotComponent },
    { path: 'business/3dscanstudio', component: BusinessScanStudioComponent },
    { path: 'business/payment/:paymentid', component: CustomPaymentComponent, canActivate: [AuthGuard] },
    { path: 'support', component: ChatbotComponent },
    { path: 'customorder', component: ChatbotComponent },
    { path: 'business', component: BusinessComponent },

    { path: 'purchases', component: PurchasesComponent, canActivate: [AuthGuard] },
    { path: 'purchases/:orderid', component: PurchaseDetailComponent, canActivate: [AuthGuard] },

    { path: 'itemview-popup', component: ProductDetailComponent, outlet: 'productdetail' },
    { path: 'itemview-popup/:modelno', component: ProductDetailComponent, outlet: 'productdetail' },

    { path: 'deleteuser', component: DeleteuserPopupComponent, canActivate: [AuthGuard], outlet: 'popup' },
    {
        path: 'modifyuser/:item', component: ModifyuserPopupComponent,
        canActivate: [AuthGuard], outlet: 'popup'
    },

    { path: 'request-password-reset', component: RequestPasswordResetPopupComponent, outlet: 'popup' },
    { path: 'reset-password/:email/:key', component: ResetPasswordPopupComponent, outlet: 'popup' },
    { path: 'change-email/:email/:key', component: ChangeEmailPopupComponent, outlet: 'popup' },

    { path: 'admin', component: AdminIndexComponent },
    { path: 'admin/asset', component: AdminAssetComponent },
    { path: 'admin/storeinfo', component: AdminStoreinfoComponent },
    { path: 'admin/thumbnail/:assetno/:type/:option', component: AdminThumbnailComponent },
    { path: 'admin/thumbnail/:assetno/:type', component: AdminThumbnailComponent },

    // { path: 'receipt-popup', component: ReceiptPopupComponent, outlet: 'popup' },
    { path: 'mail-sent/:email', component: EmailVerifictaionPopupComponent, outlet: 'popup' },
    { path: 'please-verify/:email', component: EmailVerifictaionPopupComponent, outlet: 'popup' },
    { path: 'receipt-popup/:orderno', component: ReceiptPopupComponent, outlet: 'popup' },

    { path: '', component: IndexComponent },
    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
