import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingPurchaseItem } from 'src/server/lib/types';

@Component({
    selector: 'app-purchase-mobile-popup',
    templateUrl: './purchase-mobile-popup.component.html',
    styleUrls: ['../purchases.component.scss']
})
export class PurchaseMobilePopupComponent implements OnInit {

    @Input() item!: BillingPurchaseItem
    @Input() index!: number;
    @Output() downloadAsset = new EventEmitter();
    @Output() refund = new EventEmitter();

    showPopup = false;


    constructor() { }

    ngOnInit(): void {
    }

    download() {
        this.downloadAsset.emit(this.item);
    }

    refundItem() {
        this.refund.emit(this.index);
    }
}
