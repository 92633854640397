<div>
    <img class="purcahse-popup-icon" src=" /assets/icon/ellipse_24.svg"
        (click)="showPopup=!showPopup" />
    <div [style.display]="showPopup?'flex': 'none'" class="purchase-detail-popup">
        <div *ngIf="!item.refunded" class="purchase-detail-popup-download" (click)="download()"
            [innerHTML]="'Common.DownloadSelection'|translate">
            Download
        </div>
        <div *ngIf="item.refundable" class="purchase-detail-popup-refund"
            [innerHtml]="'Refund.Title'|translate" (click)="refundItem()">환불 신청
        </div>
    </div>

</div>