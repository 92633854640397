import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService } from 'src/app/lib/resize.service';
import { Util, UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-business-scanstudio',
    templateUrl: './business-scanstudio.component.html',
    styleUrls: ['./common/business-common.component.scss']
})
export class BusinessScanStudioComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('title_container') title_container!: ElementRef<HTMLDivElement>;
    @ViewChild('videoElement') videoElement!: ElementRef;

    screenSize = this.resizeSvc.getCurrent();
    hover = false;
    currentLang: 'ko' | 'en' = this.util.getCurrentLang();

    onLangChangeSub: Subscription | null = null;

    descinfo = [
        {
            title: 'ForBusiness.LidarScan',
            desc: [
                'ForBusiness.LidarScanDesc1',
                'ForBusiness.LidarScanDesc2',
                'ForBusiness.LidarScanDesc3'
            ],
            tag: [
                'ForBusiness.LidarTag1',
                'ForBusiness.LidarTag2',
                'ForBusiness.LidarTag3'
            ],
            imagecount: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        },
        {
            title: 'ForBusiness.HumanScan',
            desc: [
                'ForBusiness.HumanScanDesc1',
                'ForBusiness.HumanScanDesc2',
                'ForBusiness.HumanScanDesc3',
                'ForBusiness.HumanScanDesc4'
            ],
            tag: [
                'ForBusiness.HumanTag1',
                'ForBusiness.HumanTag2'
            ],
            imagecount: [1, 2, 3, 4, 5]
        },
        {
            title: 'ForBusiness.PrecisionScan',
            desc: [
                'ForBusiness.PrecisionDesc3',
                'ForBusiness.PrecisionDesc4',
                'ForBusiness.PrecisionDesc5'
            ],
            tag: [
                'ForBusiness.PrecisionTag4',
                'ForBusiness.PrecisionTag5',
            ],
            imagecount: [1, 2, 3]
        },
        {
            title: 'ForBusiness.PanoramicScan',
            desc: [
                'ForBusiness.PanoramicDesc1',
                'ForBusiness.PanoramicDesc2'
            ],
            tag: [
                'ForBusiness.PanoramicTag1',
                'ForBusiness.PanoramicTag2'
            ],
            imagecount: [1, 2, 3, 4]
        }
    ];

    whymerrorinfo = [
        {
            title: 'ForBusiness.AllinOneSystem',
            desc: 'ForBusiness.Solution',

        },
        {
            title: 'ForBusiness.3DConsultant',
            desc: 'ForBusiness.Consultation'
        },
        {
            title: 'ForBusiness.CustomMade',
            desc: 'ForBusiness.Customization'
        },
        {
            title: 'ForBusiness.Excellence',
            desc: 'ForBusiness.ResonablePrice'
        }
    ];

    projectinfo = [
        ['ForBusiness.ProjectTitle4', 'ForBusiness.ProjectDesc2'],
        ['ForBusiness.ProjectTitle3', 'ForBusiness.ProjectDesc1'],
        ['ForBusiness.ProjectTitle7', 'ForBusiness.ProjectDesc5'],
        ['ForBusiness.ProjectTitle5', 'ForBusiness.ProjectDesc3'],
        ['ForBusiness.ProjectTitle6', 'ForBusiness.ProjectDesc4'],
        ['ForBusiness.ProjectTitle7', 'ForBusiness.ProjectDesc5']
    ]

    portfolioList = [
        { order: 1, hover: false, projectinfo: ['ForBusiness.ProjectTitle1', 'ForBusiness.ProjectDesc1'], address: '36' },
        { order: 2, hover: false, projectinfo: ['ForBusiness.ProjectTitle2', 'ForBusiness.ProjectDesc2'], address: '2' },
        { order: 3, hover: false, projectinfo: ['ForBusiness.ProjectTitle3', 'ForBusiness.ProjectDesc3'], address: '37' },
        { order: 4, hover: false, projectinfo: ['ForBusiness.ProjectTitle4', 'ForBusiness.ProjectDesc4'], address: '33' },
        { order: 5, hover: false, projectinfo: ['ForBusiness.ProjectTitle5', 'ForBusiness.ProjectDesc5'], address: '34' },
        { order: 6, hover: false, projectinfo: ['ForBusiness.ProjectTitle6', 'ForBusiness.ProjectDesc6'], address: '38' },
        { order: 7, hover: false, projectinfo: ['ForBusiness.ProjectTitle7', 'ForBusiness.ProjectDesc7'], address: '35' },
        { order: 8, hover: false, projectinfo: ['ForBusiness.ProjectTitle8', 'ForBusiness.ProjectDesc8'], address: '41' },
        { order: 9, hover: false, projectinfo: ['ForBusiness.ProjectTitle9', 'ForBusiness.ProjectDesc9'], address: '40' },
        { order: 10, hover: false, projectinfo: ['ForBusiness.ProjectTitle10', 'ForBusiness.ProjectDesc10'], address: '31' },
        { order: 11, hover: false, projectinfo: ['ForBusiness.ProjectTitle11', 'ForBusiness.ProjectDesc11'], address: '42' },
        { order: 12, hover: false, projectinfo: ['ForBusiness.ProjectTitle12', 'ForBusiness.ProjectDesc12'], address: '3' }]


    constructor(private client: ApiClientService,
        private resizeSvc: ResizeService,
        public util: UtilityService,
        private renderer: Renderer2,
    ) {
        this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
        });
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
    }

    ngAfterViewInit(): void {
        const video = this.videoElement.nativeElement;
        video.muted = true;
        video.autoplay = true;
        video.loop = true;
        video.playsInline = true;

        this.renderer.listen(video, 'loadedmetadata', () => {
            video.play()
        });
    }

    ngOnDestroy(): void {
    }

    scrollToService() {

        const titletop = this.title_container.nativeElement.offsetTop
        const nav = (document.getElementsByClassName('header-outer')[0] as HTMLDListElement).clientHeight;

        const height = titletop - nav;


        Util.scrollTo(height);
    }

    openPopup() {
        this.client.commonPopupSubject.next(({ popupTitle: 'enterInfoPopup', openPopup: true }));
    }


}
