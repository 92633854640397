<div class="verifyemail-container">

    <!-- 인증성공 -->
    <div class="verify-success-container" *ngIf="verifyresult==='ok'">
        <div class="verifyemail-success-outer">
            <div class="verifyemail-success-inner">
                <div class="verifyemail-success-img">
                    <img src="/assets/icon/coupon.svg">
                </div>
                <div class="verifyemail-text1 welcome-text1"
                    [innerHTML]="'Welcome.Congratulation'|translate">
                    미러 회원가입을 진심으로 축하드립니다!</div>
                <div class="verifyemail-text2 verifyemail-text2-success">
                    <div [innerHTML]="'Welcome.Message1'|translate">
                        이메일 인증이 성공적으로 완료되었습니다.</div>
                    <div class="verifyemail-text-event" [innerHTML]="'Welcome.Message2'|translate">
                        오직 회원만이 구매 가능한 3D에셋부터 특별한 이벤트까지,</div>
                </div>
            </div>

            <div class="verifyemail-success-button-wrap">
                <button class="common-button2" [innerHTML]="'Banner.GoToMerror'|translate"
                    routerLink="/store">미러
                    둘러보기</button>
                <button class="common-button1" (click)="signin()"
                    [innerHTML]="'Welcome.Signin'|translate">로그인하고 시작하기</button>
            </div>
        </div>

    </div>
    <!-- 인증성공 끝 -->

    <!-- 인증실패 -->
    <div *ngIf="verifyresult==='error'"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="verifyemail-fail-img">
            <img src="/assets/icon/mail_fail.svg">
        </div>
        <div class="verifyemail-text1" [innerHTML]="'Verification.FailureNotice'|translate">
            이메일 인증이 실패했습니다!</div>
        <div class="verifyemail-text2 verifyemail-text2-fail">
            <div [innerHTML]="'Verification.Message1'|translate">
                만료되었거나 잘못된 링크입니다.</div>
            <div [innerHTML]="'Verification.Message2'|translate">
                인증메일을 다시 보낼 수 있습니다.</div>
        </div>
        <div class="verifyemail-resent" #resent [innerHTML]="'Verification.Resent'|translate">
            인증 메일이 재발송 되었습니다.</div>
        <div class="verifyemail-fail-button-wrap">
            <button (click)="requestVerify()" [innerHTML]="'Verification.Resend'|translate"
                class="common-button2">인증 메일 다시 보내기</button>
        </div>
    </div>
    <!-- 인증실패 끝 -->

</div>