import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiClientService } from './api-client.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private client: ApiClientService,
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.client.api.CheckLogin.send().then(result => {
            console.log('check login result :', result);
            const login = result.message === 'ok';
            if (!login) {
                const url = [];
                for (const item of route.url) {
                    url.push(item.path);
                }
                this.client.userinfoSubject.next({ userinfo: { userno: 0 } } as any);
                this.client.openPopupSubject.next({ url: '/' + url.join('/'), openPopup: true });
            }
            return login;
        });
    }

}
