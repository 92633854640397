import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingPaymentItem, Data } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';
import { ApiClientService } from '../lib/api-client.service';
import { UtilityService } from '../lib/util';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-blank',
    templateUrl: './refund.component.html',
    styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private client: ApiClientService,
        private util: UtilityService,
        public translate: TranslateService,

    ) {
        const state = this.router.getCurrentNavigation()?.extras.state as any;

        if (state === undefined || state.payment === undefined || state.assetIndex === undefined) {
            this.router.navigateByUrl('/');
            return;
        }
        this.payment = state.payment;
        this.totalPrice = state.totalPrice;
        this.discountPrice = state.discountPrice;
        const assetIndex = state.assetIndex as number;

        for (const asset of this.payment.list) {
            if (asset.firstDownload === null && asset.CNLFLAG === 'N') {
                this.checked.push(false);
                this.disabled.push(false);
            }
            else {
                this.checked.push(false);
                this.disabled.push(true);
            }
        }
        if (this.payment.CCYCODE === Data.ECurrency.KRW) {
            this.currencykey = 'Common.KRW';
        } else {
            this.currencykey = 'Common.USD';
        }

        this.check(assetIndex);


        this.onLangChangeSub = this.translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang as 'ko' | 'en';
        })

    }

    payment!: BillingPaymentItem;
    checked: boolean[] = [];
    checkedAll = false;
    disabled: boolean[] = [];
    firstOption = CommonUtil.assetFirstOption;
    withComma = CommonUtil.withComma;
    ApiUrl = this.client.baseurl;
    totalAmount = 0;
    completeStatus = '';
    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;
    errorTitle = 'Refund.Error';
    errorMessage1 = 'Refund.ErrorNotice1';
    errorMessage2 = 'Refund.ErrorNotice2';
    currentLang: 'ko' | 'en' = this.util.getCurrentLang();
    totalPrice = 0;
    discountPrice = 0;

    onLangChangeSub: Subscription | null = null;

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        if (this.onLangChangeSub !== null) this.onLangChangeSub.unsubscribe();

    }

    check(index: number) {
        if (this.disabled[index] === false) this.checked[index] = !this.checked[index];

        this.checkedAll = true;
        for (let index = 0; index < this.checked.length; index++) {
            if (this.disabled[index] === false && this.checked[index] === false) {
                this.checkedAll = false;
                break;
            }
        }
        this.calcTotal();
    }

    checkAll() {
        this.checkedAll = !this.checkedAll;
        for (let index = 0; index < this.checked.length; index++) {
            if (this.disabled[index] === false) this.checked[index] = this.checkedAll;
        }
        this.calcTotal();
    }

    calcTotal() {
        this.totalAmount = 0;
        for (let index = 0; index < this.checked.length; index++) {
            if (this.disabled[index] === false && this.checked[index] === true)
                this.totalAmount += this.payment.list[index].PURCHASEPRICE;
        }
    }

    async refund() {
        if (this.totalAmount === 0 && !this.checked.includes(true)) return;
        const chargelist: string[] = [];
        for (let index = 0; index < this.checked.length; index++) {
            if (this.disabled[index] === false && this.checked[index] === true) {
                chargelist.push(this.payment.list[index].CHARGENO);
            }
        }

        const result = await this.client.api.Refund.send({
            orderid: this.payment.CASHNO, chargelist, currency: this.payment.currency
        });

        console.log(result);
        if (result.message === 'ok') {
            this.completeStatus = 'ok';
        } else {
            if (result.message.includes('partial refund')) {
                this.errorTitle = 'Refund.Partial'
                this.errorMessage1 = 'Refund.PartialNotice1'
                this.errorMessage2 = 'Refund.PartialNotice2'
            }
            this.completeStatus = 'error';
        }

        window.scrollTo(0, 0);
    }

    back() {
        window.history.back();
    }
}
