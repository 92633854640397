<main class="purchase-container">
    <div class="purchase-outer">
        <div class="purchase-inner">
            <div class="purchase-breadcrumb">
                <div>
                    <span routerLink="/store" [innerHTML]="'Common.Home'|translate">Home</span>
                </div>
                <div class="common-horizontal-alignment">
                    <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
                    <span [innerHTML]="'Common.OrderHistory'|translate" (click)="back()"></span>
                </div>

                <div class="common-horizontal-alignment">
                    <img uk-svg src=" /assets/icon/chevron_right_20.svg" />
                    <span [innerHTML]="'Common.OrderDetail'|translate"></span>
                </div>
            </div>

            <div class="purchase-title" [innerHTML]="'Common.OrderDetail'|translate">Purchase List
            </div>

            <div class="purchase-list">
                <div class="purchase-list-outer">
                    <div class="purchase-list-inner">
                        <div class="purchase-list-header">
                            <div>
                                <div class="purchase-list-orderno">
                                    {{payment.CASHNO}}
                                </div>
                                <div class="purchase-list-ordertime">
                                    {{payment.REGDATE}}
                                </div>
                            </div>
                            <div>
                                <div class="purchase-detail-list-receipt"
                                    (click)="showReceipt(payment)">
                                    <img src=" /assets/icon/receipt_20.svg" />
                                </div>
                            </div>
                        </div>

                        <div class="purchase-order-item-outer"
                            *ngFor="let item of payment.list;let index = index">
                            <div class="purchase-order-item-inner">
                                <div class="purchase-order-item">
                                    <div class="purchase-order-thumbnail">
                                        <div
                                            style="background-image:url({{ApiUrl}}{{item.IMGURL1}})">
                                        </div>
                                    </div>
                                    <div class="purchase-order-item-desc">

                                        <div>
                                            <div class="purchase-order-name">
                                                {{currentLang === 'ko'? item.ITEMNAMEKOR:
                                                item.ITEMNAMEENG}}
                                            </div>
                                            <div class="purchase-order-option">
                                                <span
                                                    [innerHTML]="('Search.'+item.ITEMTYPE)|translate"></span>
                                            </div>
                                        </div>

                                        <div class="purchase-align">
                                            <div class="purchase-order-status">
                                                <div *ngIf="item.refunded"
                                                    [innerHTML]="'Common.PurchaseCanceled'|translate">
                                                    구매취소
                                                </div>
                                                <div *ngIf="!item.refunded&&!item.refundable"
                                                    [innerHTML]="'Common.PurchaseConfirmed'|translate">
                                                    구매확정</div>
                                                <div *ngIf="item.refundable"
                                                    [innerHTML]="'Common.Purchased'|translate">구매완료
                                                </div>
                                            </div>
                                            <div class="purchase-order-price-wrap">
                                                <div class="purchase-order-price"
                                                    [style]="(item.COUPONID == 1 || item.COUPONID == 2)|| (item.ITEMPRICE !== item.ITEMORGPRICE)? 'text-decoration: line-through;font-weight: 400;font-size: 12px;color: #9E9E9E;' : ''">

                                                    {{(withComma((item.COUPONID == 1 ||
                                                    item.COUPONID
                                                    ==
                                                    2)?item.ITEMPRICE: item.ITEMORGPRICE)) == '0' &&
                                                    (item.currency === 'USD') ? '0.00':
                                                    withComma((item.COUPONID == 1 || item.COUPONID
                                                    ==
                                                    2)?item.ITEMPRICE: item.ITEMORGPRICE)}}
                                                    <span
                                                        [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                                </div>
                                                <div class="purchase-order-dcprice"
                                                    *ngIf="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)">

                                                    <img src="/assets/icon/coupon_color_24.svg"
                                                        [style.display]="(item.COUPONID == 1 || item.COUPONID == 2) ? 'block':'none'">
                                                    <span>{{withComma(item.PURCHASEPRICE) == '0' &&
                                                        item.currency === 'USD'?
                                                        '0.00':
                                                        withComma(item.PURCHASEPRICE)}}</span>
                                                    <span>&nbsp;</span>
                                                    <span
                                                        [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                </div>

                                <div class="desk-purchase-order-price-wrap">
                                    <div class="desk-purchase-order-price"
                                        [style]="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)? 'text-decoration: line-through;font-weight: 400;font-size: 12px;color: #9E9E9E;' : ''">
                                        {{(withComma((item.COUPONID == 1 || item.COUPONID
                                        ==
                                        2)?item.ITEMPRICE: item.ITEMORGPRICE)) == '0' &&
                                        (item.currency === 'USD')? '0.00': withComma((item.COUPONID
                                        == 1 || item.COUPONID
                                        ==
                                        2)?item.ITEMPRICE: item.ITEMORGPRICE)}}<span
                                            [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                    </div>
                                    <div class="purchase-order-dcprice"
                                        *ngIf="(item.COUPONID == 1 || item.COUPONID == 2) || (item.ITEMPRICE !== item.ITEMORGPRICE)">

                                        <img src="/assets/icon/coupon_color_24.svg"
                                            [style.display]="(item.COUPONID == 1 || item.COUPONID == 2) ? 'block':'none'">
                                        <span>{{withComma(item.PURCHASEPRICE) == '0' &&
                                            item.currency === 'USD'?
                                            '0.00':
                                            withComma(item.PURCHASEPRICE)}}</span>

                                        <span>&nbsp;</span>
                                        <span
                                            [innerHTML]="('Common.'+item.currency)|translate">원</span>
                                    </div>
                                </div>


                                <div class="purchase-order-download">
                                    <div *ngIf="screenSize !==0">
                                        <button *ngIf="!item.refunded" (click)="downloadAsset(item)"
                                            class="common-button2 purchase-download-button">
                                            <span
                                                [innerHTML]="'Common.DownloadSelection'|translate">
                                                다운받기</span></button>
                                        <div *ngIf="item.firstDownload !== null"
                                            class="purchase-download-status">
                                            <div [innerHTML]="'Account.FirstDownload'|translate">
                                                최초 다운로드</div>
                                            <div>
                                                {{getOrderDate(item.firstDownload)}}</div>
                                        </div>
                                        <div *ngIf="item.refundable" class="purchase-refund-button"
                                            (click)="refund(index)"
                                            [innerHtml]="'Refund.Title'|translate">환불신청</div>
                                    </div>
                                    <div
                                        *ngIf="screenSize === 0 && (!item.refunded || item.refundable)">
                                        <div class="purchase-popup-icon">
                                            <app-purchase-mobile-popup
                                                (downloadAsset)="downloadAsset($event)"
                                                (refund)="refund($event)" [item]="item"
                                                [index]="index"></app-purchase-mobile-popup>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="purchase-license-outer">
                <div class="puchase-license-inner">
                    <div class="purchase-license-header">
                        <div class="purchase-license-title">
                            <span [innerHTML]="'Order.LicenseInfo'|translate">사용권 정보</span>
                        </div>
                    </div>

                    <div class="purchase-pg-details">
                        <div class="purchase-pg-price">
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Order.LicenseType'|translate">사용권 유형</div>
                                <div class="purchase-pg-info">
                                    <span
                                        [innerHTML]="(payment.licenseinfo.type === 'personal' ? 'Order.PersonalLicense' : 'Order.CompanyLicense')|translate">
                                        개인사용권</span>
                                </div>
                            </div>
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Order.PersonalName'|translate">사용자명</div>
                                <div class="purchase-pg-info">{{payment.licenseinfo.username}}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div class="purchase-pg-outer">
                <div class="purchase-pg-inner">
                    <div class="purchase-pg-header">
                        <div class="purchase-pg-title">
                            <span [innerHTML]="'Common.PaymentInfo'|translate">결제 정보</span>
                        </div>
                    </div>

                    <div class="purchase-pg-details">
                        <div class="purchase-pg-price">
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Price.Subtotal'|translate">주문금액</div>
                                <div class="purchase-pg-info">
                                    {{(withComma(totalPrice) == '0') && (currencykey ===
                                    'Common.USD')? '0.00': withComma(totalPrice)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                            <div>
                                <div class="purchase-pg-label">
                                    <span [innerHTML]="'Price.Coupons'|translate">쿠폰
                                        할인</span>
                                    <div class="mo-purchase-sale-type">
                                        <div *ngFor="let payment of payment.list">
                                            <div
                                                *ngIf="payment.COUPONID == 1 || payment.COUPONID ==2">
                                                <img uk-svg src="/assets/icon/additional.svg"
                                                    style="stroke: #757575; fill: none;">
                                                <span
                                                    [innerHTML]="payment.COUPONID == 1 ? ('Account.WelcomeCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                    payment.ITEMNAMEENG) + ('Search.'+payment.ITEMTYPE|translate) :  ('Account.FriendCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                    payment.ITEMNAMEENG) + ('Search.'+payment.ITEMTYPE|translate)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="purchase-pg-info puchase-sale-price">
                                    <div>-{{(withComma(discountPrice) =='0') && (currencykey ===
                                        'Common.USD')? '0.00': withComma(discountPrice)}}
                                        <span [innerHTML]="currencykey|translate">원</span>
                                    </div>
                                    <div class="purchase-sale-type">
                                        <div *ngFor="let payment of payment.list">
                                            <div
                                                *ngIf="payment.COUPONID == 1 || payment.COUPONID ==2">
                                                <img uk-svg style="stroke: #BDBDBD; fill: none;"
                                                    src="/assets/icon/additional.svg">
                                                <span
                                                    [innerHTML]="payment.COUPONID == 1 ? ('Account.WelcomeCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate) :  ('Account.FriendCoupon'|translate) + (currentLang === 'ko'? payment.ITEMNAMEKOR:
                                                payment.ITEMNAMEENG) + ' ' + ('Search.'+payment.ITEMTYPE|translate)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="purchase-pg-price-total">
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Price.Total'|translate">최종 결제 금액</div>
                                <div class="purchase-pg-total-price"> {{(withComma(priceinfo.total)
                                    =='0')&& (currencykey ==='Common.USD')? '0.00':
                                    withComma(priceinfo.total)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </div>
                        <div class="purchase-pg-price-method">
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Receipt.PaymentMethod'|translate">결제수단</div>
                                <div class="purchase-pg-info"> <span [innerHTML]="(payMethod !== '') ? (payMethod| translate)
                                    :  'Order.Etc'|translate:{etc:etc}">페이팔</span>
                                    <span *ngIf="bank !== ''"> {{bank}}</span>
                                    <span *ngIf="point !== ''" [innerHTML]="point|translate"></span>
                                </div>
                            </div>
                            <div>
                                <div class="purchase-pg-label"
                                    [innerHTML]="'Receipt.ApprovalNo'|translate">승인번호</div>
                                <div class="purchase-pg-info">
                                    <div #apply_num style="opacity: 1;">{{applyNum === ''? '-' :
                                        applyNum}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="purchase-pg-outer purchase-refund-outer" *ngIf="refundinfo.list.length>0">
                <div class="purchase-pg-inner">
                    <div class="purchase-pg-header">
                        <div class="purchase-pg-title">
                            <span [innerHTML]="'Refund.RefundInfo'|translate">환불 정보</span>
                        </div>
                    </div>

                    <div class="purchase-refund-pg-details">
                        <div class="purchase-refund-details">
                            <div *ngFor="let item of refundinfo.list;let index=index">
                                <div>
                                    <div class="purchase-refund-info">
                                        <div class="purchase-refund-label"
                                            [innerHTML]="'Refund.PurchaseCanceled'|translate">구매 취소
                                        </div>
                                        <div class="purchase-refund-date">{{item.time}}</div>
                                    </div>
                                    <div class="purchase-refund-price">
                                        <div class="purchase-refund-coupon"
                                            *ngIf="item.amount === 0">
                                            <img src="/assets/icon/coupon_24.svg">
                                        </div>
                                        <div>
                                            <span
                                                *ngIf="item.amount !== 0">-</span>{{(withComma(item.amount)
                                            =='0') && (currencykey === 'Common.USD')? '0.00':
                                            withComma(item.amount)}}
                                            <span [innerHTML]="currencykey|translate">원</span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="purchase-refund-total">
                            <div>
                                <div class="purchase-refund-total-price"
                                    [innerHTML]="'Refund.RefundTotal'|translate">총 환불 금액</div>
                                <div class="purchase-refund-final">
                                    <span *ngIf="refundinfo.total !== 0">-</span>
                                    {{(withComma(
                                    refundinfo.total)) == '0' && (currencykey ==='Common.USD')?
                                    '0.00': withComma(refundinfo.total)}}
                                    <span [innerHTML]="currencykey|translate">원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="purchase-reference-outer">
                <div>
                    <span class="purchase-reference-title"
                        [innerHTML]="'Refund.DescTitle'|translate">안내사항</span>
                </div>
                <ul style="margin-top:8px;">
                    <li [innerHTML]="'Refund.Desc1'|translate"></li>
                    <li [innerHTML]="'Refund.Desc2'|translate"></li>
                    <li [innerHTML]="'Refund.Desc3'|translate"></li>
                    <li [innerHTML]="'Refund.Desc4'|translate"></li>
                </ul>
            </div>

            <div class="common-horizontal-alignment">
                <button class="common-button2 purchase-button-more" style="margin-top:64px;"
                    (click)="back()" [innerHTML]="'Order.GoBack'|translate">돌아가기</button>
            </div>

        </div>
    </div>
</main>