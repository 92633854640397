import { Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { CookieUtil, UtilityService } from 'src/app/lib/util';

@Component({
    selector: 'app-signin-ui',
    templateUrl: './signin-ui.component.html',
    styleUrls: ['signin-ui.component.scss'],
})

export class SigninUIComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('root') rootelement!: ElementRef<HTMLDivElement>;
    @ViewChild('form') formelement!: ElementRef<HTMLFormElement>;
    @Input() idPrefix = 'ui_component_signin_';
    @Output() closed = new EventEmitter<boolean>();

    desc = '';

    constructor(
        public translate: TranslateService,
        private client: ApiClientService,
        private util: UtilityService,
        private router: Router
    ) { }

    urlToGo: string = '';
    openPopupState: boolean = false;

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.client.openPopupSubject.subscribe((value) => {
            let url = this.router.url;
            if (url.startsWith('/en') || url.startsWith('/ko')) url = url.substring(3);
            this.urlToGo = value.url === '' ? url : value.url;
            this.openPopupState = value.openPopup
        });
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

    async signIn() {
        const emailInput = document.getElementById(this.idPrefix + 'email') as HTMLInputElement;
        const email = emailInput.value;
        const passwordInput = document.getElementById(this.idPrefix + 'password') as HTMLInputElement;
        const password = passwordInput.value;

        if (email.length === 0) {
            this.desc = await this.util.getTranslation('Signin.EmailCheck');
            return;
        } else if (password.length === 0) {
            this.desc = await this.util.getTranslation('Signin.PasswordCheck');
            return;
        }

        const res = await this.client.api.Login.send({ email, password });
        console.log(res);
        if (res.message == 'ok') {
            CookieUtil.setCookie('lC', true);
            this.client.api.GetUserinfo.send();
            if (this.openPopupState === true) this.router.navigateByUrl(this.urlToGo);
            //로그인시 expand 방지
            this.client.openPopupSubject.next({ url: '', openPopup: false });

        } else if (res.message.includes('email not verified')) {
            emailInput.value = '';
            passwordInput.value = '';
            this.client.openPopupSubject.next({ url: '', openPopup: false });
            this.router.navigateByUrl('/(popup:please-verify/' + email + ')');

        } else {
            this.desc = await this.util.getTranslation('Signin.SigninError');
            emailInput.value = '';
            passwordInput.value = '';
            emailInput.focus();
        }
    }

    async validation() {

        const form = this.formelement.nativeElement;
        const idx = 0;
        const emailElement = <HTMLInputElement>document.getElementById('ui_component_signin_email')
        const passwordElement = <HTMLInputElement>document.getElementById('ui_component_signin_password')


        if (!emailElement.value) {
            (form.elements[idx + 0] as HTMLInputElement).focus();
            this.desc = await this.util.getTranslation('Signin.EmailCheck');
            return;
        } else if (!passwordElement.value) {
            (form.elements[idx + 1] as HTMLInputElement).focus();
            this.desc = await this.util.getTranslation('Signin.PasswordCheck');
            return;
        } else {
            this.signIn();
        }
    }

    close() {
        this.client.openPopupSubject.next({ url: '', openPopup: false });
    }


    hideDropdown() {
        this.closed.emit(false);
    }

    async goToSignup() {
        await this.router.navigateByUrl('/signup')
        this.client.openPopupSubject.next({ url: '', openPopup: false });
    }

    async resetPasswordPage() {

        await this.client.openPopupSubject.next({ url: '', openPopup: false });
        this.router.navigate(['/', { outlets: { popup: ['request-password-reset'] } }])
    }
}
