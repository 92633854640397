import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/lib/api-client.service';

@Component({
    selector: 'app-popup-nav',
    templateUrl: './popup-nav.component.html'
})
export class PopupNavComponent implements OnInit {

    @Input() title = '';
    @Input() popup = '';

    constructor(
        private router: Router,
        private client: ApiClientService
    ) { }

    ngOnInit(): void {

    }

    close() {
        if (this.popup === 'login' || this.popup === 'mypage') {
            this.client.openPopupSubject.next({ url: '', openPopup: false });
        } else {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
        }
    }

}


