import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter } from 'rxjs';
import { ApiClientService } from 'src/app/lib/api-client.service';
import { ResizeService, SCREEN_SIZE } from 'src/app/lib/resize.service';
import { AddSearchBehavior, CategoryFilterStyler, ClearFilter, ClearSearchBehavior, ClearSearchFilter, ClickDeleteSearchBehavior, Filter, SearchBar, SearchCondition, SetPolygon, SetPrice, toggleGameReady } from 'src/app/lib/searchtool.service';
import { UtilityService } from 'src/app/lib/util';
import { Data } from 'src/server/lib/types';
import { CommonUtil } from 'src/server/lib/util';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {

    @Input() sort = 'recent';
    @Input() categoryType = 'all';
    @Input() searchcondition = this.client.searchSubject.value;

    @ViewChild('filter_header') mo_header!: ElementRef<HTMLDivElement>;
    @ViewChild('polygoncount') polygoncount!: ElementRef<HTMLInputElement>;

    @Output() openFilter = new EventEmitter<boolean>();

    showCategory = false;
    showPolygonOptions = false;
    showSortOptions = false;

    navigationEndSubscription!: Subscription;
    onLangChangeSub: Subscription | null = null;

    minValue = 0;
    maxValue = 400;
    readonly MAX_AMOUNT = 400;
    priceGap = this.maxValue / 100;
    width = 150;

    withComma = CommonUtil.withComma;
    screenSize = this.resizeSvc.getCurrent();

    polygonList: { name: string, condition: [number, number] }[] = [{ name: '0 to 10k', condition: [1, 10000] }, { name: '10k to 100k', condition: [10000, 100000] }, { name: '100k+', condition: [100000, 999999999] }];
    sortList: { name: string, value: string }[] = [{ name: 'Search.Newest', value: 'recent' }, { name: 'Search.Oldest', value: 'old' }, { name: 'Search.Popularity', value: 'popular' }, { name: 'Search.HighestPrice', value: 'highprice' }, { name: 'Search.LowestPrice', value: 'lowprice' }]

    currency = this.util.getCurrentCurrency();
    currencykey = 'Common.' + this.currency;
    currentLang = this.util.getCurrentLang();

    resizeSubscription!: Subscription;

    hasSubcategory(searchResult: SearchCondition, sub: Data.subCateogry | string[]) {
        return searchResult.categoryInfo.subCategory.some((subCategory: string) =>
            sub.includes(subCategory)
        );
    }

    subCategoryList: Data.subCateogry[]
        = ["clothings", "top", "bottom", "outer", "footwear", "accessories",
            "oriental/traditional", "electronics", "nature", "food", "toys/hobby",
            "interior", "exterior", "others"]


    constructor(
        public router: Router,
        private resizeSvc: ResizeService,
        private client: ApiClientService,
        private filterSearch: Filter,
        private translate: TranslateService,
        private util: UtilityService
    ) {
        this.resizeSubscription = this.resizeSvc.onResize$.subscribe(size => {
            this.screenSize = size
            if (this.screenSize !== SCREEN_SIZE.mobile) {
                document.body.classList.remove('no-scroll');
                if (this.showCategory) {
                    this.openFilter.emit(false);
                } else {
                    this.openFilter.emit(true);
                }

            } else {
                if (this.showCategory) {
                    this.openFilter.emit(true);
                    document.body.classList.add('no-scroll');
                } else {
                    this.openFilter.emit(false);
                    document.body.classList.remove('no-scroll');
                }

            }
        });

    }

    ngOnInit(): void {
        window.addEventListener('click', this.clickOutside.bind(this));
        this.onLangChangeSub = this.translate.onLangChange.subscribe(async (val) => {
            if (this.currentLang != val.lang) {
                this.filterSearch.setSearchBehavior(new ClearSearchBehavior());
                await this.filterSearch.performSearch();
            }
            this.currentLang = val.lang as Data.LanguageType;
            this.currency = this.util.getCurrentCurrency();
            this.currencykey = 'Common.' + this.currency;
            CategoryFilterStyler.initCategoryFilterStyle();
            this.initPrice();
        });

    }

    async ngOnDestroy() {

        window.removeEventListener('click', this.clickOutside);

        if (this.onLangChangeSub) {
            this.onLangChangeSub.unsubscribe();
        }
        this.filterSearch.setFilterBehavior(new ClearSearchFilter());
        await this.filterSearch.performFilter();

        this.resizeSubscription.unsubscribe();

    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['categoryType']) {

            this.initPrice();
            this.currentPolygonIdx = -1;
            if (this.polygoncount !== undefined) this.polygoncount.nativeElement.value = await this.util.getTranslation('Sidebar.PolyCount')

            CategoryFilterStyler.initFilterStyle();
        }
    }

    currentPolygonIdx = -1;
    async setPolygon(index: number) {
        if (this.currentPolygonIdx == index) {
            this.currentPolygonIdx = -1;
        }
        else {
            this.currentPolygonIdx = index;
        }

        if (this.currentPolygonIdx === 3) this.polygoncount.nativeElement.value = await this.util.getTranslation('Sidebar.PolyCount');
        this.showPolygonOptions = false;

        this.filterSearch.setFilterBehavior(new SetPolygon());
        await this.filterSearch.performFilter(this.currentPolygonIdx);

    }

    initPrice() {
        this.minValue = 0;
        this.maxValue = this.setPrice(this.MAX_AMOUNT);
    }

    setPrice(value: number): number {
        if (this.currency === 'USD') {
            return value
        } else {
            return value * 1000;
        }
    }


    async setPriceRange(event: Event | MouseEvent) {

        const rangeWrap = (event.target as Element).closest('.filter-range-wrap');
        if (rangeWrap === null) return;
        const rangeWrapRect = rangeWrap.getBoundingClientRect();

        const minInput = (document.getElementById('rangeMin') as HTMLInputElement);
        const maxInput = (document.getElementById('rangeMax') as HTMLInputElement);

        const thumbLeft = (document.getElementById('thumb-left') as HTMLDivElement);
        const thumbRight = (document.getElementById('thumb-right') as HTMLDivElement);
        const range = (document.getElementById('filter-range') as HTMLDivElement);

        const target = (event.target as HTMLInputElement);
        const [min, max] = [parseInt(target.min), parseInt(target.max)];

        // const current = (event as MouseEvent).clientX - rangeWrapRect.left;
        const position = (event as MouseEvent).clientX - rangeWrapRect.left;
        let targetPercent = Math.round(position / rangeWrapRect.width * 100);

        const average = (Number(minInput.value), + Number(maxInput.value)) / 8;


        if (target.id === 'rangeMin') {
            target.value = String(Math.min(parseInt(target.value), parseInt(maxInput.value) - 1));

            const percent = Number(target.value) / 4

            thumbLeft.style.left = percent + "%";
            range.style.left = percent + "%";

            this.minValue = this.setPrice(Number(target.value));
        } else if (target.id === 'rangeMax') {
            (event.target as HTMLInputElement).value = String(Math.max(parseInt(target.value), parseInt(minInput.value) + 1));

            const percent = Number(target.value) / 4
            thumbRight.style.right = 100 - percent + "%";
            range.style.right = 100 - percent + "%";

            this.maxValue = this.setPrice(Number(target.value));
        }
        else {
            if (targetPercent < 0) {
                minInput.value = '0';
                thumbLeft.style.left = 0 + "%";
                range.style.left = 0 + "%";
                this.minValue = 0;
            } else if (targetPercent > 100) {
                maxInput.value = '400';
                thumbRight.style.right = 0 + "%";
                range.style.right = 0 + "%";
                this.maxValue = this.setPrice(400000);
            }
            //들어오는 position값이 현재 input위치들의 average 중간값보다 큰지 아닌지 구하는 이유?
            //-> 왼쪽 input에 가까운지 오른쪽 output에 가까운지를 계산하여 가까운쪽의 input을 이동시키기위하여
            if (targetPercent < average) {
                if (targetPercent < 0) {
                    minInput.value = '0';
                    thumbLeft.style.left = 0 + "%";
                    range.style.left = 0 + "%";
                    this.minValue = 0;
                }
                targetPercent = (Math.min(targetPercent * 4, parseInt(maxInput.value) - 1)) / 4;
                console.log(targetPercent)
                minInput.value = String(targetPercent * 4);
                thumbLeft.style.left = targetPercent + "%";
                range.style.left = targetPercent + "%";

                this.minValue = this.setPrice(targetPercent * 4);
            } else {
                targetPercent = (Math.max(targetPercent * 4, parseInt(minInput.value) + 1)) / 4;
                maxInput.value = String(targetPercent * 4);
                thumbRight.style.right = 100 - targetPercent + "%";
                range.style.right = 100 - targetPercent + "%";

                this.maxValue = this.setPrice(targetPercent * 4);
            }
        }

        if (event.type === 'mouseup') {
            this.filterSearch.setFilterBehavior(new SetPrice());
            await this.filterSearch.performFilter({ minprice: this.minValue, maxprice: this.maxValue });
        }
    }


    async toggleGameReady() {
        this.filterSearch.setFilterBehavior(new toggleGameReady());
        this.filterSearch.performFilter();
    }

    async clearFilter() {
        this.filterSearch.setFilterBehavior(new ClearFilter());
        await this.filterSearch.performFilter();

        this.initPrice();
        this.currentPolygonIdx = -1;
        this.polygoncount.nativeElement.value = await this.util.getTranslation('Sidebar.PolyCount')

        CategoryFilterStyler.initFilterStyle();
    }

    setSearchConditionSort(sort: string) {
        const oldsort = this.searchcondition.sort;
        switch (sort) {
            case 'recent':
                this.searchcondition.sort = Data.ESortBy.Recent;
                break;
            case 'old':
                this.searchcondition.sort = Data.ESortBy.Old;
                break;
            case 'popular':
                this.searchcondition.sort = Data.ESortBy.Popular;
                break;
            case 'lowprice':
                this.searchcondition.sort = Data.ESortBy.LowPrice;
                break;

            case 'highprice':
                this.searchcondition.sort = Data.ESortBy.HighPrice;
                break;
            default:
                // handle default case
                break;
        }

        if (this.screenSize !== 0) this.showSortOptions = false;


        if (this.searchcondition.sort !== oldsort) return true;
        return false;
    }

    // clickToolOutside(event: Event) {
    //     if (this.screenSize === SCREEN_SIZE.mobile) return;
    //     let target = event.target as Node;
    //     const searchOuter = this.searchouterRef.nativeElement;
    //     const suggestedTool = document.querySelector('.main-suggested-search-outer');
    //     const searchTag = document.getElementById('user-search-tag') as HTMLButtonElement;
    //     const input = this.searchtextRef.nativeElement;

    //     if (!searchOuter?.contains(target) && !suggestedTool?.contains(target) && !searchTag?.contains(target)) {
    //         this.hideSearchTool();
    //         if (!this.searchCondition.searchtext.length && !input.value.length) {
    //             this.showSearchIcon = true;
    //         }
    //         if (this.keyboardSubscription !== undefined) {
    //             this.keyboardSubscription.unsubscribe();
    //         }
    //     }
    // }

    clickOutside(event: Event) {
        let target = event.target as Node;
        const mainfilter = document.getElementById('dropdown-main-filter')
        const subfilter = document.getElementById('dropdopwn-sub-filter')
        const dropdown = document.getElementById('main-filter-dropdown')

        const polygon = document.getElementById('main-polygon-input')
        const sort = document.getElementById('main-sort-input')

        if (this.screenSize === 2) {

            if (this.showCategory && !mainfilter?.contains(target) && !dropdown?.contains(target)) {
                this.showCategory = false;
            }

            if (this.showPolygonOptions && !polygon?.contains(target)) {
                this.showPolygonOptions = false;
            }

            if (this.showSortOptions && !sort?.contains(target)) {
                this.showSortOptions = false;
            }
        } else if (this.screenSize === 1) {
            console.log(this.showCategory, !mainfilter?.contains(target), !subfilter?.contains(target), !dropdown?.contains(target))
            if (this.showCategory && !mainfilter?.contains(target) && !subfilter?.contains(target) && !dropdown?.contains(target)) {
                this.showCategory = false;
            }
            if (this.showSortOptions && !sort?.contains(target)) {
                this.showSortOptions = false;
            }

        }

    }


    onChangeSort(idx: number) {
        if (this.setSearchConditionSort(this.sortList[idx].value)) {
            this.client.searchSubject.next(this.searchcondition);
        }
    }

    toggleMobileFilter(open: boolean) {

        this.openFilter.emit(open);

        if (open) {
            this.showCategory = true;
            document.body.classList.add('no-scroll');

        } else {
            this.showCategory = false;
            document.body.classList.remove('no-scroll');

        }

    }

}
